import { TypeState } from './../../../store/type/type.state';
import { generateID } from 'src/app/utils/fakers';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
} from '@angular/core';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import { ActivityService } from 'src/app/services/activity.service';
import { Select, Store } from '@ngxs/store';
import {
  catchError,
  combineLatest,
  concatMap,
  from,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  retry,
  Subscriber,
  Subscription,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { Title } from '@angular/platform-browser';
import {
  ActivityQuestion,
  ActivityType,
} from 'src/app/models/activities/activity.models';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateActivityOptionsComponent } from '../../core/activities/create-activity-options/create-activity-options.component';
import { CATEGORY_DIC } from 'src/app/utils/options';
import { PayloadOption } from 'src/app/models/questionOption.model';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MetaDataService } from 'src/app/services/metadata.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { ModalComponent } from '../../core/modal/modal.component';
import { toPlural, toSingular } from 'src/app/utils/categoriesFormatters';
import { ViewportScroller } from '@angular/common';

interface Parameter {
  id: string;
  label: string;
  value: number;
}

interface Category {
  id: string;
  label: string;
  parameters: Parameter[];
}

export interface Option {
  id: number;
  name: string;
  body: string;
  is_system_created: boolean;
  question: number;
  parametersId: string;
  categories: Category[];
  showCategories: boolean;
  is_correct: boolean;
}

interface ParameterValue {
  parameter_key: string;
  value: number;
}

interface ParameterDetails {
  id: number;
  parameter_key: string;
  values: ParameterValue[];
}

interface CategoryDic {
  [key: string]: {
    label: string;
    id: string;
  };
}

interface Category {
  id: string;
  label: string;
  parameters: Parameter[];
}

interface MainObject {
  id: number;
  name: string;
  body: string;
  is_system_created: boolean;
  question: number;
  [key: string]: any; // To allow dynamic access to properties
}

type DataObject = {
  id: string;
  label: string;
  parameters: Parameter[];
};

const data: DataObject[] = [
  // Your data here
];

@Component({
  templateUrl: './new-question-page.component.html',
  styleUrls: ['./new-question-page.component.scss'],
})
export class NewQuestionPageComponent implements OnInit, AfterViewInit {
  @Select(TypeState) type$!: Observable<any>;
  @ViewChild('modalNotification') modalNotification!: ElementRef;
  @ViewChild(ModalComponent) modal!: ModalComponent;
  activityOptionsForm!: CreateActivityOptionsComponent;
  private contentId!: number;
  private themeId!: number;
  private subthemeId!: number;
  private activityId!: number;
  public questionId!: number;
  public categoriesSelected: Array<Record<string, string>> = [];
  notificationMessage!: string;
  inProcess: boolean = false;
  selectedType!: string | null;
  active: boolean = false;
  public activityCreationForm: FormGroup = this.formBuilder.group({
    name: this.formBuilder.control(null, [Validators.required]),
    typeSelected: this.formBuilder.control(null, Validators.required),
    statement: this.formBuilder.control('', []),
    includeVideo: this.formBuilder.control(false, []),
    video: this.formBuilder.control('', []),
  });
  public errors: Array<{ title: string }> = [];

  public optionActivityCreationForm: FormGroup = this.formBuilder.group({
    min: this.formBuilder.control(1, []),
    max: this.formBuilder.control(1, []),
    is_ponderable: this.formBuilder.control(false, []),
    options: this.formBuilder.array<
      FormArray<
        FormGroup<{
          id: FormControl;
          body: FormControl;
          name: FormControl;
          delete: FormControl;
          showCategories: FormControl;
          categories: FormArray<
            FormGroup<{
              id: FormControl;
              label: FormControl;
              value: FormControl;
              parameters: FormArray<
                FormGroup<{
                  id: FormControl;
                  label: FormControl;
                  value: FormControl;
                }>
              >;
            }>
          >;
        }>
      >
    >([]),
  });

  optionTypes: Array<{ id: string; type: string | null; title: string }> = [
    {
      id: generateID(),
      title: 'Rango',
      type: 'range',
    },
    {
      id: generateID(),
      title: 'Checkbox',
      type: 'checkbox',
    },
  ];

  types: Array<ActivityType> = [
    {
      id: generateID(),
      title: 'Texto simple',
      slug: 'text',
      code: 'FREE_TEXT',
    },
    {
      id: generateID(),
      title: 'Video',
      slug: 'video',
      code: 'WATCH',
    },
    {
      id: generateID(),
      title: 'Lista de valores',
      slug: 'list',
      code: 'VALUES_LIST',
    },
    {
      id: generateID(),
      title: 'Selección simple',
      slug: 'simple-selection',
      code: 'SELECT_ANSWER',
    },
    {
      id: generateID(),
      title: 'Selección multiple',
      slug: 'multiple-selection',
      code: 'SELECT_MULTIPLES_ANSWERS',
    },
    {
      id: generateID(),
      title: 'Selección multiple (Evaluación)',
      slug: 'multiple-selection-evaluation',
      code: 'TEST',
    },
    {
      id: generateID(),
      title: 'Valoración regular',
      slug: 'regular-valoration',
      code: 'DISCRETE_NUMERIC_ANSWER',
    },
    {
      id: generateID(),
      title: 'Valoración por porcentaje',
      slug: 'percentual-valoration',
      code: 'CONTINUOUS_NUMERIC_ANSWER',
    },
  ];

  public jazzdooParameters = [
    {
      label: 'Objetividad de Visión Periférica',
      value: 'PERIPHERAL_VISION_OBJECTIVITY',
    },
    { label: 'Paternalismo', value: 'PATERNALISM' },
    { label: 'Capacidad de Síntesis', value: 'SYNTHESIS_CAPACITY' },
    { label: 'Naturalidad', value: 'NATURALNESS' },
    { label: 'Amplitud de Aprendizaje', value: 'LEARNING_AMPLITUDE' },
    { label: 'Subjetividad', value: 'SUBJECTIVITY' },
    { label: 'Pensamiento Estratégico', value: 'STRATEGIC_THINKING' },
  ];

  scheinOptions: Array<any> = [
    {
      name: 'Estilo de vida',
      body: 'Prioriza la vida personal sobre el trabajo, buscando un equilibrio entre ambos.',
      param: 'life_style',
    },
    {
      name: 'Seguridad',
      body: 'Prefiere la estabilidad laboral y evitar riesgos, con el objetivo de tener un empleo a largo plazo.',
      param: 'security',
    },
    {
      name: 'Creatividad',
      body: 'Busca innovar y generar nuevas ideas o proyectos.',
      param: 'creativity',
    },
    {
      name: 'Reto',
      body: 'Le motiva enfrentarse a desafíos difíciles y competitivos.',
      param: 'challenge',
    },
    {
      name: 'Organizar',
      body: ' Le interesa coordinar equipos, liderar y tener influencia sobre otros.',
      param: 'organize',
    },
    {
      name: 'Técnico/funcional',
      body: 'Desea ser valorado por sus habilidades y conocimientos especializados.',
      param: 'functional_technical',
    },
    {
      name: 'Autonomía',
      body: 'Busca independencia y libertad para trabajar a su propio ritmo.',
      param: 'autonomy',
    },
    {
      name: 'Servicio',
      body: 'Se siente comprometido con el bienestar común y el trabajo orientado a ayudar a los demás.',
      param: 'service',
    },
  ];
  scheinStatement: string =
    'A continuación, te presentamos una herramienta para reflexionar sobre lo que más valoras en tu carrera profesional. El objetivo es que distribuyas 40 puntos entre las siguientes 8 opciones, asignando más puntos a las que consideres más importantes y menos a las que no lo son tanto. Esta actividad te ayudará a identificar tus principales motivaciones laborales.';

  public activity?: ActivityQuestion;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public categoriesToSelect: Array<Record<string, string>> = [];
  public optionSubscriptions: Array<Subscription> = [];
  public categoriesToDelete: Array<FormGroup> = [];

  constructor(
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private router: Router,
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private formBuilder: FormBuilder,
    private metaDataService: MetaDataService,
    private store: Store,
    private viewportScroller: ViewportScroller
  ) {
    this.title.setTitle('Nueva Pregunta | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear pregunta',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit() {
    this.contentId = parseInt(this.route.snapshot.paramMap.get('contentId')!);
    this.themeId = parseInt(this.route.snapshot.paramMap.get('themeId')!);
    this.subthemeId = parseInt(this.route.snapshot.paramMap.get('subthemeId')!);
    this.activityId = parseInt(this.route.snapshot.paramMap.get('activityId')!);
    this.questionId = parseInt(this.route.snapshot.paramMap.get('questionId')!);
    if (this.questionId) {
      this.title.setTitle('Editar Pregunta | Backoffice - Growth Road');
      this.creationalPagesLayoutService.layoutConfig.next({
        headerTitle: 'Editar pregunta',
        headerTitleIcon: '/assets/images/icons/icon_edit_black.svg',
        footerConfig: {
          submitButtonText: 'Guardar',
          dismissButtonText: 'Cancelar',
          deleteButtonText: 'Eliminar',
        },
      });

      (async () => {
        const activity = await this.activityService.getActivityById(
          this.questionId.toString()
        );

        if (activity && activity.type === 'JAZZDOO') {
          this.categoriesToSelect = this.jazzdooParameters.map((param) => ({
            ...param,
            iconUrl: '/assets/images/icons/icon_selector.svg',
          }));
        }

        // ! (ACTIVITY) CREATION FORM
        this.activityCreationForm.patchValue({
          ...activity,
          typeSelected: activity.type,
          statement: activity.description,
          includeVideo: !!activity.video_url,
          video: activity.video_url,
        });
        // ! (OPTION) ACTIVITY CREATION FORM
        this.optionActivityCreationForm?.patchValue({
          is_ponderable: activity.is_ponderable,
          min: activity?.options_min,
          max: activity?.options_max,
        });

        const actOptsFltrd = activity.options.filter(
          (option: Option) => option.name.toLowerCase() !== 'peso'
        );

        // ! SET FORM GROUP PER OPTION
        for (let optnsN = 0; optnsN < actOptsFltrd.length; optnsN++) {
          this.addOption();
        }

        actOptsFltrd.forEach((option: Option, index: number) => {
          const optFmGp = (
            this.optionActivityCreationForm.get('options') as FormArray
          ).at(index) as FormGroup;

          if (activity.type === 'JAZZDOO') {
            console.log('OPTION', option);
            optFmGp.patchValue({
              ...option,
              categories: option.categories.filter(
                (category) => category.id === 'jazzdoos'
              ),
            });
            console.log('ACTOPTSFLTRD', this.getCommonParameters(actOptsFltrd));

            const commonParam = this.getCommonParameters(
              actOptsFltrd
            )[0] as string;

            this.patchOptionCategories(
              option.categories.filter(
                (category) => category.id === 'jazzdoos'
              ),
              optFmGp,
              this.jazzdooParameters.filter(
                (option) => option.value.toLowerCase() === commonParam
              )
            );
          } else {
            optFmGp.patchValue({
              ...option,
            });

            this.patchOptionCategories(option.categories, optFmGp);
          }

          setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]));
        });
      })();
    }

    combineLatest([
      (this.optionActivityCreationForm.get('min') as FormControl).valueChanges,
      (this.optionActivityCreationForm.get('max') as FormControl).valueChanges,
    ]).pipe(
      map(([min, max]) => ({ min, max })),
      tap(({ min, max }) => {
        this.optionActivityCreationForm
          .get('min')
          ?.addValidators([
            Validators.max(max),
            Validators.min(
              (this.optionActivityCreationForm.get('options') as FormArray)
                .value.length
            ),
          ]);

        this.optionActivityCreationForm
          .get('max')
          ?.addValidators([
            Validators.max(
              (this.optionActivityCreationForm.get('options') as FormArray)
                .value.length
            ),
            Validators.min(min + 1),
          ]);
      }),
      takeUntil(this.destroyed$)
    );

    (
      this.optionActivityCreationForm.get('is_ponderable') as FormControl
    ).valueChanges
      .pipe(
        tap(() => {
          const optionsCreated = this.optionActivityCreationForm.get(
            'options'
          ) as FormArray;
          optionsCreated.controls.forEach((optionGroup) => {
            const optionCategories = optionGroup.get('categories') as FormArray;
            optionCategories.clear();
          });
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    (this.activityCreationForm.get('typeSelected') as FormControl).valueChanges
      .pipe(
        tap(() => {
          (this.activityCreationForm.get('video') as FormControl).setValue(
            null
          );
          (
            this.activityCreationForm.get('includeVideo') as FormControl
          ).setValue(false);
          (this.activityCreationForm.get('statement') as FormControl).setValue(
            ''
          );
          (this.optionActivityCreationForm as FormGroup).reset();
          (this.optionActivityCreationForm.get('options') as FormArray).clear();
          (this.optionActivityCreationForm.get('options') as FormArray).clear();
          this.categoriesSelected = [];

          if (
            this.activityCreationForm.get('typeSelected')!.value === 'SCHEIN'
          ) {
            this.activityCreationForm
              .get('statement')
              ?.setValue(this.scheinStatement);
            this.scheinOptions.forEach((scheinOption) => {
              this.addOption({
                body: scheinOption.body,
                name: scheinOption.name,
                param: scheinOption.param,
              });
            });
          }

          if (
            this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO'
          ) {
            this.optionActivityCreationForm
              .get('is_ponderable')
              ?.setValue(true);

            this.categoriesToSelect = this.jazzdooParameters.map((param) => ({
              ...param,
              iconUrl: '/assets/images/icons/icon_selector.svg',
            }));

            if (!this.questionId) {
              [
                'No, nunca',
                'Rara vez',
                'A veces',
                'A menudo',
                'Sí, siempre',
              ].forEach((_, index) => {
                this.addOption({
                  body: _,
                  name: _,
                });
              });
            }
          } else {
            this.categoriesToSelect = this.metaDataService
              .getAllParameters()
              .map((category) => ({
                value: category.category_string_id,
                label: category.category_name,
                iconUrl: '/assets/images/icons/icon_selector.svg',
              }));
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();

    (this.activityCreationForm as FormGroup).valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => (this.errors = []));

    setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]));
  }

  private getCommonParameters(options: any[]): string[] {
    const parameterCounts: { [key: string]: Set<number> } = {};

    options.forEach((option) => {
      const optionId = option.id;

      option.categories.forEach((category: any) => {
        // Access the first object in the parameters array
        const parameterObj = category.parameters[0];
        if (parameterObj) {
          Object.keys(parameterObj).forEach((key) => {
            if (key !== 'id' && key !== 'value') {
              const value = parameterObj[key];
              if (value !== 0) {
                if (!parameterCounts[key]) {
                  parameterCounts[key] = new Set();
                }
                parameterCounts[key].add(optionId);
              }
            }
          });
        }
      });
    });

    // Collect parameters that appear in at least two options
    const commonParameters = Object.keys(parameterCounts).filter(
      (key) => parameterCounts[key].size >= 2
    );

    return commonParameters;
  }

  ngAfterViewInit(): void {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.handleSubmitQuestion();
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate([
            `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
          ]);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete(this.questionId);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get optionsFormArray() {
    return this.optionActivityCreationForm.get('options') as FormArray;
  }
  /**
   * Retrieves keys from the given object that have values different than zero,
   * excluding the specified keys ('id' and 'value').
   *
   * @param obj - The object to inspect.
   * @param excludeKeys - An array of keys to exclude from the result.
   * @returns An array of keys with non-zero values, excluding the specified keys.
   */
  private getNonZeroKeys(
    obj: Record<string, number>,
    excludeKeys: string[] = ['id', 'value']
  ): string[] {
    if (!obj || typeof obj !== 'object') {
      console.warn('Invalid input: Expected an object.');
      return [];
    }

    return Object.entries(obj)
      .filter(
        ([key, value]) =>
          !excludeKeys.includes(key) && typeof value === 'number' && value !== 0
      )
      .map(([key]) => key);
  }
  private patchOptionCategories(
    categories: Array<Category>,
    optFmGrp: FormGroup,
    selectedCategoriesCustom?: Array<Record<string, string>>
  ): void {
    categories.forEach((category: Category) => {
      const categoryID = toSingular(category.id);
      this.fillSelectedCategories(categoryID, category);
      if (this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO') {
        const parameterSelectedKey = (
          selectedCategoriesCustom as Array<Record<string, string>>
        )[0]['value'].toLowerCase();

        console.log('parameterSelectedKey', parameterSelectedKey);
        const categoryGroup = this.formBuilder.group({
          id: [parameterSelectedKey],
          label: [
            this.jazzdooParameters.find(
              (param) => param.value.toLowerCase() === parameterSelectedKey
            )?.label,
          ],
          value: [
            this.jazzdooParameters.find(
              (param) => param.value.toLowerCase() === parameterSelectedKey
            )?.value,
          ],
          parameters: this.formBuilder.array(
            this.getCategoryParameters(
              this.jazzdooParameters.find(
                (param) => param.value.toLowerCase() === parameterSelectedKey
              )?.value as string
            ).map((param: { id: string; label: string }) => {
              return this.formBuilder.group({
                id: param.id,
                label: [param.label],
                value: [
                  category.parameters.map((userParam: any) => {
                    const keyId = Object.keys(userParam).find(
                      (paramKey: string) => paramKey === param.id.toLowerCase()
                    ) as string;
                    return userParam[keyId];
                  })[0],
                ],
              });
            })
          ),
        });
        (optFmGrp.get('categories') as FormArray).push(categoryGroup);
        this.optionsFormArray.updateValueAndValidity();
      } else {
        if (category.parameters.length > 0) {
          const categoryGroup = this.formBuilder.group({
            id: [categoryID],
            label: [category.label],
            value: [categoryID],
            parameters: this.formBuilder.array(
              this.getCategoryParameters(categoryID).map(
                (param: { id: string; label: string }) =>
                  this.formBuilder.group({
                    id: param.id,
                    label: [param.label],
                    value: [
                      category.parameters.map((userParam: any) => {
                        const keyId = Object.keys(userParam).find(
                          (paramKey: string) => paramKey === param.id
                        ) as string;
                        return userParam[keyId];
                      })[0],
                    ],
                  })
              )
            ),
          });
          (optFmGrp.get('categories') as FormArray).push(categoryGroup);
          this.optionsFormArray.updateValueAndValidity();
        }
      }
    });
  }

  private fillSelectedCategories(categoryID: string, actCategory: Category) {
    const typeSelected = this.activityCreationForm.get('typeSelected')?.value;

    // Ensure actCategory has parameters
    if (!actCategory.parameters || actCategory.parameters.length === 0) {
      console.warn('No parameters found in actCategory.');
      return;
    }

    if (typeSelected === 'JAZZDOO') {
      const categoryParams = actCategory.parameters[0] as unknown as Record<
        string,
        number
      >;
      console.log('CATEGORY PARAMS', categoryParams);
      this.jazzdooParameters.forEach((param) => {
        const paramKey = param.value.toLowerCase();
        const paramValue = categoryParams[paramKey];

        // Check if the parameter is active (non-zero)
        if (paramValue && paramValue !== 0) {
          const alreadyExists = this.categoriesSelected.some(
            (cat) => cat['value'].toLowerCase() === paramKey.toLowerCase()
          );

          if (!alreadyExists) {
            this.categoriesSelected.push({
              value: param.value,
              label: param.label,
              iconUrl: '/assets/images/icons/icon_selector.svg',
            });
            console.log(`Added category: ${param.label}`);
          } else {
            console.log(`Category already exists: ${param.label}`);
          }
        }
      });
    } else {
      // Handle non-JAZZDOO categories
      this.metaDataService.getAllParameters().forEach((categoryParams) => {
        if (categoryParams.category_string_id === categoryID) {
          const alreadyExist = this.categoriesSelected.find(
            (catFind) => catFind['value'] === categoryID
          );

          if (!alreadyExist && actCategory.parameters.length > 0) {
            this.categoriesSelected.push({
              value: categoryParams.category_string_id,
              label: categoryParams.category_name,
              iconUrl: '/assets/images/icons/icon_selector.svg',
            });
          }
        }
      });
    }

    return this.categoriesSelected;
  }

  createOptionGroup(optionBase: any): FormGroup {
    return this.formBuilder.group({
      body: [optionBase.body],
      name: [optionBase.name],
      showCategories: [optionBase.showCategories],
      // Add other controls here if needed
    });
  }

  public addOption(customDefaults?: {
    body: string;
    name: string;
    param?: string;
  }): void {
    const body = customDefaults
      ? customDefaults.body
      : `Opción por defecto ${this.optionsFormArray.value.length + 1}`;
    const name = customDefaults
      ? customDefaults.name
      : `Nombre por defecto ${this.optionsFormArray.value.length + 1}`;
    const param = customDefaults ? customDefaults.param : '';
    const optionGroup = this.formBuilder.group({
      id: [null],
      _id: generateID(),
      _points: 0,
      schein_parameter: param,
      body: [body, []],
      name: [name, []],
      showCategories: [false],
      delete: [false],
      categories: this.formBuilder.array([]),
      is_correct: [true],
      disabled: [false],
    });

    const optionSubscription = optionGroup.valueChanges.subscribe(() =>
      this.optionsFormArray.updateValueAndValidity()
    );

    if (this.categoriesSelected.length > 0) {
      this.setOptionCategories(optionGroup, this.categoriesSelected);
    }

    this.optionsFormArray.controls.push(optionGroup);
    this.optionsFormArray.updateValueAndValidity();
    this.optionSubscriptions.push(optionSubscription);
  }

  private setOptionCategories(
    optionFormGroup: FormGroup,
    categoriesSelected: any
  ) {
    categoriesSelected.forEach((category: any) => {
      const categoryGroup = this.formBuilder.group({
        id: [category.value],
        label: [category.label],
        value: [category.value],
        parameters: this.formBuilder.array(
          this.getCategoryParameters(category.value).map(
            (param: { id: string; label: string }) =>
              this.formBuilder.group({
                id: param.id,
                label: [param.label],
                value: [1],
              })
          )
        ),
      });
      (optionFormGroup.get('categories') as FormArray).push(categoryGroup);
    });
  }

  private getCategoryParameters(
    categoryId: string
  ): { id: string; label: string }[] {
    const allParametersByCategory = this.metaDataService.getAllParameters();

    const findCategory = allParametersByCategory.find(
      ({ category_string_id }) => category_string_id === categoryId
    );

    if (this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO') {
      const param = this.jazzdooParameters.find(
        (param) => param.value === categoryId
      ) as { label: string; value: string };
      return param
        ? [
            {
              id: param.value,
              label: param.label,
            },
          ]
        : [];
    } else {
      if (findCategory) {
        return findCategory.parameters;
      }
    }

    return [];
  }

  public updateMainObject(
    mainObj: MainObject,
    categories: Category[]
  ): MainObject {
    categories.forEach((category) => {
      if (this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO') {
        if (category.id.toLowerCase() in CATEGORY_DIC) {
          console.log('cat parameters', category.parameters);
          this.updateCategory(
            mainObj['jazzdoos'],
            category.parameters.map((param) => ({
              ...param,
              id: param.id.toLowerCase(),
            }))
          );
        } else {
          console.warn(`Unknown category id: ${toPlural(category.id)}`);
        }
      } else {
        if (toPlural(category.id) in CATEGORY_DIC) {
          this.updateCategory(
            mainObj[toPlural(category.id)],
            category.parameters
          );
        } else {
          console.warn(`Unknown category id: ${toPlural(category.id)}`);
        }
      }
    });
    return mainObj;
  }

  private updateCategory(categoryArray: any[], parameters: Parameter[]) {
    if (categoryArray.length > 0) {
      const item = categoryArray[0];
      parameters.forEach((param) => {
        if (param.id in item) {
          item[param.id] = param.value;
        }
      });
    }
  }

  public extractParameterDetails(
    mainObj: any,
    categoryDic: CategoryDic
  ): ParameterDetails[] {
    const details: ParameterDetails[] = [];

    Object.keys(categoryDic).forEach((categoryKey) => {
      const category = categoryDic[categoryKey];
      const categoryData = mainObj[categoryKey];

      console.log(category, categoryData, categoryKey);

      if (
        categoryData &&
        Array.isArray(categoryData) &&
        categoryData.length > 0
      ) {
        categoryData.forEach((item: any) => {
          const values: ParameterValue[] = Object.keys(item)
            .filter((key) => key !== 'id')
            .map((key) => ({
              parameter_key: key,
              value: item[key],
            }));

          if (values.filter((value) => value.value !== 0).length > 0) {
            details.push({
              id: item.id,
              parameter_key: category.id,
              values,
            });
          }
        });
      }
    });
    console.log('DETAILS', details);
    return details;
  }

  private getOptionsForCreationPayload(questionId: number): PayloadOption[] {
    return this.optionActivityCreationForm
      .getRawValue()
      .options.map((option: any) => ({
        ...option,
        question: questionId,
        parameters: option.categories.map((category: any) => {
          return category.id;
        }),
      }));
  }

  private concatMapAddOption() {
    return concatMap((option: PayloadOption) => {
      if (option.delete && option.id) {
        return this.activityService.removeOptionById(option.id.toString()).pipe(
          map((optionResponse: any) => ({
            optionResponse,
            userOptionCategories: [],
          }))
        );
      } else if (option.id) {
        return this.activityService
          .updateOption(option.id.toString(), option)
          .pipe(
            map((optionResponse: any) => ({
              optionResponse,
              userOptionCategories: option.categories,
            }))
          );
      }
      {
        return this.activityService.addOption(option).pipe(
          map((optionResponse: any) => ({
            optionResponse,
            userOptionCategories: option.categories,
          }))
        );
      }
    });
  }

  private concatMapUpdateOptionParameters() {
    return mergeMap((category: any) => {
      const parameters: any = {};
      category.values.forEach(
        (parameter: any) =>
          (parameters[parameter.parameter_key] = parameter.value)
      );

      return this.activityService.updateParameterByCategoryAndId(
        category.parameter_key,
        category.id,
        parameters
      );
    });
  }

  private handleSubmitQuestion() {
    const formActivityValues = this.activityCreationForm.getRawValue();
    const formOptionsValues = this.optionActivityCreationForm.getRawValue();

    if (this.activityCreationForm.status === 'VALID') {
      if (this.questionId) {
        this.inProcess = true;
        this.activityService
          .updateActivity(this.questionId, {
            activity: this.activityId,
            type: formActivityValues.typeSelected,
            name: formActivityValues.name,
            description: formActivityValues.statement,
            video_url: formActivityValues.video,
            includeVideo: formActivityValues.includeVideo,
            is_published: formActivityValues.published,
            is_archived: false,
            options_min: formOptionsValues.min ?? 1,
            options_max: formOptionsValues.max ?? 1,
            is_ponderable: formOptionsValues.is_ponderable,
          } as any)
          .pipe(
            switchMap((question: any) => {
              if (
                this.optionActivityCreationForm.getRawValue().options.length > 0
              ) {
                return from(
                  this.getOptionsForCreationPayload(question.id)
                ).pipe(
                  this.concatMapAddOption(),
                  switchMap(({ optionResponse, userOptionCategories }) => {
                    return from(
                      userOptionCategories.length > 0
                        ? this.extractParameterDetails(
                            this.updateMainObject(
                              optionResponse,
                              userOptionCategories
                            ),
                            CATEGORY_DIC
                          )
                        : []
                    ).pipe(
                      this.concatMapUpdateOptionParameters(),
                      map((paramsUpdateResponse: any) => ({
                        paramsUpdateResponse,
                      }))
                    );
                  })
                );
              } else {
                return new Observable((observer) => {
                  observer.next({ paramsUpdateResponse: null });
                  observer.complete();
                }).pipe(
                  map((paramsUpdateResponse: any) => ({ paramsUpdateResponse }))
                );
              }
            })
          )
          .subscribe({
            next: () => {
              this.inProcess = false;
            },
            error: (error) => {
              this.inProcess = false;
              this.store.dispatch(
                new OpenModal(`Algo salio mal, intente nuevamente mas tarde.`)
              );
              this.router.navigate([
                `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
              ]);
              console.error('Error:', error);
            },
            complete: () => {
              this.inProcess = false;
              this.store.dispatch(
                new OpenModal(
                  `Pregunta ${
                    this.activityCreationForm.getRawValue().name
                  } actualizada con éxito.`
                )
              );
              this.router.navigate([
                `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
              ]);
            },
          });
      } else {
        this.inProcess = true;
        this.activityService
          .addActivity(
            this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO'
              ? ({
                  activity: this.activityId,
                  type: formActivityValues.typeSelected,
                  name: formActivityValues.name,
                  description: formActivityValues.statement,
                  video_url: formActivityValues.video,
                  includeVideo: formActivityValues.includeVideo,
                  is_published: formActivityValues.published,
                  is_archived: false,
                  jazzdoo_type:
                    this.categoriesSelected &&
                    this.categoriesSelected[0]['value'],
                  options_min: formOptionsValues.min ?? 1,
                  options_max: formOptionsValues.max ?? 1,
                  is_ponderable: formOptionsValues.is_ponderable ?? false,
                } as any)
              : ({
                  activity: this.activityId,
                  type: formActivityValues.typeSelected,
                  name: formActivityValues.name,
                  description: formActivityValues.statement,
                  video_url: formActivityValues.video,
                  includeVideo: formActivityValues.includeVideo,
                  is_published: formActivityValues.published,
                  is_archived: false,
                  options_min: formOptionsValues.min ?? 1,
                  options_max: formOptionsValues.max ?? 1,
                  is_ponderable: formOptionsValues.is_ponderable ?? false,
                } as any)
          )
          .pipe(
            switchMap((response: any) => {
              if (response.is_ponderable !== formOptionsValues.is_ponderable) {
                return this.activityService.updateActivity(response.id, {
                  activity: response.activity,
                  is_ponderable: formOptionsValues.is_ponderable ?? false,
                } as any);
              }

              return new Observable((subscriber: any) => {
                subscriber.next(response);
                subscriber.complete();
              });
            }),
            switchMap((question: any) => {
              if (formOptionsValues.options.length > 0) {
                return from(
                  this.getOptionsForCreationPayload(question.id)
                ).pipe(
                  this.concatMapAddOption(),
                  switchMap(({ optionResponse, userOptionCategories }) => {
                    return from(
                      this.extractParameterDetails(
                        this.updateMainObject(
                          optionResponse,
                          userOptionCategories
                        ),
                        CATEGORY_DIC
                      )
                    ).pipe(
                      this.concatMapUpdateOptionParameters(),
                      map((paramsUpdateResponse: any) => ({
                        paramsUpdateResponse,
                      }))
                    );
                  })
                );
              } else {
                return new Observable((observer) => {
                  observer.next({ paramsUpdateResponse: null });
                  observer.complete();
                }).pipe(
                  map((paramsUpdateResponse: any) => ({ paramsUpdateResponse }))
                );
              }
            })
          )
          .subscribe({
            next: ({ paramsUpdateResponse }) => {
              this.inProcess = false;
            },
            error: (error) => {
              this.inProcess = false;
              this.store.dispatch(
                new OpenModal(`Algo salio mal, intente nuevamente mas tarde.`)
              );
              this.router.navigate([
                `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
              ]);
              console.error('Error:', error);
            },
            complete: () => {
              this.inProcess = false;
              this.store.dispatch(
                new OpenModal(
                  `Pregunta ${
                    this.activityCreationForm.getRawValue().name
                  } creada con éxito.`
                )
              );
              this.router.navigate([
                `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
              ]);
            },
          });
      }
    } else {
      if (this.activityCreationForm.get('name')?.errors) {
        this.errors.push({ title: 'El nombre de la pregunta es obligatorio.' });
      } else if (this.activityCreationForm.get('typeSelected')?.errors) {
        this.errors.push({ title: 'El tipo de pregunta es obligatorio.' });
      } else if (this.activityCreationForm.get('statement')?.errors) {
        this.errors.push({
          title: 'El enunciado de la pregunta es obligatorio.',
        });
      }
    }
  }

  public handleParametersChange(parameters: any): void {
    this.categoriesSelected = parameters;

    this.optionsFormArray.controls.forEach(
      (optionGroup: any, optionIndex: number) => {
        const optionCategories = (optionGroup.get('categories') as FormArray)
          .controls;

        if (
          this.activityCreationForm.get('typeSelected')?.value === 'JAZZDOO'
        ) {
          optionCategories.pop();

          this.categoriesSelected.forEach((category) => {
            const categoryGroup = this.formBuilder.group({
              id: [category['value']],
              label: [category['label']],
              value: [category['value']],
              parameters: this.formBuilder.array(
                this.getCategoryParameters(category['value']).map(
                  (param: any) =>
                    this.formBuilder.group({
                      id: [param.id],
                      label: [param.label],
                      value: [optionIndex],
                    })
                )
              ),
            });
            (optionGroup.get('categories') as FormArray).push(categoryGroup);
          });
        } else {
          if (optionCategories.length !== this.categoriesSelected.length) {
            const selectedValues = new Set(
              this.categoriesSelected.map((category) => category['value'])
            );

            const categoriesNotIncluded = this.categoriesSelected.filter(
              (category) =>
                !optionCategories.some(
                  (optionCategory) =>
                    optionCategory.get('value')?.value === category['value']
                )
            );

            categoriesNotIncluded.forEach((categoryNotIncluded) => {
              const categoryGroup = this.formBuilder.group({
                id: [categoryNotIncluded['value']],
                label: [categoryNotIncluded['label']],
                value: [categoryNotIncluded['value']],
                parameters: this.formBuilder.array(
                  this.getCategoryParameters(categoryNotIncluded['value']).map(
                    (param: any) =>
                      this.formBuilder.group({
                        id: [param.id],
                        label: [param.label],
                        value: [1],
                      })
                  )
                ),
              });
              (optionGroup.get('categories') as FormArray).push(categoryGroup);
            });

            for (let i = optionCategories.length - 1; i >= 0; i--) {
              const optionCategory = optionCategories[i];
              if (!selectedValues.has(optionCategory.get('value')?.value)) {
                if (
                  !this.categoriesToDelete.find(
                    (cat: any) =>
                      cat.id ===
                      (optionGroup.get('categories') as FormArray)
                        .at(i)
                        .get('id')?.value
                  )
                ) {
                  const group = (optionGroup.get('categories') as FormArray).at(
                    i
                  );
                  (group.get('parameters') as FormArray).controls.forEach(
                    (paramControl) => paramControl.get('value')?.setValue(0)
                  );
                  this.categoriesToDelete.push(group.getRawValue());
                }

                (optionGroup.get('categories') as FormArray).removeAt(i);
              }
            }
          }
        }
      }
    );
  }

  toggleTest() {
    this.active = !this.active;
  }

  getActivityByCode(code?: string) {
    let type = this.types.find((activity) => activity.code === code);
    return type;
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deleteQuestion(id);
    }
  }

  deleteQuestion(id: string | number) {
    this.activityService.deleteActivity(parseInt(id.toString())).subscribe({
      complete: () => {
        this.router.navigate([
          `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas`,
        ]);
        this.store.dispatch(new OpenModal('Pregunta eliminada exitosamente.'));
      },
      error: ({ error }) => {},
    });
  }

  public filterData(data: DataObject[]): DataObject[] {
    return data.filter((item) => {
      return !item.parameters.every((parameter) => {
        // Check if all properties except 'value' are 0
        return Object.keys(parameter).every((key) => {
          if (key !== 'value' && key !== 'id') {
            return parameter[key as keyof Parameter] === 0;
          }
          return true;
        });
      });
    });
  }
}
