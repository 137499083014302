import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Page } from '../models/page.models';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Iquestionary } from '../models/questionary.models';
import { Content } from '../models/content.model';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private httpClient: HttpClient) {}

  getPages() {
    const url = environment.apiUrl + '/questionaries/pages/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getContents(): Observable<Content[]> {
    const url = environment.apiUrl + '/questionaries/pages/content/';
    return this.httpClient.get<Content[]>(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getSeasons() {
    const url = environment.apiUrl + '/questionaries/basic_info/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getChapterByProgramId(id: string) {
    const url =
      environment.apiUrl + '/questionaries/program/content/' + id + '/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getPageById(id: string | number) {
    const url = environment.apiUrl + '/questionaries/pages/' + id + '/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getQuestionaryById(id: string | number) {
    // /questionaries/basic_info/2
    const url = environment.apiUrl + '/questionaries/' + id + '/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getContentThemes(id: string) {
    const url =
      environment.apiUrl + '/questionaries/pages/' + id + '/children/';

    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getBranch(id: string) {
    const url = environment.apiUrl + '/questionaries/branch/' + id;
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteContentFromPage(id: string) {
    const url = environment.apiUrl + '/questionaries/program/' + id + '/';
    return this.httpClient.delete(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deletePage(id: string) {
    const url = environment.apiUrl + '/questionaries/pages/' + id + '/';
    return this.httpClient.delete(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteQuestionary(id: string) {
    const url = environment.apiUrl + '/questionaries/' + id + '/';
    return this.httpClient.delete(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getQuestionaries(): Observable<any> {
    const url = environment.apiUrl + '/questionaries/';

    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getReportTypes(): Observable<any> {
    const url = environment.apiUrl + '/reports/type/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addPageToOrg(orgIds: number[], pageId: number) {
    const url =
      environment.apiUrl +
      `/questionaries/pages/${pageId}/add-to-organizations/`;

    return this.httpClient.post(url, { organization_ids: orgIds }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addProgramToOrg(orgIds: number[], programId: number) {
    const url =
      environment.apiUrl + `/questionaries/${programId}/add-organizations/`;

    return this.httpClient.post(url, { organization_ids: orgIds }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public addContentIntoProgram(data: any) {
    const url = environment.apiUrl + '/questionaries/program/';

    return this.httpClient.post(url, data).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public deleteProgramFromOrg(orgId: number, programId: number) {
    const url =
      environment.apiUrl + `/organizations/${orgId}/delete_questionary/`;
    return this.httpClient
      .delete(url, { body: { questionary_id: programId } })
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public deletePageFromOrg(orgId: number, contentId: number) {
    const url = environment.apiUrl + `/organizations/${orgId}/delete_content/`;
    return this.httpClient
      .delete(url, { body: { content_id: contentId } })
      .pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  public modifyContentOrderInProgram(data: any) {
    const url =
      environment.apiUrl + '/questionaries/program/' + data.contentId + '/';

    return this.httpClient.put(url, { order: data.order }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  newPage(page: Page) {
    const url = environment.apiUrl + '/questionaries/pages/';
    console.debug(page.image);
    var formData: any = new FormData();
    // MANDATORY
    formData.append('name', page.name);
    formData.append('internal_name', page.internal_name);
    formData.append('internal_description', page.internal_description);
    formData.append('questionary', page.questionary);
    formData.append('is_activity', page.is_activity);
    formData.append('background_color', page.background_color);
    formData.append('gradient_color', page.gradient_color);
    formData.append('font_color', page.font_color);
    formData.append('is_preliminar_limit', page.is_preliminar_limit);
    if (page.time) {
      formData.append('time', page.time);
    } else {
      formData.append('time', 0);
    }

    // OPTIONAL
    if (page.parent) {
      formData.append('parent', page.parent);
    }
    if (page.video_url) {
      formData.append('video_url', page.video_url);
    }
    if (page.image) {
      formData.append('image', page.image);
    }
    if (page.image_url) {
      formData.append('image_url', page.image_url);
    }
    if (page.description) {
      formData.append('description', page.description);
    }

    return this.httpClient.post(url, formData).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  newQuestionary(questionary: Iquestionary) {
    const url = environment.apiUrl + '/questionaries/';

    const questionaryToCreate = { ...questionary };

    if (questionaryToCreate.organization?.length === 0) {
      delete questionaryToCreate.organization;
    }

    return this.httpClient.post(url, questionaryToCreate).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  editQuestionary(questionary: Iquestionary) {
    const url = environment.apiUrl + '/questionaries/' + questionary.id + '/';
    const questionaryToEdit = { ...questionary };

    if (
      Array.isArray(questionaryToEdit.organization) &&
      questionaryToEdit.organization?.length === 0
    ) {
      delete questionaryToEdit.organization;
    }

    return this.httpClient.put(url, questionaryToEdit);
  }

  updatePage(page: Page) {
    const url = environment.apiUrl + '/questionaries/pages/' + page.id + '/';
    var formData: any = new FormData();

    // MANDATORY
    formData.append('name', page.name);
    formData.append('internal_name', page.internal_name);
    formData.append('internal_description', page.internal_description);
    formData.append('questionary', page.questionary);
    formData.append('is_activity', page.is_activity);
    formData.append('background_color', page.background_color);
    formData.append('gradient_color', page.gradient_color);
    formData.append('font_color', page.font_color);
    formData.append('is_preliminar_limit', page.is_preliminar_limit);
    formData.append('order', page.order);
    formData.append('time', page.time);

    // OPTIONAL
    if (page.parent) {
      formData.append('parent', page.parent);
    }

    if (page.video_url) {
      formData.append('video_url', page.video_url);
    }

    if (page.image && typeof page.image !== 'string') {
      formData.append('image', page.image);
    }
    if (page.image_url) {
      formData.append('image_url', page.image_url);
    }

    if (page.description) {
      formData.append('description', page.description);
    }

    return this.httpClient.put(url, formData).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateQuestionary(questionary: any) {
    const url =
      environment.apiUrl +
      '/questionaries/' +
      questionary.id +
      '/switch-is-published/';

    return this.httpClient.put(url, questionary).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  duplicateQuestionaryById(questionaryId: number) {
    // /questionaries/2/duplicate
    const url =
      environment.apiUrl + '/questionaries/' + questionaryId + '/duplicate';

    return this.httpClient.post(url, {}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  duplicateProgramById(programId: number) {
    // /questionaries/2/duplicate
    const url =
      environment.apiUrl + '/questionaries/clone-program/' + programId + '/';

    return this.httpClient.post(url, {}).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
