import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { Iquestionary } from 'src/app/models/questionary.models';
import { Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Observable, ReplaySubject, switchMap, takeUntil } from 'rxjs';

@Component({
  templateUrl: './new-questionaries-page.component.html',
  styleUrls: ['./new-questionaries-page.component.scss'],
})
export class NewQuestionariesPageComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];

  questionary: Iquestionary = {
    internal_name: '',
    name: '',
    is_published: false,
    has_report: false,
    order: 1,
    organization: [],
    report_types: null,
    description: null,
    internal_description: null,
    tags: [],
  };
  institutions: any = null;
  report_types: Array<any> = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private pageService: PageService,
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public creationalPagesLayoutService: CreationalPagesLayoutService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear programa',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.getInstitutions();
    this.getReportsTypes();
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/pages/programas']);
        }
      });
  }

  getThree() {
    this.inProcess = true;
    this.pageService.getQuestionaries().subscribe({
      next: (data) => {
        console.debug(data);
        this.three = data as Array<any>;
        this.inProcess = false;
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.inProcess = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  togglePublishQuestionary() {
    this.questionary.is_published = !this.questionary.is_published;
  }

  toggleReportQuestionary() {
    this.questionary.has_report = !this.questionary.has_report;
  }

  isValidForm(questionary: Iquestionary) {
    const emptyName = questionary.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del cuestionario es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.questionary = {
      internal_name: '',
      name: '',
      is_published: false,
      has_report: false,
      order: 1,
      description: null,
      internal_description: null,
      tags: [],
      shared_with: [],
    };
  }

  addToThree(redirect: boolean = false) {
    if (this.isValidForm(this.questionary)) {
      this.pageService
        .newQuestionary({
          ...this.questionary,
          organization: this.selectedOrgs,
          report_types: [],
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.pageService.addProgramToOrg(
                this.selectedOrgs as number[],
                response.id as number
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            this.getThree();
            this.resetForm();
            if (redirect) {
              this.router.navigate(['/dashboard/pages/programas']);
            }
          },
          error: ({ error }) => {
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);
            this.modal.open('Algo ha ocurrido', htmlErrorsContent);
          },
        });
    }
  }

  getInstitutions() {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public handleSelectionChange(selectedOptions: Array<Record<string, string>>) {
    console.log(selectedOptions);
    this.selectedOrgs = selectedOptions.map((option: any) => option.value);
  }

  getReportsTypes() {
    this.inProcess = true;
    this.pageService.getReportTypes().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.report_types = data;
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }
}
