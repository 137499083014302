import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MetaDataService } from 'src/app/services/metadata.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-detail-category-parameter-page',
  templateUrl: './detail-category-parameter-page.component.html',
  styleUrls: ['./detail-category-parameter-page.component.scss']
})

export class DetailCategoryParameterPageComponent implements OnInit {
  public categoryId: string = ''
  public categoryParams:any = {};

  constructor(
    private route: ActivatedRoute,
    private meta: MetaDataService,
    private location: Location,
    private title: Title
  ) {
    this.title.setTitle('Detalle de Categoria del Parámetro | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.paramMap.get('id') as string;
    this.getCategoryParams();
  }

  getCategoryParams(){
    this.categoryParams = this.meta.getParamatersByCategory(this.categoryId);
  }

  back(): void {
    this.location.back()
  }
}
