import { Injectable } from '@angular/core';
import { Group } from '../models/group.model';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IUserDetail, User } from '../models/user.models';
import { environment } from 'src/environments/environment';
import { objectToFormData } from '../utils/objectToFormData';

interface CreateOrganizationPayload {
  name: string;
  logo?: File;
  primary_color?: string;
  secondary_color?: string;
  license_quantity?: number;
}
@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(private httpClient: HttpClient) {}

  private API_URL = environment.apiUrl;
  private MODEL = '/organizations';

  public createGroup(payload: CreateOrganizationPayload): Observable<any> {
    const url = `${this.API_URL + this.MODEL}/`;
    return this.httpClient.post(url, objectToFormData(payload));
  }

  public addParticipant(payload: {
    organization: number;
    participant: number;
    participant_role: 'Student' | 'Teacher';
  }) {
    const url = `${this.API_URL + this.MODEL}/${
      payload.organization
    }/add_participant/`;

    return this.httpClient.post(url, payload);
  }

  public deleteParticipant(payload: {
    organization: number;
    participant_id: number;
  }) {
    const url = `${this.API_URL + this.MODEL}/${
      payload.organization
    }/delete_participant/`;

    return this.httpClient.delete(url, { body: payload });
  }

  public updateGroup(
    payload: CreateOrganizationPayload,
    groupId: string
  ): Observable<any> {
    const url = `${this.API_URL + this.MODEL}/${groupId}/`;
    const formData = objectToFormData(payload);
    if (!(payload.logo instanceof File)) {
      formData.delete('logo');
    }
    return this.httpClient.put(url, formData);
  }

  public getGroups(): Observable<Group[]> {
    const url = `${this.API_URL + this.MODEL}/list_all_organization/`;
    return this.httpClient.get<Group[]>(url);
  }

  public getGroupById(id: string): Observable<Group> {
    const url = `${this.API_URL + this.MODEL}/${id}/`;
    return this.httpClient.get<Group>(url);
  }

  public deleteGroup(id: string): Observable<any> {
    const url = `${this.API_URL + this.MODEL}/${id}/`;
    return this.httpClient.delete(url);
  }

  getGroupUsers(groupId: string): Observable<IUserDetail[]> {
    const url = `${this.API_URL + this.MODEL}/${groupId}/`;

    return this.httpClient.get<IUserDetail[]>(url);
  }
}
