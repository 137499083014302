<main class="page detail-page-page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">&lt;{{page.name}} - ID{{pageId}}&gt;</h1>
          </div>
          <div class="col-auto off">
            <button class="btn text-red" routerLink="/dashboard/pages/programas" type="button">
              Volver
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>
  <section class="mt-3">
    <app-card [backgroundImage]="page.image!" borderRadius="10px" height="100%" padding="20px 40px"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px">
      <article card__content>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col d-flex gap-3">
                <h2 class="font-19 font-primary--b">
                  {{page.name}}
                </h2>
                <span class="d-block text-grey opacity-50">
                  &lt;{{page.type}}&gt;
                </span>
              </div>
              <div class="col-auto d-flex gap-3">
                <button type="button" class="btn bg-orange-alt text-white"
                  routerLink="/dashboard/pages/programas/{{page.id}}/editar" title="Editar página '{{page.name}}'">
                  <span class="text-white">Editar</span>
                </button>
                <button type="button" class="btn bg-red text-white" (click)="deletePage(page.id)"
                  title="Eliminar página '{{page.name}}'">
                  <span class="text-white">Eliminar</span>
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <p class="opacity-50">
                {{page.description}}
              </p>
            </div>
          </div>
        </div>
      </article>
    </app-card>
  </section>
</main>
