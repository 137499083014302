<app-page-loader *ngIf="inProcess"></app-page-loader>
<section *ngIf="!inProcess" class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="content.internal_name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre del contenido"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                    <sup
                      class="cursor-pointer"
                      title="Define la posición de la página en la lista, '0' indica que la página va de ultima."
                      >📄</sup
                    >
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="content.internal_description"
                    name="internal_description"
                    id="internal_description"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Compartir"
                    template="BLUE"
                    [options]="organizations"
                    (selectionChange)="handleSelectionChange($event)"
                  ></app-multi-select-dropdown>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Etiquetas
                    <sup
                      class="cursor-pointer"
                      title="Define la posición de la página en la lista, '0' indica que la página va de ultima."
                      >📄</sup
                    >
                  </label>
                  <input
                    class="input"
                    [(ngModel)]="content.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <app-page-form #newPageForm [three]="three"></app-page-form>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
