<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
        fluid: true,
        aspectRatio: '16:9',
        autoplay: false,
        controls: true,
        sources: [{
          src: video,
          type: 'video/mp4'
        }]
      }"></app-video>
  </div>

  <div class="valorations-labels-wrapper" *ngIf="options.length">
    <span></span>
    <ul class="valorations-labels">
      <li class="label">Nada</li>
      <li class="label">Poco</li>
      <li class="label">Algo</li>
      <li class="label">Bastante</li>
      <li class="label">Mucho</li>
    </ul>
  </div>
  <ul class="list-unstyled valorations">
    <ng-container *ngFor="let option of options, index as i">
      <li class="valoration" >
        <label class="d-block valoration__label" for="range-{{option.id}}">
          {{option.name}}
        </label>

        <div>
          <ul class="valorations-radios">
            <li>
              <app-radio (click)="updateRadio(i, 1)" [isActive]="option.value == 1" [color]="'#00CAA8'"></app-radio>
            </li>
            <li>
              <app-radio (click)="updateRadio(i, 2)" [isActive]="option.value == 2" [color]="'#00CAA8'"></app-radio>
            </li>
            <li>
              <app-radio (click)="updateRadio(i, 3)" [isActive]="option.value == 3" [color]="'#00CAA8'"></app-radio>
            </li>
            <li>
              <app-radio (click)="updateRadio(i, 4)" [isActive]="option.value == 4" [color]="'#00CAA8'"></app-radio>
            </li>
            <li>
              <app-radio (click)="updateRadio(i, 5)" [isActive]="option.value == 5" [color]="'#00CAA8'"></app-radio>
            </li>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
<h3 *ngIf="!options!.length" class="font-primary text-grey font-16 opacity-50 text-center">Aun no ha provisto de
  opciones a está actividad</h3>
