<main class="page institutions-page">
  <!-- Modals -->
  <ng-template #newInstitutionModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        <span *ngIf="!editMode">Nueva</span>
        <span *ngIf="editMode">Editar</span>
        Institución
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <!-- PREVIEW -->
        <div class="row" *ngIf="previewMainImage">
          <div class="col-12 d-flex justify-content-center">
            <img class="img-preview" [src]="previewMainImage" alt="">
            <!-- <div class="preview-block" [ngStyle]="{
                background: 'transparent linear-gradient(140deg, '+page.background_color+' 0%, '+page.gradient_color+' 100%) 0% 0%'
              }">
              <p class="preview-font text-center" [ngStyle]="{color: page.font_color}">Texto <br> de ejemplo</p>
              <div class="background-image" *ngIf="previewMainImage"
                [ngStyle]="{backgroundImage: 'url('+previewMainImage.changingThisBreaksApplicationSecurity+')'}">
              </div>
              <div class="background-image" *ngIf="editMode && !previewMainImage"
                [ngStyle]="{backgroundImage: 'url('+page.image+')'}">
              </div>

            </div> -->

          </div>
        </div>
        <div class="row" *ngIf="!previewMainImage && editMode && institution && institution.logo">
          <div class="col-12 d-flex justify-content-center">
            <img class="img-preview" [src]="institution.logo" alt="">
          </div>
        </div>
        <div class="row">
          <div class="col-auto mb-3">
            <div class="d-flex flex-column">
              <label for="bgImage" class="label mx-2 mb-1 d-flex">
                <span class="me-3 d-block">Logo de la institución</span>
              </label>
              <input class="w-100" name="bgImage" type="file" id="bgImage" accept="image/*"
                (change)="changeImageHandler($event)">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex flex-column align-items-center">
            <label for="primaryBgColor" class="label mx-2 mb-1">Color de fondo 1</label>
            <input class="input cursor-pointer" [(ngModel)]="institution.primary_color" name="primaryBgColor"
              type="color" id="primaryBgColor">
          </div>
          <div class="col-6 d-flex flex-column align-items-center">
            <label for="secondaryBgColor" class="label mx-2 mb-1">Color de fondo 2</label>
            <input class="input cursor-pointer" [(ngModel)]="institution.secondary_color" name="secondaryBgColor"
              type="color" id="secondaryBgColor">
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex flex-column">
            <label for="title">Nombre</label>
            <input class="input" type="text" id="title" [(ngModel)]="institution.name" name="title"
              placeholder="Nombre de la institución">
          </div>

          <div class="col-12 d-flex flex-column my-3">
            <label for="license_qty">Número de licencias</label>
            <input class="input" [(ngModel)]="institution.license_quantity" type="number" name="license_qty"
              id="license_qty" placeholder="0">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark bg-transparent text-red" (click)="modal.close()">Volver</button>
      <button *ngIf="!editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="newInstitution()">
        <span class="text-white" *ngIf="!inProcess">
          Guardar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
      <button *ngIf="editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="editInstitution()">
        <span class="text-white" *ngIf="!inProcess">
          Editar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
    </div>
  </ng-template>
  <ng-template #loaderModal let-modal>
    <div class="modal-header justify-content-center">
      <h4 class="modal-title text-center pb-3" id="modal-basic-title">Porfavor espere un momento</h4>
    </div>
  </ng-template>

  <!-- HEADER -->
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">
              Instituciones
            </h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white" (click)="open(newInstitutionModal)" type="button">
              Nueva Institución
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <!-- List -->
  <section class="mt-3">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let institution of institutions; index as i">
          <td>
            {{institution.id}}
          </td>
          <td>
            {{institution.name}}
          </td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary text-black" ngbDropdownToggle id="userActions" type="button">
                Acciones
              </button>

              <div ngbDropdownMenu aria-labelledby="userActions">

                <button ngbDropdownItem (click)="editInstitutionMode(institution, newInstitutionModal)">
                  Editar
                </button>

                <button ngbDropdownItem (click)="deleteInstitution(institution.id!, loaderModal)">
                  Eliminar
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</main>