import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { debounceTime, from, map, Observable } from 'rxjs';

import { ActivityService } from 'src/app/services/activity.service';
import { ActivityState } from 'src/app/store/activities/activity.state';
import { MetaDataService } from 'src/app/services/metadata.service';
import { BehaviorSubject } from 'rxjs';
import { formatOptionCategoriesAndParameters } from 'src/app/utils/options';
import { ActivatedRoute } from '@angular/router';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../modal/modal.component';
import { SetActivityCreated } from 'src/app/store/activities/activity.actions';
import { CommonService } from 'src/app/services/common.service';
import { QuestionFormService } from 'src/app/services/question-form.service';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';

interface OptionCategory {
  label: string;
  parameters: Parameter[];
}

interface Parameter {
  label: string;
  value: number;
}

interface Option {
  body: string;
  name: string;
  showCategories: boolean;
  categories: OptionCategory[];
}

@Component({
  selector: 'app-create-activity-options',
  templateUrl: './create-activity-options.component.html',
  styleUrls: ['./create-activity-options.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class CreateActivityOptionsComponent implements OnInit, OnChanges {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @Input() editMode: boolean = false;
  @Input() questionType?: string;
  @Input() categoriesToSelect: Array<Record<string, string>> = [];
  @Select(ActivityState.activityCreated) activityCreated$!: Observable<any>;
  errors: Array<{ title: string }> = [];
  @Input() options!: FormArray<
    FormGroup<{
      id: FormControl;
      body: FormControl;
      name: FormControl;
      delete: FormControl;
      schein_parameter: FormControl;
      showCategories: FormControl;
      is_correct?: FormControl;
      disabled?: FormControl;
      categories: FormArray<
        FormGroup<{
          id: FormControl;
          label: FormControl;
          value: FormControl;
          parameters: FormArray<
            FormGroup<{
              id: FormControl;
              label: FormControl;
              value: FormControl;
            }>
          >;
        }>
      >;
    }>
  >;
  @Output() handleAddOption: EventEmitter<null> = new EventEmitter();
  @Output() handleParametersChange: EventEmitter<any> = new EventEmitter();
  @Input() showMinMax: boolean = false;
  public categoriesFormatted: any[] = [];
  public controlFormContainer = inject(ControlContainer);
  @Input() categoriesSelected: Array<Record<string, string>> = [];
  scheinParams: Array<any> = []
  public jazzdooParameters = [
    {
      label: 'Objetividad de Visión Periférica',
      value: 'PERIPHERAL_VISION_OBJECTIVITY',
    },
    { label: 'Paternalismo', value: 'PATERNALISM' },
    { label: 'Capacidad de Síntesis', value: 'SYNTHESIS_CAPACITY' },
    { label: 'Naturalidad', value: 'NATURALNESS' },
    { label: 'Amplitud de Aprendizaje', value: 'LEARNING_AMPLITUDE' },
    { label: 'Subjetividad', value: 'SUBJECTIVITY' },
    { label: 'Pensamiento Estratégico', value: 'STRATEGIC_THINKING' },
  ];

  constructor(private meta: MetaDataService) {}

  ngOnInit() {
    this.scheinParams = this.meta.getScheinsParameters()

    if (this.questionType === 'JAZZDOO') {
      this.categoriesFormatted = this.jazzdooParameters.map((category) => ({
        ...category,
        iconUrl: '/assets/images/icons/icon_selector.svg',
      }));
    } else {
      this.categoriesFormatted = this.meta
        .getAllParameters()
        .map((category) => ({
          value: category.category_string_id,
          label: category.category_name,
          iconUrl: '/assets/images/icons/icon_selector.svg',
        }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.scheinParams.forEach((param) => {
      this.options.value.forEach((option) => {
        if (param.slug === option.schein_parameter) {
          param.selected = true
        }
      })
    })
    if (
      changes['categoriesSelected'] &&
      changes['categoriesSelected'].currentValue
    ) {
      this.categoriesSelected = changes['categoriesSelected'].currentValue;
    }
    if (
      changes &&
      changes['questionType'] &&
      changes['questionType'].currentValue
    ) {
      this.questionType = changes['questionType'].currentValue;
      if (changes['questionType'].currentValue === 'JAZZDOO') {
        this.categoriesFormatted = this.jazzdooParameters.map((category) => ({
          ...category,
          iconUrl: '/assets/images/icons/icon_selector.svg',
        }));
      } else {
        this.categoriesFormatted = this.meta
          .getAllParameters()
          .map((category) => ({
            value: category.category_string_id,
            label: category.category_name,
            iconUrl: '/assets/images/icons/icon_selector.svg',
          }));
      }
    }
  }

  get getParentForm(): FormGroup {
    return this.controlFormContainer.control as FormGroup;
  }

  public onSelectionChange(selectedOptions: any[]): void {
    this.handleParametersChange.emit(selectedOptions);
  }

  toggleIsPonderable(): void {
    this.getParentForm
      .get('is_ponderable')
      ?.setValue(!this.getParentForm.get('is_ponderable')?.value);
  }

  public addOption(): void {
    this.handleAddOption.emit();
  }

  onChangeOptionHandler(event: any) {
    console.debug('[event.target.value]',event.target.value)
  }

  removeOptionByIndex(index: number) {
    if (this.options.at(index).get('id')) {
      this.options.at(index).get('delete')?.setValue(true);
      this.scheinParams.forEach((param: any) => {
        if (param.slug === this.options.at(index).get('schein_parameter')?.value) {
          param.selected = false
        }
      })
    } else {
      this.options.removeAt(index);
    }
  }

  toggleShowCategories(index: number) {
    const control = this.options.at(index).get('showCategories') as FormControl;
    control.setValue(!control.value);
  }

  toggleCorrectOption(index: number) {
    const control = this.options.at(index).get('is_correct') as FormControl;
    control.setValue(!control.value);
  }
}
