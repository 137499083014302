<header #headerContainer class="page-header-container">
  <app-card
    height="100%"
    backgroundColor="rgb(237, 237, 237)"
    borderRadius="0px"
    padding="20px 70px"
    boxShadow="rgba(0, 0, 0, .2) 0px 4px 3px"
  >
    <article class="d-flex align-items-center h-100" card__content>
      <section class="d-flex justify-content-between w-100">
        <div class="h-100 row align-items-center">
          <div class="col d-flex flex-row">
            <img
              *ngIf="headerTitleIcon"
              class="w-26px"
              [alt]="headerTitle + 'icon'"
              [src]="headerTitleIcon"
            />
            <ng-container
              *ngIf="breadcrumbService.routes.length > 0 && !disableBreadcrum"
            >
              <h1
                *ngFor="
                  let segment of breadcrumbService.routes;
                  let last = last;
                  let first = first
                "
                [routerLink]="segment.urlSnapshot"
                class="font-24 font-primary--eb m-0"
                [class.ms-4]="first"
                [class.hover-text-green]="!last"
              >
                {{ segment.name | trimString : 15 }}
                <span *ngIf="!last" class="me-3 ms-2 font-bold">></span>
              </h1>
            </ng-container>
            <ng-container
              *ngIf="breadcrumbService.routes.length === 0 || disableBreadcrum"
            >
              <h1 class="font-24 font-primary--eb ms-4">
                {{ headerTitle }}
              </h1>
            </ng-container>
          </div>
        </div>
        <section *ngIf="headerButtonText">
          <button
            (click)="emitHeaderButton()"
            class="d-flex align-items-center gap-3 cursor-pointer border-0"
            style="cursor: pointer"
          >
            <img
              *ngIf="headerButtonIcon"
              [src]="headerButtonIcon"
              class="w-26px"
            />
            <span class="font-bold font-24 font-primary--eb">
              <strong>{{ headerButtonText }}</strong>
            </span>
          </button>
        </section>
      </section>
    </article>
  </app-card>
</header>
<div style="height: 50px; visibility: hidden"></div>
<section [@fadeAnimation]="o && o.activatedRouteData" class="content-container">
  <router-outlet #o="outlet"></router-outlet>
</section>

<ng-container *ngIf="footerConfig">
  <app-page-footer
    [footerConfig]="footerConfig"
    [windowResizeListener]="windowResizeListener"
    [transitionInConfig]="transitionInConfig"
    [transitionOutConfig]="transitionOutConfig"
  ></app-page-footer>
</ng-container>
