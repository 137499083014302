<div
  [style]="{ opacity: disabled ? 0.8 : 1 }"
  [class.disabled]="disabled"
  class="position-relative"
>
  <label class="label text-secondary">{{ label }}</label>
  <div
    class="form-control d-flex justify-content-between align-items-center cursor-pointer"
    (click)="!disabled && toggleDropdown($event)"
    style="min-height: 42px"
  >
    <div class="d-flex flex-wrap gap-3 m-0">
      <span *ngIf="selectedOptions.length === 0" class="text-secondary">{{
        placeholder
      }}</span>
      <ng-container *ngFor="let option of selectedOptions">
        <ng-container
          *ngIf="template === 'YELLOW'"
          [ngTemplateOutlet]="yellowOption"
          [ngTemplateOutletContext]="{ $implicit: option }"
        >
        </ng-container>
        <ng-container
          *ngIf="template === 'BLUE'"
          [ngTemplateOutlet]="blueOption"
          [ngTemplateOutletContext]="{ $implicit: option }"
        >
        </ng-container>
        <ng-container
          *ngIf="template === 'PURPLE'"
          [ngTemplateOutlet]="purpleOption"
          [ngTemplateOutletContext]="{ $implicit: option }"
        >
        </ng-container>
        <ng-container
          *ngIf="template === 'GREEN'"
          [ngTemplateOutlet]="greenOption"
          [ngTemplateOutletContext]="{ $implicit: option }"
        >
        </ng-container>
      </ng-container>
    </div>
    <figure tabindex="-1" class="m-0">
      <img
        src="/assets/images/icons/icon_arrow_down.svg"
        alt="Arrow down indicating the direction of the dropdown"
        tabindex="-1"
      />
    </figure>
  </div>
  <ul
    class="list-group dropdown-menu-container"
    [class.visible-menu]="(dropdownOpen$ | async) === true"
    style="width: 100%; z-index: 1000"
  >
    <li class="search-container">
      <input
        (click)="stopPropagation($event)"
        #searchInput
        type="text"
        class="input"
        placeholder="Buscar..."
        tabindex="0"
        [formControl]="dropdownSearch"
      />
    </li>
    <button
      *ngFor="
        let option of getOptions | async;
        trackBy: trackByValue;
        let index = index
      "
      (click)="toggleSelection(option, $event)"
      class="list-group-item"
      [disabled]="disabled"
      [class.active]="isSelected(option)"
      style="cursor: pointer"
    >
      <div class="d-flex align-items-center gap-2">
        {{ option["label"] }}
      </div>
    </button>
  </ul>
</div>

<ng-template #greenOption let-option>
  <span
    class="badge bg-green d-flex justify-content-between align-items-center gap-2 px-2 py-1"
  >
    <p class="d-flex align-items-center gap-1 text-white m-0">
      <img
        src="/assets/images/icons/icon_switch.svg"
        alt=""
        style="width: 18px; height: 18px"
      />
      {{ option["label"] }}
    </p>
    <img
      src="/assets/images/icons/close_white_icon.svg"
      alt="Icon to delete a selected parameter"
      style="width: 12px; height: 12px"
      (click)="toggleSelection(option, $event)"
    />
  </span>
</ng-template>

<ng-template #yellowOption let-option>
  <span
    class="badge bg-orange-alt d-flex justify-content-between align-items-center gap-2 px-2 py-1"
  >
    <p class="d-flex align-items-center gap-1 text-white m-0">
      <img
        src="/assets/images/icons/icon_selector_white.svg"
        alt=""
        style="width: 18px; height: 18px"
      />
      {{ option["label"] }}
    </p>
    <img
      src="/assets/images/icons/close_white_icon.svg"
      alt="Icon to delete a selected parameter"
      style="width: 12px; height: 12px"
      (click)="toggleSelection(option, $event)"
    />
  </span>
</ng-template>

<ng-template #blueOption let-option>
  <span
    class="badge bg-blue-light d-flex justify-content-between align-items-center gap-2 px-2 py-1"
  >
    <p class="d-flex align-items-center gap-1 text-white m-0">
      <img
        src="/assets/images/icons/icon_user_small.svg"
        alt=""
        style="width: 18px; height: 18px"
      />
      {{ option["label"] }}
    </p>
    <img
      src="/assets/images/icons/close_white_icon.svg"
      alt="Icon to delete a selected parameter"
      style="width: 12px; height: 12px"
      (click)="toggleSelection(option, $event)"
    />
  </span>
</ng-template>

<ng-template #purpleOption let-option>
  <span
    class="badge bg-blue-extra-light d-flex justify-content-between align-items-center gap-2 px-2 py-1"
  >
    <p class="d-flex align-items-center gap-1 text-white m-0">
      <img
        src="/assets/images/icons/icon_group_small.svg"
        alt=""
        style="width: 18px; height: 18px"
      />
      {{ option["label"] }}
    </p>
    <img
      src="/assets/images/icons/close_white_icon.svg"
      alt="Icon to delete a selected parameter"
      style="width: 12px; height: 12px"
      (click)="toggleSelection(option, $event)"
    />
  </span>
</ng-template>
