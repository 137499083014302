import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageService } from 'src/app/services/page.service';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/models/group.model';
import { IUserDetail } from 'src/app/models/user.models';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { normalizeDate } from 'src/app/utils/normalizeDate';
import { UsersService } from 'src/app/services/users.service';

@Component({
  templateUrl: './users-group-list-page.component.html',
  styleUrls: ['./users-group-list-page.component.scss'],
  providers: [SearchBarListService],
})
export class UsersGroupListPageComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  private groupId!: string | null;
  private user!: any;
  groups!: Observable<any>;
  isLoading: boolean = true;
  published: boolean = false;
  orderOptions: string[] = ['Alfabeticamente', 'Mas reciente', 'Mas antiguo'];
  private groupsData$: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private groupService: GroupService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    public searchBarListService: SearchBarListService,
    private activatedRoute: ActivatedRoute,
    private userService: UsersService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Grupos | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.groupId = this.activatedRoute.snapshot.paramMap.get('groupId');
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.goToCreation();
        }
      });

    if (this.groupId) {
      this.getGroupUsers(this.groupId);
    }
  }

  ngAfterViewInit(): void {
    this.groups = combineLatest([
      this.groupsData$,
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Mas reciente')),
    ]).pipe(
      tap(() => (this.isLoading = true)),
      map(([groups, inputSearchResult, selectedOption]) => {
        return [
          this.handleApplyFilter(groups, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([groups, inputSearchResult]) => {
        return (groups as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['id', 'name', 'email'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.groups.subscribe({
      next: () => (this.isLoading = false),
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.isLoading = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getGroupUsers(groupId: string): void {
    this.isLoading = true;
    this.groupService
      .getGroupUsers(groupId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        this.isLoading = false;
        this.creationalPagesLayoutService.layoutConfig.next({
          headerTitle: data.name,
          headerTitleIcon: '/assets/images/icons/icon_team_details.svg',
          headerButtonIcon: 'assets/images/icons/icon_create.svg',
          headerButtonText: 'Añadir usuario',
        });
        this.groupsData$.next(
          data.users.map((user: IUserDetail) => ({
            ...user,
            name: user.first_name + user.last_name,
          }))
        );
      });
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  handleCloseModal() {
    this.universalModalService.closeModal();
  }

  confirmDelete() {
    // TODO IMPROVE UI
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public deleteParticipant() {
    this.universalModalService.closeModal();
    this.groupService
      .deleteParticipant({
        organization: parseInt(this.groupId ?? ''),
        participant_id: parseInt(this.user.id),
      })
      .subscribe({
        complete: () => {
          this.getGroupUsers(this.groupId as string);
          this.universalModalService.openModal(
            undefined,
            null,
            'Usuario eliminado exitosamente'
          );
        },
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.universalModalService.openModal(
            undefined,
            null,
            'No se pudo eliminar el usuario'
          );
        },
      });
  }

  _getUserDetails(userId: number, templateRef: TemplateRef<any>): void {
    this.userService.getUserDetails(userId).subscribe({
      error: ({ error }) => {
        console.error('getUserDetails error', error);
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        // this.openModal('Algo ha ocurrido', htmlErrorsContent)
        this.isLoading = false;
      },
      next: (data: IUserDetail) => {
        const userDetail = {
          ...data,
          created_at: normalizeDate(data.authored_at, true),
        };

        this.universalModalService.openModal(templateRef, userDetail);

        this.isLoading = false;
      },
    });
  }

  public handleListElementClick(userId: number, templateRef: TemplateRef<any>) {
    this._getUserDetails(userId, templateRef);
  }

  public handleListElementClickAction(actionName: string, user: any): void {
    this.user = user;
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(user),
      delete: () => this.confirmDelete(),
    };
    actions[actionName]();
  }

  public handleListElEdit(program: any) {
    // this.router.navigate([`/dashboard/pages/programas/${program.id}/editar`]);
  }

  public goToCreation() {
    this.router.navigate([`dashboard/grupos/${this.groupId}/usuarios/añadir`]);
  }
}
