<li
  (click)="isCheckable ? _onClick(checkContentInput.nativeElement) : _onClick()"
  class="list-el-container mb-3 col-12 disable-li-style position-relative"
  [class.disabled]="disabled"
>
  <app-card
    [cursorPointer]="cursorPointer"
    [enableHover]="enableHover"
    borderRadius="10px"
    height="100%"
    padding="20px 20px 20px 10px"
    [backgroundColor]="
      disabled || visualDisabled
        ? '#d9d9d9'
        : backgroundColor
        ? backgroundColor
        : '#fff'
    "
  >
    <article card__content>
      <ng-container
        *ngIf="customTemplate === 'DEFAULT'"
        [ngTemplateOutlet]="defaultTemplate"
        [ngTemplateOutletContext]="{ $implicit: entityData }"
      >
      </ng-container>
      <ng-container
        *ngIf="customTemplate === 'TEAMS'"
        [ngTemplateOutlet]="defaultTemplateTeams"
        [ngTemplateOutletContext]="{ $implicit: entityData }"
      >
      </ng-container>
      <ng-container
        *ngIf="customTemplate === 'USERS'"
        [ngTemplateOutlet]="defaultTemplateUsers"
        [ngTemplateOutletContext]="{ $implicit: entityData }"
      >
      </ng-container>
    </article>
  </app-card>
</li>

<ng-template #defaultTemplate>
  <div
    class="row gap-2 gap-lg-0 ps-3 ps-lg-0"
    [class.ps-lg-3]="isCheckable || isDragable"
  >
    <div
      class="d-flex align-items-md-center justify-content-start col-12 col-xxl-1 m-0 p-0 text-italic"
      [class.col-lg-1]="!isDragable && !isCheckable"
      [class.col-lg-2]="isDragable && isCheckable"
      [class.justify-content-center]="!isDragable && !isCheckable"
    >
      <section
        class="d-flex flex-nowrap row-md w-100 gap-2"
        [class.gap-4]="isDragable && isCheckable"
      >
        <div
          *ngIf="isDragable"
          cdkDragHandle
          class="col-1 d-flex"
          [class.justify-content-center]="isDragable && isCheckable"
        >
          <img
            src="/assets/images/icons/icon_drag.svg"
            alt="Drag icon to order each element"
          />
        </div>
        <div
          *ngIf="isCheckable"
          class="col-1 d-flex"
          [class.justify-content-center]="isDragable && isCheckable"
        >
          <input
            #checkContentInput
            type="checkbox"
            [checked]="disabled"
            [disabled]="disabled"
            (change)="_onClick(checkContentInput)"
            style="pointer-events: none"
            class="d-none"
          />
          <img
            *ngIf="checkContentInput.checked"
            [src]="
              disabled
                ? '/assets/images/icons/icon_checked_disabled.svg'
                : '/assets/images/icons/icon_checked.svg'
            "
            alt="check input icon"
            class="w-24px h-24px"
          />
          <img
            *ngIf="!checkContentInput.checked"
            src="/assets/images/icons/icon_unchecked.svg"
            alt="check input icon"
            class="w-24px h-24px"
          />
        </div>
        <div
          class="d-flex"
          [class.col-12]="!isDragable && !isCheckable"
          [class.col-10]="isDragable || isCheckable"
          [class.justify-content-lg-center]="!isDragable || !isCheckable"
        >
          <strong
            ><i class="text-black-50" style="opacity: 0.8"
              ><small>{{ idLabel }}{{ entityData[customIdProp] }}</small></i
            ></strong
          >
        </div>
      </section>
    </div>

    <div
      class="d-flex flex-column justify-content-center gap-2 gap-md-0 col-12 col-lg-9 col-xl-8"
    >
      <div class="row w-100 w-md-32">
        <span class="font-bold fs-5 m-0 p-0">{{
          entityData["name"] | trimString : (isSingleRowDesign ? 80 : 40)
        }}</span>
      </div>
      <div *ngIf="!isSingleRowDesign" class="row">
        <div class="d-flex align-items-center p-0 m-0 col-12 col-lg-8">
          <div *ngIf="!withoutPublished">
            <span *ngIf="entityData['is_published']"
              ><small class="text-green responsive-text">Publicado</small></span
            >
            <span *ngIf="!entityData['is_published']"
              ><small class="responsive-text">No publicado</small></span
            >
          </div>
          <div
            *ngIf="!withoutPublished"
            class="d-flex align-items-center justify-content-center p-0 m-0 mx-2"
          >
            <div class="h-20px border border-1 border-dark"></div>
          </div>
          <div class="d-flex align-items-center justify-content-center p-0 m-0">
            <span
              ><small
                class="responsive-text"
                [innerHTML]="
                  'Descripción: ' +
                  (entityData['description']
                    ? (entityData['description'] | trimString : 80)
                    : 'N/A')
                "
              ></small
            ></span>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!isSingleRowDesign" class="row mt-1">
        <ul
          *ngIf="[].length === 0"
          class="d-flex align-items-center gap-2 flex-wrap p-0 m-0"
        >
          <li
            *ngFor="
              let element of [
                '2do ESO',
                'Vocacion',
                'Valores',
                'Vocacion',
                'Valores',
                '2do Eso'
              ]
            "
            class="list-el-tag"
            [class.list-el-tag--orange-alt]="tagsTemplate === 'yellowTemplate'"
          >
            <section class="d-flex gap-2">
              <img
                *ngIf="tagsTemplate === 'default'"
                src="/assets/images/icons/icon_list_tag.svg"
                alt="tag icon"
              />
              <img
                *ngIf="tagsTemplate === 'yellowTemplate'"
                src="/assets/images/icons/icon_selector.svg"
                alt="tag icon"
              />
              <span>{{ element }}</span>
            </section>
            <img
              *ngIf="tagsTemplate === 'yellowTemplate'"
              src="/assets/images/icons/close_white_icon.svg"
              class="h-11px"
            />
          </li>
        </ul>
      </div> -->
    </div>

    <div
      class="d-flex flex-column align-items-lg-center justify-content-center col-12 col-lg-2 col-xl-3 mt-3 m-md-0 p-0"
    >
      <div
        class="d-flex align-items-center justify-content-lg-end gap-3 buttons-container"
      >
        <button
          *ngFor="let action of actions"
          class="cursor-pointer border-0 bg-transparent"
          (click)="_onClickAction($event, action.action)"
        >
          <img
            class="h-28px"
            [src]="action.icon"
            [alt]="action.action + 'icon'"
          />
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #defaultTemplateTeams let-groupData>
  <div
    class="row gap-2 gap-lg-0 ps-3 ps-lg-0"
    [class.ps-lg-3]="isCheckable || isDragable"
  >
    <div
      class="d-flex align-items-md-center justify-content-start col-12 col-xxl-1 m-0 p-0 text-italic"
      [class.col-lg-1]="!isDragable && !isCheckable"
      [class.col-lg-2]="isDragable && isCheckable"
      [class.justify-content-center]="!isDragable && !isCheckable"
    >
      <section
        class="d-flex flex-nowrap row-md w-100 gap-2"
        [class.gap-4]="isDragable && isCheckable"
      >
        <div
          *ngIf="isDragable"
          cdkDragHandle
          class="col-1 d-flex"
          [class.justify-content-center]="isDragable && isCheckable"
        >
          <img
            src="/assets/images/icons/icon_drag.svg"
            alt="Drag icon to order each element"
          />
        </div>
        <div
          class="d-flex"
          [class.col-12]="!isDragable && !isCheckable"
          [class.col-10]="isDragable || isCheckable"
          [class.justify-content-lg-center]="!isDragable || !isCheckable"
        >
          <img
            *ngIf="!groupData.logo"
            src="/assets/images/icons/icon_team_green.svg"
            alt="Team avatar"
            class="rounded-circle"
            style="max-width: 60px"
          />
          <img
            *ngIf="groupData.logo"
            [src]="groupData.logo"
            alt="Team avatar"
            class="rounded-circle"
            style="width: 60px; height: 60px; object-fit: cover"
          />
        </div>
      </section>
    </div>

    <div
      class="d-flex flex-column justify-content-center gap-2 gap-md-0 col-12 col-lg-9 col-xl-8"
    >
      <div class="row w-100 w-md-32">
        <span class="font-bold fs-5 m-0 p-0">{{
          groupData["name"] | trimString
        }}</span>
      </div>
      <div *ngIf="!isSingleRowDesign" class="row">
        <div class="d-flex align-items-center p-0 m-0 col-12 col-lg-8">
          <div>
            <span
              ><small class="text-green responsive-text"
                >{{
                  groupData["users"] ? groupData["users"].length : 10
                }}
                usuarios</small
              ></span
            >
          </div>
          <div
            class="d-flex align-items-center justify-content-center p-0 m-0 mx-2"
          >
            <div class="h-20px border border-1 border-dark"></div>
          </div>
          <div class="d-flex align-items-center justify-content-center p-0 m-0">
            <span
              ><small
                class="responsive-text"
                [innerHTML]="
                  groupData['description']
                    ? (groupData['description'] | trimString : 80)
                    : 'N/A'
                "
              ></small
            ></span>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!isSingleRowDesign" class="row mt-1">
        <ul
          *ngIf="[].length === 0"
          class="d-flex align-items-center gap-2 flex-wrap p-0 m-0"
        >
          <li
            *ngFor="
              let element of [
                '2do ESO',
                'Vocacion',
                'Valores',
                'Vocacion',
                'Valores',
                '2do Eso'
              ]
            "
            class="list-el-tag"
            [class.list-el-tag--orange-alt]="tagsTemplate === 'yellowTemplate'"
          >
            <section class="d-flex gap-2">
              <img
                *ngIf="tagsTemplate === 'default'"
                src="/assets/images/icons/icon_list_tag.svg"
                alt="tag icon"
              />
              <img
                *ngIf="tagsTemplate === 'yellowTemplate'"
                src="/assets/images/icons/icon_selector.svg"
                alt="tag icon"
              />
              <span>{{ element }}</span>
            </section>
            <img
              *ngIf="tagsTemplate === 'yellowTemplate'"
              src="/assets/images/icons/close_white_icon.svg"
              class="h-11px"
            />
          </li>
        </ul>
      </div> -->
    </div>

    <div
      class="d-flex flex-column align-items-lg-center justify-content-center col-12 col-lg-2 col-xl-3 mt-3 m-md-0 p-0"
    >
      <div
        class="d-flex align-items-center justify-content-lg-end gap-3 buttons-container"
      >
        <button
          *ngFor="let action of actions"
          class="cursor-pointer border-0 bg-transparent"
          (click)="_onClickAction($event, action.action)"
        >
          <img
            class="h-28px"
            [src]="action.icon"
            [alt]="action.action + 'icon'"
          />
        </button>
      </div>
    </div>

    <section class="colors">
      <div
        [style.background]="
          'linear-gradient(-250deg, rgba(217, 217, 217, 0) 19.19%, ' +
          groupData.primary_color +
          ' 53.96%, ' +
          groupData.secondary_color +
          ' 84.87%)'
        "
      ></div>
    </section>
  </div>
</ng-template>

<ng-template #defaultTemplateUsers let-userData>
  <div class="row gap-2 gap-lg-0 ps-3 ps-lg-0">
    <div
      class="d-flex align-items-md-center justify-content-center col-12 col-lg-2 col-xxl-2 m-0 p-0 text-italic"
    >
      <section class="d-flex flex-nowrap row-md w-100">
        <div
          class="d-flex justify-content-lg-center align-items-center pt-3 gap-4"
          [class.col-12]="!isCheckable"
          [class.col-10]="isCheckable"
        >
          <div *ngIf="isCheckable" class="d-flex justify-content-center pb-1">
            <input
              #checkContentInput
              type="checkbox"
              [checked]="disabled"
              [disabled]="disabled"
              (change)="_onClick(checkContentInput)"
              style="pointer-events: none"
              class="d-none"
            />
            <img
              *ngIf="checkContentInput.checked"
              [src]="
                disabled
                  ? '/assets/images/icons/icon_checked_disabled.svg'
                  : '/assets/images/icons/icon_checked.svg'
              "
              alt="check input icon"
              class="w-24px h-24px"
            />
            <img
              *ngIf="!checkContentInput.checked"
              src="/assets/images/icons/icon_unchecked.svg"
              alt="check input icon"
              class="w-24px h-24px"
            />
          </div>
          <img
            *ngIf="!userData.image_profile"
            src="/assets/images/icons/icon_user_green.svg"
            alt="Team avatar"
            class="w-80px h-80px rounded-circle"
          />
          <img
            *ngIf="userData.image_profile"
            [src]="userData.image_profile"
            alt="User Avatar"
            class="w-80px h-80px rounded-circle"
          />
        </div>
      </section>
    </div>

    <div
      class="d-flex flex-column justify-content-center gap-2 gap-md-0 col-12 col-lg-8 col-xl-8"
    >
      <div class="row w-100 w-md-32">
        <span class="d-flex align-items-center font-bold fs-5 m-0 p-0"
          >{{ userData.first_name + " " + userData.last_name | trimString }}
          <figure *ngIf="userData.verified" class="m-0 ms-3 mb-1">
            <svg
              width="25"
              height="25"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.88443 1.02373C6.96455 -0.341242 9.03544 -0.341242 10.1156 1.02373C10.6295 1.67315 11.4132 2.05056 12.2413 2.04743C13.9819 2.04086 15.2731 3.65995 14.8794 5.35547C14.6921 6.16216 14.8856 7.01019 15.4044 7.65572C16.4948 9.01249 16.034 11.0315 14.4629 11.7808C13.7154 12.1373 13.1731 12.8173 12.9918 13.6254C12.6109 15.3239 10.7451 16.2224 9.1797 15.4612C8.43492 15.0991 7.56508 15.0991 6.8203 15.4612C5.2549 16.2224 3.3891 15.3239 3.00818 13.6254C2.82694 12.8173 2.28461 12.1373 1.53711 11.7808C-0.0339844 11.0315 -0.494801 9.01249 0.595596 7.65572C1.11438 7.01019 1.30794 6.16216 1.12061 5.35547C0.726883 3.65995 2.01806 2.04086 3.75868 2.04743C4.58683 2.05056 5.37053 1.67315 5.88443 1.02373ZM4.54826 7.89043C4.93878 7.4999 5.57195 7.4999 5.96247 7.89043L7.17778 9.10574L10.8121 5.47138C11.2027 5.08085 11.8358 5.08085 12.2264 5.47138C12.6169 5.8619 12.6169 6.49506 12.2264 6.88559L7.91607 11.1959C7.90618 11.2067 7.89601 11.2173 7.88556 11.2277C7.49504 11.6183 6.86187 11.6183 6.47135 11.2277L4.54826 9.30464C4.15773 8.91412 4.15773 8.28095 4.54826 7.89043Z"
                fill="#00caa8"
              />
            </svg>
          </figure>
        </span>
      </div>
      <div class="row">
        <div class="d-flex align-items-center p-0 m-0 col-12 col-lg-8">
          <div>
            <span
              ><small class="text-green responsive-text">{{
                userData.user_type | studentType
              }}</small></span
            >
          </div>
          <div
            class="d-flex align-items-center justify-content-center p-0 m-0 mx-2"
          >
            <div class="h-20px border border-1 border-dark separator"></div>
          </div>
          <div class="d-flex align-items-center justify-content-center p-0 m-0">
            <span
              ><small
                ><i
                  class="responsive-text"
                  [innerHTML]="
                    userData.email ? (userData.email | trimString : 80) : 'N/A'
                  "
                ></i></small
            ></span>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div
          class="user-list-el-badget"
          [class.user-list-el-badget--yellow]="userData.is_subscribed"
          [class.user-list-el-badget--gray]="!userData.is_subscribed"
        >
          <p class="text-white">
            {{ userData.is_subscribed ? "Plan Premium" : "Plan Gratuito" }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="d-flex flex-column align-items-lg-center justify-content-center col-12 col-lg-1 col-xl-2 mt-3 m-md-0 p-0"
    >
      <div class="d-flex align-items-center justify-content-lg-end gap-3">
        <div
          *ngIf="!withoutToggle"
          (click)="_onClickAction($event, 'toggle')"
          class="button-element"
        >
          <app-toggle-switch [active]="userData.is_active"></app-toggle-switch>
        </div>
        <button
          *ngFor="let action of actions"
          class="cursor-pointer border-0 bg-transparent button-element"
          [class.visible]="action?.always_visible"
          [class.d-none]="action.action === 'verify' && userData.verified"
          (click)="_onClickAction($event, action.action)"
        >
          <img
            class="h-24px"
            [src]="action.icon"
            [alt]="action.action + 'icon'"
          />
        </button>
      </div>
    </div>
  </div>
</ng-template>
