<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content class="row">
        <div class="col d-flex justify-content-between align-items-center">
          <h1 class="font-24 font-primary--eb">Categoria: {{report.categoryName}}</h1>

          <div class="d-flex col-4 justify-content-end align-items-center">
            <a type="button" routerLink="/dashboard/instituciones/parametros/{{report.id}}/editar"
              class="btn bg-warn text-white align-self-center">
              Editar
            </a>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <!-- <div class="coupon-wrapper"> -->
  <div class="mb-4 mt-4">
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <p class="font-primary--b mb-3">
            Parametro: <span>{{report.parameterName}}</span>
          </p>
        </section>
      </article>
    </app-card>
  </div>

  <div *ngFor="let phrase of report.phrases" class="mb-4">
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section class="font-16">
          <div class="mb-3">
            <div class="d-flex justify-content-start align-items-center mb-3">
              <button title="Mostrar Categorias" type="button" class="btn mt-0 pt-0" (click)="phrase.toggleShow()">
                <img class="w-30px h-30px"
                  [src]="phrase.show ? '/assets/images/icons/icon_up.svg' : '/assets/images/icons/icon_down.svg'" />
              </button>

              <span class="font-primary--b font-18">{{phrase.label}}</span>
            </div>

            <div *ngIf="phrase.show">
              <span [outerHTML]="phrase.content
                    ? phrase.content
                    : '<span class=text-error>Vacio</span>'
                  "></span>
            </div>
          </div>
        </section>
      </article>
    </app-card>
  </div>
</main>
