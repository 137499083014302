<main class="activities-page page">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        Crear Nuevo Cupon
      </h1>
    </article>
  </app-card>

  <app-card
    width="100%"
    borderRadius="10px"
    padding="20px 40px"
    class="coupon-wrapper"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="couponForm"
      card__content class="w-100"
    >
      <div>
        <label for="name" class="text-start w-100 font-bold">Nombre del Cupón</label>

        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['name']"
        >
          {{formErrors?.['name']?.label}}
        </span>
    
        <input
          id="name"
          name="name"
          class="input w-100"
          type="text"
          placeholder="Ingrese nombre del cupón"
          formControlName="name"
        />
      </div>

      <div class="mt-3">
        <label for="code" class="text-start w-100 font-bold">
          Código

          <span class="font-12 opacity-70">( MI_CODIG0-123 )</span>
        </label>
    
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['code']"
        >
          {{formErrors?.['code']?.label}}
        </span>

        <input
          id="code"
          name="code"
          class="input w-100"
          type="text"
          placeholder="Ingrese codigo del cupón"
          formControlName="code"
        />
      </div>
    
      <div class="mt-3">
        <label for="description" class="text-start w-100 font-bold">Descripción</label>
    
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['description']"
        >
          {{formErrors?.['description']?.label}}
        </span>
    
        <input
          id="description"
          name="description"
          type="text"
          class="input w-100"
          formControlName="description"
          placeholder="Ingrese descripción del cupón"
        />
      </div>

      <div class="d-flex justify-content-between align-items-center mt-3">
        <div class="me-3 w-50">
          <label for="discount_percentage" class="text-start w-100 font-bold">
            Porcentaje de Descuento <span class="text-success">{{discountPercentage}}%</span>
          </label>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['discount_percentage']"
          >
            {{formErrors?.['discount_percentage']?.label}}
          </span>

          <input
            type="text"
            id="discount_percentage"
            name="discount_percentage"
            class="input w-100"
            appPercentageDirective
            [(ngModel)]="discountPercentage"
            formControlName="discount_percentage"
            placeholder="Ingrese porcentaje a descontar"
          />
        </div>

        <div class="w-50">
          <label for="available_quantity" class="text-start w-100 font-bold">Cantidad de Cupones</label>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['available_quantity']"
          >
            {{formErrors?.['available_quantity']?.label}}
          </span>
      
          <input
            id="available_quantity"
            name="available_quantity"
            class="input w-100"
            type="number"
            min="0"
            max="999"
            placeholder="Ingrese porcentaje a descontar"
            formControlName="available_quantity"
          />
        </div>
      </div>

      <button class="btn font-btn bg-red w-100 font-26 mt-3">
        <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
    
        <span class="text-white" *ngIf="!isLoading">
          Crear Cupon
        </span>
      </button>
    </form>
  </app-card>
</main>