<main class="page pages-page">
  <!-- HEADER -->

  <!-- Loader -->
  <app-page-loader *ngIf="isLoading"></app-page-loader>

  <!-- LIST -->
  <section class="mt-3" *ngIf="!isLoading">
    <ul class="row">
      <ng-container *ngFor="let theme of chapter?.pages; index as i">
        <li class="mb-3 col-12 col-md-6">
          <app-card
            borderRadius="10px"
            height="100%"
            padding="20px 40px"
            boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px"
          >
            <article card__content>
              <div class="row">
                <div class="col d-flex gap-3">
                  <h2 class="font-18 font-primary--b">
                    <small class="font-12 opacity-50 font-primary--eb"
                      >(ID{{ theme.id }})</small
                    >
                    {{ theme.name }}
                  </h2>

                  <span class="d-block text-grey opacity-50">
                    &lt;Tema&gt;
                  </span>
                </div>

                <div class="col-auto d-flex gap-3">
                  <button
                    type="button"
                    class="btn bg-blue-light text-white"
                    routerLink="/dashboard/pages/programas/contenidos/{{
                      seasonId
                    }}/capitulos/{{ chapterId }}/temas/{{ theme.id }}"
                  >
                    Ver
                  </button>

                  <button
                    type="button"
                    class="btn bg-orange-alt text-white"
                    routerLink="/dashboard/pages/programas/{{
                      theme.id
                    }}/editar"
                  >
                    Editar
                  </button>

                  <button
                    type="button"
                    (click)="confirmDelete(theme.page_id)"
                    class="btn bg-red text-white"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </article>
          </app-card>
        </li>
      </ng-container>

      <!-- WITHOUT CONTENT -->
      <article class="mt-3" *ngIf="!chapter">
        <app-card
          borderRadius="10px"
          padding="80px 120px"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        >
          <section card__content>
            <h2 class="text-center font-24 opacity-50">
              No has creado ningún tema aún,
              <span
                routerLink="/dashboard/pages/programas/nueva"
                class="text-green cursor-pointer"
                >crea</span
              >
              uno.
            </h2>
          </section>
        </app-card>
      </article>
    </ul>
  </section>
</main>
