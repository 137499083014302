import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { OpenModal } from '../store/ui/ui.actions';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpErrorInterceptorInterceptor implements HttpInterceptor {

  constructor(private store: Store, public router: Router, private cookieService: CookieService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            // TOKEN EXPIRED HANDLING
            if (error.error.code === 'token_not_valid') {
              this.store.dispatch(new OpenModal('Su sesión ha expirado.'))
              setTimeout(() => {
                this.router.navigate(['/signin'])
                this.cookieService.deleteAll()
                this.router.navigate(['/'])
              }, 500);

            }
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          return throwError(error);
        })
      )
  }
}
