import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { Content } from 'src/app/models/content.model';
import { Page } from 'src/app/models/page.models';

@Component({
  selector: 'app-pages-themes-page',
  templateUrl: './pages-themes-page.component.html',
  styleUrls: ['./pages-themes-page.component.scss'],
})
export class PagesThemesPageComponent implements OnInit, OnDestroy {
  contentId!: string;
  themeId!: string;
  subthemeId!: string;
  themeDetails!: Content;
  themeSubthemes: Content[] = [];
  isLoading = false;

  private destroyed$ = new ReplaySubject<void>(1);

  public listElementActions = [
    { action: 'edit', icon: '/assets/images/icons/icon_edit_new.svg' },
    { action: 'delete', icon: '/assets/images/icons/icon_delete_new.svg' },
  ];

  @ViewChild('deleteConfirmationModalTemplate')
  deleteConfirmationModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteErrorModalTemplate')
  deleteErrorModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteSuccessModalTemplate')
  deleteSuccessModalTemplate!: TemplateRef<any>;

  private selectedSubthemeId!: string;

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private breadcrumbService: BreadcrumbService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Subtemas del Tema | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.contentId = this.route.snapshot.paramMap.get('contentId')!;
    this.themeId = this.route.snapshot.paramMap.get('themeId')!;
    this.subthemeId = this.route.snapshot.paramMap.get('subthemeId')!;

    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.goToCreation();
      });

    this.getThemeDetails(this.themeId);
    this.getSubthemesByThemeId(this.themeId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  navigateToBack(): void {
    this.location.back();
  }

  public goToCreation(): void {
    this.router.navigate([
      `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/nuevo`,
    ]);
  }

  private getThemeDetails(id: string): void {
    this.pageService.getPageById(id).subscribe((response: unknown) => {
      this.themeDetails = response as Content;
      this.creationalPagesLayoutService.layoutConfig.next({
        headerTitle: this.themeDetails.internal_name,
        headerTitleIcon: '/assets/images/icons/icon_content.svg',
        headerButtonIcon: 'assets/images/icons/icon_create.svg',
        headerButtonText: 'Crear subtema',
      });

      this.breadcrumbService.pushToUrl({
        id: this.themeDetails.id,
        name: this.themeDetails.internal_name,
      });
    });
  }

  getSubthemesByThemeId(themeId: string, disableLoading = false): void {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.pageService.getContentThemes(themeId).subscribe({
      next: (data: unknown) => {
        this.themeSubthemes = (data as Content[]).map((element) => ({
          ...element,
          name: element.internal_name ?? '',
          description: element.internal_description ?? '',
        }));
        this.isLoading = false;
      },
      error: (errorResponse) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(
          errorResponse.error
        );
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  openDeleteConfirmationModal(subthemeId: string): void {
    this.selectedSubthemeId = subthemeId;
    this.universalModalService.openModal(this.deleteConfirmationModalTemplate);
  }

  deleteSubtheme(): void {
    this.universalModalService.closeModal();
    this.isLoading = true;
    this.pageService.deletePage(this.selectedSubthemeId).subscribe({
      next: () => {
        this.universalModalService.openModal(this.deleteSuccessModalTemplate);
        this.getSubthemesByThemeId(this.themeId);
      },
      error: (errorResponse) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(
          errorResponse.error
        );
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  closeModal(): void {
    this.universalModalService.closeModal();
  }

  updateOrder(): void {
    this.themeSubthemes.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  public drop(event: CdkDragDrop<Content[]>): void {
    moveItemInArray(
      this.themeSubthemes,
      event.previousIndex,
      event.currentIndex
    );

    if (event.previousIndex !== event.currentIndex) {
      const updatedSubtheme = {
        ...this.themeSubthemes[event.currentIndex],
        order: event.currentIndex + 1,
      };
      this.pageService.updatePage(updatedSubtheme as Page).subscribe(() => {
        this.getSubthemesByThemeId(this.themeId, true);
      });
    }
  }

  public handleListElementClick(subtheme: Content): void {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: subtheme.name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([
      `/dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${subtheme.id}/actividades`,
    ]);
  }

  public handleListElementClickAction(
    actionName: string,
    subtheme: Content
  ): void {
    const actions: { [key: string]: () => void } = {
      delete: () => this.openDeleteConfirmationModal(subtheme.id.toString()),
      edit: () => this.handleListElEdit(subtheme.id.toString()),
    };

    if (actions[actionName]) {
      actions[actionName]();
    } else {
      console.warn(`Action "${actionName}" is not defined.`);
    }
  }

  private handleListElEdit(id: string): void {
    this.router.navigateByUrl(
      `/dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${id}/editar`
    );
  }
}
