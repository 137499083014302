import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { UsersService } from 'src/app/services/users.service';
import { UniversalModalComponent } from 'src/app/components/core/universal-modal/universal-modal.component';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { normalizeDate } from 'src/app/utils/normalizeDate';
import { IUserDetail } from 'src/app/models/user.models';
import { MetaDataService } from 'src/app/services/metadata.service';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [SearchBarListService],
})
export class UsersComponent implements OnInit, AfterViewInit {
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  entry!: ViewContainerRef;
  sub!: Subscription;
  users!: Observable<any>;
  userId!: string;
  isLoading: boolean = true;
  published: boolean = false;
  orderOptions: string[] = ['Alfabeticamente', 'Mas reciente', 'Mas antiguo'];
  private usersData$: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public listElementActions: {
    action: string;
    icon: string;
    always_visible?: boolean;
  }[] = [
    {
      action: 'verify',
      icon: '/assets/images/icons/icon_user_check.svg',
      always_visible: true,
    },
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit_new.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private store: Store,
    public searchBarListService: SearchBarListService,
    private breadcrumbService: BreadcrumbService,
    private userService: UsersService,
    private universalModalService: UniversalModalService,
    private meta: MetaDataService
  ) {
    this.title.setTitle('Usuarios | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Usuarios',
      headerTitleIcon: '/assets/images/icons/icon_user.svg',
      headerButtonText: 'Crear usuario',
      headerButtonIcon: '/assets/images/icons/icon_create.svg',
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.resetRoutes();
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.goToCreation();
        }
      });

    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.users = combineLatest([
      this.usersData$,
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Mas reciente')),
    ]).pipe(
      tap(() => {
        this.isLoading = true;
      }),
      map(([users, inputSearchResult, selectedOption]) => {
        console.log('BUM', users);
        return [
          this.handleApplyFilter(users, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([users, inputSearchResult]) => {
        return (users as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['id', 'name', 'email'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.users.subscribe({
      next: () => (this.isLoading = false),
      error: (error) => {
        this.isLoading = false;
        this.errorsService.handleErrors(error);
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getUsers() {
    this.isLoading = true;
    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.isLoading = false;
        this.usersData$.next(
          data.map((user: IUserDetail) => ({
            ...user,
            name: user.first_name + user.last_name,
          }))
        );
      });
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public deleteUser() {
    this.handleCloseModal();
    this.userService.removeUser({ id: this.userId }).subscribe({
      complete: () => {
        this.universalModalService.openModal(
          undefined,
          null,
          'Usuario eliminado exitosamente'
        );
        this.getUsers();
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'No se pudo eliminar el usuario'
        );
      },
    });
  }

  _getUserDetails(userId: number, templateRef: TemplateRef<any>): void {
    this.userService.getUserDetails(userId).subscribe({
      error: ({ error }) => {
        console.error('getUserDetails error', error);
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        // this.openModal('Algo ha ocurrido', htmlErrorsContent)
        this.isLoading = false;
      },
      next: (data: IUserDetail) => {
        const userDetail = {
          ...data,
          created_at: normalizeDate(data.authored_at, true),
        };

        this.universalModalService.openModal(templateRef, userDetail);

        this.isLoading = false;
      },
    });
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public handleListElementClick(userId: number, templateRef: TemplateRef<any>) {
    this._getUserDetails(userId, templateRef);
  }

  public handleUserStatus(userId: number) {
    this.userService
      .toggleIsActiveUser({ id: userId })
      .subscribe((response) => {
        this.getUsers();
      });
  }

  public handleVerifyUser(userId: number) {
    this.userService.verifyUser({ id: userId }).subscribe({
      next: () => {
        this.getUsers();
        this.universalModalService.openModal(
          undefined,
          null,
          'Usuario verificado exitosamente.'
        );
      },
      error: () => {
        this.universalModalService.openModal(
          undefined,
          null,
          'El usuario no se pudo verificar.'
        );
      },
    });
  }

  public handleListElementClickAction(
    actionName: string,
    userId: number
  ): void {
    this.userId = userId.toString();
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(userId),
      delete: () => this.confirmDelete(),
      toggle: () => this.handleUserStatus(userId),
      verify: () => this.handleVerifyUser(userId),
    };
    console.log({ actionName, userId });
    actions[actionName]();
  }

  public handleListElEdit(userId: number): void {
    this.router.navigate([`/dashboard/usuarios/${userId}/editar`]);
  }

  public goToCreation() {
    this.router.navigate(['dashboard/usuarios/nuevo']);
  }
}
