import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICoupon } from 'src/app/models/coupon.model';
import { CouponsService } from 'src/app/services/coupons.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-coupons-page',
  templateUrl: './coupons-page.component.html',
  styleUrls: ['./coupons-page.component.scss']
})

export class CouponsPageComponent implements OnInit {
  @ViewChild('content') modal!: ModalComponent;
  couponToDelete!: string | number
  coupons: ICoupon[] = []
  isLoading: boolean = true

  constructor(
    private couponService: CouponsService,
    private errorsService: ErrorsService,
    private modalService: NgbModal,
    private title: Title
  ) {
    this.title.setTitle('Cupones | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.couponService.getCoupons().subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICoupon[]) => this.coupons = data,
      complete: () => this.isLoading = false
    })
  }

  confirmDeleteCoupon(id: string | number) {
    this.couponToDelete = id;
    this.modalService.open(this.modal, { centered: true })
  }

  deleteCoupon(): void {
    const couponId = this.couponToDelete as string

    this.couponService.deleteCoupon(couponId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICoupon) => console.log('deleteCoupon data', data),
      complete: () => {
        const couponIdx = this.coupons.findIndex((coupon => coupon.id === couponId))
        this.coupons.splice(couponIdx, 1)
      }
    })
    this.dismissModal()
  }

  dismissModal() {
    this.modalService.dismissAll()
  }
}
