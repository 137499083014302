export enum CAREER_TYPE {
  TRAINING_CIRCLE = 'TRAINING_CIRCLE',
  UNIVERSITY_DEGREE = 'UNIVERSITY_DEGREE'
}

export const CAREER_TYPE_LABEL = {
  [CAREER_TYPE.TRAINING_CIRCLE]: 'Círculo de Entrenamiento',
  [CAREER_TYPE.UNIVERSITY_DEGREE]: 'Título Universitario'
}

export interface ICareerUniversity {
  id: number
  title: string
  type: CAREER_TYPE,
  icon: string
  primary_color: string
  secundary_color: string
  description: string
  job: string
  output_profile: string
  acronym: string | null
  vocational_interests: {
    id: number
    technical: string // all parameters should be "0.00"
    investigator: string
    entrepreneur: string
    communicator: string
    analyst: string
  }
  multiple_intelligences: {
    id: number
    linguistic: string
    mathematical_logician: string
    space: string
    cinematic_bodily: string
    musical: string
    intrapersonal: string
    interpersonal: string
    naturalist: string
  }
  professional_fields: {
    id: number
    manual_technician: string
    research_scientist: string
    creative_artistic: string
    social_healthcare: string
    business_persuasive: string
    management: string
    virtual_digital_streamer: string
  }

  output_title: string
  output_order: number
  output_left_aligned: boolean

  job_title: string
  job_order: number
  job_left_aligned: boolean

  description_order: number
  description_left_aligned: boolean


}

export interface ICareerToCreate {
  id?: string
  title: string
  type: CAREER_TYPE,
  icon?: FileList | string
  primary_color: string
  secundary_color: string
  acronym: string
  description: string
  job: string
  output_profile: string

  output_title: string
  output_order: number
  output_left_aligned: boolean

  job_title: string
  job_order: number
  job_left_aligned: boolean

  description_order: number
  description_left_aligned: boolean
}

export interface ICareerToUpdate extends ICareerToCreate {
  vocational_interests: {
    id: number
    technical: string // all parameters should be "0.00"
    investigator: string
    entrepreneur: string
    communicator: string
    analyst: string
  }
  multiple_intelligences: {
    id: number
    linguistic: string
    mathematical_logician: string
    space: string
    cinematic_bodily: string
    musical: string
    intrapersonal: string
    interpersonal: string
    naturalist: string
  }
  professional_fields: {
    id: number
    manual_technician: string
    research_scientist: string
    creative_artistic: string
    social_healthcare: string
    business_persuasive: string
    management: string
    virtual_digital_streamer: string
  }
}
