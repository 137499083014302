import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { Router } from '@angular/router';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import {
  BehaviorSubject,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { sortByProperty } from 'src/app/utils/filters';
import { Content } from 'src/app/models/content.model';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
  providers: [SearchBarListService],
})
export class ContentPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('deleteConfirmationModalTemplate', { read: TemplateRef })
  deleteConfirmationModalTemplate!: TemplateRef<any>;

  @ViewChild('duplicateConfirmationModalTemplate', { read: TemplateRef })
  duplicateConfirmationModalTemplate!: TemplateRef<any>;

  @ViewChild('duplicateSuccessModalTemplate', { read: TemplateRef })
  duplicateSuccessModalTemplate!: TemplateRef<any>;

  @ViewChild('deleteErrorModalTemplate', { read: TemplateRef })
  deleteErrorModalTemplate!: TemplateRef<any>;

  @ViewChild('deleteSuccessModalTemplate', { read: TemplateRef })
  deleteSuccessModalTemplate!: TemplateRef<any>;

  selectedContentId!: string;
  isLoading = true;

  orderOptions: string[] = ['Alfabéticamente', 'Más reciente', 'Más antiguo'];

  listElementActions = [
    {
      action: 'duplicate',
      icon: '/assets/images/icons/icon_duplicate_new.svg',
    },
    { action: 'edit', icon: '/assets/images/icons/icon_edit_new.svg' },
    { action: 'delete', icon: '/assets/images/icons/icon_delete_new.svg' },
  ];

  private destroyed$ = new ReplaySubject<void>(1);
  private contentData$ = new BehaviorSubject<Content[]>([]);
  contents$ = combineLatest([
    this.contentData$,
    this.searchBarListService.getSearchInput().pipe(startWith('')),
    this.searchBarListService
      .getFilterSelected()
      .pipe(startWith('Más reciente')),
  ]).pipe(
    map(([contents, searchInput, selectedFilter]) => {
      const filteredContents = this.applyFilter(contents, selectedFilter);
      return this.applySearch(filteredContents, searchInput);
    }),
    shareReplay(1),
    takeUntil(this.destroyed$)
  );

  constructor(
    private pageService: PageService,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private searchBarListService: SearchBarListService,
    private breadcrumbService: BreadcrumbService,
    private universalModalService: UniversalModalService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Contenidos',
      headerTitleIcon: '/assets/images/icons/icon_content.svg',
      headerButtonText: 'Crear contenido',
      headerButtonIcon: '/assets/images/icons/icon_create.svg',
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.resetRoutes();
    this.loadContents();

    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.router.navigate(['dashboard/pages/contenidos/nuevo']);
      });
  }

  ngAfterViewInit(): void {
    // The contents$ observable is already set up in the class property
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private applyFilter(contents: Content[], filter: string): Content[] {
    const filterOptions: { [key: string]: () => Content[] } = {
      [this.orderOptions[0]]: () => sortByProperty(contents, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(contents, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(contents, 'id', 'asc'),
    };

    return filterOptions[filter] ? filterOptions[filter]() : contents;
  }

  private applySearch(contents: Content[], searchTerm: string): Content[] {
    return contents.filter((content) =>
      this.searchBarListService
        .concatPropertiesValues(content as unknown as Record<string, string>, [
          'id',
          'name',
        ])
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  private loadContents(): void {
    this.isLoading = true;
    this.pageService.getContents().subscribe({
      next: (data: Content[]) => {
        const formattedData = data.map((element: Content) => ({
          ...element,
          name: element.internal_name ?? '',
          description: element.internal_description,
        }));
        this.contentData$.next(formattedData);
        this.isLoading = false;
      },
      error: (errorResponse) => {
        console.error(errorResponse);
        this.isLoading = false;
        // Handle error appropriately
      },
    });
  }

  openDeleteConfirmationModal(contentId: string): void {
    this.selectedContentId = contentId;
    this.universalModalService.openModal(this.deleteConfirmationModalTemplate);
  }

  deleteContent(): void {
    this.universalModalService.closeModal();
    this.isLoading = true;
    this.pageService.deletePage(this.selectedContentId).subscribe({
      next: () => {
        this.universalModalService.openModal(this.deleteSuccessModalTemplate);
        this.loadContents();
      },
      error: (errorResponse) => {
        console.error(errorResponse);
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  closeModal(): void {
    this.universalModalService.closeModal();
  }

  handleListElementClick(content: Content): void {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: content.internal_name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([`/dashboard/pages/contenidos/${content.id}/temas`]);
  }

  editContent(content: Content): void {
    this.router.navigate([`/dashboard/pages/contenidos/${content.id}/editar`]);
  }

  public openDuplicateConfirmationModal(contentId: string): void {
    this.selectedContentId = contentId;
    this.universalModalService.openModal(
      this.duplicateConfirmationModalTemplate
    );
  }

  public duplicateContent(): void {
    this.universalModalService.closeModal();
    this.isLoading = true;
    this.pageService
      .duplicateQuestionaryById(this.selectedContentId as unknown as number)
      .subscribe({
        next: () => {
          this.universalModalService.openModal(
            this.duplicateSuccessModalTemplate
          );
          this.loadContents();
        },
        error: (errorResponse) => {
          console.error(errorResponse);
          this.isLoading = false;
          this.universalModalService.openModal(
            undefined,
            undefined,
            'No se pudo duplicar el contenido'
          );
        },
      });
  }

  handleListElementAction(actionName: string, content: Content): void {
    const actions: { [key: string]: () => void } = {
      delete: () =>
        this.openDeleteConfirmationModal(content.id as unknown as string),
      edit: () => this.editContent(content),
      duplicate: () =>
        this.openDuplicateConfirmationModal(content.id as unknown as string),
    };

    if (actions[actionName]) {
      actions[actionName]();
    } else {
      console.warn(`Action "${actionName}" is not defined.`);
    }
  }
}
