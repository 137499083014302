<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
        fluid: true,
        aspectRatio: '16:9',
        autoplay: false,
        controls: true,
        sources: [{
          src: video,
          type: 'video/mp4'
        }]
      }"></app-video>
  </div>

  <div class="scheins-labels-wrapper" *ngIf="options.length">
    <span></span>
    <span>{{stackedPoints}}/<span class="fw-bold">{{maxPoints}}</span> </span>
  </div>
  <ul class="list-unstyled scheins">
    <ng-container *ngFor="let option of options, index as i">
      <li class="schein-item" *ngIf="!option.delete">
        <div class="label-wrapper">
          <label class="d-block schein__label" for="range-{{option.id}}">
            {{option.name}}
          </label>
          <div class="custom-tooltip">
            <img src="/assets/images/icons/icon_info_alt.svg" alt="">
            <p>
              {{option.body}}
            </p>
          </div>
        </div>

        <div class="schein-menu">
          <!-- decreaseDisabled || -->
          <button [disabled]="option._points === 0" class="minus-trigger" (click)="decreaseHandler(option)">
            <p>-</p>
          </button>
          <input disabled type="number" [value]="option._points">
          <button [disabled]="increaseDisabled" class="plus-trigger" (click)="increaseHandler(option)">
            <p>+</p>
          </button>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
<h3 *ngIf="!options!.length" class="font-primary text-grey font-16 opacity-50 text-center">Aun no ha provisto de
  opciones a está actividad</h3>
