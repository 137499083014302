<section layoutContent class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form>
        <div card__content>
          <div class="row mt-3 col-xl-10">
            <section class="d-flex mb-2 col-xl-2">
              <h5 class="font-20 text-muted me-2">Cover de perfil</h5>
            </section>
            <div
              class="d-flex flex-column align-items-center justify-content-center col-xl-10"
            >
              <picture class="position-relative" style="z-index: 0">
                <img width="100%" [src]="imageCover.preview" />
                <input
                  id="imageCover"
                  name="imageCover"
                  type="file"
                  class="imageCover-input"
                  placeholder="Ingrese imagen principal"
                  (change)="onFileChanged($event, 'cover')"
                />
              </picture>
            </div>
          </div>
          <div class="row mt-3 col-xl-10" style="transform: translateY(-80px)">
            <section class="d-flex mb-2 col-xl-2">
              <h5 class="font-20 text-muted me-2">Imagen de perfil</h5>
            </section>
            <div
              class="d-flex flex-column justify-content-center col-xl-10"
              style="transform: translateY(-40px) translateX(50px)"
            >
              <picture class="position-relative" style="width: 150px">
                <img
                  width="150px"
                  height="150px"
                  [src]="imageProfile.preview"
                  class="rounded-circle"
                />
                <input
                  id="imageProfile"
                  name="icon"
                  type="file"
                  class="imageProfile-input"
                  placeholder="Ingrese imagen principal"
                  (change)="onFileChanged($event)"
                />
              </picture>
            </div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
  <hr class="my-4 invisible" />
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="university.name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre de la universidad"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="university.university_description"
                    name="university_description"
                    id="university_description"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1">Etiquetas </label>
                  <input
                    class="input"
                    [(ngModel)]="university.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Ubicación</label>
                  <input
                    class="input"
                    [(ngModel)]="university.location"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese la ubicación"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Web</label>
                  <input
                    class="input"
                    [(ngModel)]="university.web"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese la url de la web"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label">Rango de precio </label>
                  <section class="d-flex align-items-center gap-4">
                    <fieldset class="d-flex align-items-center gap-2">
                      <input
                        class="input"
                        value="1000 - 2000"
                        [checked]="university.price_range === '1000 - 2000'"
                        [(ngModel)]="university.price_range"
                        name="tags"
                        type="radio"
                        id="tags"
                      />
                      <label class="label font-bold text-black"
                        ><small>1000€ - 2000€</small></label
                      >
                    </fieldset>
                    <fieldset class="d-flex align-items-center gap-2">
                      <input
                        class="input"
                        value="2000 - 3000"
                        [checked]="university.price_range === '2000 - 3000'"
                        [(ngModel)]="university.price_range"
                        name="tags"
                        type="radio"
                        id="tags"
                      />
                      <label class="label font-bold text-black"
                        ><small>2000€ - 3000€</small></label
                      >
                    </fieldset>
                    <fieldset class="d-flex align-items-center gap-2">
                      <input
                        class="input"
                        value="3000 - 4000"
                        [checked]="university.price_range === '3000 - 4000'"
                        [(ngModel)]="university.price_range"
                        name="tags"
                        type="radio"
                        id="tags"
                      />
                      <label class="label font-bold text-black"
                        ><small>3000€ - 4000€</small></label
                      >
                    </fieldset>
                    <fieldset class="d-flex align-items-center gap-2">
                      <input
                        class="input"
                        value="4000 - 5000"
                        [checked]="university.price_range === '4000 - 5000'"
                        [(ngModel)]="university.price_range"
                        name="tags"
                        type="radio"
                        id="tags"
                      />
                      <label class="label font-bold text-black"
                        ><small>4000€ - 5000€</small></label
                      >
                    </fieldset>
                  </section>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Tipo de universidad"
                    template="GREEN"
                    [options]="universityTypes"
                    [selectedOptions]="selectedUniTypes"
                    (selectionChange)="
                      handleUniversityTypeSelectionChange($event)
                    "
                  ></app-multi-select-dropdown>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Perfil de salida de alumnos
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="university.output_profile"
                    name="output_profile"
                    id="output_profile"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar esta universidad?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="handleDeleteuniversity()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
