import { InstitutionsService } from './../../../service/institutions.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { MetaDataService } from 'src/app/services/metadata.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { UsersService } from 'src/app/services/users.service';

import {
  ModalComponent,
  ModalContent,
} from 'src/app/components/core/modal/modal.component';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { concat, ReplaySubject, takeUntil } from 'rxjs';

interface IUserToCreate {
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  tax_id: string;
  description: string;
  email: string;
  userType: {
    label: string;
    value:
      | 'Particular'
      | 'Student'
      | 'Parent'
      | 'Teacher'
      | 'School'
      | 'Back_Office_Admin';
  };
  is_suscribed: boolean;
  is_archived: boolean;
  is_active: boolean;
  imageProfile: {
    file: null;
    preview: string | ArrayBuffer | null;
  };
  phases: {
    preliminar_phase_completed: boolean;
    completed: boolean;
  };
  organization?: string | null;
}

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  isLoading: boolean = false;
  user_types: any = [];
  USER_TYPES_DIC: any = [];
  confirmPassword = '';

  userToCreate: IUserToCreate = {
    password: '',
    first_name: '',
    last_name: '',
    phone: '',
    tax_id: '',
    description: '',
    email: '',
    userType: {
      label: 'Estudiante',
      value: 'Student',
    },
    is_suscribed: false,
    is_archived: false,
    is_active: true,
    imageProfile: {
      file: null,
      preview: 'assets/images/icons/icon_user_green.svg',
    },
    phases: {
      preliminar_phase_completed: false,
      completed: false,
    },
    organization: null,
  };

  generalInfo = [
    {
      label: 'Activo',
      value: this.userToCreate.is_active ? 'Si' : 'No',
    },
    {
      label: 'Tipo de usuario',
      value:
        this.meta.GET_USER_TYPES_DIC()[this.userToCreate.userType.value].label,
    },
    {
      label: 'Email',
      value: this.userToCreate.email,
    },
  ];

  institutions: Array<{ id: string; name: string }> = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private errorsService: ErrorsService,
    private userService: UsersService,
    private modalService: NgbModal,
    private meta: MetaDataService,
    private router: Router,
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService
  ) {
    this.title.setTitle('Crear Usuario | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear usuario',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.user_types = this.meta.getUserTypes();
    this.USER_TYPES_DIC = this.meta.GET_USER_TYPES_DIC();
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (
          this.userToCreate.password === this.confirmPassword &&
          this.userToCreate.first_name &&
          this.userToCreate.last_name &&
          this.userToCreate.phone &&
          this.userToCreate.password &&
          this.confirmPassword
        ) {
          this.onSubmitCreateUser();
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/usuarios']);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSubmitCreateUser() {
    this.isLoading = true;

    const userToCreate = {
      first_name: this.userToCreate.first_name,
      last_name: this.userToCreate.last_name,
      email: this.userToCreate.email,
      phone: this.userToCreate.phone,
      password: this.userToCreate.password,
      tax_id: null,
      image_profile: this.userToCreate.imageProfile.file,
      is_suscribed: this.userToCreate.is_suscribed,
      user_type: this.userToCreate.userType.value,
      is_archived: this.userToCreate.is_archived,
      description:
        this.userToCreate.description ||
        `Descripción de ${this.userToCreate.first_name} ${this.userToCreate.last_name}`,
      is_active: true,
      organization: this.userToCreate.organization,
    };

    this.userService.createUser(userToCreate).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.isLoading = false;
        // this.openModal('Algo ha ocurrido', htmlErrorsContent);
      },
      next: (response: any) => {
        this.userService.verifyUser(response).subscribe(() => {
          this.userService
            .updateIsSubscribed(response.id, true, response.phone)
            .subscribe(() => {
              this.router.navigate(['/dashboard/usuarios']);
              this.isLoading = false;
            });
        });
      },
    });
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.userToCreate.imageProfile.file = file;

      const reader = new FileReader();
      reader.onload = () =>
        (this.userToCreate.imageProfile.preview = reader.result);
      reader.readAsDataURL(file);
    }
  }
}
