import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  concatMap,
  finalize,
  from,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-new-report-group-page',
  templateUrl: './new-report-group-page.component.html',
  styleUrls: ['./new-report-group-page.component.scss'],
})
export class NewReportGroupPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];

  report: any = {
    name: '',
    internal_name: '',
    description: null,
    internal_description: null,
    tags: [],
    shared_with: [],
  };
  institutions: any = null;
  report_types: Array<any> = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public reportService: ReportService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModal: UniversalModalService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear informe',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.getInstitutions();
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/informes']);
        }
      });
  }
  getInstitutions() {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public handleSelectionChange(selectedOptions: Array<Record<string, string>>) {
    console.log(selectedOptions);
    this.selectedOrgs = selectedOptions.map((option: any) => option.value);
  }

  isValidForm(report: any) {
    const emptyName = report.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del informe es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.report = {
      internal_name: '',
      name: '',
      description: null,
      internal_description: null,
      tags: [],
      shared_with: [],
    };
  }

  addToThree(redirect: boolean = false) {
    this.inProcess = true;
    if (this.isValidForm(this.report)) {
      this.reportService
        .createReportGroup({
          ...this.report,
          report_types: [],
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.reportService.addReportToOrg(
                this.selectedOrgs,
                response.id as number
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            this.resetForm();
            this.router.navigate(['/dashboard/informes']);
            this.universalModal.openModal(
              undefined,
              null,
              'Informe creado exitosamente'
            );
            this.inProcess = false;
          },
          error: ({ error }) => {
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);
            this.universalModal.openModal(
              undefined,
              null,
              'Algo ha ocurrido' + htmlErrorsContent
            );
            this.inProcess = false;
          },
        });
    }
  }
}
