export const toSingular = (value: string): string => {
  const underToMinus = value.replaceAll('_', '-');
  if (underToMinus.endsWith('ies')) {
    return underToMinus.includes('competen')
      ? underToMinus.slice(0, -3) + 'e'
      : underToMinus.slice(0, -3) + 'y';
  } else if (underToMinus.endsWith('es') && !underToMinus.endsWith('ses')) {
    return underToMinus.slice(0, -1);
  } else if (
    underToMinus.endsWith('s') &&
    underToMinus.length > 1 &&
    !underToMinus.endsWith('ss')
  ) {
    return underToMinus.slice(0, -1);
  }

  return underToMinus;
};

export const toPlural = (value: string): string => {
  const minusToUnder = value.replaceAll('-', '_');

  if (
    minusToUnder.endsWith('y') &&
    !minusToUnder.endsWith('ay') &&
    !minusToUnder.endsWith('ey') &&
    !minusToUnder.endsWith('oy') &&
    !minusToUnder.endsWith('uy')
  ) {
    return minusToUnder.slice(0, -1) + 'ies';
  } else if (/s$|x$|z$|ch$|sh$/.test(minusToUnder)) {
    return minusToUnder + 'es';
  } else {
    return minusToUnder.includes('compete')
      ? minusToUnder.slice(0, -1) + 'ies'
      : minusToUnder + 's';
  }
};
