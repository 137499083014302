import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-selection-activity',
  templateUrl: './simple-selection-activity.component.html',
  styleUrls: ['./simple-selection-activity.component.scss']
})
export class SimpleSelectionActivityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
