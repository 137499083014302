import { SetActivityCreated } from './../../../store/activities/activity.actions';
import { Observable } from 'rxjs';
import { UpdateType } from './../../../store/type/type.actions';
import { Select, Store } from '@ngxs/store';
import { isMultivalued } from './../../../utils/globals';
import { generateID } from 'src/app/utils/fakers';
import {
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ActivityQuestion,
  ActivityType,
} from 'src/app/models/activities/activity.models';
import { TypeState } from 'src/app/store/type/type.state';
import { PageService } from 'src/app/services/page.service';
import { Page } from 'src/app/models/page.models';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../modal/modal.component';
import { QuestionFormService } from '../../../services/question-form.service';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class ActivityFormComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @Input() editMode: boolean = false;
  @Input() questionType: string = '';
  @Input() activityToEdit?: ActivityQuestion;
  errors: Array<{ title: string }> = [];
  @Output() archived: boolean = false;
  @Output() published: boolean = false;
  types: Array<ActivityType> = [
    {
      id: generateID(),
      title: 'Texto simple',
      slug: 'text',
      code: 'FREE_TEXT',
    },
    {
      id: generateID(),
      title: 'Video',
      slug: 'video',
      code: 'WATCH',
    },
    {
      id: generateID(),
      title: 'Lista de valores',
      slug: 'list',
      code: 'VALUES_LIST',
    },
    {
      id: generateID(),
      title: 'Selección simple',
      slug: 'simple-selection',
      code: 'SELECT_ANSWER',
    },
    {
      id: generateID(),
      title: 'Selección multiple',
      slug: 'multiple-selection',
      code: 'SELECT_MULTIPLES_ANSWERS',
    },
    {
      id: generateID(),
      title: 'Selección multiple (Evaluación)',
      slug: 'multiple-selection-evaluation',
      code: 'TEST',
    },
    {
      id: generateID(),
      title: 'Valoración regular',
      slug: 'regular-valoration',
      code: 'DISCRETE_NUMERIC_ANSWER',
    },
    {
      id: generateID(),
      title: 'Valoración por porcentaje',
      slug: 'percentual-valoration',
      code: 'CONTINUOUS_NUMERIC_ANSWER',
    },
    {
      id: generateID(),
      title: 'Schein',
      slug: 'schein',
      code: 'SCHEIN',
    },
    {
      id: generateID(),
      title: 'Jazzdoo',
      slug: 'jazzdoo',
      code: 'JAZZDOO',
    },
  ];
  @Output() statement: string = '';
  options: Array<any> = [];

  public optionsFroala: Object = {
    placeholderText: 'Ingresa el enunciado de la pregunta',
    language: 'es',
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese el enunciado de la pregunta.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };

  public parentFormContainer: ControlContainer = inject(ControlContainer);

  constructor() {}

  isSelectable(type: string) {
    let isSelectable = false;
    if (type) {
      isSelectable = isMultivalued(type);
    }
    return isSelectable;
  }

  ngOnInit() {
    console.log('BANGARANG', this.questionType, this.editMode);

    // this.getSubthemes()
  }

  get parentForm(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  /**
   * toggle video input
   */
  public toggleIncludeVideo(): void {
    const includeVideoControl = this.parentForm.get(
      'includeVideo'
    ) as FormControl;
    includeVideoControl.setValue(!includeVideoControl.value);
    if (!includeVideoControl.value) {
      (this.parentForm.get('video') as FormControl).setValue(null);
    }
  }

  /**
   * toggle video input
   */
  togglePublished(): void {
    this.published = !this.published;
  }

  /**
   * toggle video input
   */
  toggleArchived(): void {
    this.archived = !this.archived;
  }
}
