<main class="mt-3">
  <div *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
          Por favor, espere un momento
        </h2>
      </div>
    </app-card>
  </div>

  <div *ngIf="!isLoading">
    <section>
      <div class="imageProfile-container">
        <app-card
          borderRadius="10px"
          padding="26px"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        >
          <form (ngSubmit)="onSubmitUpdateImageProfile()" card__content>
            <div class="row mb-3" *ngIf="errors.length">
              <div class="col">
                <div class="alert alert-danger p-3" role="alert">
                  <ul>
                    <li *ngFor="let error of errors">
                      <p class="font-18 font-primary--b">{{ error.title }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div card__content>
              <div class="mt-3 col-xl-8">
                <section class="d-flex mb-2">
                  <h5 class="font-20 text-muted me-2">Imagen de perfil</h5>
                </section>
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <picture class="position-relative">
                    <img
                      [src]="imageProfile.preview"
                      width="150px"
                      height="150px"
                    />
                    <input
                      id="imageProfile"
                      name="imageProfile"
                      type="file"
                      class="imageProfile-input"
                      placeholder="Ingrese imagen de perfil"
                      (change)="onFileChanged($event)"
                    />
                  </picture>
                </div>
              </div>
            </div>
          </form>
        </app-card>
      </div>
      <div class="row mt-4" *ngIf="basicInfo.userType === 'Colegio'">
        <div class="col-12">
          <app-card
            padding="18px 22px"
            boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px"
            width="100%"
            borderRadius="10px"
          >
            <article card__content>
              <h5 class="font-20 font-primary--eb">Institución</h5>
              <div class="row mt-3">
                <div class="col">
                  <select
                    [(ngModel)]="basicInfo.organization"
                    class="form-select"
                    name="institution"
                    id="institution"
                  >
                    <option [ngValue]="null">Seleccione una institución</option>
                    <option
                      [ngValue]="institution.id"
                      *ngFor="let institution of institutions"
                    >
                      {{ institution.name }}
                    </option>
                  </select>
                </div>
              </div>
            </article>
          </app-card>
        </div>
      </div>

      <div class="mt-4">
        <app-card
          borderRadius="10px"
          padding="26px"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        >
          <div card__content>
            <div class="row col-xl-8 mt-3">
              <div class="col-12">
                <label for="first_name" class="text-muted font-16">
                  Nombres <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  class="input w-100"
                  placeholder="Nombre de usuario"
                  [(ngModel)]="basicInfo.firstName"
                  [value]="basicInfo.firstName"
                />
              </div>

              <div class="col-12 mt-3">
                <label for="last_name" class="text-muted font-16">
                  Apellidos <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  class="input w-100"
                  placeholder="Apellido de usuario"
                  [(ngModel)]="basicInfo.lastName"
                  [value]="basicInfo.lastName"
                />
              </div>
            </div>

            <div class="row col-xl-8 mt-3">
              <div class="col-12">
                <label for="phone" class="text-muted font-16">
                  Teléfono <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  class="input w-100"
                  placeholder="Teléfono de usuario"
                  [(ngModel)]="basicInfo.phone"
                  [value]="basicInfo.phone"
                />
              </div>

              <div class="col-12 mt-3">
                <label for="NIF" class="text-muted font-16">
                  NIF <small>(opcional)</small>
                </label>
                <br />

                <input
                  id="NIF"
                  name="NIF"
                  type="text"
                  class="input w-100"
                  placeholder="NIF de usuario"
                  [(ngModel)]="basicInfo.tax_id"
                  [value]="basicInfo.tax_id"
                />
              </div>
            </div>

            <div class="row col-xl-8 mt-3">
              <div class="col">
                <label for="emailToUpdate" class="text-muted font-16">
                  Correo electrónico <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Correo electrónico"
                  class="input w-100"
                  [(ngModel)]="basicInfo.email"
                />
              </div>
            </div>

            <div class="row col-xl-8 mt-3">
              <div class="col-12 col-md-6">
                <label for="password" class="text-muted font-16">
                  Nueva Contraseña <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="password"
                  name="password"
                  type="password"
                  class="input w-100"
                  placeholder="Contraseña de usuario"
                  [(ngModel)]="basicInfo.password"
                  [value]="basicInfo.password"
                />
              </div>

              <div class="col-12 col-md-6 mt-3 md:mt-0">
                <label for="confirmPassword" class="text-muted font-16">
                  Confirmar contraseña <span class="text-red">*</span>
                </label>
                <br />

                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  class="input w-100"
                  [disabled]="!basicInfo.password"
                  [class.disabled]="!basicInfo.password"
                  placeholder="Confirmación de contraseña"
                  [(ngModel)]="confirmPassword"
                  [value]="confirmPassword"
                />
              </div>

              <span
                class="text-start font-14 text-error mt-2"
                *ngIf="
                  basicInfo.password && basicInfo.password !== confirmPassword
                "
              >
                * Las contraseñas no coinciden
              </span>

              <span class="text-start d-block font-14 mt-2 font-bold">
                Las contraseñas deben tener
              </span>

              <span class="text-start d-block font-14 mt-1">
                <i
                  class="
                      bi ng-star-inserted
                      {{
                    basicInfo.password &&
                    basicInfo.password.length >= 6 &&
                    confirmPassword.length >= 6
                      ? 'bi-check text-blue-light'
                      : 'bi-x text-error'
                  }}
                    "
                ></i>

                Mínimo 6 caracteres
              </span>

              <span class="text-start d-block font-14 mt-1">
                Puedes colocar mayúsculas, minúsculas, espacios, números y
                caracteres especiales <br />
                como: ( . @ - _ ! # $ % ^ & * = } &#123; ] [ ; : ' " + ? / \ >
                &lt; , | )
              </span>
            </div>

            <div class="row col-xl-8 mt-3">
              <div class="col-12">
                <label for="description" class="text-muted font-16">
                  Descripción <span class="text-muted">(opcional)</span>
                </label>
                <br />

                <textarea
                  id="description"
                  name="description"
                  type="text"
                  rows="6"
                  class="w-100"
                  placeholder="Descripción de usuario"
                  [(ngModel)]="basicInfo.description"
                  [value]="basicInfo.description"
                ></textarea>
              </div>
            </div>
          </div>
        </app-card>
        <section class="mt-4">
          <app-card
            borderRadius="10px"
            padding="26px"
            boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
          >
            <div card__content>
              <div class="row col-xl-8">
                <div class="col-12 mt-4">
                  <div card__content>
                    <div class="row mt-3">
                      <label for="userType" class="text-muted font-16"
                        >Tipo de usuario</label
                      >
                      <div class="col">
                        <select
                          [(ngModel)]="basicInfo.userType"
                          class="form-select"
                          name="userType"
                          id="userType"
                        >
                          <option [ngValue]="'Student'">Estudiante</option>
                          <option [ngValue]="'Teacher'">Profesor</option>
                          <option [ngValue]="'School'">Coordinador</option>
                          <option [ngValue]="'Back_Office_Admin'">
                            Administrador
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <label
                        for="subscriptionSelected"
                        class="text-muted font-16"
                        >Suscripción</label
                      >

                      <div class="col">
                        <select
                          [(ngModel)]="subscriptionSelected"
                          class="form-select"
                          name="subscriptionSelected"
                          id="subscriptionSelected"
                        >
                          <option [ngValue]="false">Plan Gratuito</option>
                          <option [ngValue]="true">Plan Premium</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-card>
        </section>
      </div>
    </section>
  </div>
</main>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar este usuario?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteUser(userId)">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
<ng-template #modalSuccessTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-0">Usuario eliminado exitosamente</h5>
  </article>
</ng-template>
