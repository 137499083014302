import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter, map } from 'rxjs';

type RouteSegment = {
  id: number;
  name: string;
  urlSnapshot?: string;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private storage: Storage = window.localStorage;
  private ROUTES_KEY = 'breadcrumbRoutes';

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!event.url.includes('pages')) {
          this.resetRoutes();
        }
      }
    });
  }

  get routes(): Array<RouteSegment> {
    return JSON.parse(this.storage.getItem(this.ROUTES_KEY) || '[]');
  }

  set routes(newRoutes: Array<RouteSegment>) {
    this.storage.setItem(this.ROUTES_KEY, JSON.stringify(newRoutes));
  }

  public pushToUrl(segment: RouteSegment): void {
    const getRoutes = this.routes;

    const alreadyExist = getRoutes.find(
      (routeSegment) => routeSegment.id === segment.id
    );

    const indexOfSegment = getRoutes.findIndex(
      (routeSegment) => routeSegment.id === segment.id
    );

    if (!alreadyExist) {
      getRoutes.push({
        ...segment,
        urlSnapshot: this.router.url,
      });

      this.routes = getRoutes;
    } else {
      this.routes = getRoutes.splice(0, indexOfSegment + 1);
    }
  }

  public popFromUrl(): void {
    const getRoutes = this.routes;

    if (getRoutes.length > 1) {
      getRoutes.pop();
    }

    this.routes = getRoutes;
  }

  public resetRoutes(): void {
    this.storage.setItem(this.ROUTES_KEY, JSON.stringify([]));
  }
}
