import { Pipe, PipeTransform } from '@angular/core';
import { Page } from '../models/page.models';

@Pipe({
  name: 'prevPage'
})
export class PrevPagePipe implements PipeTransform {

  transform(value: Page, ...args: Array<any>): string {
    let prevPageTitle = 'Es la primera'

    args[0].forEach((page: Page, i: number) => {
      if (page.id === value.id && args[0][i - 1]) {
        prevPageTitle = args[0][i - 1].name
      }
    });

    return prevPageTitle;
  }

}
