<ng-container
  *ngIf="isTemplate()"
  [ngTemplateOutlet]="contentTemplate"
  [ngTemplateOutletContext]="{ $implicit: contentTemplateCtx }"
></ng-container>

<ng-container *ngIf="!isTemplate()">
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-0">{{ message }}</h5>
  </article>
</ng-container>
