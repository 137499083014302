import { Store } from '@ngxs/store';
import { PageFormComponent } from '../../core/page-form/page-form.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Page } from 'src/app/models/page.models';
import { SafeUrl, Title } from '@angular/platform-browser';
import { ModalComponent } from '../../core/modal/modal.component';
import { ErrorsService } from 'src/app/services/errors.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import {
  Observable,
  ReplaySubject,
  concatMap,
  finalize,
  from,
  map,
  switchAll,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { InstitutionsService } from 'src/app/service/institutions.service';

@Component({
  selector: 'app-edit-content-page',
  templateUrl: './edit-content-page.component.html',
  styleUrls: ['./edit-content-page.component.scss'],
})
export class EditContentPageComponent implements OnInit {
  @ViewChild('newPageForm') newPageForm!: PageFormComponent;
  @ViewChild(ModalComponent) modal!: ModalComponent;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  public content: any = {
    questionary: null,
    id: '',
    name: '',
    internal_name: '',
    internal_description: '',
    description: '',
    background_color: '#00CAA8',
    gradient_color: '#078CC7',
    font_color: '#ffffff',
    video_url: null,
    image: null,
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    is_preliminar_limit: false,
    time: 0,
  };

  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private errorsService: ErrorsService,
    private pageService: PageService,
    private store: Store,
    public router: Router,
    private title: Title,
    private institutionsService: InstitutionsService,
    private creationalPagesLayoutService: CreationalPagesLayoutService
  ) {
    this.title.setTitle('Editar Contenido | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Editar contenido',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
        deleteButtonText: 'Eliminar',
      },
    });
  }

  ngOnInit(): void {
    this.institutionsService
      .getInstitutions()
      .pipe(
        tap((response) => {
          this.organizations = response.map((org: any) => ({
            label: org.name,
            value: org.id,
          }));
        }),
        switchMap(() => this.route.params),
        concatMap((objectParams: Record<string, string>) => {
          console.log(objectParams);
          return this.pageService.getPageById(objectParams['id']);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.content = response;
        const orgsSelected = new Map();

        response.organizations.forEach((org: any) => {
          orgsSelected.set(org.id, true);
        });

        this.organizations.forEach((org) => {
          if (orgsSelected.get(org.value)) {
            this.selectedOrgs.push(org);
          }
        });
      });
  }

  ngAfterViewInit(): void {
    console.debug('{newPageForm}<PageFormComponent> ', this.newPageForm);
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['/dashboard/pages/contenidos']);
        }
      });
    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          this.confirmDelete(
            { stopPropagation: function () {} } as Event,
            this.content.id as string
          );
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getInstitutions(): void {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public handleSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ): void {
    this.selectedOrgs = selectedOptions;
  }

  /**
   * Fields form validations
   * @returns
   */
  isValidForm(page: Page): boolean {
    const emptyName = page.name === '';
    const emptyType = page.type === null;
    const emptyQuestionaryContainer = this.newPageForm.questionaryId === null;
    const emptyChapterContainer = this.newPageForm.chapterId === null;
    const emptyThemeContainer = this.newPageForm.themeId === null;
    const emptySubthemeContainer = this.newPageForm.subthemeId === null;
    const emptyBgImage = this.newPageForm.page.image === null;
    const emptyVideo = this.newPageForm.page.video_url === null;

    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre de la página es requerido.' });
      isValidForm = false;
    }

    if (emptyType) {
      this.errors.push({ title: 'El tipo de página es requerido.' });
      isValidForm = false;
    }

    if (!emptyBgImage) {
      if (
        !this.newPageForm.isMainImageFile &&
        !this.isImgLink(this.newPageForm.page.image!)
      ) {
        this.errors.push({
          title: 'La url de la imagen de fondo no es valida.',
        });
        isValidForm = false;
      }
    }

    if (!emptyVideo) {
      if (!this.isVideoLink(this.newPageForm.page.video_url!)) {
        this.errors.push({ title: 'La url del video no es valida.' });
        isValidForm = false;
      }
    }

    return isValidForm;
  }

  addToThree(redirect: boolean = false) {
    if (this.isValidForm(this.newPageForm.page)) {
      let toastMessage = '';
      this.newPageForm.page.questionary = String(
        this.newPageForm.questionaryId
      );

      this.newPageForm.page.parent = null;
      toastMessage = `Contenido '${this.content.internal_name}' actualizado.`;

      this.pageService
        .updatePage({
          ...this.newPageForm.page,
          ...this.content,
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.pageService.addPageToOrg(
                this.selectedOrgs.map((org: any) => org.value),
                response.id
              );
            } else if (
              this.selectedOrgs.length === 0 &&
              Array.isArray(this.content.organizations) &&
              this.content.organizations.length > 0
            ) {
              return from(this.content.organizations).pipe(
                concatMap((org: any) =>
                  this.pageService.deletePageFromOrg(org.id, response.id)
                ),
                finalize(() => {
                  this.router.navigate(['/dashboard/pages/contenidos']);
                  this.inProcess = false;
                  this.store.dispatch(new OpenModal(toastMessage));
                })
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            if (
              this.selectedOrgs.length === 0 &&
              Array.isArray(this.content.organizations) &&
              this.content.organizations.length > 0
            ) {
            } else {
              this.store.dispatch(new OpenModal(toastMessage));
              if (redirect) {
                this.router.navigate(['/dashboard/pages/contenidos']);
              }
            }
          },
          error: ({ error }) => {
            alert(
              'Algo ha ocurrido, asegurese de que el contenido no está publicado.'
            );
            // const { htmlErrorsContent } = this.errorsService.handleErrors(error)
            // this.modal.open('Algo ha ocurrido', htmlErrorsContent)
          },
        });
    }
  }

  isImgLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return (
      url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gim) !=
      null
    );
  }

  isVideoLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return url.match(/^http[^\?]*.(mp4|flv|mkv|avi|m3u8)(\?(.*))?$/gim) != null;
  }

  confirmDelete(event: Event, id: string | number) {
    // TODO IMPROVE UI
    event.stopPropagation();
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deleteQuestionary(id);
    }
  }

  public deleteQuestionary(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.router.navigate(['dashboard/pages/contenidos']);
        this.store.dispatch(new OpenModal('Contenido eliminado exitosamente.'));
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.store.dispatch(
          new OpenModal(
            'No se pudo eliminar el programa, intente de nuevo mas tarde.'
          )
        );
      },
    });
  }
}
