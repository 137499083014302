export const objectToFormData = (object: object): FormData => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    const value = object[key as keyof object];
    if (value) {
      formData.append(key, value);
    }
  });
  return formData;
};
