import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICareerUniversity, CAREER_TYPE_LABEL } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-detail-career-university-page',
  templateUrl: './detail-career-university-page.component.html',
  styleUrls: ['./detail-career-university-page.component.scss']
})
export class DetailCareerUniversityPageComponent implements OnInit {
  @ViewChild('content') modal!: ModalComponent;
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  isLoading: boolean = true
  careerId!: number
  customContent: any = []

  constructor(
    private careerService: CareersService,
    private errorsService: ErrorsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Detalle de Carrera Universitaria | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.careerId = Number(this.route.snapshot.paramMap.get('id'))
    this.getCareerById()
  }

  getCareerById() {
    this.careerService.getCareerById(this.careerId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICareerUniversity) => {
        this.career = data

        this.customContent.push({
          title: data.title,
          description: data.description,
          order: data.description_order,
          left_aligned: data.description_left_aligned,
        })
        this.customContent.push({
          title: data.job_title,
          description: data.job,
          order: data.job_order,
          left_aligned: data.job_left_aligned,
        })
        this.customContent.push({
          title: data.output_title,
          description: data.output_profile,
          order: data.output_order,
          left_aligned: data.output_left_aligned,
        })

        const groupByLeftAligned = this.customContent.reduce((group: any, item: any) => {
          const { left_aligned } = item;
          group[left_aligned] = group[left_aligned] ?? [];
          group[left_aligned].push(item);
          return group;
        }, {});

        console.clear()
        console.debug(groupByLeftAligned)
        this.customContent = groupByLeftAligned
      },
      complete: () => this.isLoading = false
    })
  }

  confirmDeleteCoupon(id: string | number) {
    this.modalService.open(this.modal, { centered: true })
  }

  deleteCoupon(): void {
    this.careerService.deleteCareer(this.career.id).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICareerUniversity) => console.log('deleteCareer data', data),
      complete: () => {
        this.dismissModal()
        this.router.navigate(['/dashboard/reportes/carreras-universitarias'])
      }
    })
  }

  dismissModal() {
    this.modalService.dismissAll()
  }
}
