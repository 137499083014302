<section class="mt-3">
  <app-page-loader *ngIf="isLoading"></app-page-loader>
  <ul
    class="d-flex flex-column gap-2 px-0"
    *ngIf="!isLoading"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <app-list-element
      *ngFor="let report of reports; index as i"
      cdkDrag
      [entityData]="report"
      [isDragable]="true"
      [isSingleRowDesign]="true"
      [actions]="listElementActions"
      [customTemplate]="'DEFAULT'"
      (onClickAction)="handleListElementClickAction($event, report)"
    >
    </app-list-element>
  </ul>
  <article class="mt-3" *ngIf="reports && reports.length === 0 && !isLoading">
    <app-card
      borderRadius="10px"
      padding="80px 120px"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <section card__content>
        <h2 class="text-center font-24 opacity-50">
          No has creado ninguna sección aún,
          <span class="text-green cursor-pointer" (click)="goToCreation()"
            >agregalas aquí</span
          >
        </h2>
      </section>
    </app-card>
  </article>
</section>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar esta sección?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteReport()">Aceptar</button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
