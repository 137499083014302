import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  public showDrawer: boolean = false;
  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex' },
      { name: 'robots', content: 'nofollow' },
      { name: 'googlebot', content: 'noindex' },
    ]);
  }

  ngOnInit(): void {}

  public toggleShow() {
    this.showDrawer = !this.showDrawer;
  }
}
