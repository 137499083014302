<main class="activities-page page">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content class=>
      <div class="row align-items-center justify-content-between w-100">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Frases</h1>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
      </div>
    </app-card>
  </div>

  <div class="mt-3" *ngIf="!isLoading && !categories.length">
    <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center">
        <span class="font-24 opacity-50">
          Aun no se han cargado frases
        </span>
      </div>
    </app-card>
  </div>

  <ngx-masonry [options]="masonryOptions" style="margin-left: auto; margin-right: auto;" class="mt-3" [ordered]="true"
    *ngIf="!isLoading && categories">
    <div [prepend]="false" ngxMasonryItem *ngFor="let category of categories; index as idx"
      style="max-width: 475px; margin-left: auto; margin-right: auto;" class="mt-4">
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100" borderRadius="10px">
        <article card__content class="row">
          <h5 class="text-start font-bold font-20">
            {{category.name}}
          </h5>
          <ul class="phraseParameter__list">
            <li *ngFor="let phrase of category.phrases" class="phraseParameter__list--item mt-2 cursor-pointer"
              [routerLink]="'/dashboard/instituciones/parametros/' + phrase.id">
              <span class="font-18">
                {{phrase.parameter_name}}
              </span>
            </li>
          </ul>
          <button class="btn bg-blue  align-self-center mt-3"
            [routerLink]="'/dashboard/instituciones/parametros/' + category.id +'/custom-content'">
            <span class="font-18 text-white">
              Contenido personalizado
            </span>
          </button>
        </article>
      </app-card>
    </div>
  </ngx-masonry>
</main>
