import { Pipe, PipeTransform } from '@angular/core';
import { Page } from '../models/page.models';

@Pipe({
  name: 'nextPage'
})
export class NextPagePipe implements PipeTransform {

  transform(value: Page, ...args: Array<any>): string {
    let nextPageTitle = 'Es la ultima'

    args[0].forEach((page: Page, i: number) => {
      if (page.id === value.id && args[0][i + 1]) {
        nextPageTitle = args[0][i + 1].name
      }
    });

    return nextPageTitle;
  }

}
