import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReplaySubject, takeUntil } from 'rxjs';

import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ActivityService } from 'src/app/services/activity.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-pages-activities-page',
  templateUrl: './pages-activities-page.component.html',
  styleUrls: ['./pages-activities-page.component.scss'],
})
export class PagesActivitiesPageComponent implements OnInit, OnDestroy {
  contentId!: number;
  themeId!: number;
  subthemeId!: string;
  activityId!: string;
  activityQuestions: any[] = [];
  isLoading = false;

  private destroyed$ = new ReplaySubject<void>(1);
  private selectedQuestionId!: string;

  @ViewChild('deleteConfirmationModalTemplate')
  deleteConfirmationModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteErrorModalTemplate')
  deleteErrorModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteSuccessModalTemplate')
  deleteSuccessModalTemplate!: TemplateRef<any>;

  public listElementActions = [
    { action: 'edit', icon: '/assets/images/icons/icon_edit_new.svg' },
    { action: 'delete', icon: '/assets/images/icons/icon_delete_new.svg' },
  ];

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private location: Location,
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Preguntas de la actividad | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.contentId = Number(this.route.snapshot.paramMap.get('contentId'));
    this.themeId = Number(this.route.snapshot.paramMap.get('themeId'));
    this.subthemeId = this.route.snapshot.paramMap.get('subthemeId')!;
    this.activityId = this.route.snapshot.paramMap.get('activityId')!;

    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.goToCreation();
      });

    this.getActivityDetails(this.activityId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  navigateToBack(): void {
    this.location.back();
  }

  public goToCreation(): void {
    this.router.navigate([
      `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas/nuevo`,
    ]);
  }

  private getActivityDetails(id: string, disableLoading = false): void {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.pageService.getPageById(id).subscribe({
      next: (response: any) => {
        this.activityQuestions = response.questions;
        this.creationalPagesLayoutService.layoutConfig.next({
          headerTitle: response.internal_name,
          headerTitleIcon: '/assets/images/icons/icon_content.svg',
          headerButtonIcon: '/assets/images/icons/icon_create.svg',
          headerButtonText: 'Crear pregunta',
        });
        this.breadcrumbService.pushToUrl({
          id: response.id,
          name: response.internal_name,
        });
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  openDeleteConfirmationModal(questionId: string): void {
    this.selectedQuestionId = questionId;
    this.universalModalService.openModal(this.deleteConfirmationModalTemplate);
  }

  deleteQuestion(): void {
    this.isLoading = true;
    this.universalModalService.closeModal();
    this.activityService
      .deleteActivity(parseInt(this.selectedQuestionId))
      .subscribe({
        next: () => {
          this.universalModalService.openModal(this.deleteSuccessModalTemplate);
          this.getActivityDetails(this.activityId);
        },
        error: (errorResponse) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(
            errorResponse.error
          );
          this.isLoading = false;
          this.universalModalService.openModal(this.deleteErrorModalTemplate);
        },
      });
  }

  closeModal(): void {
    this.universalModalService.closeModal();
  }

  public handleListElementClickAction(actionName: string, entity: any): void {
    const actions: { [key: string]: () => void } = {
      delete: () => this.openDeleteConfirmationModal(entity.id),
      edit: () => this.handleListElEdit(entity.id),
    };

    actions[actionName]();
  }

  public handleListElEdit(id: number): void {
    this.router.navigateByUrl(
      `/dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${this.activityId}/preguntas/${id}/editar`
    );
  }

  public drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(
      this.activityQuestions,
      event.previousIndex,
      event.currentIndex
    );
    if (event.previousIndex !== event.currentIndex) {
      const updatedQuestion = {
        ...this.activityQuestions[event.currentIndex],
        order: event.currentIndex + 1,
      };
      this.activityService
        .updateActivity(updatedQuestion.id, updatedQuestion)
        .subscribe(() => {
          this.getActivityDetails(this.activityId, true);
        });
    }
  }
}
