<div class="w-full h-full">
  <section class="ps-3 pe-4 pt-4 pb-4 offcanvas-header filter_header">
    <h5 class="font-primary--eb offcanvas-title">Filtros</h5>
    <button type="button" class="btn-close btn-close-drawer" aria-label="Close" (click)="offcanvasService.dismiss('Cross click')"></button>
  </section>

  <hr style="margin: 0px" />

  <div class="filter_container pt-3 pb-3 overflow-y-scroll">
    <section>
      <h5 class="font-primary--eb font-18">Tipo de usuario</h5>

      <select [(ngModel)]="optionsData.userType" class="form-select mt-2" aria-label="Seleccione el tipo de usuario">
        <option [ngValue]="''" selected [defaultSelected]="''">Todos</option>
        <option [ngValue]="'Student'">Estudiantes</option>
        <option [ngValue]="'Back_Office_Admin'">Administradores</option>
      </select>
    </section>

    <hr style="margin: 24px 0px" />
  
    <section>
      <h5 class="font-primary--eb font-18">Etapas</h5>

      <div class="mt-2 row justify-content-between align-items-start">
        <div class="col-6">
          <label for="finalPhase" class="font-primary--eb font-14">
            Final
          </label>

          <select [(ngModel)]="optionsData.isFinalPhaseCompleted" class="form-select mt-2" aria-label="Seleccione la etapa a filtrar">
            <option [ngValue]="null" selected [defaultSelected]="null">Todas</option>
            <option [ngValue]="false">No completadas</option>
            <option [ngValue]="true">Completadas</option>
          </select>
        </div>

        <div class="col-6">
          <label for="preliminarPhase" class="font-primary--eb font-14">
            Gratuita
          </label>

          <select [(ngModel)]="optionsData.isPreliminarPhaseCompleted" class="form-select mt-2" aria-label="Seleccione la etapa a filtrar">
            <option [ngValue]="null" selected [defaultSelected]="null">Todas</option>
            <option [ngValue]="false">No completadas</option>
            <option [ngValue]="true">Completadas</option>
          </select>
        </div>
      </div>
    </section>

    <hr style="margin: 24px 0px" />

    <section>
      <h5 class="font-primary--eb font-18">Suscripción</h5>

      <div class="mt-2">
        <select [(ngModel)]="optionsData.isSubscribed" class="form-select mt-2" aria-label="Seleccione la etapa a filtrar">
          <option [ngValue]="null" selected [defaultSelected]="null">Todas</option>
          <option [ngValue]="false">No suscrito</option>
          <option [ngValue]="true">Suscrito</option>
        </select>
      </div>
    </section>

    <hr style="margin: 24px 0px" />

    <section class="filtersDateRange">
      <h5 class="font-primary--eb font-18">Fechas</h5>

      <!-- <div class="row mt-2">
        <h5 class="font-primary--eb font-14">Creación de usuario</h5>

        <div class="col-6">
          <label for="createdAfter" class="font-12">
            Desde
          </label>

          <div class="input-group after">
            <input
              id="createdAfter"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-createdAfter"
              [(ngModel)]="optionsData.dates.created.after"
              ngbDatepicker
              placement="bottom"
              #createdAfter="ngbDatepicker"
            />
            
            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary bi bi-calendar3 text-white"
              (click)="createdAfter.toggle()"
              type="button"
            ></button>
          </div>
        </div>

        <div class="col-6">
          <label for="createdBefore" class="font-12">
            Hasta
          </label>

          <div class="input-group before">
            <input
              id="createdBefore"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-createdBefore"
              [(ngModel)]="optionsData.dates.created.before"
              ngbDatepicker
              placement="bottom"
              #createdBefore="ngbDatepicker"
            />

            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary blue-dark bi bi-calendar3 text-white"
              (click)="createdBefore.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div> -->

      <div class="row mt-4">
        <h5 class="font-primary--eb font-14">Registro de usuario</h5>

        <div class="col-6">
          <label for="joinedAfter" class="font-12">
            Desde
          </label>

          <div class="input-group after">
            <input
              id="joinedAfter"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-joinedAfter"
              [(ngModel)]="optionsData.dates.joined.after"
              ngbDatepicker
              placement="bottom"
              #joinedAfter="ngbDatepicker"
            />

            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary blue-dark bi bi-calendar3 text-white"
              (click)="joinedAfter.toggle()"
              type="button"
            ></button>
          </div>
        </div>

        <div class="col-6">
          <label for="joinedBefore" class="font-12">
            Hasta
          </label>

          <div class="input-group before">
            <input
              id="joinedBefore"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-joinedBefore"
              [(ngModel)]="optionsData.dates.joined.before"
              ngbDatepicker
              placement="bottom"
              #joinedBefore="ngbDatepicker"
            />
            
            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary bi bi-calendar3 text-white"
              (click)="joinedBefore.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <h5 class="font-primary--eb font-14">Última actualización de usuario</h5>

        <div class="col-6">
          <label for="editedAtAfter" class="font-12">
            Desde
          </label>

          <div class="input-group after">
            <input
              id="editedAtAfter"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-editedAtAfter"
              [(ngModel)]="optionsData.dates.editedAt.after"
              ngbDatepicker
              placement="top"
              #editedAtAfter="ngbDatepicker"
            />

            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary blue-dark bi bi-calendar3 text-white"
              (click)="editedAtAfter.toggle()"
              type="button"
            ></button>
          </div>
        </div>

        <div class="col-6">
          <label for="editedAtBefore" class="font-12">
            Hasta
          </label>

          <div class="input-group before">
            <input
              id="editedAtBefore"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-editedAtBefore"
              [(ngModel)]="optionsData.dates.editedAt.before"
              ngbDatepicker
              placement="top"
              #editedAtBefore="ngbDatepicker"
            />
            
            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary bi bi-calendar3 text-white"
              (click)="editedAtBefore.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <h5 class="font-primary--eb font-14">Último inicio de sesión de usuario</h5>

        <div class="col-6">
          <label for="lastLoginAfter" class="font-12">
            Desde 
          </label>

          <div class="input-group after">
            <input
              id="lastLoginAfter"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-lastLoginAfter"
              [(ngModel)]="optionsData.dates.lastLogin.after"
              ngbDatepicker
              placement="top"
              #lastLoginAfter="ngbDatepicker"
            />

            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary blue-dark bi bi-calendar3 text-white"
              (click)="lastLoginAfter.toggle()"
              type="button"
            ></button>
          </div>
        </div>

        <div class="col-6">
          <label for="lastLoginBefore" class="font-12">
            Hasta
          </label>

          <div class="input-group before">
            <input
              id="lastLoginBefore"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp-lastLoginBefore"
              [(ngModel)]="optionsData.dates.lastLogin.before"
              ngbDatepicker
              placement="top"
              #lastLoginBefore="ngbDatepicker"
            />
            
            <button
              style="background-color: #374052 !important;"
              class="btn btn-outline-secondary bi bi-calendar3 text-white"
              (click)="lastLoginBefore.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <hr style="margin: 0px" />

  <section class="p-4 filter_actions-container">
    <div class="row justify-content-between align-items-center filter_actions">
      <button (click)="resetFilters()" class="w-full btn btn-primary pt-2 pb-2 col-6 font-primary--eb">
        Limpiar Filtros
      </button>
  
      <button (click)="applyFilters()" class="w-full btn bg-red pt-2 pb-2 text-white col-6 font-primary--eb">
        Aplicar Filtros
      </button>
    </div>
  </section>
</div>