export interface RangeOption {
  id: string | number;
  body: string;
  value: number;
  disabled: boolean;
  type: string | null;
}
export interface CheckboxOption {
  id: string | number;
  body: string;
  value: boolean;
  disabled: boolean;
  type: string | null;
}
export interface RadioOption {
  id: string | number;
  body: string;
  value: number;
  selected: boolean;
}

export type Option = RangeOption | CheckboxOption | RadioOption;

export interface OptionActivity {
  id?: number | string;
  _id?: number | string;
  _points?: number;
  body: string;
  name?: string;
  question: number;
  delete?: boolean
  //? INTERNAL
  value?: boolean | number;
  disabled?: boolean;
  type?: string | null;
  selected?: boolean;
}

// Option From Backend
export interface OptionActivityFromBack {
  id: number;
  name: string;
  body: string | null;
  is_system_created: boolean;
  is_correct?: boolean;
  question: number;
  vocational_interests: [
    {
      id: number;
      technical: string; // all parameters should be "0.00"
      investigator: string;
      entrepreneur: string;
      communicator: string;
      analyst: string;
    }
  ];
  multiple_intelligences: [
    {
      id: number;
      linguistic: string;
      mathematical_logician: string;
      space: string;
      cinematic_bodily: string;
      musical: string;
      intrapersonal: string;
      interpersonal: string;
      naturalist: string;
    }
  ];
  professional_fields: [
    {
      id: number;
      manual_technician: string;
      research_scientist: string;
      creative_artistic: string;
      social_healthcare: string;
      business_persuasive: string;
      management: string;
      virtual_digital_streamer: string;
    }
  ];
  personalities: [
    {
      id: number;
      perfectionist: string;
      altruistic: string;
      active: string;
      affective: string;
      rational: string;
      skeptical: string;
      enthusiastic: string;
      leader: string;
      mediator: string;
    }
  ];
  avks: [
    {
      id: number;
      auditory: string;
      visual: string;
      kinesthetic: string;
    }
  ];
  learning_styles: [
    {
      id: number;
      active: string;
      thoughtful: string;
      theoretical: string;
      pragmatic: string;
    }
  ];
  intelligences: [
    {
      id: number;
      self_awareness: string;
      empathy: string;
      self_confidence: string;
      motivation: string;
      self_control: string;
      social_skills: string;
    }
  ];
  interest_options: [
    {
      id: number;
      technical_with_academic_option: string;
      technical_inclination: string;
    }
  ];
  competencies: [
    {
      id: number;
      self_study: string;
      self_critical_capacity: string;
      information_search: string;
      time_organization: string;
      social_skills: string;
      project_management: string;
      adaptability: string;
      communication_skills: string;
      information_treatment: string;
      informatic_training: string;
      it_level_used: string;
    }
  ];
}

// Types of process to format the options
export interface ICategoryNormalized {
  id: string;
  label: string;
  parameters: [
    {
      [key: string]: string;
    }
  ];
}

export interface IParameterToFormat {
  [key: string]: string;
}
