import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Islide } from '../models/insight';

@Injectable({
  providedIn: 'root'
})
export class InsightServiceService {

  constructor(
    private httpClient: HttpClient,
    // private cookieService: CookieService,
  ) { }

  getSlides(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/insight/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  newSlides(slide: Islide): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/insight/`, slide).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateSlides(slide: Islide): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/insight/${slide.id}/`, slide).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  deleteSlides(slideId: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/insight/${slideId}/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
