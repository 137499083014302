<div class="position-relative" #dropdownContainer>
  <button
    class="dropdown-container cursor-pointer"
    (click)="openDropdown($event)"
  >
    <div class="col-3 d-flex align-items-center gap-4 custom-select-btn">
      <img src="/assets/images/icons/icon_order_bar.svg" />
      <p class="fs-5"><strong>Ordenar</strong></p>
      <img src="/assets/images/icons/icon_arrow_down.svg" />
    </div>
  </button>
  <div
    class="dropdown-menu"
    *ngIf="isDropdownOpen$ | async"
    (click)="closeDropdown($event)"
  >
    <div
      *ngFor="let option of data"
      (click)="selectOption(option)"
      class="dropdown-option"
    >
      {{ option }}
    </div>
  </div>
</div>
