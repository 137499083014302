<section class="mt-3">
  <ul class="row mx-0 px-0">
    <app-search-list-bar class="px-0">
      <section left-side class="col-3 d-flex align-items-center">
        <app-selector [data]="orderOptions"></app-selector>
      </section>
    </app-search-list-bar>
    <div class="mt-3" *ngIf="isLoading">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
            Por favor, espere un momento
          </h2>
        </div>
      </app-card>
    </div>
    <section *ngIf="!isLoading" class="d-flex flex-column gap-2 px-0">
      <app-list-element
        *ngFor="let user of groups | async; index as i"
        [entityData]="user"
        [customTemplate]="'USERS'"
        [withoutToggle]="true"
        (onClick)="handleListElementClick(user.id, userModalTemplate)"
        (onClickAction)="handleListElementClickAction($event, user)"
        [actions]="listElementActions"
      >
      </app-list-element>
    </section>
    <article
      class="mt-3"
      *ngIf="groups && (groups | async).length === 0 && !isLoading"
    >
      <app-card
        borderRadius="10px"
        padding="80px 120px"
        boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
      >
        <section card__content>
          <h2 class="text-center font-24 opacity-50">
            No has agregado ningún usuario aún,
            <span class="text-green cursor-pointer" (click)="goToCreation()"
              >agregalos aquí</span
            >
          </h2>
        </section>
      </app-card>
    </article>
  </ul>
</section>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar este usuario?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteParticipant()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
<ng-template #userModalTemplate let-userData>
  <section class="modal-container">
    <article
      class="position-relative d-flex flex-column align-items-center userModalContainer"
    >
      <img
        src="/assets/images/icons/icon_delete_new.svg"
        class="close-modal-icon w-24px cursor-pointer"
        (click)="handleCloseModal()"
      />
      <img
        class="w-168px"
        [src]="
          userData.image_profile
            ? userData.image_profile
            : '/assets/images/icons/icon_user_green.svg'
        "
      />
      <hr class="border-2 border-dark border-top w-100 my-4" />
      <section class="d-flex flex-column gap-3 w-100">
        <h5 class="font-18 text-muted">
          <strong>Información general</strong>
        </h5>
        <p>
          Nombre completo: {{ userData.first_name + " " + userData.last_name }}
        </p>
        <p>Teléfono: {{ userData.phone }}</p>
        <p>Email: {{ userData.email }}</p>
        <p>Activo: {{ userData.is_active ? "Si" : "No" }}</p>
        <p>NIF: {{ userData.tax_id ?? "N/A" }}</p>
        <p>Tipo de usuario: {{ userData.user_type | studentType }}</p>
      </section>
      <hr class="border-2 border-dark border-top w-100 my-4" />
      <section class="d-flex flex-column gap-3 w-100">
        <h5 class="font-18 text-muted"><strong>Etapas</strong></h5>
        <p>
          Fase preliminar:
          <span *ngIf="!userData.preliminar_phase_completed"
            >No completada</span
          >
          <span *ngIf="userData.preliminar_phase_completed">Completada</span>
        </p>
        <p>
          Fase final:
          <span *ngIf="!userData.completed">No completada</span>
          <span *ngIf="userData.completed">Completada</span>
        </p>
        <section
          *ngIf="userData.is_subscribed"
          class="w-100 mt-4 px-4 py-3 bg-badge-yellow rounded-2"
        >
          <h4 class="text-white font-20" style="font-style: italic">
            Plan Premium
          </h4>
        </section>
        <section
          *ngIf="!userData.is_subscribed"
          class="w-100 mt-4 px-4 py-3 bg-badge-gray rounded-2"
        >
          <h4 class="text-white font-20" style="font-style: italic">
            Plan Gratuito
          </h4>
        </section>
      </section>
    </article>
  </section>
</ng-template>
