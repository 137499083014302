import {
  ComponentRef,
  Injectable,
  Injector,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { UniversalModalComponent } from '../components/core/universal-modal/universal-modal.component';
import { Subject, Subscription } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class UniversalModalService {
  private componentRef!: ComponentRef<UniversalModalComponent>;
  private componentSubscriber!: Subject<string>;
  private overlayBackdropSubscription!: Subscription;
  constructor(private overlay: Overlay, private injector: Injector) {}

  openModal(
    template?: TemplateRef<any>,
    templateCtx?: Object | null,
    message?: string
  ) {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      // backdropClass: 'custom-modal-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    const portal = new ComponentPortal(
      UniversalModalComponent,
      null,
      this.injector
    );

    this.componentRef = overlayRef.attach(portal);
    this.componentRef.instance.contentTemplate = !template ? null : template;
    this.componentRef.instance.contentTemplateCtx = !templateCtx
      ? null
      : templateCtx;
    this.componentRef.instance.message = !message ? null : message;
    this.overlayBackdropSubscription = overlayRef
      .backdropClick()
      .subscribe(() => this.closeModal());
    this.componentSubscriber = new Subject<string>();
    return this.componentSubscriber;
  }

  closeModal() {
    this.componentSubscriber.complete();
    this.componentRef.destroy();
    this.overlayBackdropSubscription.unsubscribe();
    console.log('Close', this.componentSubscriber, this.componentRef);
  }
}
