import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, debounce, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private readonly _isDrawerOpen: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  public readonly drawerWidth: number = 134;

  constructor() {}

  public get isDrawerOpen() {
    return this._isDrawerOpen.asObservable();
  }

  public set setDrawerOpen(value: boolean) {
    this._isDrawerOpen.next(value);
  }

  public get isDrawerOpenDebounced(): Observable<boolean> {
    return this.isDrawerOpen.pipe(
      debounce((value: boolean) => {
        if (value) {
          return timer(1000);
        }
        return timer(0);
      })
    );
  }

  public applyInAnimation(
    elementRef: ElementRef<HTMLElement>,
    recalculatedWidth?: number,
    transitionCSSProperties?: Partial<CSSStyleDeclaration>
  ): void {
    if (recalculatedWidth) {
      elementRef.nativeElement.style.width = `${
        recalculatedWidth - this.drawerWidth
      }px`;
    }

    if (transitionCSSProperties) {
      (Object.keys(transitionCSSProperties) as string[]).forEach(
        (attrKey: string) => {
          (elementRef.nativeElement.style as any)[attrKey] =
            transitionCSSProperties[attrKey as any];
        }
      );
    }
  }

  public applyOutAnimation(
    elementRef: ElementRef<HTMLElement>,
    recalculatedWidth?: number,
    transitionCSSProperties?: Partial<CSSStyleDeclaration>
  ): void {
    if (recalculatedWidth) {
      elementRef.nativeElement.style.width = `${recalculatedWidth}px`;
    }

    if (transitionCSSProperties) {
      (Object.keys(transitionCSSProperties) as string[]).forEach(
        (attrKey: string) => {
          (elementRef.nativeElement.style as any)[attrKey] =
            transitionCSSProperties[attrKey as any];
        }
      );
    }
  }
}
