import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { ErrorsService } from 'src/app/services/errors.service';
import { PhrasesService } from 'src/app/services/phrases.service';
import { groupBy } from 'src/app/utils/globals';
import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';

@Component({
  selector: 'app-phrases-page',
  templateUrl: './phrases-page.component.html',
  styleUrls: ['./phrases-page.component.scss']
})
export class PhrasesPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild(NgxMasonryComponent) masonry!: NgxMasonryComponent
  public masonryOptions: NgxMasonryOptions = { gutter: 20 }
  phrases: any[] = []
  isLoading: boolean = false
  limit = 3
  categories: Array<any> = []

  constructor(
    private phrasesService: PhrasesService,
    private errorsService: ErrorsService,
    private title: Title,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
  ) {
    this.title.setTitle('Frases | Backoffice - Growth Road')
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Parametros',
      headerTitleIcon: '/assets/images/icons/icon_selector.svg',
    });
  }

  ngOnInit(): void {
    this.getPhrasesCategories()
  }

  getPhrasesCategories() {
    this.phrasesService.getPhrasesCategories().subscribe({
      next: (data: any) => {
        console.debug(data)
        this.categories = data
      },
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.isLoading = false
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
    })
  }
}
