// import { OptionActivity } from './../../../../models/acitivities/option.models';
import { Component, Input, OnInit } from '@angular/core';
import { OptionActivity } from 'src/app/models/activities/option.models';
@Component({
  selector: 'app-multiple-selection-activity',
  templateUrl: './multiple-selection-activity.component.html',
  styleUrls: ['./multiple-selection-activity.component.scss']
})
export class MultipleSelectionActivityComponent implements OnInit {
  @Input() isSimple: boolean = false
  @Input() max: number = 2
  @Input() min: number = 1
  @Input() video?: string
  @Input() statement: string = '¿lorem ipsum?'
  @Input() options?: Array<OptionActivity> | [] = []

  disabledSelection: boolean = false
  selectedOptions: Array<OptionActivity> = []

  constructor() {
  }

  disabling() {
    if (this.selectedOptions.length === this.max) {
      this.options!.forEach(option => {
        if (!option.value) {
          option.disabled = true
        }
      });
    } else {
      this.options!.forEach(option => {
        option.disabled = false
      });
    }
  }

  onChange(option: OptionActivity) {
    this.options!.forEach((opt, i) => {
      console.debug('[option]',option)
      if (opt._id == option._id) {
        this.options![i].value = !this.options![i].value
        if (this.options![i].value === true) {
          this.selectedOptions.push(option)
        } else {
          this.selectedOptions = this.options!.filter(opt => opt.value);
        }
      }
    });
    this.disabling()
  }

  // initOptions() {
  //   this.options!.forEach(option => {
  //     option.body = option.body
  //   });
  // }

  ngOnInit(): void {
    // this.initOptions()
  }

}
