<section class="mt-3">
  <app-page-loader *ngIf="isLoading"></app-page-loader>

  <ul
    class="d-flex flex-column gap-2"
    *ngIf="!isLoading"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <app-list-element
      *ngFor="let question of activityQuestions; index as i; first as isFirst"
      cdkDrag
      [entityData]="question"
      [isDragable]="true"
      [tagsTemplate]="'yellowTemplate'"
      [actions]="listElementActions"
      (onClickAction)="handleListElementClickAction($event, question)"
    >
    </app-list-element>
  </ul>

  <article
    class="mt-3"
    *ngIf="activityQuestions && activityQuestions.length === 0 && !isLoading"
  >
    <app-card
      borderRadius="10px"
      padding="80px 120px"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <section card__content>
        <h2 class="text-center font-24 opacity-50">
          No has creado ninguna pregunta aún,
          <span class="text-green cursor-pointer" (click)="goToCreation()"
            >crealas aquí</span
          >
        </h2>
      </section>
    </app-card>
  </article>
</section>

<ng-template #deleteConfirmationModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar esta pregunta?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteQuestion()">Aceptar</button>
      <button class="button-dismiss-outlined" (click)="closeModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>

<ng-template #deleteErrorModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5>
      No se pudo eliminar la pregunta, asegúrese de que no haya sido respondida.
    </h5>
  </article>
</ng-template>

<ng-template #deleteSuccessModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5>Pregunta eliminada exitosamente.</h5>
  </article>
</ng-template>
