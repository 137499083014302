import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { convertToFormData } from '../utils/globals';

export interface UniversityGradePayload {
  id?: number;
  title: string;
  acronym: string;
  type: 'VOCATIONAL_TRAINING' | 'UNIVERSITY_DEGREE'; // Using enum for clarity
  description: string;
  primary_color: string;
  secondary_color: string;
  explanation_title: string;
  explanation: string;
  output_profile: string;
  output_title: string;
  study_type: 'AGNOSTIC' | 'NATIONAL' | 'INTERNATIONAL' | 'IB';
  national_secondary_modality:
    | 'SCIENCES_ENGINEERING'
    | 'HEALTH_SCIENCES'
    | 'HUMANITIES'
    | 'SOCIAL_SCIENCES'
    | 'ARTS';
  international_studies: number;
  studies_difficulty: number;
  professional_output: number;
  average_salary: number;
  sectoral_variety: number;

  // Conditional properties
  bachelor_modality?:
    | 'SCIENCES_ENGINEERING'
    | 'HEALTH_SCIENCES'
    | 'HUMANITIES'
    | 'SOCIAL_SCIENCES'
    | 'ARTS';
  important_subjects?: string;
  cutting_notes?: string;
  profile?: number[];
  study_universities?: {
    university: number;
    math_requirement: number;
    language_requirement: number;
    order: number;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  private API_URL = environment.apiUrl;
  private MODEL = '/reports/general';

  public deleteReportGroupFromOrg(orgId: number, reportGroupId: number) {
    const url = environment.apiUrl + `/organizations/${orgId}/delete_report/`;
    return this.http.delete(url, { body: { report_id: reportGroupId } }).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  public createReportGroup(payload: {
    name: string;
    internal_name: string;
    description: string;
    internal_description: string;
  }) {
    const url = `${this.API_URL + this.MODEL}/`;
    return this.http.post(url, payload);
  }

  public addReportToOrg(orgIds: number[], reportId: number) {
    const url = `${
      this.API_URL + this.MODEL
    }/${reportId}/add-to-organizations/`;

    return this.http.post(url, {
      organization_ids: orgIds,
    });
  }

  public updateReportGroup(
    reportGroupId: string,
    payload?: {
      name: string;
      internal_name: string;
      description: string;
      internal_description: string;
    }
  ) {
    const url = `${this.API_URL + this.MODEL}/${reportGroupId}/`;
    return this.http.put(url, payload);
  }

  public getReportGroups(): Observable<any> {
    const url = `${this.API_URL + this.MODEL}/`;

    return this.http.get(url);
  }

  public getReportGroupsDetails(groupId: string) {
    const url = `${this.API_URL + this.MODEL}/${groupId}/`;

    return this.http.get(url);
  }

  public getReportsByGroupId(groupId: string) {
    const reportGroupsData: any[] = [
      {
        id: '1',
        name: 'Reporte A',
        is_published: true,
      },
      {
        id: '2',
        name: 'Reporte B',
        is_published: false,
      },
      {
        id: '3',
        name: 'Reporte C',
        is_published: true,
      },
    ];

    return of(reportGroupsData);
  }

  public updateReportSection(reportData: any) {
    const url = `${this.API_URL}/reports/sections/${reportData.id}/`;

    return this.http.put(url, reportData);
  }

  public updateReportSectionOrder(reportId: string, reportData: any) {
    const url = `${
      this.API_URL + this.MODEL
    }/${reportId}/update-section-order/`;

    return this.http.put(url, reportData);
  }

  public addSectionToTheReport(
    reportGroupId: string,
    { sectionId, order }: { sectionId: number; order: number }
  ) {
    const url = `${this.API_URL + this.MODEL}/${reportGroupId}/add-section/`;

    return this.http.post(url, {
      section_id: sectionId,
      order: order,
    });
  }

  public getAllReportsSections() {
    const url = `${this.API_URL}/reports/sections/`;
    return this.http.get(url);
  }

  public deleteReport(reportId: string, groupId: string) {
    const url = `${this.API_URL}/reports/general/${groupId}/section/${reportId}`;
    return this.http.delete(url);
  }

  public deleteReportGroup(groupId: string): Observable<any> {
    const url = `${this.API_URL + this.MODEL}/${groupId}/`;
    return this.http.delete(url);
  }

  public getProfileSheets() {
    const url = `${this.API_URL}/reports/profile-sheets/`;

    return this.http.get(url);
  }

  public getProfileSheet(id: string) {
    const url = `${this.API_URL}/reports/profile-sheets/${id}/`;

    return this.http.get(url);
  }

  public createProfileSheet(payload: {
    name: string;
    description: string;
    wef: Record<string, number>;
    schein: Record<string, number>;
    vocational_interest: Record<string, number>;
  }) {
    const url = `${this.API_URL}/reports/profile-sheets/`;

    return this.http.post(url, payload);
  }

  public updateProfileSheet(
    id: string,
    payload: {
      name: string;
      description: string;
      wef: Record<string, number>;
      schein: Record<string, number>;
      vocational_interest: Record<string, number>;
    }
  ) {
    const url = `${this.API_URL}/reports/profile-sheets/${id}/`;

    return this.http.put(url, payload);
  }

  public deleteProfileSheet(id: string) {
    const url = `${this.API_URL}/reports/profile-sheets/${id}/`;

    return this.http.delete(url);
  }

  // UNIVERSITIES
  public getUniversities() {
    const url = `${this.API_URL}/reports/university-records/`;

    return this.http.get(url);
  }

  public getUniversity(id: string) {
    const url = `${this.API_URL}/reports/university-records/${id}/`;

    return this.http.get(url);
  }

  public createUniversity(payload: {
    name: string;
    info?: string;
    university_description: string;
    output_profile: string;
    location: string;
    web: string;
    university_type: string;
    icon?: File;
    cover_image?: File;
    price_range: string;
    order?: any;
  }) {
    const url = `${this.API_URL}/reports/university-records/`;
    return this.http.post(url, convertToFormData(payload));
  }

  public updateUniversity(
    id: string,
    payload: {
      id?: number;
      name: string;
      info?: string;
      university_description: string;
      output_profile: string;
      location: string;
      web: string;
      university_type: string;
      icon: File | null | string;
      price_range: string;
      order?: any;
    }
  ) {
    const url = `${this.API_URL}/reports/university-records/${id}/`;
    const payloadWithoutId = { ...payload };
    delete payloadWithoutId.id;
    if (!(payloadWithoutId.icon instanceof File)) {
      payloadWithoutId.icon = null;
    }
    return this.http.put(url, convertToFormData(payloadWithoutId));
  }

  public deleteUniversity(id: string) {
    const url = `${this.API_URL}/reports/university-records/${id}/`;

    return this.http.delete(url);
  }

  // JAZZDOO ROLES
  public getJazzdoos() {
    const url = `${this.API_URL}/reports/jazzdoo-role-sheets/`;

    return this.http.get(url);
  }

  public getJazzdoo(id: string) {
    const url = `${this.API_URL}/reports/jazzdoo-role-sheets/${id}/`;

    return this.http.get(url);
  }

  public createJazzdoo(payload: {
    name: string;
    top?: string;
    second_place?: string;
    description: string;
    skills: string;
    most_important_points: string;
    order?: number;
    role_information?: string;
  }) {
    const url = `${this.API_URL}/reports/jazzdoo-role-sheets/`;
    console.log(payload);
    return this.http.post(url, payload);
  }

  public updateJazzdoo(
    id: string,
    payload: {
      id?: number;
      name: string;
      top?: string;
      second_place?: string;
      description: string;
      skills: string;
      most_important_points: string;
      order?: number;
      role_information?: string;
    }
  ) {
    const url = `${this.API_URL}/reports/jazzdoo-role-sheets/${id}/`;
    const payloadWithoutId = { ...payload };
    delete payloadWithoutId.id;
    return this.http.put(url, payloadWithoutId);
  }

  public deleteJazzdoo(id: string) {
    const url = `${this.API_URL}/reports/jazzdoo-role-sheets/${id}/`;

    return this.http.delete(url);
  }

  // University Grades
  public getUniversityGrades() {
    const url = `${this.API_URL}/reports/studies/`;

    return this.http.get(url);
  }

  public getUniversityGrade(id: string) {
    const url = `${this.API_URL}/reports/studies/${id}/`;

    return this.http.get(url);
  }

  public createUniversityGrade(payload: UniversityGradePayload) {
    const url = `${this.API_URL}/reports/studies/`;
    console.log(payload);
    return this.http.post(url, payload);
  }

  public updateUniversityGrade(id: string, payload: UniversityGradePayload) {
    const url = `${this.API_URL}/reports/studies/${id}/`;
    const payloadWithoutId = { ...payload };
    delete payloadWithoutId.id;
    if (payload.type === 'VOCATIONAL_TRAINING') {
      delete payloadWithoutId.bachelor_modality;
    }
    return this.http.put(url, payloadWithoutId);
  }

  public deleteUniversityGrade(id: string) {
    const url = `${this.API_URL}/reports/studies/${id}/`;

    return this.http.delete(url);
  }
}
