import { Title } from '@angular/platform-browser';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  ReplaySubject,
  combineLatest,
  concatMap,
  finalize,
  from,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { CreationalPagesLayoutComponent } from '../../layouts/creational-pages-layout/creational-pages-layout.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';

interface IContent {
  id: number;
  description: string;
  internal_name: string;
  internal_description: string;
  background_color: string;
  gradient_color: string;
  font_color: string;
  video_url: string | null;
  image: string;
  image_url: string | null;
  parent: any;
  name: string;
  order: number;
  is_activity: boolean;
  is_preliminar_limit: boolean;
  time: number;
}

@Component({
  selector: 'app-pages-seasons-page',
  templateUrl: './pages-seasons-page.component.html',
  styleUrls: ['./pages-seasons-page.component.scss'],
})
export class PagesSeasonsPageComponent implements OnInit {
  programId!: number;
  programData!: any;
  season!: any;
  @ViewChild(ModalComponent) modal!: ModalComponent;
  isLoading: boolean = true;
  public chapterContents: any[] = [];
  contentsToAdd!: any;
  public addContent: boolean = false;
  private userInputContentsAdded: Map<number, IContent> = new Map();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private isEditionDestroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public orderOptions: string[] = [
    'Alfabeticamente',
    'Mas reciente',
    'Mas antiguo',
  ];
  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    @SkipSelf()
    public creationalPagesLayoutComponent: CreationalPagesLayoutComponent,
    public changeDetectorRef: ChangeDetectorRef,
    private searchBarListService: SearchBarListService
  ) {
    this.title.setTitle('Contenidos del Programa | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.programId = parseInt(this.route.snapshot.paramMap.get('programId')!);
    // this.getPages()
    this.getProgramDetail(this.programId);
    this.getChaptersByProgramId(this.programId.toString());
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.handleTurnAddContent();
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addContent = false;
          this.userInputContentsAdded.clear();
          this.creationalPagesLayoutService.layoutConfig.next({
            headerTitle: this.programData.internal_name,
            headerTitleIcon: '/assets/images/icons/icon_program.svg',
            headerButtonIcon: 'assets/images/icons/icon_create.svg',
            headerButtonText: 'Añadir contenido',
          });
          this.searchBarListService.setSearchInput('');
          this.searchBarListService.setFilterSelected('');
        }
      });

    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addContent = false;
          this.searchBarListService.setSearchInput('');
          this.searchBarListService.setFilterSelected('');
          this.handleSubmitAddContent();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.userInputContentsAdded.clear();
  }

  navigateToBack() {
    this.location.back();
  }

  public triggerCheckbox(checkboxElement: HTMLInputElement, content: any) {
    checkboxElement.checked = !checkboxElement.checked;

    this.handleContentCheck(
      { target: { checked: checkboxElement.checked } },
      content
    );
  }

  private getOrder = (chapterContents: IContent[]): number => {
    console.log(chapterContents);
    if (chapterContents.length > 0) {
      return (
        chapterContents.reduce((prev, current) => {
          return prev.order > current.order ? prev : current;
        }).order + 1
      );
    }

    return 1;
  };

  public handleContentCheck(event: any, content: any) {
    const eventTarget = event.target as { checked: boolean };
    if (eventTarget.checked) {
      this.userInputContentsAdded.set(content.id, {
        ...content,
        order: this.getOrder([
          ...this.chapterContents,
          ...Array.from(this.userInputContentsAdded.values()),
        ]),
      });
    } else {
      this.userInputContentsAdded.delete(content.id);
      this.updateInputContentAdded();
    }
    console.log(this.userInputContentsAdded);
  }

  private getAllContentsFiltered() {
    const filterExistingContents = (
      content: IContent
    ): Partial<IContent | { checked: boolean }> => {
      const programContentsIds = new Set(
        this.chapterContents.map((contentAdded: IContent) => contentAdded.id)
      );

      if (programContentsIds.has(content.id)) {
        return {
          ...content,
          checked: true,
        };
      } else {
        return {
          ...content,
          checked: false,
        };
      }
    };

    const sortCheckedObjs = (
      a: { checked: boolean },
      b: { checked: boolean }
    ) => (a.checked ? -1 : 1);

    const changeInternalProps = (element: any) => ({
      ...element,
      name: element.internal_name ?? '',
      description: element.internal_description ?? '',
    });

    return this.pageService.getContents().pipe(
      map((response: any) => response.map(filterExistingContents)),
      map((response: any[]) => response.sort(sortCheckedObjs)),
      map((response: any[]) => response.map(changeInternalProps))
    );
  }

  private handleSubmitAddContent() {
    this.isLoading = true;
    from(this.userInputContentsAdded)
      .pipe(
        concatMap(([key, content]) =>
          this.pageService.addContentIntoProgram({
            content: key,
            order: content.order,
            program: this.programId.toString(),
          })
        ),
        takeUntil(this.destroyed$),
        finalize(() => {
          this.getChaptersByProgramId(this.programId.toString());
          this.userInputContentsAdded.clear();
        })
      )
      .subscribe({
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.isLoading = false;

          this.store.dispatch(
            new OpenModal('Algo salio mal' + htmlErrorsContent)
          );
        },
        complete: () => {
          this.store.dispatch(
            new OpenModal('Contenidos agregados exitosamente.')
          );
        },
      });
  }

  getChaptersByProgramId(programId: string, disableLoading: boolean = false) {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.pageService.getChapterByProgramId(programId).subscribe({
      next: (data: any) => {
        this.chapterContents = data.map(
          (element: Partial<{ content: IContent; id: number }>) => ({
            ...element.content,
            idPerProgram: element.id,
            name: (element.content as IContent).internal_name ?? '',
            description:
              (element.content as IContent).internal_description ?? '',
          })
        );
        this.creationalPagesLayoutService.layoutConfig.next({
          headerTitle: this.programData.internal_name,
          headerTitleIcon: '/assets/images/icons/icon_program.svg',
          headerButtonIcon: 'assets/images/icons/icon_create.svg',
          headerButtonText: 'Añadir contenido',
        });
        console.log({ data });
        this.isLoading = false;
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.isLoading = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  public getProgramDetail(id: number | string) {
    this.isLoading = true;
    this.pageService.getQuestionaryById(id).subscribe({
      next: (response: any) => {
        console.log(response);
        this.programData = response;
        this.creationalPagesLayoutService.layoutConfig.next({
          ...this.creationalPagesLayoutService.layoutConfig.getValue(),
          headerTitle: response.internal_name,
          headerTitleIcon: '/assets/images/icons/icon_program.svg',
          headerButtonIcon: 'assets/images/icons/icon_create.svg',
          headerButtonText: 'Añadir contenido',
        });
        this.isLoading = false;
      },
    });
  }

  getNextPage(currentPageIndex: number) {
    let nextPage = null;
    return nextPage;
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deleteContentFromProgram(id);
    }
  }

  deleteContentFromProgram(id: string | number) {
    this.pageService.deleteContentFromPage(id as string).subscribe({
      complete: () => {
        this.getChaptersByProgramId(this.programId.toString());
        this.store.dispatch(new OpenModal('Contenido eliminado exitosamente.'));
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  public handleClick(entity: any) {
    this.router.navigate([
      `/dashboard/pages/programas/contenidos/${this.programId}/capitulos/${entity.id}`,
    ]);
  }

  public handleTurnAddContent() {
    this.addContent = true;
    this.changeDetectorRef.detectChanges();
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Añadir contenido',
      headerTitleIcon: 'assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
    this.isLoading = true;
    combineLatest([
      this.getAllContentsFiltered(),
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService.getFilterSelected().pipe(startWith('')),
    ])
      .pipe(
        tap(() => (this.isLoading = true)),
        map(([contents, inputSearchResult, selectedOption]) => {
          return [
            this.handleApplyFilter(contents, selectedOption),
            inputSearchResult,
          ];
        }),
        map(([contents, inputSearchResult]) => {
          return (contents as any[]).filter((content: any) =>
            this.searchBarListService
              .concatPropertiesValues(content, ['id', 'name'])
              .includes(inputSearchResult as string)
          );
        }),
        shareReplay(),
        takeUntil(this.destroyed$)
      )
      .subscribe((response) => {
        const checkedContent = response.filter((content) => content.checked);
        const uncheckedContent = response.filter((content) => !content.checked);
        this.contentsToAdd = [...checkedContent, ...uncheckedContent];
        this.isLoading = false;
      });
  }

  public drop(event: CdkDragDrop<any[]>): void {
    console.log(event);
    moveItemInArray(
      this.chapterContents,
      event.previousIndex,
      event.currentIndex
    );

    if (event.previousIndex !== event.currentIndex) {
      console.log(this.chapterContents[event.currentIndex]);
      this.pageService
        .modifyContentOrderInProgram({
          order: event.currentIndex + 1,
          contentId: this.chapterContents[event.currentIndex].idPerProgram,
        })
        .subscribe((data) =>
          this.getChaptersByProgramId(this.programId.toString(), true)
        );
    }
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    console.log(data, filter);

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  private updateInputContentAdded() {
    const sortedContents = Array.from(
      this.userInputContentsAdded.values()
    ).sort((a, b) => a.order - b.order);
    sortedContents.forEach((content, index) => {
      content.order = index + 1;
    });
  }

  updateOrder() {
    this.chapterContents.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  public handleListElementClickAction(actionName: string, content: any): void {
    const actions: { [key: string]: () => void } = {
      delete: () => this.confirmDelete(content.idPerProgram),
    };

    actions[actionName]();
  }
}
