import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ErrorsService } from 'src/app/services/errors.service';
import { ParametersService } from 'src/app/services/parameters.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-detail-parameter-page',
  templateUrl: './detail-parameter-page.component.html',
  styleUrls: ['./detail-parameter-page.component.scss']
})
export class DetailParameterPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  public parameterId!: number
  isLoading: boolean = true
  parameter?: any

  constructor(
    private parameterService: ParametersService,
    private errorsService: ErrorsService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('Detalle de Parámetro | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.parameterId = Number(this.route.snapshot.paramMap.get('id'))

    this.parameterService.getParametersById(this.parameterId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: any) => this.parameter = data,
      complete: () => this.isLoading = false
    })
  }
}
