<section class="mt-3">
  <ul class="row">
    <app-search-list-bar class="px-0">
      <section left-side class="col-3 d-flex align-items-center">
        <app-selector [data]="orderOptions"></app-selector>
      </section>
    </app-search-list-bar>
    <div class="mt-3" *ngIf="isLoading">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
            Por favor, espere un momento
          </h2>
        </div>
      </app-card>
    </div>
    <ul class="d-flex flex-column gap-2 px-0" *ngIf="!isLoading">
      <app-list-element
        *ngFor="let content of contents$ | async; index as i"
        [entityData]="content"
        [customTemplate]="'DEFAULT'"
        [actions]="listElementActions"
        (onClick)="handleListElementClick(content)"
        (onClickAction)="handleListElementAction($event, content)"
      >
      </app-list-element>
    </ul>
  </ul>
</section>

<ng-template #deleteConfirmationModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar este contenido?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteContent()">Aceptar</button>
      <button class="button-dismiss-outlined" (click)="closeModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>

<ng-template #duplicateConfirmationModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea duplicar este contenido?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="duplicateContent()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="closeModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>

<ng-template #duplicateSuccessModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5>Contenido duplicado exitosamente.</h5>
  </article>
</ng-template>

<ng-template #deleteErrorModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5>
      No se pudo eliminar el contenido, asegúrese de que no contenga preguntas
      que hayan sido respondidas.
    </h5>
  </article>
</ng-template>

<ng-template #deleteSuccessModalTemplate>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5>Contenido eliminado exitosamente.</h5>
  </article>
</ng-template>
