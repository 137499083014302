import { Component, Host, OnInit, SkipSelf } from '@angular/core';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';

@Component({
  selector: 'app-search-list-bar',
  templateUrl: './search-list-bar.component.html',
  styleUrls: ['./search-list-bar.component.scss'],
})
export class SearchListBarComponent implements OnInit {
  constructor(@SkipSelf() private searchBarListService: SearchBarListService) {}

  ngOnInit(): void {}

  public _onChangeSearch(value: Event): void {
    const eventTarget = value.target as HTMLInputElement;
    this.searchBarListService.setSearchInput(eventTarget.value.toLowerCase());
  }
}
