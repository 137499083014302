<section class="rating">
  <label>{{ label }}</label>
  <div class="rating-container">
    <div
      *ngFor="let sphere of spheres"
      class="sphere"
      [ngClass]="{ selected: selectedRating && selectedRating >= sphere }"
      (click)="updateRating(sphere)"
    ></div>
  </div>
</section>
