import { Store } from '@ngxs/store';
import { PageService } from './../../../services/page.service';
import { generateID } from './../../../utils/fakers';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Page } from 'src/app/models/page.models';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from 'src/app/services/activity.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { forkJoin } from 'rxjs';
import { ActivityQuestion } from 'src/app/models/activities/activity.models';
import { PageFormComponent } from '../../core/page-form/page-form.component';
import { Title } from '@angular/platform-browser';
import { ModalComponent } from '../../core/modal/modal.component';
import { ErrorsService } from 'src/app/services/errors.service';

@Component({
  templateUrl: './edit-page-page.component.html',
  styleUrls: ['./edit-page-page.component.scss']
})
export class EditPagePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('newPageForm') newPageForm!: PageFormComponent
  inProcess: boolean = false
  type: string = 'chapter'
  three: Array<any> = []
  activities: Array<ActivityQuestion> = []
  selectedActivity: string | number | null = null

  public page: Page = {
    id: generateID(),
    name: '',
    description: '',
    background_color: '#ffffff',
    gradient_color: '#ffffff',
    font_color: '#ffffff',
    image: '',
    video_url: '',
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    questionary: null,
    order: null,
    is_preliminar_limit: false
  }

  constructor(
    private errorsService: ErrorsService,
    private activeRoute: ActivatedRoute,
    private pageService: PageService,
    private store: Store,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Editar Página | Backoffice - Growth Road')

    this.activeRoute.queryParams.subscribe({
      next: () => { this.page.id = this.activeRoute.snapshot.params['id'] },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }

  ngOnInit(): void {
    this.getThreeAndPage()
    // this.getAvailableActivities()
  }

  getThree() {
    this.pageService.getQuestionaries().subscribe({
      next: (data) => {
        this.three = data as Array<any>
        this.typeSetter()
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
    })
  }

  typeSetter() {
    this.three.forEach((questionary: any) => {
      questionary.type = 'questionary'
      questionary.pages.forEach((chapter: any) => {
        chapter.type = 'chapter'
        chapter.pages.forEach((theme: any) => {
          theme.type = 'theme'
          theme.pages.forEach((subtheme: any) => {
            subtheme.type = 'subtheme'
            subtheme.pages.forEach((activity: any) => {
              activity.type = 'activity'
            });
          });
        });
      });
    });
    console.debug(this.three)
  }

  updatePage() {
    this.inProcess = true
    switch (this.newPageForm.type) {
      case 'chapter':
        this.page.parent = null
        break;
      case 'theme':
        this.page.parent = this.newPageForm.chapterId
        break;
      case 'subtheme':
        this.page.parent = this.newPageForm.themeId
        break;
      case 'activity':
        this.page.parent = this.newPageForm.subthemeId
        break;

      default:
        break;
    }
    this.pageService.updatePage(this.page).subscribe({
      next: (data: any) => {
        this.page = data as Page
        this.getThree()
        this.store.dispatch(new OpenModal(`Página '${data.name}' actualizada.`))
        this.inProcess = false
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.inProcess = false
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }

  getThreeAndPage() {
    forkJoin(
      {
        three: this.pageService.getQuestionaries(),
        page: this.pageService.getPageById(this.page.id)
      }).subscribe({
        next: ({ page, three }) => {
          // Three handling
          this.three = three as Array<any>
          this.typeSetter()

          // page handling
          this.page = page as Page
          const query = this.findInThree(this.three, 'id', +this.page.id)
          console.debug(query)
          this.type = query!.type!
          this.page.type = query!.type
          this.newPageForm.questionaryId = this.page.questionary

          switch (this.page.type) {
            case 'chapter':
              break;
            case 'theme':
              this.newPageForm.chapterId = +this.page.parent!
              break;
            case 'subtheme':
              const theme = this.findInThree(this.three, 'id', +this.page.parent!)
              if (theme) {
                this.newPageForm.chapterId = +theme.parent!
                this.newPageForm.themeId = +this.page.parent!
              }
              break;
            case 'activity':
              const subtheme = this.findInThree(this.three, 'page_id', +this.page.parent!)
              if (subtheme) {
                this.newPageForm.themeId = +subtheme.parent!
                this.newPageForm.subthemeId = +this.page.parent!
                const chapter = this.findInThree(this.three, 'page_id', +this.newPageForm.themeId!)
                if (chapter) {
                  this.newPageForm.chapterId = +chapter.parent!
                }
              }
              break;

            default:
              break;
          }
        },
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error)
          this.modal.open('Algo ha ocurrido', htmlErrorsContent)
          this.router.navigate(['/404'])
        },
      })
  }

  findInThree(three: Array<any>, key: string, value: string | number): Page | undefined {
    let queryResult;
    JSON.stringify(three, (_, nestedValue) => {
      if (nestedValue && nestedValue[key] === value) {
        queryResult = nestedValue;
      }
      return nestedValue;
    });
    return queryResult;
  };

}
