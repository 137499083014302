<main class="page detail_user-page">
  <header *ngIf="!isLoading">
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <h1 class="font-24 font-primary--eb">{{ userDetail.firstName }} {{ userDetail.lastName }}</h1>
      </article>
    </app-card>
  </header>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>

  <div class="detail-cards mt-4" *ngIf="!isLoading">
    <section>
      <div class="detail-nav">
        <app-card borderRadius="10px" padding="26px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content>
            <section class="text-center">
              <img
                [src]="userDetail.imageProfile"
                width="80px"
                height="80px"
                style="background-color: #d0d0d0;"
              />
  
              <div class="mt-2">
                <h5 class="font-primary--eb">
                  {{ userDetail.firstName || 'Sin nombre' }} {{ userDetail.lastName || 'sin apellido' }}
                </h5>

                <p>{{ userDetail.userType }}</p>
              </div>
            </section>
  
            <hr>
  
            <section>
              <h5 class="font-primary--b font-16">Información general</h5>

              <ul class="font-14">
                <li *ngFor="let info of previewInfo" style="padding: 4px 0px;">
                  <span class="font-primary--b" style="color: #7b7e82;">{{info.label}}: </span>
                  <span>{{info.data}}</span>
                </li>
              </ul>

              <hr>

              <h5 class="font-primary--b font-16">Etapas</h5>

              <ul class="font-14">
                <li *ngFor="let phase of userDetail.phases" style="padding: 4px 0px;">
                  <span class="font-primary--b" style="color: #7b7e82;">{{phase.label}}: </span>
                  <span>{{phase.value}}</span>
                </li>
              </ul>

              <hr>

              <div class="badge_subscription{{ userDetail.subscription === 'Plan Premium' ? '-premium' : '-free' }}">
                <span class="font-primary--b font-16">{{userDetail.subscription}}</span>
              </div>
            </section>
          </div>
        </app-card>
      </div>
    </section>

    <section class="detail-section">
      <div class="imageProfile-container {{ windowWidth > 768 ? 'd-block' : 'd-none' }}">
        <app-card borderRadius="10px" padding="26px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content>
            <h5 class="font-20 font-primary--eb">Imagen de perfil</h5>
  
            <div class="mt-3">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <img
                  [src]="userDetail.imageProfile"
                  width="120px"
                  height="120px"
                  style="background-color: #d0d0d0;"
                />
              </div>
            </div>
          </div>
        </app-card>
      </div>

      <div class="mt-4">
        <app-card borderRadius="10px" padding="26px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content>
            <h5 class="font-20 font-primary--eb">Información básica</h5>
  
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <label for="firstName" class="font-primary--eb font-14">Nombre</label>
                <br>
  
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  class="input w-100"
                  placeholder="Nombre de usuario"
                  [value]="userDetail.firstName"
                  [disabled]="true"
                />
              </div>
  
              <div class="col-12 col-md-6 mt-3 md:mt-0">
                <label for="lastName" class="font-primary--eb font-14">Apellido</label>
                <br>
  
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  class="input w-100"
                  placeholder="Apellido de usuario"
                  [value]="userDetail.lastName"
                  [disabled]="true"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <label for="phone" class="font-primary--eb font-14">Teléfono</label>
                <br>
  
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  class="input w-100"
                  placeholder="Teléfono de usuario"
                  [value]="userDetail.phone"
                  [disabled]="true"
                />
              </div>
  
              <div class="col-12 col-md-6 mt-3 md:mt-0">
                <label for="NIF" class="font-primary--eb font-14">NIF</label>
                <br>
  
                <input
                  id="NIF"
                  name="NIF"
                  type="text"
                  class="input w-100"
                  placeholder="NIF de usuario"
                  [value]="userDetail.taxId"
                  [disabled]="true"
                />
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col-12">
                <label for="description" class="font-primary--eb font-14">Descripción</label>
                <br>
  
                <textarea
                  id="description"
                  name="description"
                  type="text"
                  rows="6"
                  class="w-100"
                  placeholder="Descripción de usuario"
                  [value]="userDetail.description"
                  [disabled]="true"
                  ></textarea>
              </div>
            </div>
          </div>
        </app-card>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mt-4">
          <app-card borderRadius="10px" padding="26px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
            <div card__content>
              <h5 class="font-20 font-primary--eb">Correo electrónico</h5>
    
              <div class="row mt-3">
                <div class="col">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Nuevo correo electrónico"
                    class="input w-100"
                    [value]="userDetail.email"
                    [disabled]="true"
                  />
                </div>
              </div>
            </div>
          </app-card>
        </div>
  
        <div class="col-12 col-md-6 mt-4">
          <app-card borderRadius="10px" padding="26px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
            <div card__content>
              <h5 class="font-20 font-primary--eb">Suscripción</h5>
    
              <div class="row mt-3">
                <div class="col">
                  <input
                    id="subscription"
                    name="subscription"
                    type="text"
                    placeholder="Nuevo correo electrónico"
                    class="input w-100"
                    [value]="userDetail.subscription"
                    [disabled]="true"
                  />
                </div>
              </div>
            </div>
          </app-card>
        </div>
      </div>
    </section>
  </div>
</main>