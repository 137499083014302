import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { MetaDataService } from 'src/app/services/metadata.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { UsersService } from 'src/app/services/users.service';
import { normalizeDate } from 'src/app/utils/normalizeDate';

import { ModalContent } from 'src/app/components/core/modal/modal.component';
import { IUserDetail } from 'src/app/models/user.models';

@Component({
  selector: 'app-detail-user-page',
  templateUrl: './detail-user-page.component.html',
  styleUrls: ['./detail-user-page.component.scss'],
})
export class DetailUserPageComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  userDetail!: any;
  previewInfo!: any;
  windowWidth!: number;

  constructor(
    private routeActivated: ActivatedRoute,
    private errorsService: ErrorsService,
    private userService: UsersService,
    private modalService: NgbModal,
    private meta: MetaDataService,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Editar Usuario | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    window.addEventListener(
      'resize',
      () => (this.windowWidth = window.innerWidth)
    );

    this._getUserDetails();
  }

  _getUserDetails() {
    this.routeActivated.params.subscribe((params: any) => {
      this.userService.getUserDetails(params.id).subscribe({
        error: ({ error }) => {
          console.error('getUserDetails error', error);
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.router.navigate(['/dashboard/usuarios']);
          this.isLoading = false;
        },
        next: (data: IUserDetail) => {
          this.userDetail = {
            id: data.id,
            imageProfile:
              data.image_profile ||
              'assets/images/icons/icon_avatar_default.svg',
            firstName: data.first_name,
            lastName: data.last_name,
            phone: data.phone,
            taxId: data.tax_id,
            description: data.description,
            email: data.email,
            userType: this.meta.GET_USER_TYPES_DIC()[data.user_type].label,
            subscription: data.is_subscribed ? 'Plan Premium' : 'Plan Gratuito',
            createdAt: normalizeDate(data.authored_at, true),
            phases: [
              {
                label: 'Fase preliminar',
                value: data.preliminar_phase_completed
                  ? 'Completado'
                  : 'No completado',
              },
              {
                label: 'Fase final',
                value: data.completed ? 'Completado' : 'No completado',
              },
            ],
          };

          this.previewInfo = [
            {
              label: 'ID',
              data: String(data.id),
            },
            {
              label: 'Activo',
              data: data.is_active ? 'Si' : 'No',
            },
            {
              label: 'Tipo de usuario',
              data: this.meta.GET_USER_TYPES_DIC()[data.user_type].label,
            },
            {
              label: 'Creado',
              data: normalizeDate(data.authored_at, true),
            },
          ];

          this.isLoading = false;
        },
      });
    });
  }

  openModal(
    title: string,
    content: string,
    type: string = 'error',
    options?: {},
    fnAction?: () => void
  ) {
    const modalRef = this.modalService.open(ModalContent, {
      centered: true,
      ...options,
    });
    console.debug();
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }

  ngOnDestroy(): void {
    window.removeEventListener(
      'resize',
      () => (this.windowWidth = window.innerWidth)
    );
  }
}
