import { HttpErrorInterceptorInterceptor } from './interceptors/http-error-interceptor.interceptor';
import { PreviewThreeComponent } from './components/core/preview-three/preview-three.component';
import { UiState } from './store/ui/ui.state';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { ActivitiesPageComponent } from './components/pages/activities-page/activities-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { DrawerComponent } from './components/core/drawer/drawer.component';
import { LoadingComponent } from './components/core/loading/loading.component';
import { ModalComponent } from './components/core/modal/modal.component';
import { NgbModule, NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from './components/core/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  RecaptchaFormsModule,
  RecaptchaV3Module,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';
import { TextActivityComponent } from './components/core/activities/text-activity/text-activity.component';
import { VideoActivityComponent } from './components/core/activities/video-activity/video-activity.component';
import { SimpleSelectionActivityComponent } from './components/core/activities/simple-selection-activity/simple-selection-activity.component';
import { MultipleSelectionActivityComponent } from './components/core/activities/multiple-selection-activity/multiple-selection-activity.component';
import { ListActivityComponent } from './components/core/activities/list-activity/list-activity.component';
import { RegularValorationActivityComponent } from './components/core/activities/regular-valoration-activity/regular-valoration-activity.component';
import { PercentualValorationActivityComponent } from './components/core/activities/percentual-valoration-activity/percentual-valoration-activity.component';

import { NgxsModule } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ActivityState } from './store/activities/activity.state';
import { VideoComponent } from './components/core/video/video.component';
import { RadioComponent } from './components/core/radio/radio.component';
import { NewActivityPageComponent } from './components/pages/new-activity-page/new-activity-page.component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { ActivityPageComponent } from './components/pages/activity-page/activity-page.component';
import { EditActivityPageComponent } from './components/pages/edit-activity-page/edit-activity-page.component';
import { ErrorLayoutComponent } from './components/layouts/error-layout/error-layout.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { NewParameterPageComponent } from './components/pages/new-parameter-page/new-parameter-page.component';
import { CategoriesParameterPageComponent } from './components/pages/categories-parameter-page/categories-parameter-page.component';
import { DetailCategoryParameterPageComponent } from './components/pages/detail-category-parameter-page/detail-category-parameter-page.component';
import { ParametersPageComponent } from './components/pages/parameters-page/parameters-page.component';
import { ActivityPreviewComponent } from './components/core/activity-preview/activity-preview.component';
import { DetailParameterPageComponent } from './components/pages/detail-parameter-page/detail-parameter-page.component';
import { CreateActivityOptionsComponent } from './components/core/activities/create-activity-options/create-activity-options.component';
import { ActivityFormComponent } from './components/core/activity-form/activity-form.component';
import { ToggleSwitchComponent } from './components/core/toggle-switch/toggle-switch.component';
import { TypeState } from './store/type/type.state';
import { MetaDataService } from './services/metadata.service';
import { GlobalModalComponent } from './components/core/global-modal/global-modal.component';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { PagesPageComponent } from './components/pages/pages-page/pages-page.component';
import { NewContentPageComponent } from './components/pages/new-content-page/new-content-page.component';
import { PageFormComponent } from './components/core/page-form/page-form.component';
import { EditPagePageComponent } from './components/pages/edit-page-page/edit-page-page.component';
import { DetailPagePageComponent } from './components/pages/detail-page-page/detail-page-page.component';
import { UsersComponent } from './components/pages/usuarios/users/users.component';
import { UsersService } from './services/users.service';
import { NextPagePipe } from './pipes/next-page.pipe';
import { PrevPagePipe } from './pipes/prev-page.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { CareersUniversityPageComponent } from './components/pages/careers-university-page/careers-university-page.component';
import { DetailCareerUniversityPageComponent } from './components/pages/detail-career-university-page/detail-career-university-page.component';
import { EditCareerUniversityPageComponent } from './components/pages/edit-career-university-page/edit-career-university-page.component';
import { NewCareerUniversityPageComponent } from './components/pages/new-career-university-page/new-career-university-page.component';
import { CouponsPageComponent } from './components/pages/coupons-page/coupons-page.component';
import { DetailCouponPageComponent } from './components/pages/detail-coupon-page/detail-coupon-page.component';
import { EditCouponPageComponent } from './components/pages/edit-coupon-page/edit-coupon-page.component';
import { NewCouponPageComponent } from './components/pages/new-coupon-page/new-coupon-page.component';
import { PercentageDirective } from './directives/percentage.directive';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PhrasesPageComponent } from './components/pages/phrases-page/phrases-page.component';
import { EditPhrasePageComponent } from './components/pages/edit-phrase-page/edit-phrase-page.component';
import { DetailPhrasePageComponent } from './components/pages/detail-phrase-page/detail-phrase-page.component';
import { NgxMasonryModule } from 'ngx-masonry';
import { ProgramsComponent } from './components/pages/programs/programs.component';
import { PagesSeasonsPageComponent } from './components/pages/pages-seasons-page/pages-seasons-page.component';
import { PagesChaptersPageComponent } from './components/pages/pages-chapters-page/pages-chapters-page.component';
import { PagesThemesPageComponent } from './components/pages/pages-themes-page/pages-themes-page.component';
import { PagesSubthemesPageComponent } from './components/pages/pages-subthemes-page/pages-subthemes-page.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { EditParameterPageComponent } from './components/pages/edit-parameter-page/edit-parameter-page.component';
import { EmailVerificationPageComponent } from './components/pages/email-verification-page/email-verification-page.component';
import { PagesActivitiesPageComponent } from './components/pages/pages-activities-page/pages-activities-page.component';
import { NgbdToastGlobal } from './components/core/toast/toast.component';
import { ToastsContainer } from './components/core/toast/toasts-container.component';
import { SettingsPageComponent } from './components/pages/settings-page/settings-page.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FiltersOptionComponent } from './components/pages/usuarios/filters-option/filters-option.component';
import { EditUserPageComponent } from './components/pages/edit-user-page/edit-user-page.component';
import { CreateUserComponent } from './components/pages/create-user/create-user.component';
import { DetailUserPageComponent } from './components/pages/detail-user-page/detail-user-page.component';
import { InsightPageComponent } from './components/pages/insight-page/insight-page.component';
import { InstitutionsPageComponent } from './components/pages/institutions-page/institutions-page.component';
import { NewQuestionariesPageComponent } from './components/pages/new-questionaries-page/new-questionaries-page.component';
import { EditProgramsPageComponent } from './components/pages/edit-questionaries-page/edit-questionaries-page.component';
import { PageLoaderComponent } from './components/core/page-loader/page-loader.component';
import { CustomContentComponent } from './components/pages/phrases-pages/custom-content/custom-content.component';
import { PageFooterComponent } from './components/core/page-footer/page-footer.component';
import { CreationalPagesLayoutComponent } from './components/layouts/creational-pages-layout/creational-pages-layout.component';
import { ListElementComponent } from './components/core/list-element/list-element.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContentPageComponent } from './components/pages/content-page/content-page.component';
import { ContentThemesPageComponent } from './components/pages/content-themes-page/content-themes-page.component';
import { EditContentPageComponent } from './components/pages/edit-content-page/edit-content-page.component';
import { SearchListBarComponent } from './components/core/search-list-bar/search-list-bar.component';
import { SelectorComponent } from './components/core/selector/selector.component';
import { SortOrderPipe } from './pipes/sort-order.pipe';
import { TrimStringPipe } from './pipes/trim-string.pipe';
import { NewThemePageComponent } from './components/pages/new-theme-page/new-theme-page.component';
import { EditThemePageComponent } from './components/pages/edit-theme-page/edit-theme-page.component';
import { NewSubthemePageComponent } from './components/pages/new-subtheme-page/new-subtheme-page.component';
import { EditSubthemePageComponent } from './components/pages/edit-subtheme-page/edit-subtheme-page.component';
import { NewQuestionPageComponent } from './components/pages/new-question-page/new-question-page.component';
import { EditQuestionPageComponent } from './components/pages/edit-question-page/edit-question-page.component';
import { MultiSelectDropdownComponent } from './components/core/multi-select-dropdown/multi-select-dropdown.component';
import { TeamListPageComponent } from './components/pages/team-list-page/team-list-page.component';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UniversalModalComponent } from './components/core/universal-modal/universal-modal.component';
import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { NewGroupPageComponent } from './components/pages/new-group-page/new-group-page.component';
import { UsersGroupListPageComponent } from './components/pages/users-group-list-page/users-group-list-page.component';
import { UsersGroupAddListPageComponent } from './components/pages/users-group-add-list-page/users-group-add-list-page.component';
import { NewReportGroupPageComponent } from './components/pages/new-report-group-page/new-report-group-page.component';
import { ReportGroupListPageComponent } from './components/pages/report-group-list-page/report-group-list-page.component';
import { EditReportGroupPageComponent } from './components/pages/edit-report-group-page/edit-report-group-page.component';
import { StudentTypePipe } from './pipes/student-type.pipe';
import { AddReportsPageComponent } from './components/pages/add-reports-page/add-reports-page.component';
import { JazzdooListPageComponent } from './components/pages/jazzdoo-list-page/jazzdoo-list-page.component';
import { ProfessionalFormationListPageComponent } from './components/pages/professional-formation-list-page/professional-formation-list-page.component';
import { UniversityGradeListPageComponent } from './components/pages/university-grade-list-page/university-grade-list-page.component';
import { UniversityListPageComponent } from './components/pages/university-list-page/university-list-page.component';
import { ProfileInstituteListPageComponent } from './components/pages/profile-institute-list-page/profile-institute-list-page.component';
import { NewJazzdooPageComponent } from './components/pages/new-jazzdoo-page/new-jazzdoo-page.component';
import { NewProfilePageComponent } from './components/pages/new-profile-page/new-profile-page.component';
import { NewUniversityPageComponent } from './components/pages/new-university-page/new-university-page.component';
import { NewUniversityGradePageComponent } from './components/pages/new-university-grade-page/new-university-grade-page.component';
import { RatingCircularComponent } from './components/core/rating-circular/rating-circular.component';
import { NewProfessionalFormPageComponent } from './components/pages/new-professional-form-page/new-professional-form-page.component';
import { ScheinActivityComponent } from './components/core/activities/schein-activity/schein-activity.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    PercentageDirective,
    DashboardLayoutComponent,
    SigninPageComponent,
    ActivitiesPageComponent,
    HomePageComponent,
    DrawerComponent,
    LoadingComponent,
    ModalComponent,
    CardComponent,
    TextActivityComponent,
    VideoActivityComponent,
    SimpleSelectionActivityComponent,
    MultipleSelectionActivityComponent,
    ScheinActivityComponent,
    PercentualValorationActivityComponent,
    ListActivityComponent,
    RegularValorationActivityComponent,
    VideoComponent,
    RadioComponent,
    NewActivityPageComponent,
    ActivityPageComponent,
    EditActivityPageComponent,
    ErrorLayoutComponent,
    NotFoundPageComponent,
    NewParameterPageComponent,
    CategoriesParameterPageComponent,
    DetailCategoryParameterPageComponent,
    ParametersPageComponent,
    ActivityPreviewComponent,
    DetailParameterPageComponent,
    CreateActivityOptionsComponent,
    ActivityFormComponent,
    ToggleSwitchComponent,
    GlobalModalComponent,
    PagesPageComponent,
    NewContentPageComponent,
    PageFormComponent,
    EditPagePageComponent,
    DetailPagePageComponent,
    UsersComponent,
    CreateUserComponent,
    PreviewThreeComponent,
    NextPagePipe,
    PrevPagePipe,
    OrderByPipe,
    CareersUniversityPageComponent,
    DetailCareerUniversityPageComponent,
    EditCareerUniversityPageComponent,
    NewCareerUniversityPageComponent,
    CouponsPageComponent,
    DetailCouponPageComponent,
    EditCouponPageComponent,
    NewCouponPageComponent,
    ReportsPageComponent,
    PhrasesPageComponent,
    EditPhrasePageComponent,
    DetailPhrasePageComponent,
    ProgramsComponent,
    PagesSeasonsPageComponent,
    PagesChaptersPageComponent,
    PagesThemesPageComponent,
    PagesSubthemesPageComponent,
    SafeHtmlPipe,
    EditParameterPageComponent,
    PagesActivitiesPageComponent,
    EmailVerificationPageComponent,
    NgbdToastGlobal,
    ToastsContainer,
    NgbdToastGlobal,
    SettingsPageComponent,
    FiltersOptionComponent,
    EditUserPageComponent,
    DetailUserPageComponent,
    InsightPageComponent,
    InstitutionsPageComponent,
    NewQuestionariesPageComponent,
    EditProgramsPageComponent,
    PageLoaderComponent,
    CustomContentComponent,
    PageFooterComponent,
    CreationalPagesLayoutComponent,
    ListElementComponent,
    ContentPageComponent,
    ContentThemesPageComponent,
    EditContentPageComponent,
    SearchListBarComponent,
    SelectorComponent,
    SortOrderPipe,
    TrimStringPipe,
    NewThemePageComponent,
    EditThemePageComponent,
    NewSubthemePageComponent,
    EditSubthemePageComponent,
    NewQuestionPageComponent,
    EditQuestionPageComponent,
    MultiSelectDropdownComponent,
    TeamListPageComponent,
    UniversalModalComponent,
    NewGroupPageComponent,
    UsersGroupListPageComponent,
    UsersGroupAddListPageComponent,
    NewReportGroupPageComponent,
    ReportGroupListPageComponent,
    EditReportGroupPageComponent,
    StudentTypePipe,
    AddReportsPageComponent,
    JazzdooListPageComponent,
    ProfessionalFormationListPageComponent,
    UniversityGradeListPageComponent,
    UniversityListPageComponent,
    ProfileInstituteListPageComponent,
    NewJazzdooPageComponent,
    NewProfilePageComponent,
    NewUniversityPageComponent,
    NewUniversityGradePageComponent,
    RatingCircularComponent,
    NewProfessionalFormPageComponent,
  ],
  imports: [
    NgxMasonryModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    DragDropModule,
    // Store
    NgxsModule.forRoot([ActivityState, TypeState, UiState], {
      developmentMode: !environment.production,
    }),
    AngularEditorModule,
    NgxPaginationModule,
    OverlayModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6LcY1xsgAAAAAGgTMBARSx_KrF2vSfi2K0JsZe25',
    },
    MetaDataService,
    UsersService,
    // NgxMasonryModule
  ],
  exports: [NgbToast],
  bootstrap: [AppComponent, NgbdToastGlobal],
})
export class AppModule {}
