<main class="mt-3">
  <div *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
          Por favor, espere un momento
        </h2>
      </div>
    </app-card>
  </div>

  <form (ngSubmit)="onSubmitCreateUser()" *ngIf="!isLoading">
    <div class="imageProfile-container">
      <app-card
        borderRadius="10px"
        padding="20px 40px"
        boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
      >
        <div card__content>
          <div class="mt-3 col-xl-8">
            <section class="d-flex mb-2">
              <h5 class="font-20 text-muted me-2">Imagen de perfil</h5>
            </section>
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <picture class="position-relative">
                <img
                  [src]="userToCreate.imageProfile.preview"
                  width="150px"
                  height="150px"
                />
                <input
                  id="imageProfile"
                  name="imageProfile"
                  type="file"
                  class="imageProfile-input"
                  placeholder="Ingrese imagen de perfil"
                  (change)="onFileChanged($event)"
                />
              </picture>
            </div>
          </div>
        </div>
      </app-card>
    </div>

    <div class="mt-4">
      <app-card
        borderRadius="10px"
        padding="26px"
        boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
      >
        <div card__content>
          <div class="row col-xl-8 mt-3">
            <div class="col-12">
              <label for="first_name" class="text-muted font-16">
                Nombres <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="first_name"
                name="first_name"
                type="text"
                class="input w-100"
                placeholder="Nombre de usuario"
                [(ngModel)]="userToCreate.first_name"
                [value]="userToCreate.first_name"
              />
            </div>

            <div class="col-12 mt-3">
              <label for="last_name" class="text-muted font-16">
                Apellidos <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="last_name"
                name="last_name"
                type="text"
                class="input w-100"
                placeholder="Apellido de usuario"
                [(ngModel)]="userToCreate.last_name"
                [value]="userToCreate.last_name"
              />
            </div>
          </div>

          <div class="row col-xl-8 mt-3">
            <div class="col-12">
              <label for="phone" class="text-muted font-16">
                Teléfono <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="phone"
                name="phone"
                type="tel"
                class="input w-100"
                placeholder="Teléfono de usuario"
                [(ngModel)]="userToCreate.phone"
                [value]="userToCreate.phone"
              />
            </div>

            <div class="col-12 mt-3">
              <label for="NIF" class="text-muted font-16">
                NIF <small>(opcional)</small>
              </label>
              <br />

              <input
                id="NIF"
                name="NIF"
                type="text"
                class="input w-100"
                placeholder="NIF de usuario"
                [(ngModel)]="userToCreate.tax_id"
                [value]="userToCreate.tax_id"
              />
            </div>
          </div>

          <div class="row col-xl-8 mt-3">
            <div class="col-12 col-md-6">
              <label for="password" class="text-muted font-16">
                Contraseña <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="password"
                name="password"
                type="password"
                class="input w-100"
                placeholder="Contraseña de usuario"
                [(ngModel)]="userToCreate.password"
                [value]="userToCreate.password"
              />
            </div>

            <div class="col-12 col-md-6 mt-3 md:mt-0">
              <label for="confirmPassword" class="text-muted font-16">
                Confirmar contraseña <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                class="input w-100"
                placeholder="Confirmación de contraseña"
                [(ngModel)]="confirmPassword"
                [value]="confirmPassword"
              />
            </div>

            <span
              class="text-start d-block font-14 text-error mt-2"
              *ngIf="userToCreate.password !== confirmPassword"
            >
              * Las contraseñas no coinciden
            </span>

            <span class="text-start d-block font-14 mt-2 font-bold">
              Las contraseñas deben tener
            </span>

            <span class="text-start d-block font-14 mt-1">
              <i
                class="
                    bi ng-star-inserted
                    {{
                  userToCreate.password.length >= 6 &&
                  confirmPassword.length >= 6
                    ? 'bi-check text-blue-light'
                    : 'bi-x text-error'
                }}
                  "
              ></i>

              Mínimo 6 caracteres
            </span>

            <span class="text-start d-block font-14 mt-1">
              Puedes colocar mayúsculas, minúsculas, espacios, números y
              caracteres especiales <br />
              como: ( . @ - _ ! # $ % ^ & * = } &#123; ] [ ; : ' " + ? / \ >
              &lt; , | )
            </span>
          </div>

          <div class="row col-xl-8 mt-3">
            <div class="col">
              <label for="emailToUpdate" class="text-muted font-16">
                Correo electrónico <span class="text-red">*</span>
              </label>
              <br />

              <input
                id="email"
                name="email"
                type="email"
                placeholder="Correo electrónico"
                class="input w-100"
                [(ngModel)]="userToCreate.email"
              />
            </div>
          </div>

          <div class="row col-xl-8 mt-3">
            <div class="col-12">
              <label for="description" class="text-muted font-16">
                Descripción <span class="text-muted">(opcional)</span>
              </label>
              <br />

              <textarea
                id="description"
                name="description"
                type="text"
                rows="6"
                class="w-100"
                placeholder="Descripción de usuario"
                [(ngModel)]="userToCreate.description"
                [value]="userToCreate.description"
              ></textarea>
            </div>
          </div>
        </div>
      </app-card>
      <section class="mt-4">
        <app-card
          borderRadius="10px"
          padding="26px"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        >
          <div card__content>
            <div class="row col-xl-8">
              <div class="col-12">
                <div card__content>
                  <div class="row mt-3">
                    <label for="userType" class="text-muted font-16"
                      >Tipo de usuario</label
                    >
                    <div class="col">
                      <select
                        [(ngModel)]="userToCreate.userType.value"
                        class="form-select"
                        name="userType"
                        id="userType"
                      >
                        <option selected [ngValue]="'Student'">
                          Estudiante
                        </option>
                        <option [ngValue]="'Teacher'">Profesor</option>
                        <option [ngValue]="'School'">Coordinador</option>
                        <option [ngValue]="'Back_Office_Admin'">
                          Administrador
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 mt-4">
                <div card__content>
                  <div class="row mt-3">
                    <label for="subscriptionSelected" class="text-muted font-16"
                      >Suscripción</label
                    >

                    <div class="col">
                      <select
                        [(ngModel)]="userToCreate.is_suscribed"
                        class="form-select"
                        name="subscriptionSelected"
                        id="subscriptionSelected"
                      >
                        <option [ngValue]="false">Plan Gratuito</option>
                        <option [ngValue]="true">Plan Premium</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </section>
    </div>
  </form>
</main>
