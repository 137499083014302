import { UiState } from './../../../store/ui/ui.state';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UpdateModal } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrls: ['./global-modal.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class GlobalModalComponent implements OnInit {
  @ViewChild('content') content!: ElementRef
  @Input() type: string = 'notification'
  @Input() message: string = 'Default message'
  @Select(UiState) showModal$!: Observable<any>

  constructor(config: NgbModalConfig, private modalService: NgbModal, private store: Store) {
    config.centered = true
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.showModal$.subscribe({
      next: (data) => {
        if (data.showModal) {
          this.open()
          this.message = data.message
        }
      },
      error: (e) => { console.debug(e) },
    })
  }

  open() {
    this.modalService.open(this.content);
  }

  // DEBUG
  dispatchModal() {
    this.store.dispatch(new UpdateModal(true))
  }

}
