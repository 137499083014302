import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ParametersService } from 'src/app/services/parameters.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { Category } from 'src/app/models/category.model';
import { generateID } from 'src/app/utils/fakers';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';

@Component({
  templateUrl: './new-parameter-page.component.html',
  styleUrls: ['./new-parameter-page.component.scss']
})
export class NewParameterPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  inProcess: boolean = false
  name!: string
  category: string | null = null
  categories: Array<Category> = [
    {
      id: generateID(),
      title: 'Category 1',
      slug: 'category-1'
    },
    {
      id: generateID(),
      title: 'Category 2',
      slug: 'category-2'
    },
  ]
  errors: Array<{ title: string }> = []

  constructor(
    private paramsService: ParametersService,
    private errorsService: ErrorsService,
    private title: Title
  ) {
    this.title.setTitle('Nuevo Parámetro | Backoffice - Growth Road')
  }

  ngOnInit(): void {
  }

  isValidForm() {
    let isValidForm = true
    const emptyName = !this.name

    if (emptyName) {
      this.errors.push({ title: 'El campo nombre es requerido.' })
      isValidForm = false
    }

    return isValidForm
  }

  save() {
    if (this.isValidForm()) {
      this.errors = []
      const newParam = {
        name: this.name,
        category: this.category
      }
      this.paramsService.addParam(newParam).subscribe({
        complete: () => {
          console.debug('Finished')
        },
        next: (data) => {
          console.debug('Success', data)
        },
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error)
          this.modal.open('Algo ha ocurrido', htmlErrorsContent)
        },
      })
    }

  }

}
