import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  concatMap,
  finalize,
  from,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-new-jazzdoo-page',
  templateUrl: './new-jazzdoo-page.component.html',
  styleUrls: ['./new-jazzdoo-page.component.scss'],
})
export class NewJazzdooPageComponent implements OnInit {
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  @ViewChild(ModalComponent)
  modal!: ModalComponent;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  public jazzdooId!: string;

  public selectedFirstTypes: any = [];
  public selectedSecondTypes: any = [];
  public positionTypes: Array<Record<string, string>> = [
    { label: 'Rebelde Creativo', value: 'CREATIVE_REBEL' },
    { label: 'Soñador Meditativo', value: 'DREAMER_MEDIATIVE' },
    { label: 'Tenaz Perseverancia', value: 'TENACIOUS_PERSEVERANCE' },
    { label: 'Metódico Perfeccionista', value: 'METHODICAL_PERFECTIONIST' },
    { label: 'Promotor Líder', value: 'PROMOTER_LEADER' },
    { label: 'Sociable Empático', value: 'SOCIABLE_EMPATH' },
  ];

  jazzdooRole: {
    name: string;
    top?: string;
    second_place?: string;
    description: string;
    skills: string;
    most_important_points: string;
    order?: number;
    tags?: string[];
    role_information?: string;
  } = {
    name: '',
    top: '',
    second_place: '',
    description: '',
    skills: '',
    role_information: '',
    most_important_points: '',
  };
  institutions: any = null;
  report_types: Array<any> = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public reportService: ReportService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModal: UniversalModalService,
    public activatedRoute: ActivatedRoute,
    private universalModalService: UniversalModalService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear rol Jazzdoo',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.jazzdooId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    if (this.jazzdooId) {
      this.reportService
        .getJazzdoo(this.jazzdooId)
        .subscribe((response: any) => {
          this.jazzdooRole = response;

          this.positionTypes.forEach((type) => {
            if (response['top'] === type['value']) {
              this.selectedFirstTypes.push(type);
            }
            if (response['second_place'] === type['value']) {
              this.selectedSecondTypes.push(type);
            }
          });

          this.creationalPagesLayoutService.layoutConfig.next({
            headerTitle: 'Editar rol Jazzdoo',
            headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
            footerConfig: {
              submitButtonText: 'Guardar',
              dismissButtonText: 'Cancelar',
              deleteButtonText: 'Eliminar',
            },
          });
        });
    }
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/instituciones/jazzdoos']);
        }
      });
    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public handleFirstSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    if (selectedOptions.length > 1) {
      this.selectedFirstTypes = [selectedOptions[selectedOptions.length - 1]];
    } else {
      this.selectedFirstTypes = selectedOptions;
    }
    this.jazzdooRole.top = this.selectedFirstTypes[0].value;
  }

  public handleSecondSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    if (selectedOptions.length > 1) {
      this.selectedSecondTypes = [selectedOptions[selectedOptions.length - 1]];
    } else {
      this.selectedSecondTypes = selectedOptions;
    }
    this.jazzdooRole.second_place = this.selectedSecondTypes[0].value;
  }

  isValidForm(report: any) {
    const emptyName = report.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del rol Jazzdoo es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  public handleDeleteJazzdooRole(): void {
    this.handleCloseModal();
    this.reportService.deleteJazzdoo(this.jazzdooId).subscribe({
      next: () => {
        this.router.navigate(['dashboard/instituciones/jazzdoos']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Rol Jazzdoo eliminado exitosamente'
        );
      },
      error: (error) => {
        this.inProcess = false;
        this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'Algo ha ocurrido' + error
        );
      },
    });
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  addToThree(redirect: boolean = false) {
    this.inProcess = true;
    if (this.isValidForm(this.jazzdooRole)) {
      if (this.jazzdooId) {
        this.reportService
          .updateJazzdoo(this.jazzdooId, {
            ...this.jazzdooRole,
          })
          .subscribe({
            next: (value: any) => {
              this.router.navigate(['/dashboard/instituciones/jazzdoos']);
              this.universalModal.openModal(
                undefined,
                null,
                'Rol Jazzdoo actualizado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      } else {
        this.reportService
          .createJazzdoo({
            ...this.jazzdooRole,
          })
          .subscribe({
            next: (value: any) => {
              this.router.navigate(['/dashboard/instituciones/jazzdoos']);
              this.universalModal.openModal(
                undefined,
                null,
                'Rol Jazzdoo creado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      }
    }
  }
}
