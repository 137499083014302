<!-- GENERAL DATA -->
<div class="row">
  <div class="col-12 mt-2 md:mt-0">
    <!--  -->
    <div class="d-flex flex-column">
      <label for="name" class="label mx-2 mb-1">Título (*)</label>
      <input
        class="input"
        [(ngModel)]="page.name"
        name="name"
        type="text"
        id="name"
        placeholder="Ingrese el nombre de la página"
      />
    </div>
  </div>
  <div class="col-12 mt-3">
    <!--  -->
    <div class="d-flex flex-column" *ngIf="type === 'chapter'">
      <label for="time" class="label mx-2 mb-1">Tiempo</label>
      <input
        class="input"
        name="time"
        [(ngModel)]="page.time"
        type="number"
        id="time"
        placeholder="Ingrese la duración de este capítulo"
      />
    </div>
  </div>
  <ng-container *ngIf="!withoutOrder">
    <div class="col mt-2 md:mt-0" *ngIf="editMode">
      <div class="d-flex flex-column">
        <label for="order" class="label mx-2 mb-1"
          >Orden
          <sup
            class="cursor-pointer"
            title="Define la posición de la página en la lista, '0' indica que la página va de ultima."
            >📄</sup
          >
        </label>
        <input
          class="input"
          [(ngModel)]="page.order"
          name="name"
          (focusout)="handleMinMax($event)"
          type="number"
          min="1"
          max="999"
          id="order"
          placeholder="Ingrese el nombre de la página"
        />
      </div>
    </div>
  </ng-container>
  <div class="col-12 mt-3">
    <div class="d-flex flex-column">
      <label for="description" class="label mx-2 mb-1">Descripción</label>
      <!-- <textarea name="description" id="description" [(ngModel)]="page.description"
        placeholder="Ingrese la descripción de la página" id="" cols="30" rows="10"></textarea> -->
      <angular-editor
        [config]="editorConfig"
        [(ngModel)]="page.description"
        name="description"
        id="description"
      >
      </angular-editor>
    </div>
  </div>
</div>

<!-- VIDEO -->
<div class="row mt-3">
  <div class="col-12">
    <div class="d-flex flex-column">
      <label for="video" class="label mx-2 mb-1">Video</label>
      <input
        class="input w-100"
        [(ngModel)]="page.video_url"
        type="text"
        name="video"
        id="video"
        placeholder="Enlace del video"
      />
    </div>
  </div>
</div>

<div class="row mt-4 mb-4">
  <div class="col-12 d-flex gap-4">
    <div
      class="preview-block"
      [ngStyle]="{
        background:
          'transparent linear-gradient(140deg, ' +
          page.background_color +
          ' 0%, ' +
          page.gradient_color +
          ' 100%) 0% 0%'
      }"
    >
      <p
        class="preview-font text-center fs-5 font-extrabold"
        [ngStyle]="{ color: page.font_color }"
      >
        Texto de ejemplo
      </p>
      <div
        class="background-image"
        *ngIf="previewMainImage"
        [ngStyle]="{
          backgroundImage:
            'url(' +
            previewMainImage.changingThisBreaksApplicationSecurity +
            ')'
        }"
      ></div>
      <div
        class="background-image"
        *ngIf="editMode && !previewMainImage"
        [ngStyle]="{ backgroundImage: 'url(' + page.image + ')' }"
      ></div>
    </div>

    <section class="col-8 d-flex flex-column justify-content-between">
      <div class="row justify-content-around w-64">
        <div class="mb-4">
          <div
            class="d-flex align-items-center"
            title="Selecciona un color de fondo para tu página"
          >
            <label for="primaryBgColor" class="text-grey label col-10"
              >Color de fondo 1</label
            >
            <input
              class="input cursor-pointer col-2"
              [(ngModel)]="page.background_color"
              value="#00CAA8"
              name="primaryBgColor"
              type="color"
              id="primaryBgColor"
              placeholder="Selecciona el color primario para el fondo"
            />
          </div>
        </div>
        <div class="mb-4">
          <div
            class="d-flex align-items-center"
            title="Selecciona un color de fondo para tu página"
          >
            <label for="secondaryBgColor" class="text-grey label col-10"
              >Color de fondo 2</label
            >
            <input
              class="input cursor-pointer col-2"
              [(ngModel)]="page.gradient_color"
              value="#078CC7"
              name="secondaryBgColor"
              type="color"
              id="secondaryBgColor"
              placeholder="Selecciona el color secundario para el fondo"
            />
          </div>
        </div>
        <div class="mb-4">
          <div
            class="d-flex align-items-center"
            title="Selecciona un color de fondo para tu página"
          >
            <label for="fontColor" class="label text-grey col-10"
              >Color de la fuente</label
            >
            <input
              class="input cursor-pointer col-2"
              [(ngModel)]="page.font_color"
              value="#ffffff"
              name="fontColor"
              type="color"
              id="fontColor"
              placeholder="Selecciona el color del texto a usar"
            />
          </div>
        </div>
      </div>

      <div class="d-flex flex-column justify-content-end">
        <label for="bgImage" class="label mx-2 mb-1 d-flex">
          <span class="me-3 d-block" *ngIf="type === 'subtheme'; else bgHelper"
            >Miniatura del subtema</span
          >
          <ng-template #bgHelper>
            <span class="d-block text-grey fs-5"> Imagen de fondo </span>
          </ng-template>
        </label>
        <input
          *ngIf="isMainImageFile"
          class="w-100 h-0 invisible"
          name="bgImage"
          type="file"
          id="bgImage"
          accept="image/*"
          placeholder="Seleccionar archivo"
          (change)="_mainImageChange($event)"
          #inputImage
        />
        <div class="d-flex align-items-center">
          <button
            (click)="handleInputImgClick()"
            class="px-2 py-1 border rounded-2 border-1 border-solid border-secondary"
          >
            Seleccionar archivo
          </button>

          <span class="ms-2"
            ><small class="text-grey">{{
              page.image ? page.image.name : "Ningún archivo seleccionado"
            }}</small></span
          >
        </div>
        <input
          class="input w-100"
          [(ngModel)]="page.image_url"
          *ngIf="!isMainImageFile"
          type="text"
          name="bgImage"
          id="bgImage"
          placeholder="Seleccionar archivo"
        />
      </div>
    </section>
  </div>
</div>

<div class="row mt-4" *ngIf="type === 'chapter'">
  <div class="col-auto">
    <div class="d-flex align-items-center">
      <label for="type" class="label mx-2 mb-1 fs-5 text-grey"
        >¿Es el final del modo prueba?</label
      >
      <div class="col-auto">
        <app-toggle-switch
          [active]="page.is_preliminar_limit"
          (click)="togglePreliminarLimit()"
        ></app-toggle-switch>
        <span
          class="d-inline-block mx-2 font-18 font-primary--b opacity-50"
          *ngIf="page.is_preliminar_limit"
          >Si</span
        >
        <span
          class="d-inline-block mx-2 font-18 font-primary--b opacity-50"
          *ngIf="!page.is_preliminar_limit"
          >No</span
        >
      </div>
    </div>
  </div>
</div>
