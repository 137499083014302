<main class="activities-page page">
  <ng-template #content>
    <!--Modal Confirm-->
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteCareer()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content class=>
      <div class="row row-no-gutters align-items-center justify-content-between w-100">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Carreras Universitarias</h1>
        </div>

        <div class="d-flex col-md-4 col-md-offset-4 justify-content-start md:justify-content-end align-items-center">
          <a type="button" routerLink="/dashboard/reportes/carreras-universitarias/nueva"
            class="btn bg-success text-white align-self-center">
            Nueva Carrera Universitaria
          </a>
        </div>
      </div>
    </div>
  </app-card>

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
      </div>
    </app-card>
  </div>


  <div class="mt-3" *ngIf="!isLoading && !careers.length">
    <app-card borderRadius="10px" padding="80px 120px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content class="text-center">
        <span class="font-24 opacity-50">
          No has creado ninguna carrera aún,
          <span class="text-green cursor-pointer" routerLink="/dashboard/reportes/carreras-universitarias/nueva">
            crea
          </span>
          uno.
        </span>
      </div>
    </app-card>
  </div>

  <ul class="careers__list" *ngIf="!isLoading && careers.length">
    <li class="careers__list--item" *ngFor="let career of careers">
      <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px">
        <article card__content class="row">
          <div class="col">
            <p class="font-18 font-primary--b">
              {{career.title}}
              <span *ngIf="career.acronym" class="small opacity-50">
                {{career.acronym}}
              </span>
            </p>
          </div>

          <div class="col-auto text-end">
            <div class="d-flex">
              <a class="btn bg-blue-light text-white"
                routerLink="/dashboard/reportes/carreras-universitarias/{{career.id}}">
                Ver
              </a>

              <button class="btn bg-error text-white ms-2" (click)="confirmDeleteCareer(career.id!)">
                Eliminar
              </button>
            </div>
          </div>
        </article>
      </app-card>
    </li>
  </ul>
</main>
