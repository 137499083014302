<main class="page page-parameters">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col">
            <h1 class="font-24 font-primary--eb">Parametros</h1>
          </div>
        </div>
      </article>
    </app-card>
    <!-- List -->
    <div class="list mt-3">
      <ul>
        <li *ngFor="let parameter of parameters; index as i" class="mb-3">
          <app-card borderRadius="10px" height="100%" padding="20px 60px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px">
            <article class="cursor-pointer" card__content>
              <div class="row">
                <div class="col d-flex gap-3">
                  <span>
                    {{i+1}}.
                  </span>

                  <h2 class="font-18 font-primary--b">
                    {{parameter.parameter_name}}
                  </h2>

                  <span class="d-block text-grey opacity-50">
                    &lt;{{parameter.id}}&gt;
                  </span>
                </div>

                <div class="col-auto d-flex gap-3">
                  <button type="button" class="btn bg-blue-light text-white"
                    routerLink="/dashboard/parametros/{{parameter.id}}">
                    Ver
                  </button>

                  <button type="button" class="btn bg-orange-alt text-white"
                    routerLink="/dashboard/parametros/{{parameter.id}}/editar">
                    Editar
                  </button>
                </div>
              </div>
            </article>
          </app-card>
        </li>
      </ul>
    </div>
  </section>
</main>