import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  concatMap,
  finalize,
  from,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-edit-report-group-page',
  templateUrl: './edit-report-group-page.component.html',
  styleUrls: ['./edit-report-group-page.component.scss'],
})
export class EditReportGroupPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  three: any = [];
  errors: Array<{ title: string }> = [];
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  inProcess: boolean = false;
  private reportGroupId!: string;

  reportGroup: any = {
    internal_name: '',
    name: '',
    is_published: false,
    has_report: false,
    order: 1,
    organization: null,
    report_types: null,
    description: null,
    internal_description: null,
    tags: [],
    shared_with: [],
  };
  institutions: any = null;
  report_types: Array<any> = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private pageService: PageService,
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModalService: UniversalModalService,
    private reportService: ReportService,
    private route: ActivatedRoute
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Editar informe',
      headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
        deleteButtonText: 'Eliminar',
      },
    });
  }

  ngOnInit(): void {
    this.institutionsService
      .getInstitutions()
      .pipe(
        tap((response) => {
          this.organizations = response.map((org: any) => ({
            label: org.name,
            value: org.id,
          }));
        }),
        switchMap(() => this.route.params),
        concatMap((objectParams: Record<string, string>) => {
          console.log(objectParams);
          return this.reportService.getReportGroupsDetails(
            objectParams['reportGroupId']
          );
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.reportGroup = response;
        const orgsSelected = new Map();

        response.organizations.forEach((org: any) => {
          orgsSelected.set(org.id, true);
        });

        this.organizations.forEach((org) => {
          if (orgsSelected.get(org.value)) {
            this.selectedOrgs.push(org);
          }
        });
      });
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree();
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/informes']);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public handleDeleteReportGroup() {
    this.handleCloseModal();
    this.reportService.deleteReportGroup(this.reportGroupId).subscribe({
      next: () => {
        this.router.navigate(['dashboard/informes']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Informe eliminado exitosamente'
        );
      },
      error: (error) => {
        this.inProcess = false;
        this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'Algo ha ocurrido' + error
        );
      },
    });
  }

  public handleSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ): void {
    this.selectedOrgs = selectedOptions;
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  isValidForm(reportGroup: any) {
    const emptyName = !reportGroup.internal_name;
    this.errors = [];
    console.log(reportGroup);

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del informe es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.reportGroup = {
      internal_name: '',
      name: '',
      is_published: false,
      has_report: false,
      order: 1,
      description: null,
      internal_description: null,
      tags: [],
      shared_with: [],
    };
  }

  addToThree() {
    if (this.isValidForm(this.reportGroup)) {
      this.inProcess = true;
      this.reportService
        .updateReportGroup(this.reportGroup.id, {
          ...this.reportGroup,
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.reportService.addReportToOrg(
                this.selectedOrgs.map((org: any) => org.value),
                response.id
              );
            } else if (
              this.selectedOrgs.length === 0 &&
              Array.isArray(this.reportGroup.organizations) &&
              this.reportGroup.organizations.length > 0
            ) {
              return from(this.reportGroup.organizations).pipe(
                concatMap((org: any) =>
                  this.reportService.deleteReportGroupFromOrg(
                    org.id,
                    response.id
                  )
                ),
                finalize(() => {
                  this.router.navigate(['/dashboard/informes']);
                  this.inProcess = false;
                })
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            this.resetForm();
            this.router.navigate(['/dashboard/informes']);
            this.inProcess = false;
          },
          error: ({ error }) => {
            this.inProcess = false;
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);
            this.universalModalService.openModal(
              undefined,
              null,
              'Algo ha ocurrido' + htmlErrorsContent
            );
          },
        });
    }
  }
}
