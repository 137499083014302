import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CAREER_TYPE_LABEL, CAREER_TYPE, ICareerToCreate, ICareerUniversity, ICareerToUpdate } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { CATEGORY_DIC, formatParameter, normalizeCategories } from 'src/app/utils/options';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-edit-career-university-page',
  templateUrl: './edit-career-university-page.component.html',
  styleUrls: ['./edit-career-university-page.component.scss']
})
export class EditCareerUniversityPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  careerToEdit!: ICareerToCreate
  careerEditForm!: FormGroup
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  CAREER_TYPE = CAREER_TYPE
  formErrors: any = {}
  isLoading: boolean = true
  icon: { preview: any, file: any } = {
    preview: null,
    file: null,
  }
  parametersOfCareer: { id: string; label: any; parameters: any[] }[] = []
  min: number = 0
  max: number = 3

  outputProfile: string = ''
  outputTitle: string = ''
  outputOrder: number = 1
  outputLeftAligned: boolean = false

  acronym: string = ''
  description: string = ''
  descriptionOrder: number = 1
  descriptionLeftAligned: boolean = false

  job: string = ''
  jobTitle: string = ''
  jobOrder: number = 1
  jobLeftAligned: boolean = false


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la frase.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ]
  };

  constructor(
    private careerService: CareersService,
    private errorsService: ErrorsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Editar Carrera Universitaria | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.getCareerById()
  }

  // initFrom(): FormGroup {
  //   return this.fb.group({
  //     title: [this.career.title || '', [Validators.required]],
  //     type: [this.career.type || '', [Validators.required]],
  //     primary_color: [this.career.primary_color || '', [Validators.required]],
  //     secundary_color: [this.career.secundary_color || '', [Validators.required]],
  //   })
  // }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.icon.file = file;
      this.careerToEdit.icon = file

      const reader = new FileReader()
      reader.onload = () => { this.icon.preview = reader.result }
      reader.readAsDataURL(file)
    }
  }

  async onSubmit() {
    // this.formErrors = null
    // this.isLoading = true

    // const { errors, totalErrors } = handleFormErrors(this.careerEditForm)

    // if (totalErrors) {
    //   console.error('Edit Career Form Error', errors)
    //   this.formErrors = errors
    //   this.isLoading = false
    //   return
    // }

    // if (!this.description || !this.job || !this.outputProfile) {
    //   this.modal.open('Hubo un Error', 'Faltan campos requeridos')
    //   this.isLoading = false
    //   return
    // }

    if (typeof this.careerToEdit.icon === 'string' || this.careerToEdit.icon instanceof String) {
      delete this.careerToEdit.icon
    }

    this.careerService.updateCareer(this.careerToEdit).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
        this.isLoading = false
      },
      next: (data: ICareerUniversity) => {
        this.isLoading = false
        this.career = data
      },
      complete: () => {

        this.parametersOfCareer.forEach((category: any) => {
          const parameters: any = {}

          category.parameters.forEach((parameter: any) => parameters[parameter.id] = parameter.value)

          this.careerService.updateParameterById(this.career.id, CATEGORY_DIC[category.id].id, parameters).subscribe({
            error: ({ error }) => {
              const { htmlErrorsContent } = this.errorsService.handleErrors(error)
              this.modal.open('Algo ha ocurrido', htmlErrorsContent)
            },
            next: (data: any) => console.log('updateParameterById Data', data),
            complete: () => {
              this.router.navigate([`/dashboard/reportes/carreras-universitarias/${this.career.id}`])
            }
          })
        })

        this.isLoading = false
      }
    })
  }

  getIndex(index: number, obj: any) {
    return index
  }

  getCareerById() {
    const careerId = Number(this.route.snapshot.paramMap.get('id'))

    this.careerService.getCareerById(careerId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: any) => {
        this.career = data
        this.icon.preview = data.icon

        this.careerToEdit = {
          id: data.id,
          type: data.type,
          icon: data.icon,
          primary_color: data.primary_color,
          secundary_color: data.secundary_color,

          title: data.title,
          acronym: data.acronym,
          description: data.description,
          description_order: data.description_order,
          description_left_aligned: data.description_left_aligned,

          job_title: data.job_title,
          job: data.job,
          job_order: data.job_order,
          job_left_aligned: data.job_left_aligned,

          output_profile: data.output_profile,
          output_title: data.output_title,
          output_order: data.output_order,
          output_left_aligned: data.output_left_aligned,
        }

        const categoriesFormated = normalizeCategories(data)
        const parametersFormated = categoriesFormated.flatMap(category => ({
          ...category,
          parameters: formatParameter(category.parameters)
        }))

        this.parametersOfCareer = parametersFormated
      },
      complete: () => {
        // this.careerEditForm = this.initFrom()
        this.isLoading = false
        console.log('data cargada')
      }
    })
  }
}
