<section class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="activity.internal_name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre de la actividad"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <app-page-form #newPageForm [three]="three"></app-page-form>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
