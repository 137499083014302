import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageService } from 'src/app/services/page.service';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { ReportService } from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  selector: 'app-report-group-list-page',
  templateUrl: './report-group-list-page.component.html',
  styleUrls: ['./report-group-list-page.component.scss'],
})
export class ReportGroupListPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  reportGroups!: Observable<any>;
  inProcess: boolean = true;
  public reportGroupId!: string;
  published: boolean = false;
  orderOptions: string[] = ['Alfabeticamente', 'Mas reciente', 'Mas antiguo'];
  private reportsData$: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit_new.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private store: Store,
    public searchBarListService: SearchBarListService,
    private breadcrumbService: BreadcrumbService,
    private reportService: ReportService,
    private universalModal: UniversalModalService
  ) {
    this.title.setTitle('Informes | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Informes',
      headerTitleIcon: '/assets/images/icons/icon_program.svg',
      headerButtonText: 'Crear informe',
      headerButtonIcon: '/assets/images/icons/icon_create.svg',
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.resetRoutes();
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.goToCreation();
        }
      });

    this.getReportGroups();
  }

  ngAfterViewInit(): void {
    this.reportGroups = combineLatest([
      this.reportsData$,
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Mas reciente')),
    ]).pipe(
      tap(() => (this.inProcess = true)),
      map(([reportGroups, inputSearchResult, selectedOption]) => {
        return [
          this.handleApplyFilter(reportGroups, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([reportGroups, inputSearchResult]) => {
        return (reportGroups as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['id', 'name'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.reportGroups.subscribe({
      next: () => (this.inProcess = false),
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.inProcess = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getReportGroups() {
    this.inProcess = true;
    this.reportService
      .getReportGroups()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.inProcess = false;
        this.reportsData$.next(
          data.map((element: any) => ({
            ...element,
            name: element.internal_name ?? '',
            description: element.internal_description ?? '',
          }))
        );
      });
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  private confirmDelete() {
    this.universalModal.openModal(this.modalDeleteTemplateRef);
  }

  public confirmDuplicate(id: number): void {
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.duplicateProgram(id);
    }
  }

  public duplicateProgram(id: number): void {
    this.pageService.duplicateProgramById(id).subscribe((data) => {
      this.getReportGroups();
    });
  }

  public deleteReportGroup() {
    this.universalModal.closeModal();
    this.reportService.deleteReportGroup(this.reportGroupId).subscribe({
      complete: () => {
        this.getReportGroups();
        this.universalModal.openModal(
          undefined,
          null,
          'Informe eliminado exitosamente'
        );
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.universalModal.openModal(
          undefined,
          null,
          'No se pudo eliminar el informe, elimine los reportes asociados.'
        );
      },
    });
  }

  public handleListElementClick(event: any) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Informe A Example',
      headerTitleIcon: '/assets/images/icons/icon_content.svg',
      headerButtonIcon: 'assets/images/icons/icon_create.svg',
      headerButtonText: 'Añadir reporte',
    });
    this.router.navigate([`/dashboard/informes/${event.id}/reportes`]);
  }

  public handleListElementClickAction(actionName: string, entity: any): void {
    this.reportGroupId = entity.id;
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(entity),
      delete: () => this.confirmDelete(),
      duplicate: () => this.confirmDuplicate(entity.id),
    };

    actions[actionName]();
  }

  public handleListElEdit(entity: any) {
    this.router.navigate([`/dashboard/informes/${entity.id}/editar`]);
  }

  public handleCloseModal() {
    this.universalModal.closeModal();
  }

  public goToCreation() {
    this.router.navigate(['dashboard/informes/nuevo']);
  }
}
