<main class="activities-page page">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">Inicio (En desarrollo)</h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white" routerLink="/dashboard/preguntas/nueva" type="button">Nueva
              Pregunta</button>
          </div>
        </div>
      </article>
    </app-card>
  </section>
</main>
