<main class="activities-page page">

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content>
      <div class="row align-items-center justify-content-between">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Categorías de Parametros</h1>
        </div>
      </div>
    </article>
  </app-card>

  <div class="category-wrapper">
    <div class="list">
      <ul class="items">
        <li class="item" *ngFor="let category of categories">
          <app-card padding="20px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100%" borderRadius="10px">
            <article card__content class="row">

              <div class="col-auto">
                <p class="font-18 font-primary--b">{{category.label}}</p>
              </div>

              <div class="col-12 md:col justify-content-start md:justify-content-end d-flex">
                <button type="button" class="btn bg-blue-light text-white me-2 w-100 md:w-fit"
                  routerLink="/dashboard/categorias/{{category.id}}">
                  Ver
                </button>
              </div>
            </article>
          </app-card>
        </li>
      </ul>
    </div>
  </div>
</main>
