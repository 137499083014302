import { Store } from '@ngxs/store';
import { PageFormComponent } from '../../core/page-form/page-form.component';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ViewChildren,
} from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { Router } from '@angular/router';
import { Page } from 'src/app/models/page.models';
import { SafeUrl, Title } from '@angular/platform-browser';
import { ModalComponent } from '../../core/modal/modal.component';
import { ErrorsService } from 'src/app/services/errors.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import {
  concatMap,
  debounceTime,
  finalize,
  from,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { InstitutionsService } from 'src/app/service/institutions.service';

@Component({
  templateUrl: './new-content-page.component.html',
  styleUrls: ['./new-content-page.component.scss'],
})
export class NewContentPageComponent implements OnInit, AfterViewInit {
  @ViewChild('newPageForm') newPageForm!: PageFormComponent;
  @ViewChild(ModalComponent) modal!: ModalComponent;
  public selectedOrgs: any = [];
  public content: any = {
    questionary: null,
    id: '',
    name: '',
    internal_name: '',
    description: '',
    internal_description: '',
    background_color: '#00CAA8',
    gradient_color: '#078CC7',
    font_color: '#ffffff',
    video_url: null,
    order: 0,
    image: null,
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    is_preliminar_limit: false,
    time: 0,
  };

  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: any = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private store: Store,
    public router: Router,
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private institutionsService: InstitutionsService
  ) {
    this.title.setTitle('Nuevo Contenido | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear contenido',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.getInstitutions();
  }

  ngAfterViewInit(): void {
    console.debug('{newPageForm}<PageFormComponent> ', this.newPageForm);
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/pages/contenidos']);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  /**
   * Fields form validations
   * @returns
   */
  isValidForm(page: Page): boolean {
    const emptyName = page.name === '';
    const emptyType = page.type === null;
    const emptyBgImage = this.newPageForm.page.image === null;
    const emptyVideo = this.newPageForm.page.video_url === null;

    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre de la página es requerido.' });
      isValidForm = false;
    }

    if (emptyType) {
      this.errors.push({ title: 'El tipo de página es requerido.' });
      isValidForm = false;
    }

    if (!emptyBgImage) {
      if (
        !this.newPageForm.isMainImageFile &&
        !this.isImgLink(this.newPageForm.page.image!)
      ) {
        this.errors.push({
          title: 'La url de la imagen de fondo no es valida.',
        });
        isValidForm = false;
      }
    }

    if (!emptyVideo) {
      if (!this.isVideoLink(this.newPageForm.page.video_url!)) {
        this.errors.push({ title: 'La url del video no es valida.' });
        isValidForm = false;
      }
    }

    return isValidForm;
  }

  public handleSelectionChange(selectedOptions: Array<Record<string, string>>) {
    console.log(selectedOptions);
    this.selectedOrgs = selectedOptions.map((option: any) => option.value);
  }

  getInstitutions() {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  addToThree(redirect: boolean = false) {
    if (this.isValidForm(this.newPageForm.page)) {
      this.inProcess = true;
      let toastMessage = '';
      this.newPageForm.page.questionary = String(
        this.newPageForm.questionaryId
      );

      this.newPageForm.page.parent = null;
      toastMessage = `Capitulo '${this.content.internal_name}' creado.`;
      this.pageService
        .newPage({
          ...this.content,
          ...this.newPageForm.page,
          organization: this.selectedOrgs.map((org: any) => org.value),
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.pageService.addPageToOrg(
                this.selectedOrgs,
                response.id as number
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            this.store.dispatch(new OpenModal(toastMessage));
            if (redirect) {
              this.router.navigate(['/dashboard/pages/contenidos']);
            }
            this.inProcess = false;
          },
          error: ({ error }) => {
            alert(
              'Algo ha ocurrido, asegurese de que el contenido no está publicado.'
            );
            // const { htmlErrorsContent } = this.errorsService.handleErrors(error)
            // this.modal.open('Algo ha ocurrido', htmlErrorsContent)
            this.inProcess = false;
          },
        });
    }
  }

  isImgLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return (
      url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gim) !=
      null
    );
  }

  isVideoLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return url.match(/^http[^\?]*.(mp4|flv|mkv|avi|m3u8)(\?(.*))?$/gim) != null;
  }
}
