import { Pipe, PipeTransform } from '@angular/core';
import { IUserDetail } from '../models/user.models';
import { MetaDataService } from '../services/metadata.service';

@Pipe({
  name: 'studentType',
})
export class StudentTypePipe implements PipeTransform {
  constructor(private metaDataService: MetaDataService) {}

  transform(value: IUserDetail['user_type'], ...args: unknown[]): unknown {
    const userType = this.metaDataService.GET_USER_TYPES_DIC()[value];

    if (userType) {
      return userType.label;
    }

    return null;
  }
}
