import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ICareerToCreate, ICareerToUpdate } from '../models/career.model';
import { convertToFormData } from '../utils/globals';

@Injectable({ providedIn: 'root' })

export class CareersService {
  API_URL: string = environment.apiUrl

  constructor(private httpClient: HttpClient) { }

  getCareers(): Observable<any> {
    const URL = `${this.API_URL}/reports/studies/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  getCareerById(id: number): Observable<any> {
    const URL = `${this.API_URL}/reports/studies/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  createCareer(newCareer: ICareerToCreate): Observable<any> {
    const formData = convertToFormData(newCareer)

    const URL = `${this.API_URL}/reports/studies/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.post(URL, formData).pipe(catchError(handleErrors))
  }

  updateCareer(careerToUpate: any): Observable<any> {
    const formData = convertToFormData(careerToUpate)

    console.log('formData', formData)

    const URL = `${this.API_URL}/reports/studies/${careerToUpate.id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.put(URL, formData).pipe(catchError(handleErrors))
  }

  deleteCareer(id: number): Observable<any> {
    const URL = `${this.API_URL}/reports/studies/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.delete(URL).pipe(catchError(handleErrors))
  }

  updateParameterById(
    parameterId: number,
    categoryId: ('professional_fields' | 'multiple_intelligences' | 'vocational_interests'),
    parameterBody: { [key: string]: any }
  ) {
    const url = `${this.API_URL}/reports/studies/${categoryId}/${parameterId}/`

    return this.httpClient.put(url, parameterBody).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }
}
