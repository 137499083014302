<main class="activities-page page">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb text-orange-alt">
        Editar Parametro: <span>{{parameter.parameter_name}}</span>
      </h1>
    </article>
  </app-card>

  <app-card width="100%" borderRadius="10px" padding="20px 40px" class="parameter-wrapper"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <form (ngSubmit)="onSubmit()" [formGroup]="parameterForm" card__content class="w-100">
      <div class="mt-3">
        <label for="description" class="text-start w-100 font-bold">Descripción</label>

        <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['description']">
          {{formErrors?.['description']?.label}}
        </span>

        <textarea
          id="description"
          name="description"
          type="text"
          class="w-100 mt-2"
          formControlName="description"
          placeholder="Ingrese descripción del parámetro"
          [value]="parameter.description || ''"
          rows="10"
        ></textarea>
      </div>

      <button class="btn font-btn bg-red w-100 font-26 mt-3">
        <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>

        <span class="text-white" *ngIf="!isLoading">
          Editar Descripción
        </span>
      </button>
    </form>
  </app-card>
</main>