import {
  AddActivity,
  RemoveActivity,
  ResetActivityCreated,
  SetActivity,
  SetActivityCreated
} from './activity.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { Activities, Activity } from "src/app/models/activities/activity.models";

const STATE_TOKEN = new StateToken<Activities>(
  'Activities'
);

// STATE
@State<Activities>({
  name: STATE_TOKEN,
  defaults: {
    activities: [],
    activityCreated: null
  }
})

@Injectable()
export class ActivityState {

  @Action(AddActivity)
  addingActivity(ctx: StateContext<Activities>) {
    console.debug(ctx)
  }

  @Action(RemoveActivity)
  removingActivity(ctx: StateContext<Activities>, action: RemoveActivity) {
    console.debug(ctx)
    console.debug(action.activityId)
  }

  @Action(SetActivityCreated)
  setActivityCreated(ctx: StateContext<any>, { activity }: SetActivityCreated) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      activityCreated: activity
    });
  }
  @Action(ResetActivityCreated)
  resetActivityCreated(ctx: StateContext<any>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      activityCreated: null
    });
  }

  @Selector()
  static activities(state: Activities) {
    return state.activities
  }
  @Selector()
  static activityCreated(state: Activities) {
    return state.activityCreated
  }
}

