import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { OpenModal, UpdateModal } from './ui.actions';
import { UiStateModel } from './ui.models';



const STATE_TOKEN = new StateToken<UiStateModel>(
  'Ui'
);

@State<UiStateModel>({
  name: STATE_TOKEN,
  defaults: {
    showModal: false,
    message: 'Default message'
  }
})

@Injectable()
export class UiState {
  @Action(UpdateModal)
  toggleModal(ctx: StateContext<UiStateModel>, { payload }: UpdateModal) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showModal: payload
    });
  }
  @Action(OpenModal)
  openModal(ctx: StateContext<UiStateModel>, { payload }: OpenModal) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      showModal: true,
      message: payload,
    });
  }

  @Selector()
  static showModal(state: UiStateModel) {
    return state.showModal
  }
}
