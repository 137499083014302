<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">
              {{parameter.parameter_name}} <span class="text-grey opacity-50">&lt;{{parameterId}}&gt;</span>
            </h1>
          </div>

          <div class="col-auto off">
            <button type="button" class="btn bg-orange-alt text-white"
              routerLink="/dashboard/parametros/{{parameterId}}/editar">
              Editar Parámetro
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <div class="parameter-wrapper">
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section>
          <strong>Nombre:</strong>
          <h5 class="mb-3 mt-2">{{parameter.parameter_name}}</h5>
        </section>
      </article>
    </app-card>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%">
      <article card__content class="w-100">
        <section>
          <p class="font-primary--b mb-3">Categoría:</p>
          <button class="btn bg-blue mt-3">
            <span class="text-white {{!parameter.parameter_category_name && 'text-red'}}">
              {{parameter.parameter_category_name || 'Sin Categoria' }}
            </span>
          </button>
        </section>
      </article>
    </app-card>

    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px" width="100%"
      style="grid-column: 1 / 3;">
      <article card__content class="w-100">
        <section>
          <strong>Descripción:</strong>
          <p>
            {{parameter.description || 'Sin Descripción'}}
          </p>
        </section>
      </article>
    </app-card>
  </div>
</main>
