import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { Content } from 'src/app/models/content.model';
import { Page } from 'src/app/models/page.models';

@Component({
  selector: 'app-content-themes-page',
  templateUrl: './content-themes-page.component.html',
  styleUrls: ['./content-themes-page.component.scss'],
})
export class ContentThemesPageComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmationModalTemplate', { read: TemplateRef })
  deleteConfirmationModalTemplate!: TemplateRef<any>;

  @ViewChild('deleteErrorModalTemplate', { read: TemplateRef })
  deleteErrorModalTemplate!: TemplateRef<any>;

  @ViewChild('deleteSuccessModalTemplate', { read: TemplateRef })
  deleteSuccessModalTemplate!: TemplateRef<any>;

  selectedThemeId!: string;
  contentId!: string;
  contentDetails!: Content;
  contentThemes: Content[] = [];
  isLoading = false;

  private destroyed$ = new ReplaySubject<void>(1);

  public listElementActions = [
    { action: 'edit', icon: '/assets/images/icons/icon_edit_new.svg' },
    { action: 'delete', icon: '/assets/images/icons/icon_delete_new.svg' },
  ];

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private breadcrumbService: BreadcrumbService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Temas del contenido | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.goToCreation();
      });

    this.contentId = this.route.snapshot.paramMap.get('contentId')!;
    this.getContentDetails(this.contentId);
    this.getThemesByContentId(this.contentId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  navigateToBack(): void {
    this.location.back();
  }

  private getContentDetails(id: string): void {
    this.pageService.getPageById(id).subscribe((response: unknown) => {
      this.contentDetails = response as Content;
      this.creationalPagesLayoutService.layoutConfig.next({
        headerTitle: this.contentDetails.internal_name,
        headerTitleIcon: '/assets/images/icons/icon_content.svg',
        headerButtonIcon: 'assets/images/icons/icon_create.svg',
        headerButtonText: 'Crear tema',
      });

      this.breadcrumbService.pushToUrl({
        id: this.contentDetails.id,
        name: this.contentDetails.internal_name,
      });
    });
  }

  public goToCreation(): void {
    this.router.navigate([
      `dashboard/pages/contenidos/${this.contentId}/temas/nuevo`,
    ]);
  }

  private getThemesByContentId(
    contentId: string,
    disableLoading = false
  ): void {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.pageService.getContentThemes(contentId).subscribe({
      next: (data: unknown) => {
        this.contentThemes = (data as Content[]).map((element) => ({
          ...element,
          name: element.internal_name ?? '',
          description: element.internal_description,
        }));
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  openDeleteConfirmationModal(themeId: string): void {
    this.selectedThemeId = themeId;
    this.universalModalService.openModal(this.deleteConfirmationModalTemplate);
  }

  deleteTheme(): void {
    this.universalModalService.closeModal();
    this.isLoading = true;
    this.pageService.deletePage(this.selectedThemeId).subscribe({
      next: () => {
        this.universalModalService.openModal(this.deleteSuccessModalTemplate);
        this.getThemesByContentId(this.contentId);
      },
      error: (errorResponse) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(
          errorResponse.error
        );
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  closeModal(): void {
    this.universalModalService.closeModal();
  }

  public handleListElementClick(theme: Content): void {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: theme.name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([
      `/dashboard/pages/contenidos/${this.contentId}/temas/${theme.id}/subtemas`,
    ]);
  }

  public handleListElementAction(actionName: string, theme: Content): void {
    const actions: { [key: string]: () => void } = {
      edit: () => this.editTheme(theme),
      delete: () => this.openDeleteConfirmationModal(theme.id.toString()),
    };

    if (actions[actionName]) {
      actions[actionName]();
    } else {
      console.warn(`Action "${actionName}" is not defined.`);
    }
  }

  private editTheme(theme: Content): void {
    this.router.navigate([
      `/dashboard/pages/contenidos/${this.contentId}/temas/${theme.id}/editar`,
    ]);
  }

  updateOrder(): void {
    this.contentThemes.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  public drop(event: CdkDragDrop<Content[]>): void {
    moveItemInArray(
      this.contentThemes,
      event.previousIndex,
      event.currentIndex
    );

    if (event.previousIndex !== event.currentIndex) {
      const updatedTheme = {
        ...this.contentThemes[event.currentIndex],
        order: event.currentIndex + 1,
      } as Page;
      this.pageService.updatePage(updatedTheme).subscribe(() => {
        this.getThemesByContentId(this.contentId, true);
      });
    }
  }
}
