<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <ng-template #content>
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Ok</span>
      </button>
    </div>
  </ng-template>

  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        Nueva Carrera Universitaria
      </h1>
    </article>
  </app-card>

  <form (ngSubmit)="onSubmit()" [formGroup]="createCareerForm" class="career-wrapper">
    <!-- CARRERA -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="font-24 font-primary--eb">Información de carrera</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                    {{formErrors?.['title']?.label}}
                  </span> -->

                  <input [(ngModel)]="descriptionOrder" [ngModelOptions]="{standalone: true}" id="descriptionOrder"
                    name="descriptionOrder" type="text" class="input w-100" />
                </div>

                <div>
                  <label for="descriptionLeftAligned" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="descriptionLeftAligned" id="descriptionLeftAligned" class="input w-100"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="descriptionLeftAligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Icon -->
            <!-- <div class="mt-3">
              <label for="icon" class="text-start w-100 font-bold">Icono</label>

              <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['icon']">
                {{formErrors?.['icon']?.label}}
              </span>

              <input id="icon" name="icon" type="file" class="icon-input w-100"
                placeholder="Ingrese icono de la carrera" (change)="onFileChanged($event)" />

              <div class="career__icon--wrapper mt-3">
                <img [src]="icon.preview" width="200" height="200" class="career-icon" />
              </div>
            </div> -->
            <!-- COLORS -->
            <div class="row mt-3 justify-content-end">
              <div class="col-auto">
                <div class="row">
                  <div class="col-auto">
                    <label for="primary_color" class="text-start w-100 font-bold">Color Primario</label>

                    <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['primary_color']">
                      {{formErrors?.['primary_color']?.label}}
                    </span>

                    <input type="color" id="primary_color" name="primary_color" formControlName="primary_color" />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Acrónimo</label>

                    <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                      {{formErrors?.['title']?.label}}
                    </span> -->

                    <input id="acronym" name="acronym" type="text" [(ngModel)]="acronym"
                      [ngModelOptions]="{standalone: true}" class="input w-100" placeholder="Ejemplo: FP" />
                  </div>
                  <!-- Title -->
                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Titulo</label>

                    <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                      {{formErrors?.['title']?.label}}
                    </span>

                    <input id="title" name="title" type="text" class="input w-100"
                      placeholder="Ingrese titulo de la carrera universitaria" formControlName="title" />
                  </div>

                  <!-- Type -->
                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Tipo </label>

                    <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['type']">
                      {{formErrors?.['type']?.label}}
                    </span>

                    <select id="type" name="type" class="w-100" formControlName="type">
                      <option value="" selected disabled hidden>Elije un tipo</option>

                      <option [value]="CAREER_TYPE['TRAINING_CIRCLE']">
                        {{CAREER_TYPE_LABEL[CAREER_TYPE['TRAINING_CIRCLE']]}}
                      </option>

                      <option [value]="CAREER_TYPE['UNIVERSITY_DEGREE']">
                        {{CAREER_TYPE_LABEL[CAREER_TYPE['UNIVERSITY_DEGREE']]}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label for="description" class="text-start w-100 font-bold">Descripción</label>

                <angular-editor [ngModelOptions]="{standalone: true}" [config]="editorConfig" [(ngModel)]="description"
                  name="description" id="description" class="mt-2"></angular-editor>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>

    <!-- OUTPUT PROFILE -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100 ">
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="font-24 font-primary--eb">Información del perfil de salida</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                                                {{formErrors?.['title']?.label}}
                                              </span> -->

                  <input [(ngModel)]="outputOrder" [ngModelOptions]="{standalone: true}" id="outputOrder"
                    name="outputOrder" type="text" class="input w-100" />
                </div>

                <div>
                  <label for="outputLeftAligned" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="outputLeftAligned" id="outputLeftAligned" class="input w-100"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="outputLeftAligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="outputProfile" class=" font-bold">Titulo</label>

                  <angular-editor [(ngModel)]="outputTitle" [ngModelOptions]="{standalone: true}"
                    [config]="editorConfig" name="outputProfile" id="outputProfile" class="mt-2"></angular-editor>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="outputProfile" class=" font-bold">Descripción</label>

                  <angular-editor [ngModelOptions]="{standalone: true}" [config]="editorConfig"
                    [(ngModel)]="outputProfile" name="outputProfile" id="outputProfile2" class="mt-2"></angular-editor>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>

    <!-- JOB -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="font-24 font-primary--eb">Información del empleo</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                                            {{formErrors?.['title']?.label}}
                                          </span> -->

                  <input [(ngModel)]="jobOrder" [ngModelOptions]="{standalone: true}" id="jobOrder" name="jobOrder"
                    type="text" class="input w-100" />
                </div>

                <div>
                  <label for="title" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="jobLeftAligned" id="jobLeftAligned" class="input w-100"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="jobLeftAligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="job" class="text-start w-100 font-bold">Titulo</label>

                <angular-editor [ngModelOptions]="{standalone: true}" [config]="editorConfig" [(ngModel)]="jobTitle"
                  name="job" id="job" class="mt-2"></angular-editor>
              </div>

              <div class="col-md-6 mt-3 md:mt-0">
                <label for="description" class="text-start w-100 font-bold">Descripción</label>

                <angular-editor [ngModelOptions]="{standalone: true}" [config]="editorConfig" [(ngModel)]="job"
                  name="description" id="description" class="mt-2"></angular-editor>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-auto">
        <!-- CREATE BUTTON -->
        <button type="submit" class="btn font-btn bg-red font-26 mt-3">
          <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>

          <span class="text-white" *ngIf="!isLoading">
            Guardar
          </span>
        </button>
      </div>
    </div>
  </form>
</main>