import { Color } from "../models/color.models"

export interface ActivityNormalized {
  id: number
  page_id: number
  description: null,
  background_color: string
  font_color: string
  video_url: string | null
  image: string | null
  questionary: number
  parent: number
  name: string
  order: number
  progress: {
    answered_count: string
    questions_count: string
    progress_percentage: string
  },
  is_activity: true
  is_preliminar_limit: boolean
  pages: [],
  questions: []
}

export interface SubthemeNormalized {
  id: number
  page_id: number
  description: string | null
  background_color: string
  font_color: string
  video_url: string | null
  image: string | null
  questionary: number
  parent: number
  name: string
  order: number
  progress: {
    answered_count: number
    questions_count: number
    children_total_1: number
    progress_percentage: number
    children_completed_1: number
  },
  is_activity: boolean
  is_preliminar_limit: boolean
  questions: [],
  activities: ActivityNormalized[]
}

export interface ThemeNormalized {
  id: number
  page_id: number
  description: string | null,
  background_color: string
  font_color: string
  video_url: string | null,
  image: string | null,
  questionary: number
  parent: number
  name: string
  order: number
  progress: {
    answered_count: number
    questions_count: number
    children_total_1: number
    children_total_2: number
    progress_percentage: number
    children_completed_1: number
    children_completed_2: number
  },
  is_activity: boolean
  is_preliminar_limit: boolean
  questions: [],
  subthemes: SubthemeNormalized[]
}

export interface ChapterNormalized {
  id: number
  page_id: number
  description: string
  background_color: Color
  font_color: string | null
  video_url: string | null
  image: string | null
  questionary: number
  parent: number | null
  name: string
  order: number
  progress: {
    answered_count: number
    questions_count: number
    children_total_1: number
    children_total_2: number
    children_total_3: number
    progress_percentage: number
    children_completed_1: number
    children_completed_2: number
    children_completed_3: number
  },
  is_activity: boolean
  is_preliminar_limit: boolean
  questions: []
  themes: ThemeNormalized[]
}

export interface SeasonNormalized {
  id: number
  name: string
  is_published: boolean
  progress: {
    answered_count: number
    questions_count: number
    children_total_1: number
    children_total_2: number
    children_total_3: number
    children_total_4: number
    progress_percentage: number
    children_completed_1: number
    children_completed_2: number
    children_completed_3: number
    children_completed_4: number
  },
  chapters: ChapterNormalized[]
}

export const normalizeActivities = (subtheme: any) => {
  return subtheme.pages.map((page: any) => page)
}

const normalizeSubtheme = (theme: any) => {
  return theme.pages.map((page: any) => {
    const subtheme = { ...page }
    
    const activities = normalizeActivities(subtheme)
    delete subtheme.pages
    
    return { ...subtheme, activities }
  })
}

export const normalizeTheme = (chapter: any) => {
  return chapter.pages.map((page: any) => {
    const theme = { ...page }
    
    const subthemes = normalizeSubtheme(theme)
    delete theme.pages
    
    return { ...theme, subthemes }
  })
}

export const normalizeChapters = (season: any) => {
  return season.pages.map((page: any) => {
    const chapter = { ...page }
    
    const themes = normalizeTheme(chapter)
    delete chapter.pages
    
    return { ...chapter, themes }
  })
}

export const normalizeSeasons = (data: any) => {
  return data.map((page: any) => {
    const season = { ...page }
    
    const chapters = normalizeChapters(season)
    
    delete season.pages
    
    return { ...season, chapters }
  })
}
 
export const normalizePages = (pages: any): SeasonNormalized[] => {
	const seasons = normalizeSeasons(pages)
  return seasons
}