import { ICouponToCreate } from './../models/coupon.model';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class CouponsService { // TODO - Integrate
  API_URL: string = environment.apiUrl

  // [GET] /coupons/ : List
  // [GET] /coupons/<int:id>/ : Detail
  // [POST] /coupons/ : Create
  // [PUT, PATCH]  /coupons/<int:id>/ :Update o Partial Update
  // [DELETE] /coupons/<int:id>/ : Destroy

  constructor(private httpClient: HttpClient) { }

  getCoupons(): Observable<any> { 
    const URL = `${this.API_URL}/coupons/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  getCouponById(id: string): Observable<any> { 
    const URL = `${this.API_URL}/coupons/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  createCoupon(newCoupon: ICouponToCreate): Observable<any> { 
    const URL = `${this.API_URL}/coupons/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.post(URL, newCoupon).pipe(catchError(handleErrors))
  }

  updateCoupon(id: string | null, couponToUpdate: ICouponToCreate): Observable<any> { 
    const URL = `${this.API_URL}/coupons/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.put(URL, couponToUpdate).pipe(catchError(handleErrors))
  }

  deleteCoupon(id: string): Observable<any> { 
    const URL = `${this.API_URL}/coupons/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.delete(URL).pipe(catchError(handleErrors))
  }
}
