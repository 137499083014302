import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { ErrorsService } from 'src/app/services/errors.service';
import { AuthService } from 'src/app/services/auth.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';

@Component({
  templateUrl: './email-verification-page.component.html',
  styleUrls: ['./email-verification-page.component.scss']
})
export class EmailVerificationPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  isEmailVerified: boolean = false
  isLoading: boolean = true

  constructor(
    private cookieService: CookieService,
    private errorsService: ErrorsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Verificación de Correo | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    const token = this.route.snapshot.paramMap.get('token') as string;
    const emailIdB64 = this.route.snapshot.paramMap.get('emailIdB64') as string;
    const userIdB64 = this.route.snapshot.paramMap.get('userIdB64') as string;

    if (!token || !emailIdB64 || !userIdB64) {
      this.isLoading = false
      return
    }

    this.authService.verifyEmail(token, emailIdB64, userIdB64).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.isLoading = false

        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: any) => {
        console.debug(data)
        if (!data) {
          this.isLoading = false
          return
        }

        this.cookieService.set('bo_access', data.access)
        this.cookieService.set('bo_refresh', data.refresh)
        this.router.navigate(['/dashboard/home'])
      },
      complete: () => this.isLoading = false
    })
  }

}
