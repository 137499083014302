import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  concatMap,
  finalize,
  from,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { GroupService } from 'src/app/services/group.service';
import { ReportService } from 'src/app/services/report.service';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  templateUrl: './add-reports-page.component.html',
  styleUrls: ['./add-reports-page.component.scss'],
  providers: [SearchBarListService],
})
export class AddReportsPageComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  reports!: Observable<any>;
  reportSections!: any[];
  reportsToAdd!: any[];
  isLoading: boolean = true;
  groupId!: string | null;
  published: boolean = false;
  private userInputContentsAdded: Map<number, any> = new Map();
  orderOptions: string[] = ['Alfabeticamente', 'Orden natural'];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private userInputUsersAdded: Map<string, any> = new Map();

  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit_new.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private groupService: GroupService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    public searchBarListService: SearchBarListService,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService,
    private store: Store
  ) {
    this.title.setTitle('Añadir sección a informe | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Añadir sección',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.groupId = this.activatedRoute.snapshot.paramMap.get('reportGroupId');
  }

  ngAfterViewInit(): void {
    this.searchBarListService.setFilterSelected('Orden natural');
    this.reports = combineLatest([
      this.reportService.getReportGroupsDetails(this.groupId as string).pipe(
        tap((response: any) => (this.reportSections = response.sections)),
        switchMap((report: any) =>
          this.getCheckedAndUncheckedCnts(report.sections)
        )
      ),
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Orden natural')),
    ]).pipe(
      tap(() => (this.isLoading = true)),
      map(([groups, inputSearchResult, selectedOption]) => {
        return [
          this.handleApplyFilter(groups, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([groups, inputSearchResult]) => {
        return (groups as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['order', 'name'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.reports.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (response: any) => {
        this.reportsToAdd = response;
        this.isLoading = false;
      },
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);

        this.isLoading = false;
      },
    });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.userInputUsersAdded.clear();
          this.searchBarListService.setSearchInput('');
          this.searchBarListService.setFilterSelected('');
          this.router.navigate([`dashboard/informes/${this.groupId}/reportes`]);
        }
      });

    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.searchBarListService.setSearchInput('');
          this.searchBarListService.setFilterSelected('');
          this.handleSubmitAddUsers();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getCheckedAndUncheckedCnts(reportSections: any[]) {
    const filterExistingContents = (
      content: any
    ): Partial<any | { checked: boolean }> => {
      const programContentsIds = new Set(
        reportSections.map((contentAdded: any) => contentAdded.id)
      );

      if (programContentsIds.has(content.id)) {
        return {
          ...content,
          checked: true,
        };
      } else {
        return {
          ...content,
          checked: false,
        };
      }
    };

    return this.reportService
      .getAllReportsSections()
      .pipe(map((response: any) => response.map(filterExistingContents)));
  }
  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'order', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  private handleSubmitAddUsers() {
    this.isLoading = true;
    from(this.userInputUsersAdded)
      .pipe(
        concatMap(([key, content]) =>
          this.reportService.addSectionToTheReport(this.groupId as string, {
            sectionId: parseInt(key),
            order: content.order,
          })
        ),
        takeUntil(this.destroyed$),
        finalize(() => {
          this.router.navigate([`dashboard/informes/${this.groupId}/reportes`]);
          this.userInputUsersAdded.clear();
        })
      )
      .subscribe({
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.isLoading = false;

          this.store.dispatch(
            new OpenModal('Algo salio mal' + htmlErrorsContent)
          );
        },
        complete: () => {
          this.store.dispatch(
            new OpenModal('Secciones agregadas exitosamente.')
          );
        },
      });
  }

  private getOrder = (chapterContents: any[]): number => {
    if (chapterContents.length > 0) {
      return (
        chapterContents.reduce((prev, current) => {
          return prev.order > current.order ? prev : current;
        }).order + 1
      );
    }

    return 1;
  };

  public triggerCheckbox(checkboxElement: HTMLInputElement, content: any) {
    checkboxElement.checked = !checkboxElement.checked;

    this.handleContentCheck(
      { target: { checked: checkboxElement.checked } },
      content
    );
  }

  public handleContentCheck(event: any, user: any) {
    const eventTarget = event.target as { checked: boolean };
    if (eventTarget.checked) {
      this.userInputUsersAdded.set(user.id, {
        ...user,
        order: this.getOrder([
          ...this.reportSections,
          ...Array.from(this.userInputContentsAdded.values()),
        ]),
      });
    } else {
      this.userInputUsersAdded.delete(user.id);
    }
  }
}
