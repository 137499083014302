import { NgbDateStruct, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { Component } from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { IFilters } from 'src/app/models/userListFilters.model';

interface IDateRange {
  after: NgbDateStruct,
  before: NgbDateStruct
}

@Component({
  selector: 'app-filters-option',
  templateUrl: './filters-option.component.html',
  styleUrls: ['./filters-option.component.scss']
})
export class FiltersOptionComponent {
  optionsData!: IFilters
  
  constructor(
    public offcanvasService: NgbOffcanvas,
    private commonService: CommonService
  ) {
    this.optionsData = this.commonService.filtersData
  }

  setUserType(userType: 'Student' | 'Back_Office_Admin' | '') { this.optionsData.userType = userType }

  getLabelUserType(userType: 'Student' | 'Back_Office_Admin' | '') {
    if (userType === 'Student') return 'Estudiantes'
    if (userType === 'Back_Office_Admin') return 'Administradores'
    else return 'Todos'
  }

  resetFilters() {
    this.optionsData.userType = ''
    this.optionsData.isSubscribed = null
    this.optionsData.isPreliminarPhaseCompleted = null
    this.optionsData.isFinalPhaseCompleted = null

    this.optionsData.dates.joined.after = { year: 0, month: 0, day: 0 }
    this.optionsData.dates.joined.before = { year: 0, month: 0, day: 0 }

    this.optionsData.dates.created.after = { year: 0, month: 0, day: 0 }
    this.optionsData.dates.created.before = { year: 0, month: 0, day: 0 }

    this.optionsData.dates.editedAt.after = { year: 0, month: 0, day: 0 }
    this.optionsData.dates.editedAt.before = { year: 0, month: 0, day: 0 }

    this.optionsData.dates.lastLogin.after = { year: 0, month: 0, day: 0 }
    this.optionsData.dates.lastLogin.before = { year: 0, month: 0, day: 0 }

    this.commonService.changeFilterOptions(this.optionsData)
    this.offcanvasService.dismiss('Cross click')
  }

  applyFilters() {
    this.commonService.changeFilterOptions(this.optionsData)
    this.offcanvasService.dismiss('Cross click')
  }
}
