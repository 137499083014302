<section layoutContent class="mt-3 mb-4">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label text-secondary mx-2 mb-1"
                    >Nombre (*)</label
                  >
                  <input
                    class="input"
                    [(ngModel)]="university.title"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre de la formación profesional"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="university.description"
                    name="university_description"
                    id="university_description"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label text-secondary mx-2 mb-1"
                    >Etiquetas
                  </label>
                  <input
                    class="input"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="name" class="label text-secondary mx-2 mb-1"
                    >Explicación de formación profesional</label
                  >
                  <textarea
                    class="input"
                    [(ngModel)]="university.explanation"
                    name="grade_explanation"
                    id="grade_explanation"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label text-secondary mx-2 mb-1"
                    >Perfil de salida
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="university.output_profile"
                    name="output_profile"
                    id="output_profile"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="name" class="label text-secondary mx-2 mb-1"
                    >Grado Superior complementario</label
                  >
                  <input
                    class="input"
                    [(ngModel)]="university.output_title"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el grado Universitario complementario"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <label for="name" class="label text-secondary mx-2 mb-1"
                    >Grado Universitario complementario</label
                  >
                  <textarea
                    class="input"
                    [(ngModel)]="university.explanation_title"
                    name="master_description"
                    id="master_description"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <app-rating
                    label="Estudio internacional"
                    [selectedRating]="university.international_studies"
                    (ratingSelected)="
                      onInternationalStudiesRatingSelected($event)
                    "
                  >
                  </app-rating>
                  <app-rating
                    label="Dificultad de estudios"
                    [selectedRating]="university.studies_difficulty"
                    (ratingSelected)="onStudiesDifficultyRatingSelected($event)"
                  >
                  </app-rating>

                  <app-rating
                    label="Salidas profesional"
                    [selectedRating]="university.professional_output"
                    (ratingSelected)="
                      onProfessionalOutputRatingSelected($event)
                    "
                  >
                  </app-rating>

                  <app-rating
                    label="Salario medio"
                    [selectedRating]="university.average_salary"
                    (ratingSelected)="onAverageSalaryRatingSelected($event)"
                  >
                  </app-rating>

                  <app-rating
                    label="Variedad sectorial"
                    [selectedRating]="university.sectoral_variety"
                    (ratingSelected)="onSectoralVarietyRatingSelected($event)"
                  >
                  </app-rating>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4 md:mb-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Ponderación high level"
                    template="BLUE"
                    [options]="profilesToSelect"
                    [selectedOptions]="selectedProfiles"
                    (selectionChange)="handleSelectProfile($event)"
                  ></app-multi-select-dropdown>
                </div>
              </div>
              <div class="row row-no-gutters mt-4">
                <div class="col-xl-12 col-xxl-13">
                  <div class="row">
                    <div class="col-12">
                      <label class="label text-secondary"
                        >Ponderación low level</label
                      >
                      <ul>
                        <li
                          *ngFor="
                            let category of lowLevelParameters.controls
                              .parameters.controls
                          "
                          class="category-container w-100 row mt-4"
                        >
                          <div
                            class="text-black m-0 ps-0 pb-3 mb-4 font-primary--b font-18"
                            style="border-bottom: 1px solid #000"
                          >
                            {{ category.value.label }}
                          </div>

                          <!-- Display Parameters -->
                          <ul
                            [formGroup]="category"
                            class="parameters-container"
                            style="flex-wrap: wrap"
                          >
                            <li
                              class="parameters-element"
                              *ngFor="
                                let parameterGroup of category.controls
                                  .parameters.controls
                              "
                            >
                              <div
                                class="d-flex align-items-center justify-content-start"
                              >
                                <span>{{
                                  parameterGroup.value.label | trimString : 35
                                }}</span>
                              </div>

                              <div
                                [formGroup]="parameterGroup"
                                class="d-flex align-items-center justify-content-start"
                                style="width: 80px"
                              >
                                <input
                                  formControlName="value"
                                  type="number"
                                  class="input mt-1 w-100"
                                />
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row mt-3"></div>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Ponderación modalidad secundaria España"
                    template="GREEN"
                    [options]="specializationTypes"
                    [selectedOptions]="selectedSpecializationFirstTypes"
                    (selectionChange)="
                      handleSpecializationFirstSelectionChange($event)
                    "
                  ></app-multi-select-dropdown>
                </div>
              </div>
              <div class="col-12 mt-2 md:mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Tipo de universidad"
                    template="GREEN"
                    [options]="universityTypes"
                    [selectedOptions]="selectedUniTypes"
                    (selectionChange)="
                      handleUniversityTypeSelectionChange($event)
                    "
                  ></app-multi-select-dropdown>
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
<br />
<br />
<br />
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar esta formación profesional?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="handleDeleteuniversity()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
