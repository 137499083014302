import { Component, Input, OnInit } from '@angular/core';
import { generateID } from 'src/app/utils/fakers';

@Component({
  selector: 'app-text-activity',
  templateUrl: './text-activity.component.html',
  styleUrls: ['./text-activity.component.scss']
})
export class TextActivityComponent implements OnInit {
  @Input() video?: string

  placeholder: string = 'Lorem ipsum...'
  @Input() statement: string = '¿Lorem ipsum?'
  _id: string = 'activity-' + generateID()
  inputData: string = ''

  constructor() { }


  ngOnInit(): void {
  }

}
