import { OpenModal } from './../../../store/ui/ui.actions';
import { Store } from '@ngxs/store';
import { PageService } from './../../../services/page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Page } from 'src/app/models/page.models';
import { Title } from '@angular/platform-browser';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  templateUrl: './detail-page-page.component.html',
  styleUrls: ['./detail-page-page.component.scss']
})
export class DetailPagePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  public pageId!: string
  public page: Page = {
    id: 1,
    name: '',
    description: '',
    background_color: '#ffffff',
    gradient_color: '#ffffff',
    font_color: '#374052',
    image: '',
    video_url: '',
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    questionary: null,
    order: null,
    is_preliminar_limit: false
  }
  public three: Array<any> = [
    {
      id: 1,
      title: 'Temporada 1',
      type: 'questionary',
      chapters: [
        {
          id: 1,
          title: 'Capitulo 1',
          type: 'chapter',
          themes: [
            {
              id: 1,
              title: 'Tema 1',
              type: 'theme',
              subthemes: [
                {
                  id: 1,
                  title: 'Subtema 1',
                  type: 'subtheme',
                },
                {
                  id: 2,
                  title: 'Subtema 2',
                  type: 'subtheme',
                },
              ]
            }
          ]
        }
      ]
    }
  ]

  constructor(
    private errorsService: ErrorsService,
    private activeRoute: ActivatedRoute,
    private pageService: PageService,
    private store: Store,
    private router: Router,
    private title: Title
  ) {
    this.pageId = this.activeRoute.snapshot.params['id']
    this.title.setTitle('Detalle de Página | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.getPageById()
  }

  getPageById() {
    this.pageService.getPageById(this.pageId).subscribe({
      next: (value) => {
        this.page = value as Page
        if (this.page.parent === null) {
          this.page.type = 'chapter'
        } else {
          // TODO: CHECK IF PAGE IS THEME OR SUBTHEME
          this.page.type = 'theme'
        }
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })

  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.store.dispatch(new OpenModal('Página eliminada exitosamente.'))
        this.router.navigate(['/dashboard/pages/programas']);
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }
}
