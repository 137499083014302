import { SafeUrl } from '@angular/platform-browser';
import { Color } from './../../../models/color.models';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() borderRadius?: string;
  @Input() boxShadow?: string = '0px 2px 6px #0000001A';
  @Input() backgroundColor?: Color;
  @Input() dialog?: boolean = false;
  @Input() padding?: string = '40px 90px 40px 90px';
  @Input() width?: string = 'auto';
  @Input() height?: string = 'auto';
  @Input() backgroundImage?: string | SafeUrl;
  @Input() enableHover?: boolean;
  @Input() cursorPointer?: boolean;

  constructor() {}

  ngOnInit(): void {}

  cardStyles() {
    let styles: any = {
      // boxShadow: this.boxShadow,
      borderRadius: this.borderRadius,
      padding: this.padding,
    };
    if (this.cursorPointer) {
      styles.cursor = 'pointer';
    }
    if (this.backgroundColor) {
      styles.backgroundColor = this.backgroundColor;
    }
    if (this.backgroundImage) {
      styles.backgroundImage = `url('${this.backgroundImage}')`;
      styles.backgroundPosition = `center`;
      styles.backgroundSize = `cover`;
    }
    return styles;
  }

  cardWrapperStyles() {
    return {
      filter: 'drop-shadow(' + this.boxShadow + ')',
      width: this.width,
      height: this.height,
    };
  }
}
