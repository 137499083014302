import { Injectable, Output, EventEmitter } from '@angular/core'
import { IFilters } from '../models/userListFilters.model'
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class CommonService {
  private activityEdited$ = new BehaviorSubject<any>({});
  selectedActivity$ = this.activityEdited$.asObservable();
  @Output() updateFilteroptionsData = new EventEmitter<any>()
  filtersData: IFilters = {
    userType: '',
    isSubscribed: null,
    isPreliminarPhaseCompleted: null,
    isFinalPhaseCompleted: null,
    dates: {
      created:{
        before: {
          year: 0,
          month: 0,
          day: 0
        },
        after: {
          year: 0,
          month: 0,
          day: 0
        }
      },
      joined: {
        before: {
          year: 0,
          month: 0,
          day: 0
        },
        after: {
          year: 0,
          month: 0,
          day: 0
        }
      },
      editedAt: {
        before: {
          year: 0,
          month: 0,
          day: 0
        },
        after: {
          year: 0,
          month: 0,
          day: 0
        }
      },
      lastLogin: {
        before: {
          year: 0,
          month: 0,
          day: 0
        },
        after: {
          year: 0,
          month: 0,
          day: 0
        }
      }
    }
  }

  setActivityChanges(activity: any) {
    this.activityEdited$.next(activity);
  }

  changeFilterOptions(data: any) {
    this.updateFilteroptionsData.emit(data)
    this.filtersData = data
    console.log('CommonService > onChangeFilterOptions > data', data)
  }
}