<main class="page page-parameters">
  <div>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col">
            <h1 class="font-24 font-primary--eb">Nuevo Parametro</h1>
          </div>
        </div>
      </article>
    </app-card>
  </div>
  <div class="row justify-content-center mt-3">
    <div class=" col-10">
      <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <article card__content>
          <form action="">
            <div class="row" *ngIf="errors.length">
              <div class="col-12">
                <div class="alert alert-danger p-3" role="alert">
                  <ul class="list-unstyled">
                    <li *ngFor="let error of errors">
                      <p class="font-18 font-primary--b ">{{error.title}}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input [(ngModel)]="name" class="input" id="name" name="paramName" type="text"
                    placeholder="Parametro ABC">
                </div>

              </div>
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="category" class="label mx-2 mb-1">Categoría</label>
                  <select [(ngModel)]="category" title="Categoría" class="input" name="activity-type" id="category">
                    <option [value]="null" selected disabled hidden>Selecciona la categoría</option>
                    <option [value]="category.id" *ngFor="let category of categories">{{category.title}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col d-flex justify-content-end">
                <button class="btn text-red mx-2 " routerLink="/dashboard/preguntas" type="button">Volver</button>
                <button class="btn bg-blue text-white mx-2" (click)="save()" type="button">Guardar</button>
              </div>
            </div>
          </form>
        </article>
      </app-card>
    </div>
  </div>
</main>