<main class="settings-page page">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__header>
      <h1 class="font-primary--b font-24 mb-3">Configuraciones</h1>
    </article>
    <article card__content>
      <div class="d-flex align-items-center gap-3">
        <p class="opacity-50 mb-1">Modo mantenimiento</p>
        <app-toggle-switch
          color="red"
          (click)="toggleMaintenanceMode()"
          [active]="maintenanceMode"
        >
        </app-toggle-switch>
      </div>
      <hr />
      <div class="d-flex gap-3 align-items-center">
        <div class="preview-block">
          <img
            class="brand-preview"
            *ngIf="previewMainImage"
            src="{{ previewMainImage.changingThisBreaksApplicationSecurity }}"
            alt=""
          />

          <img
            class="brand-preview"
            *ngIf="!previewMainImage"
            src="{{ image }}"
            alt=""
          />
        </div>
        <div class="d-flex align-items-end gap-2">
          <div>
            <label for="bgImage" class="label mx-2 mb-1 d-flex">
              Imagen de marca
            </label>
            <input
              *ngIf="isMainImageFile"
              class="w-100"
              name="bgImage"
              type="file"
              id="bgImage"
              accept="image/*"
              (change)="_mainImageChange($event)"
            />
            <input
              class="input w-100"
              [(ngModel)]="image"
              *ngIf="!isMainImageFile"
              type="text"
              name="bgImage"
              id="bgImage"
              placeholder="Enlace de la imagen"
            />
          </div>
          <!-- <div class="d-flex align-items-center">
            <app-toggle-switch [active]="isMainImageFile" (click)="toggleFileTypeImage()"></app-toggle-switch>

            <span class="ms-2 d-block font-12 font-primary--b opacity-50" *ngIf="isMainImageFile">
              Archivo
            </span>
            <span class="ms-2 d-block font-12 font-primary--b opacity-50" *ngIf="!isMainImageFile">
              Enlace
            </span>
          </div> -->
        </div>
      </div>

      <section class="mt-4 max-w-md">
        <fieldset class="form-group mb-4">
          <label for="default_organization_id">
            <strong>ID de Organización por Defecto</strong>
          </label>
          <p>
            <small class="text-red">
              Los usuarios serán asignados a esta organización por defecto.
            </small>
          </p>
          <input
            type="number"
            class="form-control"
            [(ngModel)]="defaultOrgId"
            name="default_organization_id"
            placeholder="Ingrese el ID de organización por defecto"
            aria-describedby="default_organization_idHelp"
            required
            id="default_organization_id"
            min="1"
          />
          <div id="default_organization_idHelp" class="form-text">
            Por favor, ingrese un ID válido de organización.
          </div>
        </fieldset>

        <fieldset class="form-group">
          <label for="default_room_id">
            <strong>ID de Clase por Defecto</strong>
          </label>
          <p>
            <small class="text-red">
              Los usuarios serán asignados a esta clase por defecto.
            </small>
          </p>
          <input
            type="number"
            class="form-control"
            id="default_room_id"
            [(ngModel)]="defaultRoomId"
            name="default_room_id"
            placeholder="Ingrese el ID de clase por defecto"
            aria-describedby="default_room_idHelp"
            required
            min="1"
          />
          <div id="default_room_idHelp" class="form-text">
            Por favor, ingrese un ID válido de clase.
          </div>
        </fieldset>
      </section>
      <br />
      <div class="actions d-flex justify-content-end">
        <button
          class="btn bg-green text-white"
          (click)="submitSettings()"
          type="button"
        >
          Actualizar
        </button>
      </div>
    </article>
  </app-card>
</main>
