<section layoutContent class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="jazzdooRole.name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre del rol Jazzdoo"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="jazzdooRole.description"
                    name="internal_description"
                    id="internal_description"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1">Etiquetas </label>
                  <input
                    class="input"
                    [(ngModel)]="jazzdooRole.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Información sobre el rol
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="jazzdooRole.role_information"
                    name="role_information"
                    id="role_information"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Habilidades del perfil
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="jazzdooRole.skills"
                    name="skills"
                    id="skills"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Puntos a tener en cuenta
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="jazzdooRole.most_important_points"
                    name="most_important_points"
                    id="most_important_points"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Primer puesto"
                    template="GREEN"
                    [selectedOptions]="selectedFirstTypes"
                    [options]="positionTypes"
                    [singleOption]="true"
                    (selectionChange)="handleFirstSelectionChange($event)"
                  ></app-multi-select-dropdown>
                </div>
              </div>

              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Segundo puesto"
                    template="GREEN"
                    [options]="positionTypes"
                    [selectedOptions]="selectedSecondTypes"
                    [singleOption]="true"
                    (selectionChange)="handleSecondSelectionChange($event)"
                  ></app-multi-select-dropdown>
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar este rol Jazzdoo?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="handleDeleteJazzdooRole()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
