import { ErrorsService } from './../../../services/errors.service';
import { ModalContent } from 'src/app/components/core/modal/modal.component';
import { InstitutionsService } from './../../../service/institutions.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Iinstitution } from 'src/app/models/institution';
import { PageService } from 'src/app/services/page.service';
import { Iquestionary } from 'src/app/models/questionary.models';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './institutions-page.component.html',
  styleUrls: ['./institutions-page.component.scss']
})
export class InstitutionsPageComponent implements OnInit {
  editMode: boolean = false
  inProcess: boolean = false
  institutions: Array<Iinstitution> = []
  questionaries: Array<Iquestionary> = []
  previewMainImage: any = null
  institution: Iinstitution = {
    name: '',
    logo: null,
    primary_color: '#ffffff',
    secondary_color: '#ffffff',
    license_quantity: 9999,
  }
  institutionLogoEdit:boolean = false;

  constructor(
    private modalService: NgbModal,
    public institutionsService: InstitutionsService,
    public pageService: PageService,
    private errorsService: ErrorsService,
    private store: Store,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getInstitutions()
  }

  getInstitutions() {
    this.inProcess = true
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false
        this.institutions = data
      },
      error: (e) => {
        this.inProcess = false
        console.debug(e)
      },
    })
  }

  open(content: any, toEditMode: boolean = false) {
    this.previewMainImage = null
    if (!toEditMode) {
      this.editMode = false
      this.institution = {
        name: '',
        logo: null,
        primary_color: '#ffffff',
        secondary_color: '#ffffff',
        license_quantity: 9999,
        image: null
      }
    }

    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
    })
  }

  newInstitution() {
    this.inProcess = true
    this.institutionsService.newInstitution(this.institution).subscribe({
      next: (data) => {
        this.inProcess = false
        this.getInstitutions()
        this.modalService.dismissAll()
      },
      error: (e) => {
        this.inProcess = false
        const { htmlErrorsContent } = this.errorsService.handleErrors(e)
        this.openModal('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }

  openModal(title: string, content: string, type: string = 'error', options?: {}, fnAction?: () => void) {
    const modalRef = this.modalService.open(ModalContent, { centered: true, ...options });
    console.debug()
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }

  editInstitutionMode(institution: any, content: any) {
    this.editMode = true
    this.open(content, true)
    this.institution = institution
    this.inProcess = true
    this.institutionsService.getInstitutionById(institution.id).subscribe({
      next: (data) => {
        this.inProcess = false
        this.institution = data;
        console.log('Institution in Edition ', this.institution)
      },
      error: (e) => {
        this.inProcess = false
        console.debug(e)
      },
    })
  }

  editInstitution() {
    this.inProcess = true
    this.institutionsService.updateInstitution(this.institution, this.institutionLogoEdit).subscribe({
      next: (data) => {
        this.inProcess = false
        this.getInstitutions()
        this.modalService.dismissAll()
      },
      error: (e) => {
        this.modalService.dismissAll()
        const { htmlErrorsContent } = this.errorsService.handleErrors(e)
        this.openModal('Algo ha ocurrido', htmlErrorsContent)
      },
    })
  }

  deleteInstitution(institutionId: string, content: any) {
    if (confirm('¿Está seguro de realizar esta acción?')) {
      this.inProcess = true
      this.modalService.open(content, { backdrop: 'static' })
      this.institutionsService.deleteInstitution(institutionId).subscribe({
        next: (data) => {
          this.inProcess = false
          this.getInstitutions()
          this.modalService.dismissAll()
        },
        error: (e) => {
          this.inProcess = false
          this.modalService.dismissAll()
          const { htmlErrorsContent } = this.errorsService.handleErrors(e)
          this.openModal('Algo ha ocurrido', htmlErrorsContent)
        },
      })
    }
  }

  changeImageHandler(event: any) {
    this.institution.logo = event.target.files[0];
    const imageType = event.target.files[0].type

    const imageSize = event.target.files[0].size
    const invalidSize = imageSize > 1000000 //?? 1mb === 1000000 bytes

    const invalidType = imageType !== 'image/png' && imageType !== 'image/jpeg' && imageType !== 'image/jpg' && imageType !== 'image/gif' && imageType !== 'image/svg+xml'
    const isInvalid = invalidType

    const reader = new FileReader();
    if (invalidType) {
      this.store.dispatch(new OpenModal('Archivo invalido.'))
      this.institution.logo = null
      return
    }
    if (invalidSize) {
      this.store.dispatch(new OpenModal('El maximo de peso soportado por archivo es 1mb.'))
      this.institution.logo = null
      return
    }

    if(this.editMode) this.institutionLogoEdit = true;

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.previewMainImage = reader.result as string;
    };
  }

}
