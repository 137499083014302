<section class="mt-3">
  <ul class="row mx-0 px-0">
    <app-search-list-bar class="px-0">
      <section left-side class="col-3 d-flex align-items-center">
        <app-selector [data]="orderOptions"></app-selector>
      </section>
    </app-search-list-bar>
    <div class="mt-3" *ngIf="inProcess">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
            Por favor, espere un momento
          </h2>
        </div>
      </app-card>
    </div>
    <section *ngIf="!inProcess" class="d-flex flex-column gap-2 px-0">
      <app-list-element
        *ngFor="let university of universities | async; index as i"
        [entityData]="university"
        [customTemplate]="'DEFAULT'"
        (onClickAction)="handleListElementClickAction($event, university)"
        [actions]="listElementActions"
      >
      </app-list-element>
    </section>
  </ul>
  <article
    class="mt-3"
    *ngIf="universities && (universities | async).length === 0 && !inProcess"
  >
    <app-card
      borderRadius="10px"
      padding="80px 120px"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <section card__content>
        <h2 class="text-center font-24 opacity-50">
          No has creado ninguna universidad aún,
          <span class="text-green cursor-pointer" (click)="goToCreation()"
            >crea una aquí</span
          >
        </h2>
      </section>
    </app-card>
  </article>
</section>

<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar esta universidad?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="deleteUniversity()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
