import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { Observable, Subscription, fromEvent, map, merge } from 'rxjs';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit, AfterViewInit {
  @ViewChild('dropdownContainer') dropdownMenu!: ElementRef<HTMLMenuElement>;
  @Input() data: string[] = [];

  public isDropdownOpen$: Observable<boolean> =
    this.searchBarListService.getIsDropdownOpen();

  public windowClickSubscription!: Subscription;
  public buttonHoverSubscription!: Subscription;
  constructor(@SkipSelf() private searchBarListService: SearchBarListService) {}

  ngOnInit(): void {
    this.windowClickSubscription = fromEvent(window, 'click').subscribe(() => {
      this.searchBarListService.setIsDropdownOpen(false);
    });
  }

  ngAfterViewInit() {
    this.buttonHoverSubscription = merge(
      fromEvent(this.dropdownMenu.nativeElement, 'mouseover').pipe(
        map(() => 'MOUSEOVER')
      ),
      fromEvent(this.dropdownMenu.nativeElement, 'mouseleave').pipe(
        map(() => 'MOUSELEAVE')
      )
    ).subscribe((value: string) => {
      if (value === 'MOUSEOVER') {
        this.searchBarListService.setIsDropdownOpen(true);
      } else {
        this.searchBarListService.setIsDropdownOpen(false);
      }
    });
  }

  ngOnDestroy() {
    this.windowClickSubscription.unsubscribe();
    this.buttonHoverSubscription.unsubscribe();
  }

  public selectOption(option: string) {
    this.searchBarListService.setFilterSelected(option);
    this.searchBarListService.setIsDropdownOpen(false);
  }

  public openDropdown(event: Event): void {
    event.stopPropagation();
    this.searchBarListService.setIsDropdownOpen(true);
  }

  public closeDropdown(event: Event): void {
    event.stopPropagation();
    this.searchBarListService.setIsDropdownOpen(false);
  }
}
