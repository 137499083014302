<app-card
  borderRadius="10px"
  padding="20px 40px"
  boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  height="100%"
  *ngIf="
    questionType === 'SELECT_ANSWER' ||
    questionType === 'SELECT_MULTIPLES_ANSWERS' ||
    questionType === 'JAZZDOO' ||
    questionType === 'TEST' ||
    questionType === 'DISCRETE_NUMERIC_ANSWER' ||
    questionType === 'CONTINUOUS_NUMERIC_ANSWER' ||
    questionType === 'SCHEIN'
  "
>
  <article card__content style="height: 100%" class="option-panel">
    <section>
      <article card__content>
        <div
          *ngIf="
            questionType === 'SELECT_MULTIPLES_ANSWERS' ||
            questionType === 'TEST'
          "
          class="col-10 mb-4 mt-4"
        >
          <div class="row">
            <div class="col-6 d-flex align-items-center gap-4">
              <label for="min" class="font-14">Mínima Elegible</label>

              <input
                id="min"
                formControlName="min"
                type="number"
                step="1"
                min="1"
                class="form-control w-90px"
                placeholder="Mínimo"
              />
            </div>

            <div class="col-6 d-flex align-items-center gap-4">
              <label for="max" class="font-14">Máxima Elegible</label>

              <input
                id="max"
                formControlName="max"
                type="number"
                placeholder="Máximo"
                class="form-control w-90px"
              />
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <!--?? OPTIONS HEADER ??-->
          <div
            class="col-auto d-flex align-items-center"
            [class.disabled]="questionType === 'JAZZDOO'"
            *ngIf="questionType !== 'SCHEIN'"
            [style]="{ opacity: questionType === 'JAZZDOO' ? 0.6 : 1 }"
          >
            <span class="text-secondary"> Ponderado </span>

            <label class="switch ms-3">
              <input
                type="checkbox"
                (click)="toggleIsPonderable()"
                formControlName="is_ponderable"
                [checked]="
                  getParentForm.get('is_ponderable')?.valueChanges | async
                "
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <ng-container
          *ngIf="
            (getParentForm.get('is_ponderable')?.valueChanges | async) ||
            getParentForm.get('is_ponderable')?.value
          "
        >
          <app-multi-select-dropdown
            [label]="questionType === 'JAZZDOO' ? 'Parámetro' : 'Parámetros'"
            [selectedOptions]="categoriesSelected"
            [placeholder]="
              questionType === 'JAZZDOO'
                ? 'Seleccione el parámetro'
                : 'Seleccione los parametros'
            "
            [singleOption]="questionType === 'JAZZDOO'"
            [options]="categoriesFormatted"
            (selectionChange)="onSelectionChange($event)"
            [disabled]="editMode && questionType === 'JAZZDOO'"
          ></app-multi-select-dropdown>
        </ng-container>

        <h6 class="text-secondary my-4">Opciones</h6>
        <ul
          *ngIf="
            !(getParentForm.get('is_ponderable')?.valueChanges | async) &&
            !getParentForm.get('is_ponderable')?.value
          "
        >
          <li
            *ngFor="let option of options.controls; let index = index"
            class="col"
            [class.d-none]="option.value.delete"
          >
            <fieldset
              [formGroup]="option"
              class="option-wrapper mb-3"
              [class.with-check]="questionType === 'TEST'"
            >
              <ng-container *ngIf="questionType === 'TEST'">
                <img
                  (click)="toggleCorrectOption(index)"
                  *ngIf="!option.value.is_correct"
                  src="/assets/images/icons/icon_unchecked.svg"
                  alt="check input icon"
                  title="Esta opción aun no ha sido seleccionada como correcta"
                  class="w-20px h-20px me-3 cursor-pointer"
                />
                <img
                  (click)="toggleCorrectOption(index)"
                  *ngIf="option.value.is_correct"
                  src="/assets/images/icons/icon_checked.svg"
                  alt="check input icon"
                  title="Esta opción fue seleccionada como correcta"
                  class="w-20px h-20px me-3 cursor-pointer"
                />
              </ng-container>
              <div class="fields-group">
                <input
                  formControlName="name"
                  type="text"
                  class="input w-100 col"
                  placeholder="Título de la opción"
                />
                <select name="scheinParams" id="" *ngIf="questionType === 'SCHEIN'" (change)='onChangeOptionHandler($event)'>
                  <option [value]="param.slug" [selected]="param.slug === option.value.schein_parameter" [disabled]="param.selected" *ngFor="let param of scheinParams">{{param.label}}</option>
                </select>
                <textarea
                  *ngIf="questionType === 'SCHEIN'"
                  formControlName="body"
                  id="description"
                ></textarea>
              </div>

              <button
                *ngIf="questionType !== 'JAZZDOO'"
                title="Quitar Opción"
                type="button"
                class="btn col-1 p-0"
                (click)="removeOptionByIndex(index)"
              >
                <img
                  class="w-30px h-30px"
                  src="/assets/images/icons/icon_close.svg"
                  alt=""
                />
              </button>
            </fieldset>
          </li>

          <li
            *ngIf="questionType !== 'JAZZDOO'"
            class="col cursor-pointer mt-2"
            (click)="addOption()"
          >
            <ng-container>
              <img
                src="../../../../../assets/images/icons/icon_unchecked.svg"
              />
            </ng-container>
            <span class="m-0 ms-4 p-0">Agregar nuevo</span>
          </li>
        </ul>

        <ul
          *ngIf="
            (getParentForm.get('is_ponderable')?.valueChanges | async) ||
            getParentForm.get('is_ponderable')?.value
          "
          class="col activity__options"
        >
          <!-- Display Options -->
          <li
            *ngFor="let option of options.controls; let index = index"
            class="col activity__option"
            [class.d-none]="option.value.delete"
          >
            <div [formGroup]="option" class="d-flex align-items-center mb-3">
              <img
                src="/assets/images/icons/icon_unchecked.svg"
                alt="check input icon"
                class="w-20px h-20px me-3"
              />

              <input
                name="name"
                type="text"
                class="input w-100"
                placeholder="Título de la opción"
                formControlName="name"
              />

              <div class="d-flex">
                <button
                  title="Mostrar Categorias"
                  type="button"
                  class="btn mt-0 pt-0"
                  (click)="toggleShowCategories(index)"
                >
                  <img
                    class="w-30px h-30px"
                    [src]="
                      option.value.showCategories
                        ? '/assets/images/icons/icon_up.svg'
                        : '/assets/images/icons/icon_down.svg'
                    "
                  />
                </button>

                <button
                  *ngIf="questionType !== 'JAZZDOO'"
                  title="Quitar Opción"
                  type="button"
                  class="btn mt-0 pt-0"
                  (click)="removeOptionByIndex(index)"
                >
                  <img
                    class="w-30px h-30px"
                    src="/assets/images/icons/icon_close.svg"
                  />
                </button>
              </div>
            </div>

            <!-- Display Categories -->
            <div class="ps-4" *ngIf="option.value.showCategories">
              <ul class="p-0">
                <li
                  *ngFor="let category of option.controls.categories.controls"
                  class="w-100 row mt-4"
                  style="
                    border-radius: 4px;
                    padding: 14px 8px 14px 0px;
                    background-color: #fffbef;
                  "
                >
                  <div class="text-black mt-3 font-primary--b font-18">
                    {{ category.value.label }}
                  </div>

                  <!-- Display Parameters -->
                  <ul
                    [formGroup]="category"
                    class="d-flex pb-2 ps-4"
                    style="flex-wrap: wrap"
                  >
                    <li
                      class="mt-2 me-3"
                      *ngFor="
                        let parameterGroup of category.controls.parameters
                          .controls
                      "
                    >
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <span>{{ parameterGroup.value.label }}</span>
                      </div>

                      <div
                        [formGroup]="parameterGroup"
                        class="d-flex align-items-center justify-content-start"
                      >
                        <input
                          formControlName="value"
                          type="number"
                          class="input mt-1"
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </li>
          <li
            *ngIf="questionType !== 'JAZZDOO'"
            class="col cursor-pointer mt-2"
            (click)="addOption()"
          >
            <ng-container>
              <img
                src="../../../../../assets/images/icons/icon_unchecked.svg"
              />
            </ng-container>
            <span class="m-0 ms-4 p-0">Agregar nuevo</span>
          </li>
        </ul>
        <!--?? OPTIONS TOOLBAR ??-->

        <!--?? ERRORS ??-->
        <!-- <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
      </article>
      <br />
    </section>
  </article>
</app-card>
