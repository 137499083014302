import { CdkDragHandle } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, ReplaySubject, fromEvent, map, takeUntil } from 'rxjs';
import { Color } from 'src/app/models/color.models';

type TemplateType = 'default' | 'dragTemplate' | 'checkTemplate';
type TagsTemplateType = 'default' | 'yellowTemplate';

@Component({
  selector: 'app-list-element',
  templateUrl: './list-element.component.html',
  styleUrls: ['./list-element.component.scss'],
  providers: [CdkDragHandle],
})
export class ListElementComponent {
  @ViewChild('checkContentInput')
  checkContentInput!: ElementRef<HTMLInputElement>;
  @Input() enableHover: boolean = true;
  @Input() templateTeams!: boolean;
  @Input() isDragable: boolean = false;
  @Input() isCheckable: boolean = false;
  @Input() idLabel: string = 'ID';
  @Input() customIdProp: string = 'id';
  @Input() cursorPointer: boolean = true;
  @Input() disabled: boolean = false;
  @Input() withoutPublished: boolean = false;
  @Input() backgroundColor!: Color;
  @Input() withoutToggle!: boolean;
  @Input() entityData!: any;
  @Input() visualDisabled!: boolean;
  @Input() customTemplate: 'DEFAULT' | 'TEAMS' | 'USERS' = 'DEFAULT';
  @Input() tagsTemplate: TagsTemplateType = 'default';
  @Input() isSingleRowDesign: boolean = false;
  @Input() actions: {
    action: string;
    icon: string;
    always_visible?: boolean;
  }[] = [];
  @Output() onClick = new EventEmitter<HTMLInputElement>();
  @Output() onClickAction = new EventEmitter<string>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public viewportWidth$ = fromEvent(window, 'resize').pipe(
    map((event: any) => event.target.innerWidth),
    takeUntil(this.destroyed$)
  );

  constructor() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public _onClick(event?: HTMLInputElement) {
    this.onClick.emit(event);
  }

  public _onClickAction(event: Event, action: string): void {
    event.stopPropagation();
    this.onClickAction.emit(action);
  }
}
