import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IPhrase, IPhraseToRender } from 'src/app/models/phrase.model';
import { ErrorsService } from 'src/app/services/errors.service';
import { PhrasesService } from 'src/app/services/phrases.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-detail-phrase-page',
  templateUrl: './detail-phrase-page.component.html',
  styleUrls: ['./detail-phrase-page.component.scss']
})
export class DetailPhrasePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  report!: IPhraseToRender
  isLoading: boolean = true

  constructor(
    private phraseService: PhrasesService,
    private errorsService: ErrorsService,
    private route: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('Detalle de Frase | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    const phraseId = Number(this.route.snapshot.paramMap.get('id'))

    this.phraseService.getPhrasesById(phraseId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: IPhrase) => {
        this.report = {
          id: data.id,
          categoryName: data.parameter_category_name,
          parameterName: data.parameter_name,
          phrases: [
            {
              id: 'preliminary_first_part',
              label: 'Preliminar Primera Parte',
              content: data.preliminary_first_part,
              show: false,
              toggleShow: function () { this.show = !this.show }
            },
            {
              id: 'preliminary_second_part',
              label: 'Preliminar Segunda Parte',
              content: data.preliminary_second_part,
              show: false,
              toggleShow: function () { this.show = !this.show }
            },
            {
              id: 'final_first_part',
              label: 'Final Primera Parte',
              content: data.final_first_part,
              show: false,
              toggleShow: function () { this.show = !this.show }
            },
            {
              id: 'final_second_part',
              label: 'Final Segunda Parte',
              content: data.final_second_part,
              show: false,
              toggleShow: function () { this.show = !this.show }
            }
          ]
        }
      },
      complete: () => this.isLoading = false
    })
  }
}
