import { OptionActivity } from "./option.models"

export interface Activity {
  id: string
  title: string
  type: string
  value: string
  video: { videoUrl: string } | null
  options?: Array<OptionActivity>
  min?: number
  max?: number
}
export interface Activities {
  activities: Array<Activity>,
  activityCreated: ActivityQuestion | null
}

export interface ActivityQuestion {
  id?: number | string
  activity?: number | string
  name: string
  description?: string
  video_url?: string
  includeVideo?: boolean
  is_published?: boolean
  is_ponderable?: boolean
  type: string
  is_archived?: boolean
  options_min?: number
  options_max?: number
  options?: Array<OptionActivity> | []
}

export interface ActivityType {
  id: string
  title: string
  slug: string
  code: string
}
