import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  from,
  fromEvent,
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { Color } from 'src/app/models/color.models';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnChanges, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;
  @Input() options: Array<Record<string, string>> = [];
  @Input() label: string = '';
  @Input() singleOption: boolean = false;
  @Input() placeholder: string = '';
  @Input() template: 'YELLOW' | 'BLUE' | 'GREEN' | 'PURPLE' = 'YELLOW';
  @Output() selectionChange = new EventEmitter<Array<Record<string, string>>>();
  @Input() disabled: boolean = false;
  @Input() selectedOptions: Array<Record<string, string>> = [];
  public dropdownSearch: FormControl<string> = this.fb.control('', {
    nonNullable: true,
  });
  public dropdownOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isOpen: boolean = false;
  private options$: BehaviorSubject<Record<string, string>[]> =
    new BehaviorSubject<Record<string, string>[]>(this.options);
  public getOptions = combineLatest([
    this.options$,
    this.dropdownSearch.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([options, value]: any) => {
      console.log({ options, value });
      return options.filter(
        (option: any) =>
          Object.hasOwn(option, 'label') &&
          option.label.toLowerCase().includes(value.toLowerCase())
      );
    })
  );
  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('CHANGES', changes);
    if (changes['options'] && changes['options'].currentValue) {
      this.options$.next(changes['options'].currentValue);
    }
  }
  ngAfterViewInit() {
    fromEvent(window, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        event.stopPropagation();
        this.closeDropdown();
      });

    this.dropdownOpen$
      .pipe(debounceTime(10), takeUntil(this.destroy$))
      .subscribe(
        (open: boolean) => open && this.searchInput.nativeElement.focus()
      );
  }
  ngOnDestroy() {
    this.dropdownOpen$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public stopPropagation(event: Event) {
    event.stopPropagation();
  }

  public toggleSelection(option: Record<string, string>, event: Event): void {
    event.stopPropagation();
    const index = this.selectedOptions.findIndex(
      (o) =>
        (o as { value: string }).value === (option as { value: string }).value
    );
    if (index === -1) {
      if (this.singleOption) {
        this.selectedOptions = [option];
      } else {
        this.selectedOptions.push(option);
      }
    } else {
      this.selectedOptions.splice(index, 1);
    }
    this.selectionChange.emit(this.selectedOptions);
  }

  public isSelected(option: Record<string, string>): boolean {
    return this.selectedOptions.some(
      (o) =>
        (o as { value: string }).value === (option as { value: string }).value
    );
  }

  public toggleDropdown(event: Event): void {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
    this.dropdownOpen$.next(this.isOpen);
  }

  public closeDropdown() {
    this.isOpen = false;
    this.dropdownOpen$.next(false);
  }

  public trackByValue(index: number, item: { value: string }) {
    return item.value;
  }
}
