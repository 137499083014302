import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(private title: Title) {
    this.title.setTitle('Pagina No Encontrada | Backoffice - Growth Road')
  }

  ngOnInit(): void {}
}
