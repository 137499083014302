import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating-circular.component.html',
  styleUrls: ['./rating-circular.component.scss'],
})
export class RatingCircularComponent implements OnInit {
  @Input() selectedRating: number | null = null;
  @Input() label: string = '';
  @Output() ratingSelected: EventEmitter<number> = new EventEmitter<number>();

  spheres: number[] = [1, 2, 3, 4, 5];

  ngOnInit(): void {
    // Set initial rating if provided
    if (this.selectedRating !== null) {
      this.updateRating(this.selectedRating);
    }
  }

  updateRating(rating: number): void {
    this.selectedRating = rating;
    this.ratingSelected.emit(this.selectedRating);
  }
}
