import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageService } from 'src/app/services/page.service';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
  providers: [SearchBarListService],
})
export class ProgramsComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  @ViewChild('modalDeleteErrorTemplateRef', { read: TemplateRef })
  modalDeleteErrorTemplateRef!: TemplateRef<any>;
  @ViewChild('modalSuccessDeleteTemplateRef', { read: TemplateRef })
  modalSuccessDeleteTemplateRef!: TemplateRef<any>;
  programs!: Observable<any>;
  inProcess: boolean = true;
  private programIdToDelete: string | null = null;
  published: boolean = false;
  orderOptions: string[] = ['Alfabeticamente', 'Mas reciente', 'Mas antiguo'];
  private questionariesData$: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'duplicate',
      icon: '/assets/images/icons/icon_duplicate_new.svg',
    },
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit_new.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private store: Store,
    public searchBarListService: SearchBarListService,
    private breadcrumbService: BreadcrumbService,
    private universalModal: UniversalModalService
  ) {
    this.title.setTitle('Programas | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Programas',
      headerTitleIcon: '/assets/images/icons/icon_program.svg',
      headerButtonText: 'Crear programa',
      headerButtonIcon: '/assets/images/icons/icon_create.svg',
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.resetRoutes();
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.goToCreation();
        }
      });

    this.getQuestionaries();
  }

  ngAfterViewInit(): void {
    this.programs = combineLatest([
      this.questionariesData$,
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Mas reciente')),
    ]).pipe(
      map(([programs, inputSearchResult, selectedOption]) => {
        return [
          this.handleApplyFilter(programs, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([programs, inputSearchResult]) => {
        return (programs as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['id', 'name'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.programs.subscribe({
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.inProcess = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getQuestionaries() {
    this.inProcess = true;
    this.pageService
      .getQuestionaries()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.inProcess = false;
        this.questionariesData$.next(
          data.map((element: any) => ({
            ...element,
            name: element.internal_name ?? '',
            description: element.internal_description ?? '',
          }))
        );
      });
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  public confirmDelete(id: string | number): void {
    // TODO IMPROVE UI
    this.programIdToDelete = id as string;
    this.universalModal.openModal(this.modalDeleteTemplateRef, null);
  }

  public handleCloseModal(): void {
    this.universalModal.closeModal();
  }

  public confirmDuplicate(id: number): void {
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.duplicateProgram(id);
    }
  }

  public duplicateProgram(id: number): void {
    this.pageService.duplicateProgramById(id).subscribe((data) => {
      this.getQuestionaries();
    });
  }

  public deleteQuestionary() {
    this.universalModal.closeModal();
    this.pageService
      .deleteQuestionary(this.programIdToDelete as string)
      .subscribe({
        complete: () => {
          this.universalModal.openModal(
            this.modalSuccessDeleteTemplateRef,
            null
          );
          this.getQuestionaries();
        },
        error: ({ error }) => {
          this.universalModal.openModal(this.modalDeleteErrorTemplateRef, null);
        },
      });
  }

  public handleListElementClick(event: any) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: event.name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([`/dashboard/pages/programas/contenidos/${event.id}`]);
  }

  public handleListElementClickAction(actionName: string, program: any): void {
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(program),
      delete: () => this.confirmDelete(program.id),
      duplicate: () => this.confirmDuplicate(program.id),
    };

    actions[actionName]();
  }

  public handleListElEdit(program: any) {
    this.router.navigate([`/dashboard/pages/programas/${program.id}/editar`]);
  }

  public goToCreation() {
    this.router.navigate(['dashboard/pages/programas/nuevo']);
  }
}
