import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionActivity } from 'src/app/models/activities/option.models';

@Component({
  selector: 'app-schein-activity',
  templateUrl: './schein-activity.component.html',
  styleUrls: ['./schein-activity.component.scss']
})
export class ScheinActivityComponent implements OnInit {
  @Input() video?: string
  @Input() statement: string = ''
  @Input() options: Array<OptionActivity> = []

  @Output() dataEvent = new EventEmitter<any>();
  @Input() edit: any = []

  // disableAll = false
  decreaseDisabled: boolean = false
  increaseDisabled: boolean = false
  fieldDisabled: boolean = false

  maxPoints: number = 40
  minPoints: number = 0
  stackedPoints: number = 40
  currentPoints: number = 0

  constructor() { }

  ngOnInit() {
  }

  disabledAll(currentPoints: number) {
    const total = this.options.reduce(function(a, b){
      return a + b['_points']!;
    }, 0);

    this.currentPoints = total

    this.decreaseDisabled = currentPoints <= this.minPoints,
    this.increaseDisabled = currentPoints >= this.maxPoints || total >= this.maxPoints,
    this.fieldDisabled = this.increaseDisabled
  }

  decreaseHandler(option: any) {
    option._points -= 1
    this.stackedPoints +=1
    // this.currentPoints -= 1
    this.disabledAll(option._points)
  }

  increaseHandler(option: any) {
    option._points += 1
    this.stackedPoints -=1
    // this.currentPoints += 1
    this.disabledAll(option._points)
  }

  pointsChangeHandler(event: number) {
    console.debug('[event]', event)

    setTimeout(() => {
      if (event !== null && event >= 0) {
        if (event > this.maxPoints) {
          console.debug('[event]',event)
          console.debug('[this.maxPoints]',this.maxPoints)
          this.currentPoints = 0
        }
      } else {
        this.currentPoints = 0
      }
    }, 500);
  }

}
