import { Injectable, ViewChild } from '@angular/core'
import { ModalComponent } from '../components/core/modal/modal.component'

type TBackErrorsForms = { [key: string]: [string] } | { [key: string]: string }
// type TBackErrorsManualHandled = string
type THandleBackErrorsProps = TBackErrorsForms

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  @ViewChild(ModalComponent) modal!: ModalComponent;

  handleErrors(errors: THandleBackErrorsProps): { htmlErrorsContent: string } { // TODO Refactor
    delete errors?.['code']
    const msgs = Object.values(errors)
    let errorsMsgs: string[] = []

    console.log('msgs', msgs)

    if (Array.isArray(msgs[0])) {

      msgs.forEach(msg => {
        if (msg[0] instanceof Object) { // if item is a object with message content
          return msg[0]['message']
        }
  
        const contentMsg = `<li>${ msg[0] }</li>`
        errorsMsgs.push(contentMsg)
      })

    } else {

      const contentMsg = `<li>${ msgs[0] }</li>`
      errorsMsgs.push(contentMsg)

    }

    const htmlErrorsContent = `
      <ul class="list-dots">
        ${ errorsMsgs.join('') }
      </ul>
    `
    return { htmlErrorsContent }
  }
}