import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-question-page',
  templateUrl: './edit-question-page.component.html',
  styleUrls: ['./edit-question-page.component.scss'],
})
export class EditQuestionPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
