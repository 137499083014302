import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { Iquestionary } from 'src/app/models/questionary.models';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ReplaySubject, takeUntil } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Group } from 'src/app/models/group.model';
import { GroupService } from 'src/app/services/group.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  templateUrl: './new-group-page.component.html',
  styleUrls: ['./new-group-page.component.scss'],
})
export class NewGroupPageComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('inputImage') inputImage!: ElementRef<HTMLInputElement>;
  @ViewChild('modalSuccessTemplateRef', { read: TemplateRef })
  modalSuccessTemplateRef!: TemplateRef<any>;
  private groupId!: string;
  three: any = [];
  public editMode: boolean = false;
  previewMainImage: any = null;
  isMainImageFile: boolean = true;
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;

  public group: Group = {
    name: '',
    description: '',
    license_quantity: 0,
    tags: [],
    logo: undefined,
    primary_color: '#00CAA8',
    secondary_color: '#078CC7',
    font_color: '#FFFFFF',
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public router: Router,
    public institutionsService: InstitutionsService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private sanitizer: DomSanitizer,
    private groupService: GroupService,
    private title: Title,
    private activatedRoute: ActivatedRoute,
    private universalModalService: UniversalModalService,
    private errorsService: ErrorsService
  ) {
    this.title.setTitle('Crear grupo | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear grupo',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.groupId = this.activatedRoute.snapshot.paramMap.get('groupId') ?? '';

    if (this.groupId) {
      this.groupService.getGroupById(this.groupId).subscribe((response) => {
        this.group = response;
        this.title.setTitle('Editar grupo | Backoffice - Growth Road');
        this.creationalPagesLayoutService.layoutConfig.next({
          headerTitle: 'Editar grupo',
          headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
          footerConfig: {
            submitButtonText: 'Guardar',
            dismissButtonText: 'Cancelar',
            deleteButtonText: 'Eliminar',
          },
        });
      });
    }
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.handleSubmit(true);
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/grupos']);
        }
      });

    if (this.groupId) {
      this.creationalPagesLayoutService.onDelete
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          if (value) {
            this.groupService.deleteGroup(this.groupId).subscribe({
              next: (response: any) => {
                this.router.navigate(['dashboard/grupos']);
                this.universalModalService.openModal(
                  undefined,
                  null,
                  'Grupo eliminado exitosamente'
                );
              },
              error: (response: any) => {
                this.universalModalService.openModal(
                  undefined,
                  null,
                  'Algo salio mal'
                );
              },
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  _mainImageChange(event: any) {
    this.group.logo = event.target.files[0];
    const imageType = event.target.files[0].type;

    const imageSize = event.target.files[0].size;
    const invalidSize = imageSize > 1000000; //?? 1mb === 1000000 bytes

    const invalidType =
      imageType !== 'image/png' &&
      imageType !== 'image/jpeg' &&
      imageType !== 'image/jpg' &&
      imageType !== 'image/gif' &&
      imageType !== 'image/svg+xml';
    const isInvalid = invalidType;

    const reader = new FileReader();
    if (invalidType) {
      // this.store.dispatch(new OpenModal('Archivo invalido.'));
      this.group.logo = undefined;
      return;
    }
    if (invalidSize) {
      // this.store.dispatch(
      //   new OpenModal('El maximo de peso soportado por archivo es 1mb.')
      // );
      this.group.logo = undefined;
      return;
    }

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.previewMainImage = this.sanitizer.bypassSecurityTrustUrl(
        reader.result as string
      );
    };
  }

  isValidForm(group: Group) {
    const emptyName = group.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del grupo es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.group = {
      name: '',
      description: '',
      license_quantity: 0,
      tags: [],
      logo: undefined,
      primary_color: '#00CAA8',
      secondary_color: '#078CC7',
      font_color: '#FFFFFF',
    };
  }

  handleSubmit(redirect: boolean = false) {
    if (this.groupId) {
      if (this.isValidForm(this.group)) {
        this.groupService.updateGroup(this.group, this.groupId).subscribe({
          next: (response) => {
            console.log(response);
            this.router.navigate(['dashboard/grupos']);

            this.universalModalService.openModal(
              undefined,
              null,
              'Grupo editado exitosamente'
            );
          },
          error: ({ error }) => {
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);

            this.universalModalService.openModal(
              undefined,
              null,
              `Algo ha ocurrido ${htmlErrorsContent}`
            );
          },
        });
      }
    } else {
      if (this.isValidForm(this.group)) {
        this.groupService.createGroup(this.group).subscribe({
          next: (response) => {
            console.log(response);
            this.router.navigate(['dashboard/grupos']);

            this.universalModalService.openModal(
              undefined,
              null,
              'Grupo creado exitosamente'
            );
          },
          error: ({ error }) => {
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);

            this.universalModalService.openModal(
              undefined,
              null,
              `Algo ha ocurrido ${htmlErrorsContent}`
            );
          },
        });
      }
    }
  }

  toggleFileTypeImage() {
    this.isMainImageFile = !this.isMainImageFile;
  }

  handleInputImgClick() {
    this.inputImage.nativeElement.click();
  }

  public handleOpenModal(templateRef: TemplateRef<any>): void {
    this.universalModalService
      .openModal(templateRef, null)
      .subscribe((response) => console.log(response));
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }
}
