<section layoutContent class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="profileSheet.name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre del perfil"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="profileSheet.description"
                    name="internal_description"
                    id="internal_description"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1">Etiquetas </label>
                  <input
                    class="input"
                    [(ngModel)]="profileSheet.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-12 col-xxl-10">
            <div class="row">
              <div class="col-12">
                <ul>
                  <li
                    *ngFor="
                      let category of profileParameters.controls.parameters
                        .controls
                    "
                    class="category-container w-100 row mt-4"
                  >
                    <div
                      class="text-black m-0 ps-0 pb-3 mb-4 font-primary--b font-18"
                      style="border-bottom: 1px solid #000"
                    >
                      {{ category.value.label }}
                    </div>

                    <!-- Display Parameters -->
                    <ul
                      [formGroup]="category"
                      class="parameters-container"
                      style="flex-wrap: wrap"
                    >
                      <li
                        class="parameters-element"
                        *ngFor="
                          let parameterGroup of category.controls.parameters
                            .controls
                        "
                      >
                        <div
                          class="d-flex align-items-center justify-content-start"
                        >
                          <span>{{
                            parameterGroup.value.label | trimString : 35
                          }}</span>
                        </div>

                        <div
                          [formGroup]="parameterGroup"
                          class="d-flex align-items-center justify-content-start"
                          style="width: 80px"
                        >
                          <input
                            formControlName="value"
                            type="number"
                            class="input mt-1 w-100"
                          />
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
<ng-template #modalDeleteTemplateRef>
  <article
    class="modal-container-rounded d-flex flex-column align-items-center justify-content-center"
  >
    <h5 class="mb-2">¿Seguro desea eliminar este perfil?</h5>
    <div class="d-flex gap-4 mt-4">
      <button class="button-submit" (click)="handleDeleteProfileSheet()">
        Aceptar
      </button>
      <button class="button-dismiss-outlined" (click)="handleCloseModal()">
        Cancelar
      </button>
    </div>
  </article>
</ng-template>
