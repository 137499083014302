import { Activity } from 'src/app/models/activities/activity.models';
import { ActivityState } from 'src/app/store/activities/activity.state';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-activity-page',
  templateUrl: './activity-page.component.html',
  styleUrls: ['./activity-page.component.scss']
})

export class ActivityPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  activityId!: string
  activity!: Activity
  @Select(ActivityState.activities) activities$!: Observable<any>;

  constructor(
    private errorsService: ErrorsService,
    private activeRoute: ActivatedRoute,
    private title: Title
  ) {
    this.title.setTitle('Detalle de Pregunta | Backoffice - Growth Road')

    this.activeRoute.queryParams.subscribe((qp) => {
      this.activityId = this.activeRoute.snapshot.params['id']
    });
  }

  ngOnInit(): void {
    this.getActivity()
  }
  getActivity() {
    this.activities$.subscribe({
      next: (data) => {
        console.debug('data ', data)
        console.debug('this.activityId ', this.activityId)
        const filteredData = data.find((activity: Activity) => activity.id === this.activityId)
        if (filteredData) {
          this.activity = filteredData
        } else {
          console.debug('404')
        }
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }

}
