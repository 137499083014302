<main class="activities-page page">
  <!-- FORM CUSTOM CONTENT -->
  <ng-template #newCustomContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        <span *ngIf="!editMode">Nuevo</span>
        <span *ngIf="editMode">Editar</span>
        Contenido Personalizado
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
          <div class="col-6 d-flex flex-column my-3">
            <label for="order">Orden</label>
            <input class="input" [(ngModel)]="customContent.order" type="number" min="1" name="order" id="order"
              placeholder="1">
          </div>

          <div class="col-6 d-flex flex-column my-3">
            <label for="order">Columna</label>

            <select title="Columna" name="column" id="column" class="input" [(ngModel)]="customContent.left_aligned">
              <option [value]="false" selected>Derecha</option>
              <option [value]="true">Izquierda</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex flex-column">
            <label for="title">Título</label>
            <input class="input" type="text" id="title" [(ngModel)]="customContent.title" name="title"
              placeholder="Título principal">
          </div>

          <div class="col-12 d-flex flex-column mt-3">
            <label for="title">Descripción</label>

            <angular-editor [config]="editorConfig" [(ngModel)]="customContent.description" name="description"
              id="description">
            </angular-editor>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark bg-transparent text-red" (click)="modal.close()">Volver</button>
      <button *ngIf="!editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="createCustomContent()">
        <span class="text-white" *ngIf="!inProcess">
          Guardar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
      <button *ngIf="editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="editCustomContent()">
        <span class="text-white" *ngIf="!inProcess">
          Editar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
      <pre *ngIf="false">
        <!-- {{categoryId | json}} -->
        <!-- {{editMode | json}} -->
        <!-- {{customContent | json}} -->
      </pre>
    </div>
  </ng-template>

  <!-- CONTENT -->
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content class=>
      <div class="row align-items-center justify-content-between w-100">
        <div class="col">
          <h1 class="font-24 font-primary--eb">Contenido Personalizado</h1>
        </div>
        <div class="col-auto off">
          <button class="btn bg-green text-white" (click)="open(newCustomContent)" type="button">
            Añadir nuevo contenido
          </button>
        </div>
      </div>
    </div>
  </app-card>


  <section class="row mt-5">
    <div class="col-6">
      <div class="row">
        <div class="col-12 mt-3" [ngClass]="'order-'+item.order" *ngFor="let item of customContents.false">
          <app-card padding="27px 36px 79px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100"
            borderRadius="10px">
            <article card__content>
              <div class="d-flex align-items-start justify-content-between">
                <h2 class="font-18 font-bold mb-4">
                  {{item.order}}. {{item.title}}
                </h2>
                <div>
                  <button class="btn bg-danger text-white me-2" (click)="deleteCustomContent(item.id!)">
                    Eliminar
                  </button>
                  <button class="btn bg-warning text-white" (click)="editingCustomContent(item, newCustomContent)">
                    Editar
                  </button>
                </div>
              </div>
              <div style="line-height: 1.7;" [innerHtml]="item.description | safeHtml"></div>
            </article>
          </app-card>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12 mt-3" [ngClass]="'order-'+item.order" *ngFor="let item of customContents.true">
          <app-card padding="27px 36px 79px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100"
            borderRadius="10px">
            <article card__content>
              <div class="d-flex align-items-start justify-content-between">
                <h2 class="font-18 font-bold mb-4">
                  {{item.order}}. {{item.title}}
                </h2>
                <div>
                  <button class="btn bg-danger text-white me-2" (click)="deleteCustomContent(item.id!)">
                    Eliminar
                  </button>
                  <button class="btn bg-warning text-white" (click)="editingCustomContent(item, newCustomContent)">
                    Editar
                  </button>
                </div>
              </div>
              <div style="line-height: 1.7;" [innerHtml]="item.description | safeHtml"></div>
            </article>
          </app-card>
        </div>
      </div>
    </div>
  </section>
</main>