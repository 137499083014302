<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container" *ngIf="video; else callbackVideo">
    <app-video [options]="{
          fluid: true,
          aspectRatio: '16:9',
          autoplay: false,
          controls: true,
          sources: [{
            src: video,
            type: 'video/mp4' }]
          }">
    </app-video>
  </div>
  <ng-template #callbackVideo>
    <h2 class="font-primary text-grey font-16 opacity-50 text-center">
      Aun no ha provisto la url del video.
    </h2>
  </ng-template>
</div>
