import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Observable,
  Subscription,
  combineLatest,
  fromEvent,
  map,
  pairwise,
  startWith,
  takeUntil,
} from 'rxjs';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { DrawerService } from 'src/app/services/drawer-service.service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent implements OnInit, AfterViewInit {
  @ViewChild('footerContainer')
  public footerContainer!: ElementRef<HTMLElement>;
  @Input() footerConfig!: {
    submitButtonText: string;
    dismissButtonText?: string;
    deleteButtonText?: string;
  };
  @Input() transitionInConfig!: Partial<CSSStyleDeclaration>;
  @Input() transitionOutConfig!: Partial<CSSStyleDeclaration>;
  @Input() windowResizeListener!: Observable<number>;
  public isDrawerOpen = false;
  private drawerOpenSubscriptionRef!: Subscription;
  private drawerSubscriptionRef!: Subscription;

  constructor(
    private drawerService: DrawerService,
    private creationalPagesLayoutService: CreationalPagesLayoutService
  ) {}

  ngOnInit(): void {
    this.drawerSubscriptionRef = this.drawerService.isDrawerOpen.subscribe(
      (value) => (this.isDrawerOpen = value)
    );
  }

  ngAfterViewInit(): void {
    this.drawerOpenSubscriptionRef = combineLatest([
      this.drawerService.isDrawerOpen.pipe(startWith(true), pairwise()),
      this.windowResizeListener,
    ]).subscribe(([isDrawerOpen, windowInnerWidth]) => {
      const itWasOpened = isDrawerOpen.filter((value) => value).length !== 2;
      if (isDrawerOpen[1]) {
        this.drawerService.applyInAnimation(
          this.footerContainer,
          windowInnerWidth,
          itWasOpened ? this.transitionInConfig : {}
        );
      } else {
        this.drawerService.applyOutAnimation(
          this.footerContainer,
          windowInnerWidth,
          this.transitionOutConfig
        );
      }
    });
  }

  ngOnDestroy() {
    this.drawerSubscriptionRef.unsubscribe();
    this.drawerOpenSubscriptionRef.unsubscribe();
  }

  handleSubmit() {
    this.creationalPagesLayoutService.triggerSubmit = true;
  }

  handleDismiss() {
    this.creationalPagesLayoutService.triggerDismiss = true;
  }

  handleDelete() {
    this.creationalPagesLayoutService.triggerDelete = true;
  }
}
