import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-activity',
  templateUrl: './video-activity.component.html',
  styleUrls: ['./video-activity.component.scss']
})
export class VideoActivityComponent implements OnInit {
  @Input() video?: string
  @Input() statement: string = '¿lorem ipsum?'
  // videoUrl: string = 'https://grapi.growthroad.es:5555/noauth/video?vid=intro_voc&ext=mp4'

  constructor() { }

  ngOnInit(): void {
  }

}
