import { ElementRef, ViewChild } from '@angular/core';
import { InstitutionsService } from './../../../service/institutions.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MetaDataService } from 'src/app/services/metadata.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { UsersService } from 'src/app/services/users.service';

import { ModalContent } from 'src/app/components/core/modal/modal.component';
import { normalizeDate } from 'src/app/utils/normalizeDate';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

interface IUserDetail {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  tax_id: string | number | null;
  image_profile: string | null;
  is_subscribed: boolean;
  is_active: boolean;
  description: string;
  authored_at: string;
  preliminar_phase_completed: boolean;
  completed: boolean;
  user_type:
    | 'Particular'
    | 'Student'
    | 'Parent'
    | 'Teacher'
    | 'School'
    | 'Back_Office_Admin';
  users_organizations: Array<any>;
}

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss'],
})
export class EditUserPageComponent implements OnInit {
  // @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', {
    read: TemplateRef,
  })
  modalDeleteTemplateRef!: TemplateRef<any>;
  @ViewChild('modalSuccessTemplateRef', {
    read: TemplateRef,
  })
  modalSuccessTemplateRef!: TemplateRef<any>;
  public userId: string = '';
  isLoading: boolean = true;
  basicInfo!: any;
  emailToUpdate: string = '';
  imageProfile: { preview: any; file: any } = {
    preview: null,
    file: null,
  };
  errors: Array<{ title: string }> = [];
  subscriptionSelected!: boolean;
  isSubscribed: boolean = false;
  previewInfo!: any[];
  public confirmPassword: string = '';

  institutions: Array<{ id: string; name: string }> = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    private routeActivated: ActivatedRoute,
    private errorsService: ErrorsService,
    private userService: UsersService,
    private modalService: NgbModal,
    private meta: MetaDataService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Editar Usuario | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Editar usuario',
      headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
        deleteButtonText: 'Eliminar',
      },
    });
  }

  ngOnInit(): void {
    this.userId = this.routeActivated.snapshot.paramMap.get('id') ?? '';
    this._getUserDetails();
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.onSubmitUpdateBasicInfo();
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/usuarios']);
        }
      });
    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          console.log(this.modalDeleteTemplateRef);
          this.handleOpenModal(this.modalDeleteTemplateRef);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  isValidForm(user: any) {
    const emptyName = user.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({
        title: 'El nombre de la formación profesional es requerido.',
      });
      isValidForm = false;
    } else if (
      user.password &&
      user.password.length > 0 &&
      user.password !== this.confirmPassword
    ) {
      this.errors.push({
        title: 'Las contraseñas no coinciden.',
      });
      isValidForm = false;
    }

    if (!isValidForm) {
      window.scrollTo(0, 0);
    }

    return isValidForm;
  }
  _getUserDetails() {
    this.routeActivated.params.subscribe((params: any) => {
      this.userService.getUserDetails(params.id).subscribe({
        error: ({ error }) => {
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: (data: IUserDetail) => {
          this.imageProfile.preview =
            data.image_profile || 'assets/images/icons/icon_user_green.svg';
          this.subscriptionSelected = data.is_subscribed;
          this.isSubscribed = data.is_subscribed;

          this.basicInfo = {
            id: data.id,
            firstName: data.first_name,
            lastName: data.last_name,
            phone: data.phone,
            taxId: data.tax_id,
            description: data.description,
            email: data.email,
            userType: data.user_type,
            subscription: data.is_subscribed ? 'Plan Premium' : 'Plan Gratuito',
            phases: [
              {
                label: 'Fase preliminar',
                value: data.preliminar_phase_completed
                  ? 'Completado'
                  : 'No completado',
              },
              {
                label: 'Fase final',
                value: data.completed ? 'Completado' : 'No completado',
              },
            ],
            organization: data.users_organizations.length
              ? data.users_organizations[0].organization
              : null,
          };

          this.previewInfo = [
            {
              label: 'ID',
              data: String(data.id),
            },
            {
              label: 'Activo',
              data: data.is_active ? 'Si' : 'No',
            },
            {
              label: 'Tipo de usuario',
              data: this.meta.GET_USER_TYPES_DIC()[data.user_type].label,
            },
            {
              label: 'Creado',
              data: normalizeDate(data.authored_at, true),
            },
          ];

          this.isLoading = false;
        },
      });
    });
  }

  public deleteUser(userId: string) {
    this.handleCloseModal();
    this.userService.removeUser({ id: userId }).subscribe({
      complete: () => {
        // this.store.dispatch(new OpenModal('Usuario eliminado exitosamente.'));

        this.router.navigate(['dashboard/usuarios']);
        this.universalModalService.openModal(
          this.modalSuccessTemplateRef,
          null
        );
        // this.getUsers();
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        // this.store.dispatch(
        //   new OpenModal(
        //     'No se pudo eliminar el usuario, elimine los contenidos asociados.'
        //   )
        // );
      },
    });
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.imageProfile.file = file;

      const reader = new FileReader();
      reader.onload = () => (this.imageProfile.preview = reader.result);
      reader.readAsDataURL(file);
      this.onSubmitUpdateImageProfile();
    }
  }

  onSubmitUpdateBasicInfo() {
    const newBasicInfo = {
      first_name: this.basicInfo.firstName,
      last_name: this.basicInfo.lastName,
      phone: this.basicInfo.phone,
      tax_id: this.basicInfo.taxId || null,
      password: this.basicInfo.password,
      is_subscribed: this.subscriptionSelected,
      user_type: this.basicInfo.userType,
      description:
        this.basicInfo.description ||
        `Descripción de ${this.basicInfo.firstName} ${this.basicInfo.lastName}`,
      organization: this.basicInfo.organization,
    };

    if (this.isValidForm(newBasicInfo)) {
      this.isLoading = true;
      this.userService.updateUser(this.basicInfo.id, newBasicInfo).subscribe({
        error: ({ error }) => {
          console.error('updateUser error', error);
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: (data: any) => {
          this.basicInfo = {
            ...this.basicInfo,
            firstName: data.first_name,
            lastName: data.last_name,
            phone: data.phone,
            taxId: data.tax_id,
            description: data.description,
          };

          if (
            this.basicInfo.password &&
            this.basicInfo.password.length > 0 &&
            this.basicInfo.password === this.confirmPassword
          ) {
            this.userService
              .changePass(this.basicInfo.id, this.basicInfo.password)
              .subscribe((data) => {
                this.isLoading = false;
                this.router.navigate(['dashboard/usuarios']);
              });
          } else {
            this.router.navigate(['dashboard/usuarios']);
          }

          this.isLoading = false;
        },
      });
    }
  }

  onSubmitUpdateEmail() {
    this.isLoading = true;

    this.userService
      .updateEmail(this.basicInfo.id, this.emailToUpdate)
      .subscribe({
        error: () => {
          const errorMsg = {
            detail: 'No se pudo actualizar el correo electrónico',
          };
          const { htmlErrorsContent } =
            this.errorsService.handleErrors(errorMsg);

          this.emailToUpdate = '';
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: () => {
          this.openModal(
            'Correo enviado',
            `El correo <strong>${this.emailToUpdate}</strong> está en proceso de verificación. El usuario <strong>${this.basicInfo.firstName} ${this.basicInfo.lastName}</strong> debe verificar su correo electronico para terminar el proceso.`
          );
          this.emailToUpdate = '';
          this.isLoading = false;
        },
      });
  }

  onSubmitUpdateImageProfile() {
    this.isLoading = true;

    this.userService
      .updateImageProfile(this.basicInfo.id, this.imageProfile.file)
      .subscribe({
        error: ({ error }) => {
          console.error('updateImageProfile error', error);
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: (data: any) => {
          this.imageProfile.preview = data.image_profile;
          this.imageProfile.file = null;
          this.isLoading = false;
        },
      });
  }

  onSubmitUpdateSubscription() {
    this.isLoading = true;

    this.userService
      .updateIsSubscribed(
        this.basicInfo.id,
        this.subscriptionSelected,
        this.basicInfo.phone
      )
      .subscribe({
        error: ({ error }) => {
          console.error('updateIsSubscribed error', error);
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: (data: any) => {
          this.subscriptionSelected = data.is_subscribed;
          this.isSubscribed = data.is_subscribed;

          this.basicInfo = {
            ...this.basicInfo,
            taxId: data.tax_id,
            description: data.description,
            subscription: data.is_subscribed ? 'Plan Premium' : 'Plan Gratuito',
          };
          this.isLoading = false;
        },
      });
  }

  public handleOpenModal(templateRef: TemplateRef<any>): void {
    this.universalModalService
      .openModal(templateRef, null)
      .subscribe((response) => console.log(response));
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  openModal(
    title: string,
    content: string,
    type: string = 'error',
    options?: {},
    fnAction?: () => void
  ) {
    const modalRef = this.modalService.open(ModalContent, {
      centered: true,
      ...options,
    });
    console.debug();
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }

  updateStudentToProfessor() {
    if (
      confirm('Esta acción es irreversible, ¿Esta seguro de querer realizarla?')
    ) {
      this.isLoading = true;

      const newBasicInfo = {
        user_type: 'School',
      };

      this.userService.updateUser(this.basicInfo.id, newBasicInfo).subscribe({
        error: ({ error }) => {
          console.error('updateUser error', error);
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.openModal('Algo ha ocurrido', htmlErrorsContent);
          this.isLoading = false;
        },
        next: (data: any) => {
          this.basicInfo = {
            ...this.basicInfo,
            firstName: data.first_name,
            lastName: data.last_name,
            phone: data.phone,
            taxId: data.tax_id,
            description: data.description,
          };

          this.isLoading = false;
          this._getUserDetails();
        },
      });
    }
  }
}
