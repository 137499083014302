import {
  ICategoryNormalized,
  IParameterToFormat,
  OptionActivityFromBack,
} from '../models/activities/option.models';
import { ICareerUniversity } from '../models/career.model';

// MAP OPTIONS TO RETURN CATEGORIES AND PARAMETERS
export const CATEGORY_DIC: any = {
  interest_options: {
    label: 'Opciones de Interés',
    id: 'interest-option',
  },

  intelligences: {
    label: 'Inteligencias',
    id: 'intelligence',
  },

  learning_styles: {
    label: 'Aprendiendo Estilos',
    id: 'learning-style',
  },

  avks: {
    label: 'AVKS',
    id: 'avk',
  },

  personalities: {
    label: 'Personalidades',
    id: 'personality',
  },

  professional_fields: {
    label: 'Campos Profesionales',
    id: 'professional-field',
  },

  multiple_intelligences: {
    label: 'Inteligencias Multiples',
    id: 'multiple-intelligence',
  },

  vocational_interests: {
    label: 'Intereses Vocacionales',
    id: 'vocational-interest',
  },

  competencies: {
    label: 'Competencias',
    id: 'competence',
  },

  jazzdoos: {
    label: 'Jazzdoo',
    id: 'jazzdoo',
  },

  wefs: {
    label: 'Wef',
    id: 'wef',
  },

  scheins: {
    label: 'Schein',
    id: 'schein',
  },

  peripheral_vision_objectivity: {
    label: 'Objetividad de Visión Periférica',
    value: 'PERIPHERAL_VISION_OBJECTIVITY',
  },

  paternalism: {
    label: 'Paternalismo',
    value: 'PATERNALISM',
  },

  synthesis_capacity: {
    label: 'Capacidad de Síntesis',
    value: 'SYNTHESIS_CAPACITY',
  },

  naturalness: {
    label: 'Naturalidad',
    value: 'NATURALNESS',
  },

  learning_amplitude: {
    label: 'Amplitud de Aprendizaje',
    value: 'LEARNING_AMPLITUDE',
  },

  subjectivity: {
    label: 'Subjetividad',
    value: 'SUBJECTIVITY',
  },

  strategic_thinking: {
    label: 'Pensamiento Estratégico',
    value: 'STRATEGIC_THINKING',
  },
};

const PARAMETERS_DIC: any = {
  // vocational_interests category
  technical: { label: 'Técnico' },
  investigator: { label: 'Investigador' },
  entrepreneur: { label: 'Emprededor' },
  communicator: { label: 'Comunicador' },
  analyst: { label: 'Analista' },
  artistic: { label: 'Artistico' },

  // multiple_intelligences category
  linguistic: { label: 'Lingüístico' },
  mathematical_logician: { label: 'Lógico-matemático' },
  space: { label: 'Espacial' },
  cinematic_bodily: { label: 'Cinemático-corporal' },
  musical: { label: 'Musical' },
  intrapersonal: { label: 'Intrapersonal' },
  interpersonal: { label: 'Interpersonal' },
  naturalist: { label: 'Naturalista' },

  // professional_fields category
  manual_technician: { label: 'Técnico-manual' },
  research_scientist: { label: 'Científico-investigador' },
  creative_artistic: { label: 'Artístico-creativo' },
  social_healthcare: { label: 'Social-asistencial' },
  business_persuasive: { label: 'Empresarial-persuasivo' },
  management: { label: 'Administración-gestión' },
  virtual_digital_streamer: { label: 'Virtual/Digital-Streamer' },

  // personalities category
  perfectionist: { label: 'Perfeccionista' },
  altruistic: { label: 'Altruista' },
  actives: { label: 'Activos' },
  affective: { label: 'Social-asistencial' },
  rational: { label: 'Racional' },
  skeptical: { label: 'Escéptico' },
  enthusiastic: { label: 'Entusiasta' },
  leader: { label: 'Líder' },
  mediator: { label: 'Mediador' },

  // avks category
  auditory: { label: 'Auditivo' },
  visual: { label: 'Visual' },
  kinesthetic: { label: 'Kinestésico' },

  // learning_styles category
  active: { label: 'Activo' },
  thoughtful: { label: 'Reflexivo' },
  theoretical: { label: 'Teórico' },
  pragmatic: { label: 'Pragmático' },

  // intelligences category
  self_awareness: { label: 'Autoconciencia' },
  empathy: { label: 'Empatía' },
  self_confidence: { label: 'Autoconfianza' },
  motivation: { label: 'Motivación' },
  self_control: { label: 'Autocontrol' },
  social_skills: { label: 'Habilidades Sociales' },

  // interest_options category
  technical_with_academic_option: { label: 'Técnico con opción académica' },
  technical_inclination: { label: 'Inclinación técnica' },

  // competencies category
  self_study: { label: 'Autoaprendizaje' },
  self_critical_capacity: { label: 'Capacidad de autocrítica' },
  information_search: { label: 'Busqueda de información' },
  time_organization: { label: 'Organización del tiempo' },
  // social_skills: { label: 'Habilidades sociales' }, KEY already exists
  project_management: {
    label: 'Gestión de proyectos y resolución de problemas',
  },
  adaptability: { label: 'Capacidad de Adaptación' },
  communication_skills: { label: 'Habilidades de comunicación' },
  information_treatment: {
    label: 'Tratamiento de la información y competencia dígital',
  },
  informatic_training: { label: 'Nivel de formación en APP informaticas' },
  it_level_used: { label: 'Nivel de uso de las TIC' },
};

// Format options to return options with categories and parameters formated

// How Format a Option
//  Entry Point = formatOptionWithCategoriesAndParameters(option: OptionActivityFromBack)

// Steps to format a option
// 1. normalizeCategoryOfOption
// 2. normalizeCategories
// - 2.1 formatParameter

let parametersIdState = ''; // refactor this
let parentActivityPonderable: any = false; // refactor this

export const normalizeCategories = (
  option: OptionActivityFromBack | ICareerUniversity
) => {
  const optionEntries = Object.entries(option);
  // @ts-ignore - Manual Check
  const onlyCategoriesAllowed = optionEntries.filter(
    (option) => CATEGORY_DIC[option[0]]
  );
  const categoriesNormalized = onlyCategoriesAllowed.map((category) => {
    return {
      id: category[0],
      // @ts-ignore - Manual Check
      label: CATEGORY_DIC[category[0]].label,
      parameters: category[1].map((parameters: any) => ({
        ...parameters,
        value: 1,
      })),
    };
  });

  return categoriesNormalized;
};

export const formatParameter = (parameterToFormat: IParameterToFormat) => {
  const parametersEntries = Object.entries(parameterToFormat);
  parametersIdState = parametersEntries[0][1];
  // @ts-ignore - Manual Check
  const onlyParametersAllowed = parametersEntries.filter((ponderation: any) =>
    Boolean(PARAMETERS_DIC[ponderation[0]])
  );
  //console.log('Parent Activity Ponderable ', parentActivityPonderable);
  const parametersMapped = onlyParametersAllowed.map((parameter: any) => ({
    id: parameter[0],
    originalBdId: parametersIdState,
    // @ts-ignore - Manual Check
    label: PARAMETERS_DIC[parameter[0]].label,
    //In Case value is Zero set 1 as default
    value: parameter[1] == '0' ? 1 : parameter[1],
  }));
  console.log('FORMAT');
  console.log({
    parametersEntries,
    parametersIdState,
    onlyParametersAllowed,
    parametersMapped,
  });
  return parametersMapped;
};

const normalizeParametersOfCategories = (
  categoriesNormalized: ICategoryNormalized[]
) => {
  return categoriesNormalized.map((category) => {
    const parametersNormalized = category.parameters.flatMap(formatParameter);

    return {
      ...category,
      parameters: parametersNormalized,
    };
  });
};

export const formatOptionCategoriesAndParameters = (
  option: OptionActivityFromBack
) => {
  const categoriesNormalized = normalizeCategories(option);
  // const categoriesAndParametersNormalized =
  //   normalizeParametersOfCategories(categoriesNormalized);
  console.log({ categoriesNormalized });
  return {
    id: option['id'],
    name: option['name'],
    body: option['body'] || 'Opción por defecto',
    is_system_created: option['is_system_created'],
    question: option['question'],
    parametersId: parametersIdState,
    categories: categoriesNormalized,
    showCategories: false,
    is_correct: option['is_correct'],
    toggleShowCategories: function () {
      this.showCategories = !this.showCategories;
    },
  };
};

export const formatActivityWithOptions = (
  activity: any,
  isActivityWithQuestionPercentage: boolean = false
) => {
  parentActivityPonderable = activity.is_ponderable;
  const optionsWithoutDefault = isActivityWithQuestionPercentage
    ? activity.options
    : activity.options.filter((option: any) => option.name !== 'Peso');
  const optionWithCategoriesAndParametersFormated = optionsWithoutDefault.map(
    formatOptionCategoriesAndParameters
  );

  return {
    ...activity,
    is_ponderable: isActivityWithQuestionPercentage
      ? true
      : activity.is_ponderable,
    options: optionWithCategoriesAndParametersFormated,
  };
};

export const getMismatchPassword = (value: string) => {
  return (
    value.match('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$') &&
    value.length > 0
  );
};

export const getMismatchEmail = (value: string) => {
  return (
    value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) && value.length > 0
  );
};
