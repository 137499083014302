<div class="activity-wrapper">
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
                fluid: true,
                aspectRatio: '16:9',
                autoplay: false,
                controls: true,
                sources: [{
                  src: video,
                  type: 'video/mp4' }]
                }">
    </app-video>
  </div>
  <div class="list">
    <div class="form">
      <input class="list__input input bg-white" [(ngModel)]="newValue" type="text" placeholder="Escribe algo...">
      <button class="list__button btn bg-green text-white" (click)="addItem()">Pulsa ENTER para añadir</button>
    </div>

    <ul class="items">
      <li class="item" *ngFor="let value of values">
        <app-card padding="18px 22px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="max-content"
          borderRadius="10px">
          <article class="badge" card__content>
            <p class="badge__title">
              {{value.title}}
            </p>
            <button type="button" (click)="removeItem(value.id)" title="Eliminar [{{value.title}}]"
              class="btn badge__button">
              <img class="img-fluid icon" src="/assets/images/icons/icon_close.svg" alt="">
            </button>
          </article>
        </app-card>
      </li>
    </ul>


  </div>
</div>
