<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>



<div class="activities-page page" *ngIf="!isLoading && !career">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <span class="font-25 font-bold">
        ¡Hubo un error al cargar un cupón!
      </span>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading && career">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <app-modal></app-modal>

    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        {{career.title}}
      </h1>
    </article>
  </app-card>

  <form class="career-wrapper">
    <!-- CARRERA -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="font-24 font-primary--eb">Información de carrera</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <input [(ngModel)]="careerToEdit.description_order" id="descriptionOrder" name="descriptionOrder"
                    type="text" class="input w-100" />
                </div>

                <div>
                  <label for="descriptionLeftAligned" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="descriptionLeftAligned" id="descriptionLeftAligned" class="input w-100"
                    [(ngModel)]="careerToEdit.description_left_aligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- COLORS -->
            <div class="row mt-3 justify-content-end">
              <div class="col-auto">
                <div class="row">
                  <div class="col-auto">
                    <label for="primary_color" class="text-start w-100 font-bold">Color Primario</label>

                    <input type="color" id="primary_color" name="primary_color"
                      [(ngModel)]="careerToEdit.primary_color" />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">

                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Acrónimo</label>

                    <input id="acronym" name="acronym" type="text" class="input w-100" placeholder="Ejemplo: FP"
                      [(ngModel)]="careerToEdit.acronym" />
                  </div>
                  <!-- Title -->
                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Titulo</label>

                    <input id="title" name="title" type="text" class="input w-100"
                      placeholder="Ingrese titulo de la carrera universitaria" [(ngModel)]="careerToEdit.title" />
                  </div>

                  <!-- Type -->
                  <div class="col-4">
                    <label for="title" class="text-start w-100 font-bold">Tipo </label>

                    <select id="type" name="type" class="w-100" [(ngModel)]="careerToEdit.type">
                      <option value="" selected disabled hidden>Elije un tipo</option>

                      <option [value]="CAREER_TYPE['TRAINING_CIRCLE']">
                        {{CAREER_TYPE_LABEL[CAREER_TYPE['TRAINING_CIRCLE']]}}
                      </option>

                      <option [value]="CAREER_TYPE['UNIVERSITY_DEGREE']">
                        {{CAREER_TYPE_LABEL[CAREER_TYPE['UNIVERSITY_DEGREE']]}}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label for="description" class="text-start w-100 font-bold">Descripción</label>

                <angular-editor [config]="editorConfig" [(ngModel)]="careerToEdit.description" name="description"
                  id="description" class="mt-2"></angular-editor>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>

    <!-- OUTPUT PROFILE -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100 ">
            <div class="d-flex align-items-center justify-content-between">
              <h2 class="font-24 font-primary--eb">Información del perfil de salida</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                                                  {{formErrors?.['title']?.label}}
                                                </span> -->

                  <input [(ngModel)]="careerToEdit.output_order" id="outputOrder" name="outputOrder" type="text"
                    class="input w-100" />
                </div>

                <div>
                  <label for="outputLeftAligned" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="outputLeftAligned" id="outputLeftAligned" class="input w-100"
                    [(ngModel)]="careerToEdit.output_left_aligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="outputProfile" class=" font-bold">Titulo</label>

                  <angular-editor [(ngModel)]="careerToEdit.output_title" [config]="editorConfig" name="outputTitle"
                    id="outputProfile" class="mt-2"></angular-editor>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex flex-column">
                  <label for="outputProfile" class=" font-bold">Descripción</label>

                  <angular-editor [config]="editorConfig" [(ngModel)]="careerToEdit.output_profile" name="outputProfile"
                    id="outputProfile2" class="mt-2"></angular-editor>
                </div>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>

    <!-- JOB -->
    <div class="row mt-4">
      <div class="col-12">
        <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
          <div card__content class="w-100">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="font-24 font-primary--eb">Información del empleo</h2>

              <div class="d-flex gap-2">
                <div>
                  <label for="title" class="text-start w-100 font-bold">Orden</label>

                  <!-- <span class="text-start d-block font-14 text-error" *ngIf="formErrors?.['title']">
                                              {{formErrors?.['title']?.label}}
                                            </span> -->

                  <input [(ngModel)]="careerToEdit.job_order" id="jobOrder" name="jobOrder" type="text"
                    class="input w-100" />
                </div>

                <div>
                  <label for="title" class="text-start w-100 font-bold">Alineación </label>

                  <select title="Columna" name="jobLeftAligned" id="jobLeftAligned" class="input w-100"
                    [(ngModel)]="careerToEdit.job_left_aligned">
                    <option [value]="false" selected>Derecha</option>
                    <option [value]="true">Izquierda</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="job" class="text-start w-100 font-bold">Titulo</label>

                <angular-editor [config]="editorConfig" [(ngModel)]="careerToEdit.job_title" name="job" id="job"
                  class="mt-2"></angular-editor>
              </div>

              <div class="col-md-6 mt-3 md:mt-0">
                <label for="description" class="text-start w-100 font-bold">Descripción</label>

                <angular-editor [config]="editorConfig" [(ngModel)]="careerToEdit.job" name="description"
                  id="description" class="mt-2"></angular-editor>
              </div>
            </div>
          </div>
        </app-card>
      </div>
    </div>
  </form>

  <div class="career-wrapper mt-3">
    <!-- Categories -->
    <app-card width="100%" borderRadius="10px" padding="20px 40px" class="coupon-wrapper"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div class="row" card__content>
        <div class="col-12 mt-3">
          <div *ngIf="parametersOfCareer.length">
            <span class="font-18 font-primary--b">
              Categorías
            </span>

            <ul class="p-0">
              <li *ngFor="let category of parametersOfCareer" class="w-100 row category-list">
                <div class="text-black mb-3 font-primary--b">
                  {{category.label}}
                </div>

                <!-- Display Parameters -->
                <ul class="d-flex pb-2 ps-4" style="flex-wrap: wrap;">
                  <li class="w-100 md:w-auto mt-2 me-0 md:me-3"
                    *ngFor="let parameter of category.parameters;let idx = index; trackBy: getIndex">
                    <div class="d-flex align-items-center justify-content-start">
                      <span>{{parameter.label}}</span>
                    </div>

                    <div class="d-flex align-items-center justify-content-start">
                      <input name="{{ parameter.label }}-parameter-{{idx}}" type="number" class="input mt-1" min="0.00"
                        step="0.01" maxlength="6" [(ngModel)]="parameter.value" />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </app-card>
  </div>

  <div class="row justify-content-end">
    <div class="col-auto">
      <!-- CREATE BUTTON -->
      <button type="submit" class="btn font-btn bg-red font-26 mt-3" (click)="onSubmit()">
        <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>

        <span class="text-white" *ngIf="!isLoading">
          Actualizar
        </span>
      </button>
    </div>
  </div>
</main>