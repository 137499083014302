import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ReplaySubject, takeUntil } from 'rxjs';
import { Page } from 'src/app/models/page.models';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { PageFormComponent } from '../../core/page-form/page-form.component';
import { ModalComponent } from '../../core/modal/modal.component';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  selector: 'app-new-activity-page',
  templateUrl: './new-activity-page.component.html',
  styleUrls: ['./new-activity-page.component.scss'],
})
export class NewActivityPageComponent implements OnInit {
  @ViewChild('newPageForm') newPageForm!: PageFormComponent;
  @ViewChild(ModalComponent) modal!: ModalComponent;
  private contentId!: number;
  private themeId!: number;
  private subthemeId!: number;
  public activity: any = {
    questionary: null,
    id: '',
    internal_name: '',
    description: '',
    background_color: '#00CAA8',
    gradient_color: '#078CC7',
    font_color: '#ffffff',
    video_url: null,
    order: 0,
    image: null,
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    is_preliminar_limit: false,
    time: 0,
  };

  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private store: Store,
    public router: Router,
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private route: ActivatedRoute
  ) {
    this.title.setTitle('Nueva actividad | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear actividad',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.contentId = parseInt(this.route.snapshot.paramMap.get('contentId')!);
    this.themeId = parseInt(this.route.snapshot.paramMap.get('themeId')!);
    this.subthemeId = parseInt(this.route.snapshot.paramMap.get('subthemeId')!);
    console.log(this.subthemeId);
  }

  ngAfterViewInit(): void {
    console.debug('{newPageForm}<PageFormComponent> ', this.newPageForm);
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree(true);
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate([
            `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades`,
          ]);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  /**
   * Fields form validations
   * @returns
   */
  isValidForm(page: Page): boolean {
    const emptyName = page.name === '';
    const emptyType = page.type === 'subtheme';
    const emptyBgImage = this.newPageForm.page.image === null;
    const emptyVideo = this.newPageForm.page.video_url === null;

    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del subtema es requerido.' });
      isValidForm = false;
    }

    if (emptyType) {
      this.errors.push({ title: 'El tipo de tema es requerido.' });
      isValidForm = false;
    }

    if (!emptyBgImage) {
      if (
        !this.newPageForm.isMainImageFile &&
        !this.isImgLink(this.newPageForm.page.image!)
      ) {
        this.errors.push({
          title: 'La url de la imagen de fondo no es valida.',
        });
        isValidForm = false;
      }
    }

    if (!emptyVideo) {
      if (!this.isVideoLink(this.newPageForm.page.video_url!)) {
        this.errors.push({ title: 'La url del video no es valida.' });
        isValidForm = false;
      }
    }

    return isValidForm;
  }

  addToThree(redirect: boolean = false) {
    if (this.isValidForm(this.newPageForm.page)) {
      let toastMessage = '';
      // this.newPageForm.page.questionary = String(
      //   this.newPageForm.questionaryId
      // );

      this.newPageForm.page.parent = this.subthemeId;
      toastMessage = `Actividad '${this.activity.internal_name}' creada.`;

      this.pageService
        .newPage({
          ...this.activity,
          ...this.newPageForm.page,
          parent: this.subthemeId,
          type: 'activity',
          is_activity: true,
        })
        .subscribe({
          next: (value: any) => {
            this.newPageForm.resetForm();
            this.store.dispatch(new OpenModal(toastMessage));
            if (redirect) {
              this.router.navigate([
                `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades`,
              ]);
            }
          },
          error: ({ error }) => {
            alert(
              'Algo ha ocurrido, asegurese de que la actividad no está publicado.'
            );
          },
        });
    }
  }

  isImgLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return (
      url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gim) !=
      null
    );
  }

  isVideoLink(url: string | SafeUrl) {
    if (typeof url !== 'string') return false;
    return url.match(/^http[^\?]*.(mp4|flv|mkv|avi|m3u8)(\?(.*))?$/gim) != null;
  }
}
