import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IFilters } from 'src/app/models/userListFilters.model';
import { convertToFormData } from '../utils/globals';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private API_URL = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  // private normalizeDatesRange({ dates }: IFilters) {
  //   dates?.created.before && `&authored_at_before=${dates.created.before}`
  //   dates?.created.after  `&authored_at_after=${dates.created.after}` : ''
  // }

  getUsers(page = '1', searchText = '', filters?: IFilters): Observable<any> {
    console.log('UsersService getUsers filters', filters);
    const URL = `${this.API_URL}/users/`;

    return this.httpClient
      .get(URL)
      .pipe(catchError((error) => throwError(() => error)));
  }

  getUserDetails(id: any): Observable<any> {
    const URL = `${this.API_URL}/users/${id}/`;

    return this.httpClient
      .get(URL)
      .pipe(catchError((error) => throwError(() => error)));
  }

  createUser(userToCreate: any): Observable<any> {
    const URL = `${this.API_URL}/users/`;

    // const formData = convertToFormData(userToCreate)

    return this.httpClient
      .post(URL, userToCreate)
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateUser(userId: number, userData: any): Observable<any> {
    const URL = `${this.API_URL}/users/${userId}/`;

    const payload = { ...userData };

    delete payload.password;

    return this.httpClient
      .patch(URL, { ...payload })
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateEmail(userId: number, newEmail: string) {
    const URL = `${this.API_URL}/users/change-email`;

    return this.httpClient
      .post(URL, { id: userId, new_email: newEmail }, { observe: 'response' })
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateImageProfile(userId: number, image_profile: File) {
    const URL = `${this.API_URL}/users/${userId}/`;
    const formData = convertToFormData({ image_profile });

    return this.httpClient
      .patch(URL, formData)
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateIsSubscribed(
    userId: number,
    isSubscribed: boolean,
    phone: string
  ): Observable<any> {
    const URL = `${this.API_URL}/users/${userId}/`;

    return this.httpClient
      .patch(URL, { is_subscribed: isSubscribed })
      .pipe(catchError((error) => throwError(() => error)));
  }

  updateFreeSuscriptionUser(
    userId: number,
    productId: number
  ): Observable<any> {
    const URL = `${environment.apiUrl}/free-suscription/`;

    return this.httpClient
      .post(URL, { user: userId, product: productId })
      .pipe(catchError((error) => throwError(() => error)));
  }

  toggleIsActiveUser(user_data: any): Observable<any> {
    const URL = `${this.API_URL}/users/toogle-is-active/${user_data.id}`;

    return this.httpClient
      .put(URL, user_data)
      .pipe(catchError((error) => throwError(() => error)));
  }

  importUsers(users: any) {
    const url = environment.apiUrl + '/users/upload-csv';

    var formData: any = new FormData();

    // MANDATORY
    formData.append('users_file', users);

    return this.httpClient.post(url, formData).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateUserRol(userId: number, userData: any): Observable<any> {
    const URL = `${this.API_URL}/users/${userId}/`;

    return this.httpClient
      .patch(URL, { ...userData })
      .pipe(catchError((error) => throwError(() => error)));
  }

  verifyUser(user_data: any) {
    const URL = `${this.API_URL}/users/${user_data.id}/verify`;

    return this.httpClient
      .put(URL, { verified: true })
      .pipe(catchError((error) => throwError(() => error)));
  }

  changePass(id: number, password: string) {
    const URL = `${this.API_URL}/users/change-password/${id}/admin`;

    return this.httpClient
      .put(URL, {
        password: password,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  removeUser(user_data: any) {
    const URL = `${this.API_URL}/users/${user_data.id}/`;

    return this.httpClient
      .delete(URL)
      .pipe(catchError((error) => throwError(() => error)));
  }
}
