<section layoutContent class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="questionary.internal_name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre del contenido"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                    <sup
                      class="cursor-pointer"
                      title="Define la posición de la página en la lista, '0' indica que la página va de ultima."
                      >📄</sup
                    >
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="questionary.internal_description"
                    name="internal_description"
                    id="internal_description"
                  ></textarea>
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <app-multi-select-dropdown
                    label="Compartir"
                    template="BLUE"
                    [selectedOptions]="selectedOrgs"
                    [options]="organizations"
                    (selectionChange)="handleSelectionChange($event)"
                  ></app-multi-select-dropdown>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Etiquetas
                    <sup
                      class="cursor-pointer"
                      title="Define la posición de la página en la lista, '0' indica que la página va de ultima."
                      >📄</sup
                    >
                  </label>
                  <input
                    class="input"
                    [(ngModel)]="questionary.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="title" class="label mx-2 mb-1">Título (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="questionary.name"
                    name="title"
                    type="text"
                    id="title"
                    placeholder="Ingrese el título del programa"
                  />
                </div>
              </div>
              <div class="d-flex flex-column mt-4">
                <label for="description" class="label mx-2 mb-1"
                  >Descripción</label
                >
                <!-- <textarea name="description" id="description" [(ngModel)]="page.description"
						placeholder="Ingrese la descripción de la página" id="" cols="30" rows="10"></textarea> -->
                <angular-editor
                  [config]="editorConfig"
                  [(ngModel)]="questionary.description"
                  name="description"
                  id="description"
                >
                </angular-editor>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center mt-4">
                  <label for="type" class="label mx-2 mb-1">Publicar</label>
                  <div class="col-auto">
                    <!-- (click)="togglePreliminarLimit()" -->
                    <app-toggle-switch
                      [active]="questionary.is_published"
                      (click)="togglePublishQuestionary()"
                    ></app-toggle-switch>
                    <span
                      class="d-inline-block mx-2 font-12 font-primary--b opacity-50"
                      *ngIf="questionary.is_published"
                      >Si</span
                    >
                    <span
                      class="d-inline-block mx-2 font-12 font-primary--b opacity-50"
                      *ngIf="!questionary.is_published"
                      >No</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-flex align-items-center mt-4">
                  <label for="type" class="label mx-2 mb-1"
                    >Generar reporte</label
                  >
                  <div class="col-auto">
                    <app-toggle-switch
                      [active]="questionary.has_report"
                      (click)="toggleReportQuestionary()"
                    ></app-toggle-switch>
                    <span
                      class="d-inline-block mx-2 font-12 font-primary--b opacity-50"
                      *ngIf="questionary.has_report"
                      >Si</span
                    >
                    <span
                      class="d-inline-block mx-2 font-12 font-primary--b opacity-50"
                      *ngIf="!questionary.has_report"
                      >No</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
