export type Order = 'asc' | 'desc';

export function sortByProperty<T>(
  data: T[],
  property: keyof T,
  order: Order = 'asc'
): T[] {
  return data.sort((a, b) => {
    if (a[property] < b[property]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });
}
