import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Page } from 'src/app/models/page.models';

@Component({
  selector: 'app-preview-three',
  templateUrl: './preview-three.component.html',
  styleUrls: ['./preview-three.component.scss']
})
export class PreviewThreeComponent {
  @Input() three: Array<any> = []
  @Input() loading: boolean = false

  constructor() { }

  toggleShowPages(page: Page) {
    page.showPages = !page.showPages
  }

}
