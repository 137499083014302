import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  constructor(private meta: Meta) {
    this.meta.addTags([
      { name: 'robots', content: 'noindex' },
      { name: 'robots', content: 'nofollow' },
      { name: 'googlebot', content: 'noindex' },
    ])
  }

  ngOnInit(): void {}
}
