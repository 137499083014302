import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';

import { normalizePages, SeasonNormalized } from 'src/app/utils/pages';
import { PageService } from './../../../services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { ErrorsService } from 'src/app/services/errors.service';

@Component({
  selector: 'app-pages-page',
  templateUrl: './pages-page.component.html',
  styleUrls: ['./pages-page.component.scss'],
})
export class PagesPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  seasons: SeasonNormalized[] = [];
  isLoading: boolean = true;

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private store: Store,
    private title: Title
  ) {
    this.title.setTitle('Páginas | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.getSeasons();
  }

  getSeasons() {
    this.isLoading = true;
    this.pageService.getSeasons().subscribe({
      next: (data: any) => {
        this.seasons = data;
        this.isLoading = false;
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.isLoading = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  getNextPage(currentPageIndex: number) {
    let nextPage = null;
    if (this.seasons[currentPageIndex + 1]) {
      nextPage = this.seasons[currentPageIndex + 1];
    }
    return nextPage;
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deletePage(id);
    }
  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.getSeasons();
        this.store.dispatch(new OpenModal('Programa eliminado exitosamente.'));
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  toggleQuestionary(questionary: any) {
    if (confirm('¿Está seguro de realizar esta accion?')) {
      this.isLoading = true;
      questionary.is_published = !questionary.is_published;
      this.pageService.updateQuestionary(questionary).subscribe({
        next: (data) => {
          this.getSeasons();
          this.isLoading = false;
        },
        error: (error) => {
          console.debug(error.error);
          questionary.is_published = !questionary.is_published;
          if (error.hasOwnProperty('error') && error.error.length) {
            this.store.dispatch(
              new OpenModal(`
            Ha ocurrido un error:
            ${error.error[0]}
            `)
            );
          } else {
            this.store.dispatch(
              new OpenModal(`
            Ha ocurrido un error, pongase en contacto con el soporte`)
            );
          }
          this.isLoading = false;
        },
      });
    }
  }

  duplicateQuestionaryHandler(questionaryId: number) {
    if (confirm('Esta seguro de realizar esta acción')) {
      this.isLoading = true;
      this.pageService.duplicateQuestionaryById(questionaryId).subscribe({
        next: (data) => {
          this.isLoading = false;
          this.getSeasons();
          this.store.dispatch(
            new OpenModal('Cuestionario duplicado exitosamente.')
          );
        },
        error: ({ error }) => {
          this.isLoading = false;
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.modal.open('Algo ha ocurrido', htmlErrorsContent);
        },
      });
    }
  }
}
