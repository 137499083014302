import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimString',
})
export class TrimStringPipe implements PipeTransform {
  transform(value: string | File, maxLength: number = 40): string | File {
    if (!value) return '';

    if (value instanceof File) return value;

    // Remove HTML tags
    const plainText = value.replace(/<\/?[^>]+(>|$)/g, '');

    // Trim the string and append ellipsis if necessary
    return plainText.length > maxLength
      ? plainText.substring(0, maxLength) + '...'
      : plainText;
  }
}
