import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Iinstitution } from '../models/institution';

@Injectable({
  providedIn: 'root'
})
export class InstitutionsService {

  constructor(private httpClient: HttpClient) { }

  getInstitutions(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/organizations/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  newInstitution(institution: Iinstitution): Observable<any> {
    var formData: any = new FormData();

    formData.append('name', institution.name);
    formData.append('primary_color', institution.primary_color);
    formData.append('secondary_color', institution.secondary_color);
    formData.append('license_quantity', institution.license_quantity);
    if (institution.logo) {
      formData.append('logo', institution.logo);
    }

    return this.httpClient.post(`${environment.apiUrl}/organizations/`, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  deleteInstitution(institutionId: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/organizations/${institutionId}/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  getInstitutionById(institutionId: string): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}/organizations/${institutionId}/`).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }

  updateInstitution(institution: Iinstitution, logoEdition:boolean): Observable<any> {
    var formData: any = new FormData();

    formData.append('name', institution.name);
    formData.append('primary_color', institution.primary_color);
    formData.append('secondary_color', institution.secondary_color);
    formData.append('license_quantity', institution.license_quantity);

    if (institution.logo && logoEdition) {
      formData.append('logo', institution.logo);
    }

    return this.httpClient.put(`${environment.apiUrl}/organizations/${institution.id}/`, formData).pipe(
      catchError(error => {
        return throwError(() => error);
      })
    )
  }


}
