import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MenuOption } from 'src/app/models/menuOption.models';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../modal/modal.component';
import { DrawerService } from 'src/app/services/drawer-service.service';
import {
  Observable,
  Subscription,
  fromEvent,
  merge,
  startWith,
  map,
  filter,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChildren('routerElement') routerElement!: any;
  @ViewChild('drawerButton') drawerButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('secondDrawerButton')
  secondDrawerButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('openDrawerIcon') openDrawerIcon!: ElementRef<SVGPathElement>;
  @ViewChild('openSecondDrawerIcon')
  openSecondDrawerIcon!: ElementRef<SVGPathElement>;
  @ViewChild('closeDrawerIcon') closeDrawerIcon!: ElementRef<SVGPathElement>;

  @ViewChild('navbarWrapper') navbarWrapper!: ElementRef<HTMLDivElement>;

  appUrl: string;
  options: Array<MenuOption> = [
    {
      link: '/dashboard/inicio',
      slug: 'pages',
      icon: '/assets/images/icons/icon_home.svg',
      title: 'Inicio',
    },
    {
      link: '/dashboard/pages/programas',
      slug: 'pages',
      icon: '/assets/images/icons/icon_program.svg',
      title: 'Programas',
    },
    {
      link: '/dashboard/pages/contenidos',
      slug: 'pages',
      icon: '/assets/images/icons/icon_content.svg',
      title: 'Contenido',
    },
    {
      link: '/dashboard/grupos',
      slug: 'teams',
      icon: '/assets/images/icons/icon_team_compressed.svg',
      title: 'Grupos',
    },
    {
      link: '/dashboard/usuarios',
      slug: 'users',
      icon: '/assets/images/icons/icon_user.svg',
      title: 'Usuarios',
    },
    {
      link: '/dashboard/informes',
      slug: 'reportes',
      icon: '/assets/images/icons/icon_career_university.svg',
      title: 'Informes',
    },
    {
      link: '/dashboard/instituciones',
      slug: 'institutions',
      icon: '/assets/images/icons/icon_category.svg',
      title: 'Instituciones',
      dropDownOptions: [
        {
          link: '/dashboard/instituciones/perfil',
          slug: 'perfil',
          icon: '/assets/images/icons/icon_profile_new.svg',
          title: 'Perfil',
        },
        {
          link: '/dashboard/instituciones/universidad',
          slug: 'universidad',
          icon: '/assets/images/icons/icon_university.svg',
          title: 'Universidad ',
        },
        {
          link: '/dashboard/instituciones/grado-universitario',
          slug: 'grado-universitario',
          icon: '/assets/images/icons/icon_orientacion.svg',
          title: 'Grado Universitario ',
        },
        {
          link: '/dashboard/instituciones/profesional',
          slug: 'profesional',
          icon: '/assets/images/icons/icon_professional_formation.svg',
          title: 'Formacion Profesional',
        },
        {
          link: '/dashboard/instituciones/jazzdoos',
          slug: 'jazzdoos',
          icon: '/assets/images/icons/icon_jzd.svg',
          title: 'Jazzdoo',
        },
        {
          link: '/dashboard/instituciones/parametros',
          slug: 'parametros',
          icon: '/assets/images/icons/icon_selector.svg',
          title: 'Parámetros',
        },
      ],
    },
    {
      link: '/dashboard/configuracion',
      slug: 'configuracion',
      icon: '/assets/images/icons/icon_settings.svg',
      title: 'Configuraciones',
    },
  ];

  brandLogo?: string | undefined;
  showDrawer: boolean = true;
  showSecondDrawer: boolean = false;
  public childrenOptions: MenuOption['dropDownOptions'] = [];
  private debouncedDrawerSubscriptionRef!: Subscription;
  private debouncedDrawerSubscriptionColorRef!: Subscription;
  private drawerOpenSubscription!: Subscription;

  constructor(
    private cookieService: CookieService,
    private errorsService: ErrorsService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private drawerService: DrawerService
  ) {
    this.appUrl = environment.appUrl;
  }

  ngOnInit(): void {
    this.initializeLogo();
    this.initializeDrawerState();
    this.subscribeToChildRoutes();
  }

  ngAfterViewInit(): void {
    this.subscribeToDrawerEvents();
    this.handleDrawerButtonColor();

    this.debouncedDrawerSubscriptionRef = this.drawerService.isDrawerOpen
      .pipe(startWith(true))
      .subscribe((isDrawerOpen) => {
        if (isDrawerOpen) {
          this.drawerService.applyInAnimation(this.drawerButton, 0, {
            transitionDelay: '300ms',
            transitionDuration: '100ms',
          });
        } else {
          this.drawerService.applyOutAnimation(this.drawerButton, 0, {
            transitionDelay: '30ms',
            transitionDuration: '100ms',
          });
        }
      });
  }

  ngOnDestroy() {
    this.debouncedDrawerSubscriptionRef.unsubscribe();
    this.debouncedDrawerSubscriptionColorRef.unsubscribe();
    this.drawerOpenSubscription.unsubscribe();
  }

  private initializeLogo(): void {
    this.brandLogo = this.isImage(localStorage.getItem('brandlogo')!)
      ? localStorage.getItem('brandlogo')!
      : undefined;
  }

  private initializeDrawerState(): void {
    this.drawerService.setDrawerOpen = this.showDrawer;
  }

  retriggerNavigation(option: MenuOption): void {
    const currentUrl = this.router.url;

    if (option.dropDownOptions && this.showSecondDrawer) {
      return;
    }

    if (currentUrl.startsWith(option.link) && currentUrl !== option.link) {
      this.router.navigateByUrl(option.link, { skipLocationChange: false });
    }
  }

  private subscribeToChildRoutes(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        map((event) => (event as NavigationStart).url)
      )
      .subscribe((activeRoute) => {
        this.childrenOptions =
          this.options.find((option) => activeRoute.includes(option.link))
            ?.dropDownOptions || [];
      });
  }

  private subscribeToDrawerEvents(): void {
    this.drawerOpenSubscription = this.drawerService.isDrawerOpen.subscribe(
      (value) => {
        this.animateDrawer(value);
      }
    );
  }

  private animateDrawer(isOpen: boolean): void {
    const transitionDuration = '300ms';
    const transitionDelay = '60ms';
    console.log({ isOpen });
    if (isOpen) {
      this.drawerService.applyInAnimation(this.navbarWrapper, 0, {
        width: this.drawerService.drawerWidth + 'px',
        transitionProperty: 'width',
        transitionDelay: transitionDelay,
        transitionDuration: transitionDuration,
      });

      this.openDrawerIcon.nativeElement.style.transitionDuration =
        transitionDuration;
      this.openDrawerIcon.nativeElement.style.transitionDelay = transitionDelay;
    } else {
      this.drawerService.applyOutAnimation(this.navbarWrapper, 0, {
        width: '0px',
        transitionProperty: 'width',
        transitionDelay: '20ms',
        transitionDuration: '70ms',
      });

      this.openDrawerIcon.nativeElement.style.transitionDuration = '70ms';
      this.openDrawerIcon.nativeElement.style.transitionDelay = '20ms';
    }
  }

  private handleDrawerButtonColor(): void {
    this.debouncedDrawerSubscriptionColorRef =
      this.changeDrawerBtnColor().subscribe((eventType) => {
        this.updateDrawerButtonIconColor(
          eventType,
          this.openDrawerIcon,
          this.closeDrawerIcon
        );
      });
  }

  private changeDrawerBtnColor(): Observable<string> {
    return merge(
      fromEvent(this.drawerButton.nativeElement, 'mouseover').pipe(
        map(() => 'mouseover')
      ),
      fromEvent(this.drawerButton.nativeElement, 'mouseout').pipe(
        map(() => 'mouseout')
      )
    );
  }

  private updateDrawerButtonIconColor(
    eventType: string,
    openIcon: ElementRef<SVGPathElement>,
    closeIcon: ElementRef<SVGPathElement>
  ): void {
    const fillColor = eventType === 'mouseover' ? '#fff' : '#000';
    if (openIcon) openIcon.nativeElement.setAttribute('fill', fillColor);
    if (closeIcon) closeIcon.nativeElement.setAttribute('fill', fillColor);
  }

  isImage(url: string): boolean {
    return /jpg|jpeg|png|webp|avif|gif|svg/.test(url);
  }

  logoutHandler(): void {
    const refreshToken = { refresh: this.cookieService.get('bo_refresh') };
    this.authService.logout(refreshToken).subscribe({
      complete: () => {
        this.router.navigate(['/']);
      },
      next: () => {
        this.cookieService.delete('bo_access', '/');
        this.cookieService.delete('bo_refresh', '/');
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  toggleShow(): void {
    this.showDrawer = !this.showDrawer;
    this.drawerService.setDrawerOpen = this.showDrawer;
  }

  toggleOffSecondDrawer(): void {
    this.showSecondDrawer = false;
  }

  toggleShowSecondDrawer(): void {
    this.showSecondDrawer = !this.showSecondDrawer;
  }
}
