import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'any',
})
export class SearchBarListService {
  private searchInput$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  private filterSelected$: BehaviorSubject<string> =
    new BehaviorSubject<string>('Más reciente');
  private isDropdownOpen$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor() {}

  public setSearchInput(value: string) {
    this.searchInput$.next(value);
  }

  public setFilterSelected(value: string) {
    this.filterSelected$.next(value);
  }

  public setIsDropdownOpen(value: boolean) {
    this.isDropdownOpen$.next(value);
  }

  public getSearchInput(): Observable<string> {
    return this.searchInput$.asObservable();
  }

  public getFilterSelected(): Observable<string> {
    return this.filterSelected$.asObservable();
  }

  public getIsDropdownOpen(): Observable<boolean> {
    return this.isDropdownOpen$.asObservable();
  }

  public concatPropertiesValues = (
    item: { [key: string]: string | number },
    properties: string[]
  ): string => {
    return properties
      .reduce((prev, acc) => prev + this.customToString(item[acc]), '')
      .toLowerCase();
  };

  private customToString(value: unknown): string {
    const isStringable = typeof value === 'string' || typeof value === 'number';

    if (isStringable) {
      return value.toString();
    }

    return '';
  }
}
