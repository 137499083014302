import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Iquestionary } from 'src/app/models/questionary.models';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  Observable,
  ReplaySubject,
  concatMap,
  finalize,
  from,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { Store } from '@ngxs/store';

interface Item {
  label: string;
  value: number; // Assuming ID is a number, change type if necessary
}

function areIdsEqual(ids: number[], items: Item[]): boolean {
  // Check if the lengths are equal
  if (ids.length !== items.length) {
    return false;
  }

  // Create a set of IDs for quick lookup
  const idsSet = new Set(ids);

  // Check if every item's value exists in the idsSet
  for (const item of items) {
    if (!idsSet.has(item.value)) {
      return false;
    }
  }

  return true;
}

function getIdsToDelete(ids: number[], items: Item[]): number[] {
  const itemValues = items.map((item) => item.value);
  return ids.filter((id) => !itemValues.includes(id));
}

function getIdsToAdd(ids: number[], items: Item[]): number[] {
  const idsSet = new Set(ids);
  return items
    .filter((item) => !idsSet.has(item.value))
    .map((item) => item.value);
}

@Component({
  templateUrl: './edit-questionaries-page.component.html',
  styleUrls: ['./edit-questionaries-page.component.scss'],
})
export class EditProgramsPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  public three: any = [];
  public errors: Array<{ title: string }> = [];
  public inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  public questionary: Iquestionary = {
    id: undefined,
    name: '',
    internal_name: '',
    is_published: false,
    has_report: false,
    order: 1,
    organization: [],
    report_types: null,
    description: null,
    internal_description: null,
    tags: [],
    shared_with: [],
  };
  public institutions: any = null;
  public report_types: Array<any> = [];
  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      ['insertImage', 'insertVideo', 'customClasses'],
    ],
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private pageService: PageService,
    private errorsService: ErrorsService,
    public router: Router,
    public route: ActivatedRoute,
    public institutionsService: InstitutionsService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private store: Store
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Editar programa',
      headerTitleIcon: '/assets/images/icons/icon_edit_black.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
        deleteButtonText: 'Eliminar',
      },
    });
  }

  ngOnInit(): void {
    this.institutionsService
      .getInstitutions()
      .pipe(
        tap((response) => {
          this.organizations = response.map((org: any) => ({
            label: org.name,
            value: org.id,
          }));
        }),
        switchMap(() => this.route.params),
        concatMap((objectParams: Record<string, string>) => {
          console.log(objectParams);
          return this.pageService.getQuestionaryById(objectParams['id']);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        this.questionary = response;
        const orgsSelected = new Map();

        response.organization.forEach((orgId: number) => {
          orgsSelected.set(orgId, true);
        });

        this.organizations.forEach((org) => {
          if (orgsSelected.get(org.value)) {
            this.selectedOrgs.push(org);
          }
        });
      });
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          this.handleEditQuestionary(true);
        }
      });

    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          this.router.navigate(['dashboard/pages/programas']);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if (value) {
          this.confirmDelete(
            { stopPropagation: function () {} } as Event,
            this.questionary.id as string
          );
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public togglePublishQuestionary(): void {
    this.questionary.is_published = !this.questionary.is_published;
  }

  public toggleReportQuestionary(): void {
    this.questionary.has_report = !this.questionary.has_report;
  }

  public handleSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ): void {
    this.selectedOrgs = selectedOptions;
  }

  public isValidForm(questionary: Iquestionary) {
    const emptyName = questionary.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del cuestionario es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  public resetForm(): void {
    this.questionary = {
      name: '',
      internal_name: '',
      is_published: false,
      has_report: false,
      order: 1,
      description: null,
      internal_description: null,
      tags: [],
      shared_with: [],
    };
  }

  public handleEditQuestionary(redirect: boolean = false): void {
    if (this.isValidForm(this.questionary)) {
      this.inProcess = true;
      this.pageService
        .editQuestionary({
          ...this.questionary,
          organization: this.selectedOrgs.map((org: any) => org.value),
          report_types: [],
          type: 'questionary',
        })
        .pipe(
          switchMap((response: any) => {
            if (this.selectedOrgs.length > 0) {
              return this.pageService.addProgramToOrg(
                this.selectedOrgs.map((org: any) => org.value),
                response.id
              );
            } else if (
              this.selectedOrgs.length === 0 &&
              Array.isArray(this.questionary.organization) &&
              this.questionary.organization.length > 0
            ) {
              return from(this.questionary.organization).pipe(
                concatMap((orgId: any) =>
                  this.pageService.deleteProgramFromOrg(orgId, response.id)
                ),
                finalize(() => {
                  this.router.navigate(['/dashboard/pages/programas']);
                  this.inProcess = false;
                })
              );
            }
            return new Observable((subscriber) => subscriber.next(response));
          })
        )
        .subscribe({
          next: (value: any) => {
            this.resetForm();
            if (
              this.selectedOrgs.length === 0 &&
              Array.isArray(this.questionary.organization) &&
              this.questionary.organization.length > 0
            ) {
            } else {
              if (redirect) {
                this.router.navigate(['/dashboard/pages/programas']);
              }
            }
            this.inProcess = false;
          },
          error: ({ error }) => {
            const { htmlErrorsContent } =
              this.errorsService.handleErrors(error);
            this.modal.open('Algo ha ocurrido', htmlErrorsContent);
            this.inProcess = false;
          },
        });
    }
  }

  public getInstitutions(): void {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public getReportsTypes(): void {
    this.inProcess = true;
    this.pageService.getReportTypes().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.report_types = data;
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public confirmDelete(event: Event, id: string | number): void {
    // TODO IMPROVE UI
    event.stopPropagation();
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deleteQuestionary(id);
    }
  }

  public deleteQuestionary(id: string | number): void {
    this.pageService.deleteQuestionary(id as string).subscribe({
      complete: () => {
        this.router.navigate(['dashboard/pages/programas']);
        this.store.dispatch(new OpenModal('Programa eliminado exitosamente.'));
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.store.dispatch(
          new OpenModal(
            'No se pudo eliminar el programa, intente de nuevo mas tarde.'
          )
        );
      },
    });
  }
}
