<div
  class="card-wrapper"
  [ngStyle]="cardWrapperStyles()"
  [class.dialog]="dialog"
  [class.card-wrapper--hover]="enableHover"
>
  <div class="icon-dialog" *ngIf="dialog"></div>
  <div
    class="card"
    [ngStyle]="cardStyles()"
    [class.card-wrapper--hover]="enableHover"
  >
    <div class="card__header">
      <ng-content select="[card__header]"></ng-content>
    </div>
    <div class="card__content">
      <ng-content select="[card__content]"></ng-content>
    </div>
    <div class="card__footer"></div>
  </div>
</div>
