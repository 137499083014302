<div class="list-el-container mb-3 col-12 disable-li-style">
  <section class="position-absolute">
    <app-card
      [enableHover]="false"
      [cursorPointer]="false"
      borderRadius="10px 10px 0 0"
      height="126px"
      padding="20px 20px 20px 10px"
      [backgroundColor]="'#EDEDED'"
    >
      <article class="d-flex align-items-center h-100" card__content>
        <section
          class="d-flex align-items-center justify-content-between row-el-container"
        >
          <div class="row ps-4 gap-4 col-10">
            <ng-content select="[left-side]"></ng-content>
            <div class="col-6 d-flex align-items-center gap-4">
              <div class="input-search">
                <input
                  class="input w-100"
                  name="name"
                  type="text"
                  id="name"
                  placeholder="Buscar"
                  (input)="_onChangeSearch($event)"
                />
                <img src="/assets/images/icons/icon_search.svg" />
              </div>
            </div>
            <ng-content select="[right-side]"></ng-content>
          </div>
        </section>
      </article>
    </app-card>
  </section>
</div>
