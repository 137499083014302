<div *ngIf="loading">
  <app-loading color="#de377c"></app-loading>
  <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">Por favor, espere un momento</h2>
</div>
<ul class="p-0 three-component" *ngIf="!loading">
  <li *ngFor="let questionary of three">
    {{questionary.order}}. {{questionary.name}}
    <ul>
      <li class="chapter" *ngFor="let chapter of questionary.pages | orderBy:'order'">
        <span class="opacity-50">{{chapter.order}}. </span> {{chapter.name}}
        <small class="font-12 opacity-50">(ID{{chapter.id}})</small>
        <div class="d-inline toggle-tab cursor-pointer" *ngIf="chapter.pages.length" (click)="toggleShowPages(chapter)">
          <img *ngIf="chapter.showPages" class="opacity-50" src="/assets/images/icons/icon_arrow_up.svg" alt="">
          <img *ngIf="!chapter.showPages" class="opacity-50" src="/assets/images/icons/icon_arrow_down.svg" alt="">
        </div>
        <ul *ngIf="chapter.showPages">
          <li *ngFor="let theme of chapter.pages | orderBy:'order'">
            <span class="opacity-50">{{theme.order}}.</span> {{theme.name}}
            <small class="font-12 opacity-50">(ID{{theme.id}})</small>
            <ul>
              <li *ngFor="let subtheme of theme.pages | orderBy:'order'">
                <span class="opacity-50">{{subtheme.order}}.</span> {{subtheme.name}}
                <small class="font-12 opacity-50">(ID{{subtheme.id}})</small>
                <ul>
                  <li *ngFor="let activity of subtheme.pages | orderBy:'order'">
                    <span class="opacity-50">{{activity.order}}.</span> {{activity.name}}
                    <small class="font-12 opacity-50">(ID{{activity.id}})</small>
                    <ul>
                      <li *ngFor="let question of activity.questions | orderBy:'order'">
                        <span class="opacity-50">{{question.order}}.</span> {{question.name}}
                        <small class="font-12 opacity-50">(ID{{question.id}})</small>

                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
