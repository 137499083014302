import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-content',
  templateUrl: './modal.component.html'
})
export class ModalContent {
  @Input() content: any;
  @Input() title: any;
  @Input() type: any;
  @Input() actionLabel?: string
  @Input() fnAction?: () => void;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  close() {
    this.modalService.dismissAll(ModalContent);
  }
}

@Component({ selector: 'app-modal', templateUrl: './modal.component.html' })
export class ModalComponent implements OnInit {
  @Input() title!: string
  @Input() content!: string
  @Input() type?: string
  @Input() actionLabel?: string
  @Input() fnAction?: () => void;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  close() {
    this.modalService.dismissAll(ModalContent);
  }

  open(title: string, content: string, type: string = 'error', options?: {}, fnAction?: () => void) {
    const modalRef = this.modalService.open(ModalContent, { centered: true, ...options });
    console.debug()
    if (fnAction !== undefined) {
      modalRef.componentInstance.fnAction = fnAction;
    }
    modalRef.componentInstance.content = content;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.type = type;
  }
}
