import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IPhraseToUpdate } from '../models/phrase.model';
import { convertToFormData } from '../utils/globals';
import { ICustomContent } from '../models/customContent.models';

@Injectable({ providedIn: 'root' })

export class PhrasesService {
  API_URL: string = environment.apiUrl

  constructor(private httpClient: HttpClient) { }

  getPhrases(): Observable<any> {
    const URL = `${this.API_URL}/reports/phrases/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  getPhrasesById(id: number): Observable<any> {
    const URL = `${this.API_URL}/reports/phrases/${id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  updatePhrase(id: number | null, phraseToUpate: IPhraseToUpdate): Observable<any> {
    const URL = `${this.API_URL}/reports/phrases/${id}/`
    const isUrl = typeof phraseToUpate.icon === 'string'

    if (isUrl) {
      phraseToUpate.icon_url = phraseToUpate.icon
      delete phraseToUpate.icon
    }

    const formData = convertToFormData(phraseToUpate)
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.put(URL, formData).pipe(catchError(handleErrors))
  }

  getPhrasesCategories() {
    const URL = `${this.API_URL}/reports/phrases-categories/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }

  newCustomContent(customContent: ICustomContent) {
    // ${customContent.category!}/
    const URL = `${this.API_URL}/reports/additional-content/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.post(URL, customContent).pipe(catchError(handleErrors))
  }

  editCustomContent(customContent: ICustomContent) {
    const URL = `${this.API_URL}/reports/additional-content/${customContent.id}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.put(URL, customContent).pipe(catchError(handleErrors))
  }
  removeCustomContent(customContentId: string) {
    const URL = `${this.API_URL}/reports/additional-content/${customContentId}/`
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.delete(URL).pipe(catchError(handleErrors))
  }

  getCustomContentByCategory(categoryId: string) {
    //
    const URL = `${this.API_URL}/reports/additional-content-by-category/${categoryId}/` //TODO: falta filtrar los contenidos por categoria
    const handleErrors = (error: any) => throwError(() => error);

    return this.httpClient.get(URL).pipe(catchError(handleErrors))
  }


}
