import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  Subscription,
  combineLatest,
  filter,
  fromEvent,
  map,
  pairwise,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';
import { DrawerService } from 'src/app/services/drawer-service.service';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  ResolveEnd,
  Router,
} from '@angular/router';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { fadeAnimation } from 'src/app/animations';

@Component({
  selector: 'app-creational-pages-layout',
  templateUrl: './creational-pages-layout.component.html',
  styleUrls: ['./creational-pages-layout.component.scss'],
  animations: [fadeAnimation],
})
export class CreationalPagesLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('headerContainer') headerContainer!: ElementRef<HTMLElement>;
  public headerTitle!: string;
  public headerTitleIcon!: string;
  public headerButtonText!: string;
  public headerButtonIcon!: string;
  public disableBreadcrum: boolean = false;
  public footerConfig!: {
    submitButtonText: string;
    dismissButtonText?: string;
    deleteButtonText?: string;
  };

  public hasBackButton: boolean = false;
  public isDrawerOpen = false;
  public windowResizeListener = fromEvent(window, 'resize').pipe(
    map((event: any) => event.target.innerWidth),
    startWith(window.innerWidth),
    shareReplay()
  );
  private drawerOpenSubscriptionRef!: Subscription;
  private drawerSubscriptionRef!: Subscription;
  public transitionInConfig: Partial<CSSStyleDeclaration> = {
    transitionProperty: 'width',
    transitionDuration: '255ms',
    transitionDelay: '290ms',
  };
  public transitionOutConfig: Partial<CSSStyleDeclaration> = {
    transitionProperty: 'width',
    transitionDuration: '205ms',
    transitionDelay: '0ms',
  };

  constructor(
    private drawerService: DrawerService,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    public breadcrumbService: BreadcrumbService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.drawerSubscriptionRef = this.drawerService.isDrawerOpen.subscribe(
      (value) => (this.isDrawerOpen = value)
    );

    if (
      this.router.url.includes('nuevo') ||
      this.router.url.includes('editar')
    ) {
      this.disableBreadcrum = true;
    } else {
      this.disableBreadcrum = false;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('nuevo') || event.url.includes('editar')) {
          this.disableBreadcrum = true;
        } else {
          this.disableBreadcrum = false;
        }
      }
    });

    this.creationalPagesLayoutService.layoutConfig.subscribe((config: any) => {
      this.headerTitle = config.headerTitle;
      this.headerTitleIcon = config.headerTitleIcon;
      this.headerButtonText = config.headerButtonText;
      this.headerButtonIcon = config.headerButtonIcon;
      this.footerConfig = config.footerConfig;
    });
  }

  ngAfterViewInit(): void {
    this.drawerOpenSubscriptionRef = combineLatest([
      this.drawerService.isDrawerOpen.pipe(startWith(true), pairwise()),
      this.windowResizeListener,
    ]).subscribe(([isDrawerOpen, windowInnerWidth]) => {
      console.log(isDrawerOpen, windowInnerWidth);
      const itWasOpened = isDrawerOpen.filter((value) => value).length !== 2;

      if (isDrawerOpen[1]) {
        this.drawerService.applyInAnimation(
          this.headerContainer,
          windowInnerWidth,
          itWasOpened ? this.transitionInConfig : {}
        );
      } else {
        this.drawerService.applyOutAnimation(
          this.headerContainer,
          windowInnerWidth,
          this.transitionOutConfig
        );
      }
    });
  }

  ngOnDestroy() {
    this.drawerSubscriptionRef.unsubscribe();
    this.drawerOpenSubscriptionRef.unsubscribe();
  }

  public emitHeaderButton() {
    this.creationalPagesLayoutService.triggerClickHeaderButton = true;
  }
}
