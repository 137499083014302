import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoupon, ICouponToCreate } from 'src/app/models/coupon.model';
import { FormModel } from 'src/app/models/form.model';
import { CouponsService } from 'src/app/services/coupons.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-edit-coupon-page',
  templateUrl: './edit-coupon-page.component.html',
  styleUrls: ['./edit-coupon-page.component.scss']
})
export class EditCouponPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  couponEditForm!: FormGroup
  coupon!: ICoupon
  isCouponActive: boolean = false
  formErrors: any = {}
  isLoading: boolean = true
  discountPercentage: number = 0

  constructor(
    private couponService: CouponsService,
    private errorsService: ErrorsService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Editar Cupón | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    const couponId = this.route.snapshot.paramMap.get('id') as string

    this.couponService.getCouponById(couponId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICoupon) => {
        this.coupon = data
        this.discountPercentage = this.coupon.discount_percentage
      },
      complete: () => {
        this.couponEditForm = this.initFrom()
        this.isLoading = false
        console.log('data cargada')
      }
    })
  }

  initFrom(): FormGroup {
    return this.fb.group<FormModel<ICouponToCreate>>({
      name: [ this.coupon.name || '', [ Validators.required ] ],
      code: [ this.coupon.code || '', [ Validators.required ] ],
      description: [ this.coupon.description || '', [ Validators.required ] ],
      discount_percentage: [ this.coupon.discount_percentage || 0, [ Validators.required ] ],
      available_quantity: [ this.coupon.available_quantity || 0, [ Validators.required ] ],
    })
  }

  onSubmit() {
    this.formErrors = null
    this.isLoading = true
    
    const { errors, totalErrors } = handleFormErrors(this.couponEditForm)
    
    if (totalErrors) {
      console.error('Edit Coupon Form Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    const couponToUpdate: ICouponToCreate = this.couponEditForm.value
    
    this.couponService.updateCoupon(this.coupon?.id || null, couponToUpdate).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICoupon) => this.coupon = data,
      complete: () => {
        this.isLoading = false
        this.router.navigate([`/dashboard/cupones/${this.coupon?.id}`])
      }
    })
  }

  toggleIsActive() {
    this.isCouponActive = !this.isCouponActive
  }
}

