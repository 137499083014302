<section>
  <div class="row">
    <div class="col">
      <div class="row">
        <!-- Activity type -->
        <div class="col-12 mb-3">
          <div
            class="d-flex flex-column"
            [class.disabled]="
              parentForm.get('typeSelected')?.value === 'JAZZDOO' && editMode
            "
          >
            <label for="activityType" class="label mx-2 mb-1">Tipo (*)</label>
            <select
              title="Tipo de pregunta"
              id="activityType"
              class="input"
              formControlName="typeSelected"
              [disabled]="
                parentForm.get('typeSelected')?.value === 'JAZZDOO' && editMode
              "
              [style]="{
                opacity:
                  parentForm.get('typeSelected')?.value === 'JAZZDOO' &&
                  editMode
                    ? 0.8
                    : 1
              }"
              [class.disabled]="
                parentForm.get('typeSelected')?.value === 'JAZZDOO' && editMode
              "
              [tabIndex]="
                parentForm.get('typeSelected')?.value === 'JAZZDOO' && editMode
                  ? -1
                  : 1
              "
            >
              <option [value]="null" selected disabled hidden>
                Selecciona un tipo
              </option>
              <option *ngFor="let type of types" [value]="type.code">
                {{ type.title }}
              </option>
            </select>
          </div>
        </div>

        <!-- Activity Statement -->
        <div class="col-12 mb-3">
          <div class="d-flex flex-column">
            <label for="activity-statement" class="label mx-2 mb-1">
              Enunciado
              <span *ngIf="parentForm.get('typeSelected')?.value !== 'WATCH'"
                >(*)</span
              >
            </label>
            <angular-editor
              [config]="editorConfig"
              formControlName="statement"
            ></angular-editor>
          </div>
        </div>

        <div class="col-12 d-flex">
          <div
            class="d-flex align-items-center"
            *ngIf="
              parentForm.get('typeSelected')?.value !== 'WATCH' &&
              parentForm.get('typeSelected')?.value !==
                'CONTINUOUS_NUMERIC_ANSWER'
            "
          >
            <label for="include-video" class="label mx-2">Añadir vídeo</label>
            <input
              class="d-none"
              type="checkbox"
              formControlName="includeVideo"
              id="include-video"
            />
            <app-radio
              [isActive]="parentForm.get('includeVideo')?.value"
              (click)="toggleIncludeVideo()"
              [color]="'#00CAA8'"
            ></app-radio>
          </div>
        </div>

        <!-- Video Url -->
        <div
          class="col-12 mt-3"
          *ngIf="
            (parentForm.get('includeVideo')?.valueChanges | async) === true ||
            (parentForm.get('typeSelected')?.valueChanges | async) === 'WATCH'
          "
        >
          <div class="d-flex flex-column">
            <label for="video-url" class="label mx-2 mb-1"
              >Enlace del vídeo</label
            >
            <input
              class="input"
              formControlName="video"
              type="text"
              id="video-url"
              placeholder="URL del vídeo"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Preview new activity -->
  </div>
</section>
