import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  concatMap,
  finalize,
  from,
  Observable,
  ReplaySubject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { MetaDataService } from 'src/app/services/metadata.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { toPlural, toSingular } from 'src/app/utils/categoriesFormatters';
import { CATEGORY_DIC } from 'src/app/utils/options';

type CategoriesParsed = {
  [key: string]: Record<string, number>;
};

type Category = {
  id: string;
  label: string;
  value: string;
  parameters: Array<{ id: string; label: string; value: number }>;
};
@Component({
  selector: 'app-new-profile-page',
  templateUrl: './new-profile-page.component.html',
  styleUrls: ['./new-profile-page.component.scss'],
})
export class NewProfilePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  public profileSheetId!: string;

  public categories = this.metaService
    .getAllParameters()
    .filter(
      ({ category_string_id }) =>
        category_string_id === 'wef' ||
        category_string_id === 'schein' ||
        category_string_id === 'vocational-interest'
    )
    .map((category) => ({
      ...category,
      parameters: category.parameters.map((param) => ({
        ...param,
        value: 1,
      })),
    }));

  profileSheet: any = {
    name: '',
    description: '',
    tags: [],
  };

  public profileParameters: FormGroup<{
    parameters: FormArray<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >;
  }> = this.formBuilder.group({
    parameters: this.formBuilder.array<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >([]),
  });
  institutions: any = null;
  report_types: Array<any> = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public reportService: ReportService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModal: UniversalModalService,
    private metaService: MetaDataService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private universalModalService: UniversalModalService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear perfil',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.profileSheetId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';
    console.log('PROFILE SHEET', this.profileSheetId);
    this.getInstitutions();

    this.categories.forEach((category) => {
      const categoriesFormArray = this.profileParameters.get(
        'parameters'
      ) as FormArray;

      const categoryID = toSingular(category.category_string_id);

      const categoryGroup = this.formBuilder.group({
        id: [categoryID],
        label: [category.category_name],
        value: [categoryID],
        parameters: this.formBuilder.array(
          this.getCategoryParameters(categoryID).map(
            (param: { id: string; label: string }) =>
              this.formBuilder.group({
                id: param.id,
                label: [param.label],
                value: [1],
              })
          )
        ),
      });

      categoriesFormArray.push(categoryGroup);
      categoriesFormArray.updateValueAndValidity();
    });

    if (this.profileSheetId) {
      this.reportService
        .getProfileSheet(this.profileSheetId)
        .subscribe((response: Record<string, any>) => {
          this.profileSheet = response;
          this.creationalPagesLayoutService.layoutConfig.next({
            headerTitle: 'Editar perfil',
            headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
            footerConfig: {
              submitButtonText: 'Guardar',
              dismissButtonText: 'Cancelar',
              deleteButtonText: 'Eliminar',
            },
          });
          const categories = Object.keys(response).forEach((key: string) => {
            if (toPlural(key) in CATEGORY_DIC) {
              const categoryGroup = this.profileParameters.get(
                'parameters'
              ) as FormArray;
              const categoryIndex = categoryGroup.controls.findIndex(
                (control) => this.dashToUnder(control.value.id) === key
              );
              if (categoryIndex > -1) {
                const parameters = Object.keys(
                  response[key] as Record<string, number>
                ).map((parameterKey) => ({
                  id: parameterKey,
                  value: (response[key] as Record<string, number>)[
                    parameterKey
                  ],
                }));

                categoryGroup.controls.forEach((categoryControl) => {
                  const paramFormArray = categoryControl.get(
                    'parameters'
                  ) as FormArray;

                  paramFormArray.controls.forEach((control) => {
                    parameters.forEach((param) => {
                      if (control.get('id')?.value === param.id) {
                        control.patchValue(param);
                      }
                    });
                  });
                });
              }
            }
          });
        });
    }
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree();
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/instituciones/perfil']);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getCategoryParameters(
    categoryId: string
  ): { id: string; label: string }[] {
    const allParametersByCategory = this.metaService.getAllParameters();

    const findCategory = allParametersByCategory.find(
      ({ category_string_id }) => category_string_id === categoryId
    );

    if (findCategory) {
      return findCategory.parameters;
    }

    return [];
  }
  getInstitutions() {
    this.inProcess = true;
    this.institutionsService.getInstitutions().subscribe({
      next: (data) => {
        this.inProcess = false;
        this.organizations = data.map((organization: any) => ({
          label: organization.name,
          value: organization.id,
        }));
      },
      error: (e) => {
        this.inProcess = false;
        console.debug(e);
      },
    });
  }

  public handleSelectionChange(selectedOptions: Array<Record<string, string>>) {
    this.selectedOrgs = selectedOptions.map((option: any) => option.value);
  }

  isValidForm(report: any) {
    const emptyName = report.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre del perfil es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.profileSheet = {
      name: '',
      description: null,
    };
  }

  addToThree() {
    this.inProcess = true;
    console.log(
      'Form',
      this.parseCategories(this.profileParameters.getRawValue().parameters)
    );
    if (this.isValidForm(this.profileSheet)) {
      if (this.profileSheetId) {
        this.reportService
          .updateProfileSheet(this.profileSheetId, {
            ...this.profileSheet,
            ...this.parseCategories(
              this.profileParameters.getRawValue().parameters
            ),
          })
          .subscribe({
            next: (value: any) => {
              this.resetForm();
              this.router.navigate(['/dashboard/instituciones/perfil']);
              this.universalModal.openModal(
                undefined,
                null,
                'Perfil actualizado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      } else {
        this.reportService
          .createProfileSheet({
            ...this.profileSheet,
            ...this.parseCategories(
              this.profileParameters.getRawValue().parameters
            ),
          })
          .subscribe({
            next: (value: any) => {
              this.resetForm();
              this.router.navigate(['/dashboard/instituciones/perfil']);
              this.universalModal.openModal(
                undefined,
                null,
                'Perfil creado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      }
    }
  }

  public dashToUnder(value: string): string {
    const includeDash = value.includes('-');

    if (includeDash) {
      return value.replace(/-/g, '_');
    }

    return value;
  }

  public underToDash(value: string): string {
    const includeUnder = value.includes('_');

    if (includeUnder) {
      return value.replace(/_/g, '-');
    }

    return value;
  }

  public parseCParameters(
    catParameters: Category['parameters']
  ): Record<string, number> {
    const parametersParsed: Record<string, number> = {};
    catParameters.forEach(
      (parameter: { id: string; label: string; value: number }) => {
        parametersParsed[parameter.id] = parameter.value;
      }
    );
    return parametersParsed;
  }

  parseCategories(categories: Array<Category>): CategoriesParsed {
    const payload: Record<string, Record<string, number>> = {};

    categories.forEach((category: Category) => {
      payload[this.dashToUnder(category.id)] = this.parseCParameters(
        category.parameters
      );
    });

    return payload;
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public handleDeleteProfileSheet() {
    this.handleCloseModal();
    this.reportService.deleteProfileSheet(this.profileSheetId).subscribe({
      next: () => {
        this.router.navigate(['dashboard/instituciones/perfil']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Perfil eliminado exitosamente'
        );
      },
      error: (error) => {
        this.inProcess = false;
        this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'Algo ha ocurrido' + error
        );
      },
    });
  }
}
