import { MetaDataService } from 'src/app/services/metadata.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';

@Component({
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent implements OnInit {
  maintenanceMode: boolean = false;
  inProcess: boolean = false;
  isMainImageFile: boolean = true;
  image?: string | SafeUrl | null;
  previewMainImage: any = null;
  defaultOrgId: number = 0;
  defaultRoomId: number = 0;

  constructor(
    private metaDataService: MetaDataService,
    protected sanitizer: DomSanitizer,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.getMaintenanceMode();
  }

  getMaintenanceMode() {
    this.inProcess = true;
    this.metaDataService.getSettings().subscribe({
      next: (data: any) => {
        this.inProcess = false;
        this.maintenanceMode = data.is_in_maintenance;
        this.defaultOrgId = data.default_organization_pk;
        this.defaultRoomId = data.default_room_pk;
        this.image = data.logo;
        localStorage.setItem('brandlogo', data.logo);
        if (data.logo) {
          this.previewMainImage = false;
        }
      },
      error: (e) => {
        this.inProcess = false;
        alert(
          'Ha ocurrido un error al, pongase en contacto con el administrador.'
        );
      },
    });
  }

  toggleMaintenanceMode() {
    this.maintenanceMode = !this.maintenanceMode;
  }

  _mainImageChange(event: any) {
    console.debug('hey');
    this.image = event.target.files[0];
    const imageType = event.target.files[0].type;

    const imageSize = event.target.files[0].size;
    const invalidSize = imageSize > 1000000; //?? 1mb === 1000000 bytes

    const invalidType =
      imageType !== 'image/png' &&
      imageType !== 'image/jpeg' &&
      imageType !== 'image/jpg' &&
      imageType !== 'image/gif' &&
      imageType !== 'image/svg+xml';
    const isInvalid = invalidType;

    const reader = new FileReader();
    if (invalidType) {
      this.store.dispatch(new OpenModal('Archivo invalido.'));
      this.image = null;
      return;
    }
    if (invalidSize) {
      this.store.dispatch(
        new OpenModal('El maximo de peso soportado por archivo es 1mb.')
      );
      this.image = null;
      return;
    }

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.previewMainImage = this.sanitizer.bypassSecurityTrustUrl(
        reader.result as string
      );
    };
  }

  toggleFileTypeImage() {
    this.isMainImageFile = !this.isMainImageFile;
  }

  submitSettings() {
    if (confirm('¿Está seguro de realizar esta acción?')) {
      this.inProcess = true;
      this.metaDataService
        .updateSettings({
          is_in_maintenance: this.maintenanceMode ? 'True' : 'False',
          logo: this.image,
          default_organization_pk: this.defaultOrgId,
          default_room_pk: this.defaultRoomId,
        })
        .subscribe({
          next: (data: any) => {
            this.store.dispatch(
              new OpenModal('Configuración actualizada exitosamente.')
            );
            this.inProcess = false;
            this.maintenanceMode = data.is_in_maintenance;
            localStorage.setItem('brandlogo', data.logo);
          },
          error: (error) => {
            this.store.dispatch(
              new OpenModal('Ha ocurrido un error al actualizar.')
            );
            this.inProcess = false;
            alert(
              'Ha ocurrido un error al, pongase en contacto con el administrador.'
            );
          },
        });
    }
  }
}
