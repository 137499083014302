import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { ReportService } from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-new-university-page',
  templateUrl: './new-university-page.component.html',
  styleUrls: ['./new-university-page.component.scss'],
})
export class NewUniversityPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  public organizations: Array<any> = [];
  public selectedOrgs: any = [];
  public selectedUniTypes: any = [];
  public universityId!: string;
  imageProfile: { preview: any; file: any } = {
    preview: 'assets/images/icons/icon_group_green.svg',
    file: null,
  };
  imageCover: { preview: any; file: any } = {
    preview: 'assets/images/icons/icon_group_cover.svg',
    file: null,
  };

  university: any = {
    name: '',
    university_description: '',
    location: '',
    web: '',
    price_range: '',
    cover_image: '',
    icon: '',
    university_type: '',
    output_profile: '',
    tags: [],
  };

  public universityTypes: Array<Record<string, string>> = [
    { label: 'Agnóstica', value: 'AGNOSTIC' },
    { label: 'Nacional', value: 'NATIONAL' },
    { label: 'Internacional', value: 'INTERNATIONAL' },
    { label: 'IB', value: 'IB' },
  ];

  public profileParameters: FormGroup<{
    parameters: FormArray<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >;
  }> = this.formBuilder.group({
    parameters: this.formBuilder.array<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >([]),
  });
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public reportService: ReportService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModal: UniversalModalService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private universalModalService: UniversalModalService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear universidad',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }

  ngOnInit(): void {
    this.universityId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    if (this.universityId) {
      this.reportService
        .getUniversity(this.universityId)
        .subscribe((response: Record<string, any>) => {
          this.university = response;
          this.imageProfile.preview = response['icon'];
          this.imageCover.preview = response['cover_image'];

          const selectedUniTypes = this.university.university_type.split(',');

          this.universityTypes.forEach((type) => {
            if (selectedUniTypes.find((id: string) => id === type['value'])) {
              this.selectedUniTypes.push(type);
            }
          });

          this.creationalPagesLayoutService.layoutConfig.next({
            headerTitle: 'Editar universidad',
            headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
            footerConfig: {
              submitButtonText: 'Guardar',
              dismissButtonText: 'Cancelar',
              deleteButtonText: 'Eliminar',
            },
          });
        });
    }
  }

  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree();
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/instituciones/universidad']);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete();
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.complete();
    this.destroyed$.next(true);
  }

  onFileChanged(event: any, imageType: 'profile' | 'cover' = 'profile') {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.imageProfile.file = file;
      if (imageType === 'profile') {
        this.university.icon = file;
      } else {
        this.university.cover_image = file
      }

      const reader = new FileReader();
      if (imageType === 'profile') {
        reader.onload = () => (this.imageProfile.preview = reader.result);
      } else {
        reader.onload = () => (this.imageCover.preview = reader.result);
      }
      reader.readAsDataURL(file);
      // this.onSubmitUpdateImageProfile();
    }
  }

  public concatOptions(options: Array<Record<string, string>>): string {
    return options.map((option) => option['value']).join(',');
  }

  public handleUniversityTypeSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    this.selectedUniTypes = selectedOptions;
    this.university.university_type = this.concatOptions(
      this.selectedUniTypes
    ) as any;
  }

  public handleSelectionChange(selectedOptions: Array<Record<string, string>>) {
    this.selectedOrgs = selectedOptions.map((option: any) => option.value);
  }

  isValidForm(report: any) {
    const emptyName = report.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({ title: 'El nombre de la universidad es requerido.' });
      isValidForm = false;
    }

    return isValidForm;
  }

  resetForm() {
    this.university = {
      name: '',
      description: null,
    };
  }

  addToThree() {
    this.inProcess = true;
    if (this.isValidForm(this.university)) {
      if (this.universityId) {
        this.reportService
          .updateUniversity(this.universityId, {
            ...this.university,
          })
          .subscribe({
            next: (value: any) => {
              this.resetForm();
              this.router.navigate(['/dashboard/instituciones/universidad']);
              this.universalModal.openModal(
                undefined,
                null,
                'Universidad editada exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      } else {
        this.reportService
          .createUniversity({
            ...this.university,
          })
          .subscribe({
            next: (value: any) => {
              this.resetForm();
              this.router.navigate(['/dashboard/instituciones/universidad']);
              this.universalModal.openModal(
                undefined,
                null,
                'Universidad creada exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      }
    }
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public handlePriceRange(value: number): void {
    this.university.price_range = value;
  }

  public handleDeleteuniversity() {
    this.handleCloseModal();
    this.reportService.deleteUniversity(this.universityId).subscribe({
      next: () => {
        this.router.navigate(['dashboard/instituciones/universidad']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Universidad eliminada exitosamente'
        );
      },
      error: (error) => {
        this.inProcess = false;
        this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'Algo ha ocurrido' + error
        );
      },
    });
  }
}
