<ng-template #content let-c="close" let-d="dismiss">
  <!-- <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div> -->
  <div class="modal-body">
    <p class="font-18 text-center font-primary--b">
      {{message}}

    </p>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Save click')">Save</button>
  </div> -->
</ng-template>

<!-- <button class="btn btn-lg btn-outline-primary" (click)="dispatchModal()">Launch demo modal</button>
<pre>
  {{showModal$ | async}}
</pre> -->
