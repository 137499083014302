<section layoutContent class="mt-3">
  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
                  <input
                    class="input"
                    [(ngModel)]="group.name"
                    name="name"
                    type="text"
                    id="name"
                    placeholder="Ingrese el nombre del grupo"
                  />
                </div>
              </div>
              <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1"
                    >Descripción
                  </label>
                  <textarea
                    class="input"
                    [(ngModel)]="group.description"
                    name="description"
                    id="description"
                  ></textarea>
                </div>
              </div>
              <!-- <div class="col-12 mt-2 mt-4">
                <div class="d-flex flex-column">
                  <label for="order" class="label mx-2 mb-1">Etiquetas </label>
                  <input
                    class="input"
                    [(ngModel)]="group.tags"
                    name="tags"
                    type="number"
                    min="1"
                    max="999"
                    id="tags"
                  />
                </div>
              </div> -->
            </div>
            <div class="row mt-3"></div>
          </div>
        </div>
      </form>
    </article>
  </app-card>

  <hr class="my-4 invisible" />

  <app-card
    borderRadius="10px"
    padding="20px 40px"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <article card__content>
      <form action="">
        <div class="row mb-3" *ngIf="errors.length">
          <div class="col">
            <div class="alert alert-danger p-3" role="alert">
              <ul>
                <li *ngFor="let error of errors">
                  <p class="font-18 font-primary--b">{{ error.title }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row row-no-gutters">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-12 mt-2 md:mt-0">
                <div class="d-flex flex-column">
                  <label for="license_quantity" class="label mx-2 mb-1"
                    >Número de licencias</label
                  >
                  <input
                    class="input"
                    [(ngModel)]="group.license_quantity"
                    name="license_quantity"
                    type="text"
                    id="title"
                    placeholder="Ingrese el número de licencias"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-4 mb-4">
              <div class="col-12 d-flex gap-4">
                <div
                  class="preview-block"
                  [ngStyle]="{
                    background:
                      'transparent linear-gradient(140deg, ' +
                      group.primary_color +
                      ' 0%, ' +
                      group.secondary_color +
                      ' 100%) 0% 0%'
                  }"
                >
                  <p
                    class="preview-font text-center fs-5 font-extrabold"
                    [ngStyle]="{ color: group.font_color }"
                  >
                    Texto de ejemplo
                  </p>
                  <div
                    class="background-image"
                    *ngIf="previewMainImage"
                    [ngStyle]="{
                      backgroundImage:
                        'url(' +
                        previewMainImage.changingThisBreaksApplicationSecurity +
                        ')'
                    }"
                  ></div>
                  <div
                    class="background-image"
                    *ngIf="editMode && !previewMainImage"
                    [ngStyle]="{ backgroundImage: 'url(' + group.logo + ')' }"
                  ></div>
                </div>

                <section
                  class="col-8 d-flex flex-column justify-content-between"
                >
                  <div class="row justify-content-around w-64">
                    <div class="mb-4">
                      <div
                        class="d-flex align-items-center"
                        title="Selecciona un color de fondo para tu página"
                      >
                        <label
                          for="primaryBgColor"
                          class="text-grey label col-10"
                          >Color de fondo 1</label
                        >
                        <input
                          class="input cursor-pointer col-2"
                          [(ngModel)]="group.primary_color"
                          value="#00CAA8"
                          name="primaryBgColor"
                          type="color"
                          id="primaryBgColor"
                          placeholder="Selecciona el color primario para el fondo"
                        />
                      </div>
                    </div>
                    <div class="mb-4">
                      <div
                        class="d-flex align-items-center"
                        title="Selecciona un color de fondo para tu página"
                      >
                        <label
                          for="secondaryBgColor"
                          class="text-grey label col-10"
                          >Color de fondo 2</label
                        >
                        <input
                          class="input cursor-pointer col-2"
                          [(ngModel)]="group.secondary_color"
                          value="#078CC7"
                          name="secondaryBgColor"
                          type="color"
                          id="secondaryBgColor"
                          placeholder="Selecciona el color secundario para el fondo"
                        />
                      </div>
                    </div>
                    <div class="mb-4">
                      <div
                        class="d-flex align-items-center"
                        title="Selecciona un color de fondo para tu página"
                      >
                        <label for="fontColor" class="label text-grey col-10"
                          >Color de la fuente</label
                        >
                        <input
                          class="input cursor-pointer col-2"
                          [(ngModel)]="group.font_color"
                          value="#ffffff"
                          name="fontColor"
                          type="color"
                          id="fontColor"
                          placeholder="Selecciona el color del texto a usar"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-column justify-content-end">
                    <label for="bgImage" class="label mx-2 mb-1 d-flex">
                      <ng-container #bgHelper>
                        <span class="d-block text-grey fs-5">
                          Imagen de fondo
                        </span>
                      </ng-container>
                    </label>
                    <input
                      *ngIf="isMainImageFile"
                      class="w-100 h-0 invisible"
                      name="bgImage"
                      type="file"
                      id="bgImage"
                      accept="image/*"
                      placeholder="Seleccionar archivo"
                      (change)="_mainImageChange($event)"
                      #inputImage
                    />
                    <div class="d-flex flex-column w-200px">
                      <button
                        (click)="handleInputImgClick()"
                        class="px-2 py-1 border rounded-2 border-1 border-solid border-secondary"
                      >
                        Seleccionar archivo
                      </button>

                      <span class="ms-2"
                        ><small
                          class="text-grey"
                          style="word-break: break-all"
                          >{{
                            group.logo
                              ? group.logo.name
                                ? group.logo.name
                                : (group.logo | trimString)
                              : "Ningún archivo seleccionado"
                          }}</small
                        ></span
                      >
                    </div>
                    <input
                      class="input w-100"
                      [(ngModel)]="group.logo"
                      *ngIf="!isMainImageFile"
                      type="text"
                      name="bgImage"
                      id="bgImage"
                      placeholder="Seleccionar archivo"
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </form>
    </article>
  </app-card>
</section>
