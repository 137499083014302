import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortOrder',
})
export class SortOrderPipe implements PipeTransform {
  transform(value: any, key: any): any[] {
    if (!value || !key) {
      return value;
    }

    return value.sort((a: any, b: any) => a[key] - b[key]);
  }
}
