import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionsService } from 'src/app/service/institutions.service';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import {
  ReportService,
  UniversityGradePayload,
} from 'src/app/services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { toPlural, toSingular } from 'src/app/utils/categoriesFormatters';
import { MetaDataService } from 'src/app/services/metadata.service';
import { CATEGORY_DIC } from 'src/app/utils/options';

type CategoriesParsed = {
  [key: string]: Record<string, number>;
};

type Category = {
  id: string;
  label: string;
  value: string;
  parameters: Array<{ id: string; label: string; value: number }>;
};
@Component({
  selector: 'app-new-university-grade-page',
  templateUrl: './new-university-grade-page.component.html',
  styleUrls: ['./new-university-grade-page.component.scss'],
})
export class NewUniversityGradePageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  three: any = [];
  errors: Array<{ title: string }> = [];
  inProcess: boolean = false;
  profilesToSelect: any[] = [];
  universitiesToSelect: any[] = [];
  selectedProfiles: any[] = [];
  public organizations: Array<any> = [];
  public selectedSpecializationFirstTypes: any[] = [];
  public selectedSpecializationSecondTypes: any[] = [];
  public specializationTypes = [
    { label: 'Ciencias e Ingeniería', value: 'SCIENCES_ENGINEERING' },
    { label: 'Ciencias de la Salud', value: 'HEALTH_SCIENCES' },
    { label: 'Humanidades', value: 'HUMANITIES' },
    { label: 'Ciencias Sociales', value: 'SOCIAL_SCIENCES' },
  ];
  public selectedUniTypes: any = [];
  public universityId!: string;
  imageProfile: { preview: any; file: any } = {
    preview: 'assets/images/icons/icon_group_green.svg',
    file: null,
  };
  imageCover: { preview: any; file: any } = {
    preview: 'assets/images/icons/icon_group_cover.svg',
    file: null,
  };

  university: UniversityGradePayload = {
    title: '',
    acronym: '',
    type: 'UNIVERSITY_DEGREE', // Or 'VOCATIONAL_TRAINING' based on your default
    description: '',
    primary_color: '',
    secondary_color: '',
    explanation_title: '',
    explanation: '',
    output_profile: '',
    output_title: '',
    study_type: 'AGNOSTIC',
    national_secondary_modality: 'SCIENCES_ENGINEERING',
    study_universities: [
      {
        university: 0, // UniversityRecord ID
        math_requirement: 0,
        language_requirement: 0,
        order: 0,
      },
      {
        university: 0, // UniversityRecord ID
        math_requirement: 0,
        language_requirement: 0,
        order: 0,
      },
      {
        university: 0, // UniversityRecord ID
        math_requirement: 0,
        language_requirement: 0,
        order: 0,
      },
    ],
    international_studies: 1,
    studies_difficulty: 1,
    professional_output: 1,
    average_salary: 1,
    sectoral_variety: 1,
  };

  public universityTypes: Array<Record<string, string>> = [
    { label: 'Agnóstica', value: 'AGNOSTIC' },
    { label: 'Nacional', value: 'NATIONAL' },
    { label: 'Internacional', value: 'INTERNATIONAL' },
    { label: 'IB', value: 'IB' },
  ];

  public categories = this.metaService
    .getAllParameters()
    .filter(
      ({ category_string_id }) =>
        category_string_id === 'vocational-interest' ||
        category_string_id === 'learning-style'
    )
    .map((category) => ({
      ...category,
      parameters: category.parameters.map((param) => ({
        ...param,
        value: 1,
      })),
    }));

  public lowLevelParameters: FormGroup<{
    parameters: FormArray<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >;
  }> = this.formBuilder.group({
    parameters: this.formBuilder.array<
      FormGroup<{
        id: FormControl;
        label: FormControl;
        value: FormControl;
        parameters: FormArray<
          FormGroup<{
            id: FormControl;
            label: FormControl;
            value: FormControl;
          }>
        >;
      }>
    >([]),
  });
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private errorsService: ErrorsService,
    public router: Router,
    public institutionsService: InstitutionsService,
    public reportService: ReportService,
    public creationalPagesLayoutService: CreationalPagesLayoutService,
    private universalModal: UniversalModalService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private universalModalService: UniversalModalService,
    private metaService: MetaDataService
  ) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Crear grado universitario',
      headerTitleIcon: '/assets/images/icons/icon_create.svg',
      footerConfig: {
        submitButtonText: 'Guardar',
        dismissButtonText: 'Cancelar',
      },
    });
  }
  ngOnInit(): void {
    this.universityId = this.activatedRoute.snapshot.paramMap.get('id') ?? '';

    this.setupDefaultParameters();

    this.getUniversities();

    this.getProfiles();

    if (this.universityId) {
      this.reportService
        .getUniversityGrade(this.universityId)
        .subscribe((response: any) => {
          this.university = response;
          this.imageProfile.preview = response['icon'];

          const selectedStudyTypes = this.university.study_type.split(',');
          const selectedNationalModalities = (
            this.university.national_secondary_modality as string
          ).split(',');
          const selectedBachelorModalities = (
            this.university.bachelor_modality as string
          ).split(',');

          this.universityTypes.forEach((type) => {
            if (selectedStudyTypes.find((id: string) => id === type['value'])) {
              this.selectedUniTypes.push(type);
            }
          });

          this.specializationTypes.forEach((type) => {
            if (
              selectedNationalModalities.find(
                (id: string) => id === type['value']
              )
            ) {
              this.selectedSpecializationFirstTypes.push(type);
            }
            if (
              selectedBachelorModalities.find(
                (id: string) => id === type['value']
              )
            ) {
              this.selectedSpecializationSecondTypes.push(type);
            }
          });

          this.reportService.getProfileSheets().subscribe((data: any) => {
            this.profilesToSelect = data.map((profile: any) => ({
              label: profile.name,
              value: profile.id,
            }));
            this.profilesToSelect.forEach((profile) => {
              console.log('PROFILE TO SELECT', profile);
              if (
                response['profile']?.find((id: number) => id === profile.value)
              ) {
                console.log('SELECTED', profile);
                this.selectedProfiles.push(profile);
              }
            });
          });

          this.specializationTypes.forEach((type) => {
            if (type['value'] === response['national_secondary_modality']) {
              this.selectedSpecializationFirstTypes.push(type);
            }

            if (type['value'] === response['bachelor_modality']) {
              this.selectedSpecializationSecondTypes.push(type);
            }
          });

          Object.keys(response).forEach((key: string) => {
            if (toPlural(key) in CATEGORY_DIC) {
              const categoryGroup = this.lowLevelParameters.get(
                'parameters'
              ) as FormArray;
              const categoryIndex = categoryGroup.controls.findIndex(
                (control) => this.dashToUnder(control.value.id) === key
              );
              if (categoryIndex > -1) {
                const parameters = Object.keys(
                  response[key] as Record<string, number>
                ).map((parameterKey) => ({
                  id: parameterKey,
                  value: (response[key] as Record<string, number>)[
                    parameterKey
                  ],
                }));

                categoryGroup.controls.forEach((categoryControl) => {
                  const paramFormArray = categoryControl.get(
                    'parameters'
                  ) as FormArray;

                  paramFormArray.controls.forEach((control) => {
                    parameters.forEach((param) => {
                      if (control.get('id')?.value === param.id) {
                        control.patchValue(param);
                      }
                    });
                  });
                });
              }
            }
          });

          this.creationalPagesLayoutService.layoutConfig.next({
            headerTitle: 'Editar grado universitario',
            headerTitleIcon: '/assets/images/icons/icon_edit_new.svg',
            footerConfig: {
              submitButtonText: 'Guardar',
              dismissButtonText: 'Cancelar',
              deleteButtonText: 'Eliminar',
            },
          });
        });
    }
  }
  ngAfterViewInit() {
    this.creationalPagesLayoutService.onSubmit
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.addToThree();
        }
      });
    this.creationalPagesLayoutService.onDismiss
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.router.navigate(['dashboard/instituciones/grado-universitario']);
        }
      });

    this.creationalPagesLayoutService.onDelete
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value) {
          this.confirmDelete();
        }
      });
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  public handleSelectProfile(selectedOptions: Array<Record<string, string>>) {
    this.selectedProfiles = selectedOptions;
  }

  public getUniversities() {
    this.reportService.getUniversities().subscribe((data: any) => {
      this.universitiesToSelect = data.map((university: any) => ({
        label: university.name,
        value: university.id,
      }));
    });
  }
  public getProfiles() {
    this.reportService.getProfileSheets().subscribe((data: any) => {
      this.profilesToSelect = data.map((profile: any) => ({
        label: profile.name,
        value: profile.id,
      }));
    });
  }
  public setupDefaultParameters() {
    this.categories.forEach((category) => {
      const categoriesFormArray = this.lowLevelParameters.get(
        'parameters'
      ) as FormArray;

      const categoryID = toSingular(category.category_string_id);

      const categoryGroup = this.formBuilder.group({
        id: [categoryID],
        label: [category.category_name],
        value: [categoryID],
        parameters: this.formBuilder.array(
          this.getCategoryParameters(categoryID).map(
            (param: { id: string; label: string }) =>
              this.formBuilder.group({
                id: param.id,
                label: [param.label],
                value: [1],
              })
          )
        ),
      });

      categoriesFormArray.push(categoryGroup);
      categoriesFormArray.updateValueAndValidity();
    });
  }
  private getCategoryParameters(
    categoryId: string
  ): { id: string; label: string }[] {
    const allParametersByCategory = this.metaService.getAllParameters();

    const findCategory = allParametersByCategory.find(
      ({ category_string_id }) => category_string_id === categoryId
    );

    if (findCategory) {
      return findCategory.parameters;
    }

    return [];
  }
  public onInternationalStudiesRatingSelected(rating: number): void {
    this.university.international_studies = rating;
  }

  public onStudiesDifficultyRatingSelected(rating: number): void {
    this.university.studies_difficulty = rating;
  }

  public onProfessionalOutputRatingSelected(rating: number): void {
    this.university.professional_output = rating;
  }

  public onAverageSalaryRatingSelected(rating: number): void {
    this.university.average_salary = rating;
  }

  public onSectoralVarietyRatingSelected(rating: number): void {
    this.university.sectoral_variety = rating;
  }

  public handleSpecializationFirstSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    this.selectedSpecializationFirstTypes = selectedOptions;
    this.university.national_secondary_modality = this.concatOptions(
      this.selectedSpecializationFirstTypes
    ) as any;
  }

  public handleSpecializationSecondSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    this.selectedSpecializationSecondTypes = selectedOptions;
    this.university.bachelor_modality = this.concatOptions(
      this.selectedSpecializationSecondTypes
    ) as any;
  }

  public handleUniversityTypeSelectionChange(
    selectedOptions: Array<Record<string, string>>
  ) {
    this.selectedUniTypes = selectedOptions;
    this.university.study_type = this.concatOptions(
      this.selectedUniTypes
    ) as any;
  }

  public concatOptions(options: Array<Record<string, string>>): string {
    return options.map((option) => option['value']).join(',');
  }

  isValidForm(report: any) {
    const emptyName = report.name === '';
    this.errors = [];

    let isValidForm = true;

    //?? Check mandatory fields
    if (emptyName) {
      this.errors.push({
        title: 'El nombre del grado universitario es requerido.',
      });
      isValidForm = false;
    }

    return isValidForm;
  }

  public parseCParameters(
    catParameters: Category['parameters']
  ): Record<string, number> {
    const parametersParsed: Record<string, number> = {};
    catParameters.forEach(
      (parameter: { id: string; label: string; value: number }) => {
        parametersParsed[parameter.id] = parameter.value;
      }
    );
    return parametersParsed;
  }

  public parseCategories(categories: Array<Category>): CategoriesParsed {
    const payload: Record<string, Record<string, number>> = {};

    categories.forEach((category: Category) => {
      payload[this.dashToUnder(category.id)] = this.parseCParameters(
        category.parameters
      );
    });

    return payload;
  }

  public dashToUnder(value: string): string {
    const includeDash = value.includes('-');

    if (includeDash) {
      return value.replace(/-/g, '_');
    }

    return value;
  }

  public underToDash(value: string): string {
    const includeUnder = value.includes('_');

    if (includeUnder) {
      return value.replace(/_/g, '-');
    }

    return value;
  }

  public addToThree() {
    this.inProcess = true;
    if (this.isValidForm(this.university)) {
      if (this.universityId) {
        this.reportService
          .updateUniversityGrade(this.universityId, {
            ...this.university,
            study_universities: this.university.study_universities?.filter(
              (university) => university.university !== 0
            ),
            ...this.parseCategories(
              this.lowLevelParameters.getRawValue().parameters
            ),
            profile: this.selectedProfiles.map((option) => option.value),
          } as any)
          .subscribe({
            next: (value: any) => {
              this.router.navigate([
                '/dashboard/instituciones/grado-universitario',
              ]);
              this.universalModal.openModal(
                undefined,
                null,
                'Grado universitario editado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      } else {
        this.reportService
          .createUniversityGrade({
            ...this.university,
            ...this.parseCategories(
              this.lowLevelParameters.getRawValue().parameters
            ),
            study_universities: this.university.study_universities?.filter(
              (university) => university.university !== 0
            ),
            profile: this.selectedProfiles.map((option) => option.value),
          })
          .subscribe({
            next: (value: any) => {
              this.router.navigate([
                '/dashboard/instituciones/grado-universitario',
              ]);
              this.universalModal.openModal(
                undefined,
                null,
                'Grado universitario creado exitosamente'
              );
              this.inProcess = false;
            },
            error: ({ error }) => {
              const { htmlErrorsContent } =
                this.errorsService.handleErrors(error);
              this.universalModal.openModal(
                undefined,
                null,
                'Algo ha ocurrido' + htmlErrorsContent
              );
              this.inProcess = false;
            },
          });
      }
    }
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public handleDeleteuniversity() {
    this.handleCloseModal();
    this.reportService.deleteUniversityGrade(this.universityId).subscribe({
      next: () => {
        this.router.navigate(['dashboard/instituciones/grado-universitario']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Grado universitario eliminado exitosamente'
        );
      },
      error: (error) => {
        this.inProcess = false;
        this.errorsService.handleErrors(error);
        this.universalModalService.openModal(
          undefined,
          null,
          'Algo ha ocurrido' + error
        );
      },
    });
  }
}
