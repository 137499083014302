<!-- MODAL -->
<ng-template #modalNotification let-c="close" let-d="dismiss">
  <div class="modal-body" [innerHTML]="notificationMessage"></div>
</ng-template>
<app-page-loader *ngIf="inProcess"></app-page-loader>
<form
  *ngIf="!inProcess"
  [formGroup]="activityCreationForm"
  class="mt-3"
  style="margin-bottom: 50px"
>
  <!-- CONTENT -->
  <div class="row mb-3" *ngIf="errors.length > 0">
    <div class="col">
      <div class="alert alert-danger p-3" role="alert">
        <ul>
          <li *ngFor="let error of errors">
            <p class="font-18 font-primary--b">{{ error.title }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <article class="question-form-grid">
    <section
      class="scrollable-column d-flex flex-column gap-4 overflow-y-scroll"
    >
      <app-card borderRadius="10px" padding="20px 40px">
        <article card__content>
          <div class="col-12 mb-3">
            <div class="d-flex flex-column">
              <label for="name" class="label mx-2 mb-1">Nombre (*)</label>
              <input
                class="input"
                formControlName="name"
                type="text"
                id="name"
                placeholder="Ingrese el nombre de la pregunta"
              />
            </div>
          </div>
        </article>
      </app-card>
      <app-card
        borderRadius="10px"
        padding="20px 40px"
        boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        height="100%"
      >
        <article card__content style="height: 100%">
          <app-activity-form
            [editMode]="!!questionId"
            [questionType]="
              activityCreationForm.get('typeSelected')?.valueChanges | async
            "
            [activityToEdit]="activity"
            #activityForm
          >
          </app-activity-form>
        </article>
      </app-card>
      <form [formGroup]="optionActivityCreationForm">
        <app-create-activity-options
          [options]="optionsFormArray"
          [editMode]="!!questionId"
          [categoriesSelected]="categoriesSelected"
          [questionType]="
            activityCreationForm.get('typeSelected')?.valueChanges | async
          "
          (handleAddOption)="addOption()"
          (handleParametersChange)="handleParametersChange($event)"
          [showMinMax]="
            (activityCreationForm.get('typeSelected')?.valueChanges | async) ===
              'SELECT_MULTIPLES_ANSWERS' ||
            (activityCreationForm.get('typeSelected')?.valueChanges | async) ===
              'JAZZDOO'
          "
        >
        </app-create-activity-options>
      </form>
    </section>
    <div
      class="sticky-column"
      [class]="
        'type-' +
        (activityCreationForm.get('typeSelected')?.valueChanges | async)
      "
    >
      <!-- style="height: 500px" -->
      <section
        class="d-flex justify-content-center align-items-center flex-column"
        style="width: 75%"
      >
        <ng-container>
          <app-activity-preview
            helperText="Para empezar, indica el tipo de pregunta que deseas crear."
            class="w-100"
            [statement]="
              (activityCreationForm.get('statement')?.valueChanges | async) ??
              ''
            "
            [type]="
              activityCreationForm.get('typeSelected')?.valueChanges | async
            "
            [video]="activityCreationForm.get('video')?.valueChanges | async"
            [options]="
              optionActivityCreationForm.get('options')?.valueChanges | async
            "
            [min]="optionActivityCreationForm.get('min')?.valueChanges | async"
            [max]="optionActivityCreationForm.get('max')?.valueChanges | async"
          >
          </app-activity-preview>
        </ng-container>
      </section>
    </div>
  </article>
</form>
