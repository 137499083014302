import { CustomContentComponent } from './components/pages/phrases-pages/custom-content/custom-content.component';
import { InstitutionsPageComponent } from './components/pages/institutions-page/institutions-page.component';
import { InsightPageComponent } from './components/pages/insight-page/insight-page.component';
import { EmailVerificationPageComponent } from './components/pages/email-verification-page/email-verification-page.component';
import { DetailPagePageComponent } from './components/pages/detail-page-page/detail-page-page.component';
import { EditPagePageComponent } from './components/pages/edit-page-page/edit-page-page.component';
import { NewContentPageComponent } from './components/pages/new-content-page/new-content-page.component';
import { PagesPageComponent } from './components/pages/pages-page/pages-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { EditActivityPageComponent } from './components/pages/edit-activity-page/edit-activity-page.component';
import { ActivityPageComponent } from './components/pages/activity-page/activity-page.component';
import { NewActivityPageComponent } from './components/pages/new-activity-page/new-activity-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { ActivitiesPageComponent } from './components/pages/activities-page/activities-page.component';
import { SigninPageComponent } from './components/pages/signin-page/signin-page.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { NgModule } from '@angular/core';
import { DashboardLayoutComponent } from './components/layouts/dashboard-layout/dashboard-layout.component';
import { CategoriesParameterPageComponent } from './components/pages/categories-parameter-page/categories-parameter-page.component';
import { DetailCategoryParameterPageComponent } from './components/pages/detail-category-parameter-page/detail-category-parameter-page.component';
import { DetailParameterPageComponent } from './components/pages/detail-parameter-page/detail-parameter-page.component';
import { ParametersPageComponent } from './components/pages/parameters-page/parameters-page.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { UsersComponent } from './components/pages/usuarios/users/users.component';
import { CouponsPageComponent } from './components/pages/coupons-page/coupons-page.component';
import { DetailCouponPageComponent } from './components/pages/detail-coupon-page/detail-coupon-page.component';
import { EditCouponPageComponent } from './components/pages/edit-coupon-page/edit-coupon-page.component';
import { NewCouponPageComponent } from './components/pages/new-coupon-page/new-coupon-page.component';
import { CareersUniversityPageComponent } from './components/pages/careers-university-page/careers-university-page.component';
import { NewCareerUniversityPageComponent } from './components/pages/new-career-university-page/new-career-university-page.component';
import { DetailCareerUniversityPageComponent } from './components/pages/detail-career-university-page/detail-career-university-page.component';
import { EditCareerUniversityPageComponent } from './components/pages/edit-career-university-page/edit-career-university-page.component';
import { ReportsPageComponent } from './components/pages/reports-page/reports-page.component';
import { PhrasesPageComponent } from './components/pages/phrases-page/phrases-page.component';
import { EditPhrasePageComponent } from './components/pages/edit-phrase-page/edit-phrase-page.component';
import { DetailPhrasePageComponent } from './components/pages/detail-phrase-page/detail-phrase-page.component';
import { ProgramsComponent } from './components/pages/programs/programs.component';
import { PagesSeasonsPageComponent } from './components/pages/pages-seasons-page/pages-seasons-page.component';
import { PagesChaptersPageComponent } from './components/pages/pages-chapters-page/pages-chapters-page.component';
import { PagesThemesPageComponent } from './components/pages/pages-themes-page/pages-themes-page.component';
import { PagesSubthemesPageComponent } from './components/pages/pages-subthemes-page/pages-subthemes-page.component';
import { EditParameterPageComponent } from './components/pages/edit-parameter-page/edit-parameter-page.component';
import { PagesActivitiesPageComponent } from './components/pages/pages-activities-page/pages-activities-page.component';
import { SettingsPageComponent } from './components/pages/settings-page/settings-page.component';
import { EditUserPageComponent } from './components/pages/edit-user-page/edit-user-page.component';
import { CreateUserComponent } from './components/pages/create-user/create-user.component';
import { DetailUserPageComponent } from './components/pages/detail-user-page/detail-user-page.component';
import { NewQuestionariesPageComponent } from './components/pages/new-questionaries-page/new-questionaries-page.component';
import { EditProgramsPageComponent } from './components/pages/edit-questionaries-page/edit-questionaries-page.component';
import { ContentPageComponent } from './components/pages/content-page/content-page.component';
import { CreationalPagesLayoutComponent } from './components/layouts/creational-pages-layout/creational-pages-layout.component';
import { ContentThemesPageComponent } from './components/pages/content-themes-page/content-themes-page.component';
import { EditContentPageComponent } from './components/pages/edit-content-page/edit-content-page.component';
import { NewThemePageComponent } from './components/pages/new-theme-page/new-theme-page.component';
import { EditThemePageComponent } from './components/pages/edit-theme-page/edit-theme-page.component';
import { NewSubthemePageComponent } from './components/pages/new-subtheme-page/new-subtheme-page.component';
import { EditSubthemePageComponent } from './components/pages/edit-subtheme-page/edit-subtheme-page.component';
import { NewQuestionPageComponent } from './components/pages/new-question-page/new-question-page.component';
import { TeamListPageComponent } from './components/pages/team-list-page/team-list-page.component';
import { NewGroupPageComponent } from './components/pages/new-group-page/new-group-page.component';
import { UsersGroupListPageComponent } from './components/pages/users-group-list-page/users-group-list-page.component';
import { UsersGroupAddListPageComponent } from './components/pages/users-group-add-list-page/users-group-add-list-page.component';
import { NewReportGroupPageComponent } from './components/pages/new-report-group-page/new-report-group-page.component';
import { ReportGroupListPageComponent } from './components/pages/report-group-list-page/report-group-list-page.component';
import { EditReportGroupPageComponent } from './components/pages/edit-report-group-page/edit-report-group-page.component';
import { AddReportsPageComponent } from './components/pages/add-reports-page/add-reports-page.component';
import { JazzdooListPageComponent } from './components/pages/jazzdoo-list-page/jazzdoo-list-page.component';
import { ProfessionalFormationListPageComponent } from './components/pages/professional-formation-list-page/professional-formation-list-page.component';
import { UniversityGradeListPageComponent } from './components/pages/university-grade-list-page/university-grade-list-page.component';
import { UniversityListPageComponent } from './components/pages/university-list-page/university-list-page.component';
import { ProfileInstituteListPageComponent } from './components/pages/profile-institute-list-page/profile-institute-list-page.component';
import { NewJazzdooPageComponent } from './components/pages/new-jazzdoo-page/new-jazzdoo-page.component';
import { NewProfilePageComponent } from './components/pages/new-profile-page/new-profile-page.component';
import { NewUniversityPageComponent } from './components/pages/new-university-page/new-university-page.component';
import { NewUniversityGradePageComponent } from './components/pages/new-university-grade-page/new-university-grade-page.component';
import { NewProfessionalFormPageComponent } from './components/pages/new-professional-form-page/new-professional-form-page.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', redirectTo: 'signin', pathMatch: 'full' },
      { path: 'signin', component: SigninPageComponent },
      {
        path: 'verification-email/:token/:emailIdB64/:userIdB64',
        component: EmailVerificationPageComponent,
      },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'pages/programas', pathMatch: 'full' },
      { path: 'inicio', component: HomePageComponent },
      { path: 'configuracion', component: SettingsPageComponent },
      { path: 'insight', component: InsightPageComponent },
      {
        path: 'instituciones',
        component: CreationalPagesLayoutComponent,
        children: [
          {
            path: 'jazzdoos',
            component: JazzdooListPageComponent,
          },
          {
            path: 'jazzdoos/nuevo',
            component: NewJazzdooPageComponent,
          },
          {
            path: 'jazzdoos/:id/editar',
            component: NewJazzdooPageComponent,
          },
          {
            path: 'profesional',
            component: ProfessionalFormationListPageComponent,
          },
          {
            path: 'profesional/nuevo',
            component: NewProfessionalFormPageComponent,
          },
          {
            path: 'profesional/:id/editar',
            component: NewProfessionalFormPageComponent,
          },
          {
            path: 'grado-universitario',
            component: UniversityGradeListPageComponent,
          },
          {
            path: 'grado-universitario/nuevo',
            component: NewUniversityGradePageComponent,
          },
          {
            path: 'grado-universitario/:id/editar',
            component: NewUniversityGradePageComponent,
          },
          {
            path: 'universidad',
            component: UniversityListPageComponent,
          },
          {
            path: 'universidad/nuevo',
            component: NewUniversityPageComponent,
          },
          {
            path: 'universidad/:id/editar',
            component: NewUniversityPageComponent,
          },
          {
            path: 'perfil',
            component: ProfileInstituteListPageComponent,
          },
          {
            path: 'perfil/nuevo',
            component: NewProfilePageComponent,
          },
          {
            path: 'perfil/:id/editar',
            component: NewProfilePageComponent,
          },
          {
            path: 'parametros',
            component: PhrasesPageComponent,
          },
          {
            path: 'parametros/:id',
            component: DetailPhrasePageComponent,
          },
          {
            path: 'parametros/:id/editar',
            component: EditPhrasePageComponent,
          },
          {
            path: 'parametros/:id/custom-content',
            component: CustomContentComponent,
          },
          {
            path: '**',
            pathMatch: 'prefix',
            redirectTo: 'jazzdoos',
          },
        ],
      },
      {
        path: 'preguntas',
        component: ActivitiesPageComponent,
      },
      {
        path: 'preguntas/nueva',
        component: NewActivityPageComponent,
      },
      {
        path: 'preguntas/:id',
        component: ActivityPageComponent,
      },
      {
        path: 'preguntas/:id/editar',
        component: EditActivityPageComponent,
      },
      {
        path: 'parametros',
        component: ParametersPageComponent,
      },
      {
        path: 'parametros/:id/editar',
        component: EditParameterPageComponent,
      },
      {
        path: 'parametros/:id',
        component: DetailParameterPageComponent,
      },
      {
        path: 'categorias',
        component: CategoriesParameterPageComponent,
      },
      {
        path: 'categorias/:id',
        component: DetailCategoryParameterPageComponent,
      },
      {
        path: 'pages',
        component: CreationalPagesLayoutComponent,
        children: [
          {
            path: 'programas',
            component: ProgramsComponent,
          },
          {
            path: 'programas/nuevo',
            component: NewQuestionariesPageComponent,
          },
          {
            path: 'programas/:id/editar',
            component: EditProgramsPageComponent,
          },
          {
            path: 'contenidos',
            component: ContentPageComponent,
          },
          {
            path: 'contenidos/nuevo',
            component: NewContentPageComponent,
          },
          {
            path: 'contenidos/:id/editar',
            component: EditContentPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas',
            component: ContentThemesPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/nuevo',
            component: NewThemePageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/editar',
            component: EditThemePageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas',
            component: PagesThemesPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/editar',
            component: EditSubthemePageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/nuevo',
            component: NewSubthemePageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades',
            component: PagesSubthemesPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades/nuevo',
            component: NewActivityPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades/:activityId/editar',
            component: EditActivityPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades/:activityId/preguntas',
            component: PagesActivitiesPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades/:activityId/preguntas/:questionId/editar',
            component: NewQuestionPageComponent,
          },
          {
            path: 'contenidos/:contentId/temas/:themeId/subtemas/:subthemeId/actividades/:activityId/preguntas/nuevo',
            component: NewQuestionPageComponent,
          },
          {
            path: 'programas/contenidos/:programId',
            component: PagesSeasonsPageComponent,
          },
          {
            path: 'programas/contenidos/:programId/capitulos/:chapterId/temas/:themeId',
            component: PagesThemesPageComponent,
          },
          {
            path: 'programas/:id',
            component: DetailPagePageComponent,
          },
          {
            path: 'programas/:id/editar',
            component: EditPagePageComponent,
          },
        ],
      },
      {
        path: 'grupos',
        component: CreationalPagesLayoutComponent,
        children: [
          {
            path: '',
            component: TeamListPageComponent,
          },
          {
            path: 'nuevo',
            component: NewGroupPageComponent,
          },
          {
            path: ':groupId/editar',
            component: NewGroupPageComponent,
          },
          {
            path: ':groupId/usuarios',
            component: UsersGroupListPageComponent,
          },
          {
            path: ':groupId/usuarios/añadir',
            component: UsersGroupAddListPageComponent,
          },
        ],
      },
      {
        path: 'usuarios',
        component: CreationalPagesLayoutComponent,
        children: [
          {
            path: '',
            component: UsersComponent,
          },
          {
            path: 'nuevo',
            component: CreateUserComponent,
          },
          {
            path: ':id/editar',
            component: EditUserPageComponent,
          },
        ],
      },
      {
        path: 'informes',
        component: CreationalPagesLayoutComponent,
        children: [
          {
            path: '',
            component: ReportGroupListPageComponent,
          },
          {
            path: 'nuevo',
            component: NewReportGroupPageComponent,
          },
          {
            path: ':reportGroupId/editar',
            component: EditReportGroupPageComponent,
          },
          {
            path: ':reportGroupId/reportes',
            component: ReportsPageComponent,
          },
          {
            path: ':reportGroupId/reportes/añadir',
            component: AddReportsPageComponent,
          },
        ],
      },
      {
        path: 'reportes/carreras-universitarias',
        component: CareersUniversityPageComponent,
      },
      {
        path: 'reportes/carreras-universitarias/nueva',
        component: NewCareerUniversityPageComponent,
      },
      {
        path: 'reportes/carreras-universitarias/:id',
        component: DetailCareerUniversityPageComponent,
      },
      {
        path: 'reportes/carreras-universitarias/:id/editar',
        component: EditCareerUniversityPageComponent,
      },
      {
        path: 'reportes/frases',
        component: PhrasesPageComponent,
      },
      {
        path: 'reportes/frases/:id',
        component: DetailPhrasePageComponent,
      },
      {
        path: 'reportes/frases/:id/editar',
        component: EditPhrasePageComponent,
      },
      {
        path: 'reportes/frases/:id/custom-content',
        component: CustomContentComponent,
      },
      {
        path: 'cupones',
        component: CouponsPageComponent,
      },
      {
        path: 'cupones/nuevo',
        component: NewCouponPageComponent,
      },
      {
        path: 'cupones/:id',
        component: DetailCouponPageComponent,
      },
      {
        path: 'cupones/:id/editar',
        component: EditCouponPageComponent,
      },
    ],
  },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
