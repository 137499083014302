<main class="page pages-page">
  <!-- HEADER -->
  <!-- LOADER -->

  <div class="mt-3" *ngIf="isLoading">
    <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <div card__content>
        <app-loading color="#de377c"></app-loading>
        <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
          Por favor, espere un momento
        </h2>
      </div>
    </app-card>
  </div>

  <!-- LIST -->
  <section class="mt-3" *ngIf="!isLoading">
    <ul class="row">
      <ng-container *ngFor="let season of seasons; index as i">
        <li class="mb-3 col-12 col-md-6">
          <app-card
            borderRadius="10px"
            height="100%"
            padding="20px 40px"
            boxShadow="rgba(0, 0, 0, .1) 0px 2px 1px"
          >
            <article card__content>
              <div class="row">
                <div class="col d-flex gap-3">
                  <h2 class="col-2 font-18 font-primary--b">
                    <!-- <small class="font-12 opacity-50 font-primary--eb"
                      >(ID{{ season.id }})</small
                    >
                    {{ season.name }} -->
                  </h2>
                </div>

                <div class="col-4">
                  <span class="text-danger">Eliminar</span>
                </div>
                <!-- <div class="col-auto d-flex gap-3 align-items-center">
                  <div class="cursor-pointer" (click)="toggleQuestionary(season)">

                    <span *ngIf="!season.is_published">
                      No Publicado
                    </span>
                  </div>
                  <app-toggle-switch color="red" [active]="season.is_published" (click)="toggleQuestionary(season)">
                  </app-toggle-switch>
                  <span *ngIf="season.is_published">
                    Publicado
                  </span>
                </div> -->
              </div>
            </article>
          </app-card>
        </li>
      </ng-container>

      <article class="mt-3" *ngIf="seasons && !seasons.length && !isLoading">
        <app-card
          borderRadius="10px"
          padding="80px 120px"
          boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
        >
          <section card__content>
            <h2 class="text-center font-24 opacity-50">
              No has creado ninguna página aún,
              <span
                routerLink="/dashboard/pages/programas/nueva"
                class="text-green cursor-pointer"
                >crea</span
              >
              una.
            </h2>
          </section>
        </app-card>
      </article>
    </ul>
  </section>
</main>
