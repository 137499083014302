import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { catchError, throwError } from 'rxjs';
import { Parameters } from '../models/parameter.model';
import { objectToFormData } from '../utils/objectToFormData';

@Injectable()
export class MetaDataService {
  categories_list: any;
  paramters: any;
  constructor(private httpClient: HttpClient) {}

  getCategories() {
    this.categories_list = [
      {
        id: 1,
        stting_id: 'vocational-interest',
        label: 'Intereses Vocacionales',
      },

      {
        id: 2,
        stting_id: 'multiple-intelligence',
        label: 'Inteligencias Multiples',
      },

      {
        id: 3,
        stting_id: 'professional-field',
        label: 'Campos Profesionales',
      },

      {
        id: 4,
        stting_id: 'personality',
        label: 'Personalidades',
      },

      {
        id: 5,
        stting_id: 'AVK',
        label: 'AVK',
      },

      {
        id: 6,
        stting_id: 'learning-style',
        label: 'Estilos de Aprendizaje',
      },

      {
        id: 7,
        stting_id: 'intelligence',
        label: 'Inteligencia',
      },

      {
        id: 8,
        stting_id: 'interest-option',
        label: 'Opciones de Interés',
      },

      {
        id: 9,
        stting_id: 'competence',
        label: 'Competencias',
      },
      {
        id: 10,
        stting_id: 'jazzdoo',
        label: 'Jazzdoo',
      },
      {
        id: 11,
        stting_id: 'wef',
        label: 'Wef',
      },
      {
        id: 12,
        stting_id: 'schein',
        label: 'Schein',
      },
    ];
    return this.categories_list;
  }

  getAllParameters(): Parameters {
    this.paramters = [
      {
        category_id: 1,
        category_string_id: 'vocational-interest',
        category_name: 'Intereses Vocacionales',
        parameters: [
          { id: 'technical', label: 'Técnico' },
          { id: 'investigator', label: 'Investigador' },
          { id: 'entrepreneur', label: 'Emprededor' },
          { id: 'communicator', label: 'Comunicador' },
          { id: 'analyst', label: 'Analista' },
          { id: 'artistic', label: 'Artistico' },
        ],
      },
      {
        category_id: 2,
        category_string_id: 'multiple-intelligence',
        category_name: 'Inteligencias Multiples',
        parameters: [
          { id: 'linguistic', label: 'Lingüístico' },
          { id: 'mathematical_logician', label: 'Lógico matemático' },
          { id: 'space', label: 'Espacial' },
          { id: 'cinematic_bodily', label: 'Cinemático corporal' },
          { id: 'musical', label: 'Musical' },
          { id: 'intrapersonal', label: 'Intrapersonal' },
          { id: 'interpersonal', label: 'Interpersonal' },
          { id: 'naturalist', label: 'Naturalista' },
        ],
      },
      {
        category_id: 3,
        category_string_id: 'professional-field',
        category_name: 'Campos Profesionales',
        parameters: [
          { id: 'manual_technician', label: 'Técnico manual' },
          { id: 'research_scientist', label: 'Científico investigador' },
          { id: 'creative_artistic', label: 'Artístico creativo' },
          { id: 'social_healthcare', label: 'Social-asistencial' },
          { id: 'business_persuasive', label: 'Empresarial persuasivo' },
          { id: 'management', label: 'Administración gestión' },
          { id: 'virtual_digital_streamer', label: 'Virtual/Digital Streamer' },
        ],
      },
      {
        category_id: 4,
        category_string_id: 'personality',
        category_name: 'Personalidades',
        parameters: [
          { id: 'perfectionist', label: 'Perfeccionista' },
          { id: 'altruistic', label: 'Altruista' },
          { id: 'actives', label: 'Activo' },
          { id: 'affective', label: 'Social asistencial' },
          { id: 'rational', label: 'Racional' },
          { id: 'skeptical', label: 'Escéptico' },
          { id: 'enthusiastic', label: 'Entusiasta' },
          { id: 'leader', label: 'Líder' },
          { id: 'mediator', label: 'Mediador' },
        ],
      },
      {
        category_id: 5,
        category_string_id: 'avk',
        category_name: 'AVK',
        parameters: [
          { id: 'auditory', label: 'Auditivo' },
          { id: 'visual', label: 'Visual' },
          { id: 'kinesthetic', label: 'Kinestésico' },
        ],
      },
      {
        category_id: 6,
        category_string_id: 'learning-style',
        category_name: 'Estilos de Aprendizaje',
        parameters: [
          { id: 'active', label: 'Activo' },
          { id: 'thoughtful', label: 'Reflexivo' },
          { id: 'theoretical', label: 'Teórico' },
          { id: 'pragmatic', label: 'Pragmático' },
        ],
      },
      {
        category_id: 7,
        category_string_id: 'intelligence',
        category_name: 'Inteligencia',
        parameters: [
          { id: 'self_awareness', label: 'Autoconciencia' },
          { id: 'empathy', label: 'Empatía' },
          { id: 'self_confidence', label: 'Autoconfianza' },
          { id: 'motivation', label: 'Motivación' },
          { id: 'self_control', label: 'Autocontrol' },
          { id: 'social_skills', label: 'Habilidades Sociales' },
        ],
      },
      {
        category_id: 8,
        category_string_id: 'interest-option',
        category_name: 'Opciones de Interés',
        parameters: [
          {
            id: 'technical_with_academic_option',
            label: 'Técnico con opción académica',
          },
          { id: 'technical_inclination', label: 'Inclinación técnica' },
        ],
      },
      {
        category_id: 9,
        category_string_id: 'competence',
        category_name: 'Competencías',
        parameters: [
          { id: 'self_study', label: 'Autoaprendizaje' },
          { id: 'self_critical_capacity', label: 'Capacidad de autocrítica' },
          { id: 'information_search', label: 'Busqueda de información' },
          { id: 'time_organization', label: 'Organización del tiempo' },
          { id: 'social_skills', label: 'Habilidades sociales' },
          {
            id: 'project_management',
            label: 'Gestión de proyectos y resolución de problemas',
          },
          { id: 'adaptability', label: 'Capacidad de Adaptación' },
          { id: 'communication_skills', label: 'Habilidades de comunicación' },
          {
            id: 'information_treatment',
            label: 'Tratamiento de la información y competencia dígital',
          },
          {
            id: 'informatic_training',
            label: 'Nivel de formación en APP informaticas',
          },
          { id: 'it_level_used', label: 'Nivel de uso de las TIC' },
        ],
      },
      {
        category_id: 10,
        category_string_id: 'jazzdoo',
        category_name: 'Jazzdoo',
        parameters: [
          {
            id: 'peripheral_vision_objectivity',
            label: 'Objetividad de visión periférica',
          },
          { id: 'paternalism', label: 'Paternalismo' },
          { id: 'synthesis_capacity', label: 'Capacidad de síntesis' },
          { id: 'naturalness', label: 'Naturalidad' },
          { id: 'learning_amplitude', label: 'Amplitud de aprendizaje' },
          { id: 'subjectivity', label: 'Subjetividad' },
          { id: 'strategic_thinking', label: 'Pensamiento estratégico' },
        ],
      },
      {
        category_id: 11,
        category_string_id: 'wef',
        category_name: 'Wef',
        parameters: [
          {
            id: 'analytical_thinking_innovation',
            label: 'Pensamiento analítico e innovación',
          },
          {
            id: 'active_learning_strategies',
            label: 'Estrategias de aprendizaje activo',
          },
          {
            id: 'creativity_originality_initiative',
            label: 'Creatividad, originalidad e iniciativa',
          },
          {
            id: 'technology_design_programming',
            label: 'Diseño y programación tecnológica',
          },
          {
            id: 'critical_thinking_analysis',
            label: 'Pensamiento crítico y análisis',
          },
          {
            id: 'complex_problem_solving',
            label: 'Resolución de problemas complejos',
          },
          {
            id: 'leadership_social_influence',
            label: 'Liderazgo e influencia social',
          },
          { id: 'emotional_intelligence', label: 'Inteligencia emocional' },
          {
            id: 'reasoning_problem_resolving_ideation',
            label: 'Razonamiento, resolución de problemas e ideación',
          },
          {
            id: 'systems_analysis_evaluation',
            label: 'Análisis y evaluación de sistemas',
          },
        ],
      },
      {
        category_id: 12,
        category_string_id: 'schein',
        category_name: 'Schein',
        parameters: [
          { id: 'life_style', label: 'Estilo de vida' },
          { id: 'security', label: 'Seguridad' },
          { id: 'creativity', label: 'Creatividad' },
          { id: 'challenge', label: 'Desafío' },
          { id: 'organize', label: 'Organización' },
          { id: 'functional_technical', label: 'Funcional y técnico' },
          { id: 'autonomy', label: 'Autonomía' },
          { id: 'service', label: 'Servicio' },
        ],
      },
    ];
    return this.paramters;
  }

  getParamatersByCategory(categ_id: any) {
    let parameters = this.getAllParameters();
    return parameters.find((param: any) => param.category_id == categ_id);
  }

  getCategoryByParamId(id: string) {
    let selectedCategory: any;
    let categories = this.getAllParameters();
    categories.forEach((category: any) => {
      category.parameters.forEach((parameter: any) => {
        if (parameter.id === id) {
          selectedCategory = category;
        }
      });
    });
    return selectedCategory;
  }

  getParamByParamId(id: string) {
    let selectedParam: any;
    let categories = this.getAllParameters();
    categories.forEach((category: any) => {
      category.parameters.forEach((parameter: any) => {
        if (parameter.id === id) {
          selectedParam = parameter;
        }
      });
    });
    return selectedParam;
  }

  getUserTypes() {
    let user_types = [
      {
        id: 1,
        string_id: 'Particular',
        label: 'Particular',
      },
      {
        id: 2,
        string_id: 'Student',
        label: 'Estudiante',
      },
      {
        id: 3,
        string_id: 'Parent',
        label: 'Representante',
      },
      {
        id: 4,
        string_id: 'Teacher',
        label: 'Profesor',
      },
      {
        id: 5,
        string_id: 'School',
        label: 'Colegio',
      },
      {
        id: 6,
        string_id: 'Back_Office_Admin',
        label: 'Administrador del Back Office',
      },
      {
        id: 7,
        string_id: 'Coordinator',
        label: 'Coordinador',
      },
    ];
    return user_types;
  }

  GET_USER_TYPES_DIC() {
    return {
      Particular: {
        id: 1,
        label: 'Particular',
      },
      Student: {
        id: 2,
        label: 'Estudiante',
      },
      Parent: {
        id: 3,
        label: 'Representante',
      },
      Teacher: {
        id: 4,
        label: 'Profesor',
      },
      School: {
        id: 5,
        label: 'Coordinador',
      },
      Back_Office_Admin: {
        id: 6,
        label: 'Administrador',
      },
    };
  }

  getSettings() {
    const url = environment.apiUrl + '/system-settings/1/';
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateSettings(settings: any) {
    const url = environment.apiUrl + '/system-settings/1/';

    const settingsWithoutLogo = { ...settings };
    delete settingsWithoutLogo.logo;

    var formData: any = objectToFormData(settings);

    if (settings.logo instanceof File) {
      formData.append('logo', settings.logo);
    }

    return this.httpClient.put(url, formData).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  getScheinsParameters() {
    return [
      {
        label: 'Estilo de vida',
        slug: 'life_style',
      },
      {
        label: 'Seguridad',
        slug: 'security'
      },
      {
        label: 'Creatividad',
        slug: 'creativity'
      },
      {
        label: 'Reto',
        slug: 'challenge'
      },
      {
        label: 'Organizar',
        slug: 'organize'
      },
      {
        label: 'Técnico/funcional',
        slug: 'functional_technical'
      },
      {
        label: 'Autonomía',
        slug: 'autonomy'
      },
      {
        label: 'Servicio',
        slug: 'service'
      },
    ]
  }
}
