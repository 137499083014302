<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading">
  <ng-template #content> <!--Modal Confirm-->
    <div class="modal-body">
      <p class="font-16 font-primary--b text-center">¿Está usted seguro de realizar esta acción?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn">
        <span class="text-red" (click)="dismissModal()">Cancelar</span>
      </button>
      <button type="button" class="btn bg-blue">
        <span class=" text-white" (click)="deleteCoupon()">Continuar</span>
      </button>
    </div>
  </ng-template>

  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content class="row">
        <div class="col d-flex justify-content-start align-items-center">
          <h1 class="font-24 font-primary--eb">{{career.title}}</h1>
          <!-- <span class="font-24 font-primary--eb {{career?.is_available ? 'text-success' : 'text-error'}}">
            {{career?.is_available ? 'Activo' : 'Inactivo'}}
          </span> -->
        </div>

        <div class="d-flex col-md-4 col-md-offset-4 justify-content-start md:justify-content-end align-items-center">
          <a type="button" routerLink="/dashboard/reportes/carreras-universitarias/{{career.id}}/editar"
            class="btn bg-warn text-white align-self-center">
            Editar
          </a>

          <button class="btn bg-error text-white ms-2" (click)="confirmDeleteCoupon(career.id!)">
            Eliminar
          </button>
        </div>
      </article>
    </app-card>
  </section>
  <div class="career-wrapper">
    <div class="row">
      <div class="col-6">
        <div class="row">
          <div class="col-12 mt-3" [ngClass]="'order-'+item.order" *ngFor="let item of customContent.true">
            <app-card padding="27px 36px 79px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100"
              borderRadius="10px">
              <article card__content>
                <div class="d-flex align-items-start justify-content-between">
                  <h2 class="font-18 font-bold mb-4">
                    {{item.order}}. {{item.title}}
                  </h2>
                  <!-- <div>
                        <button class="btn bg-danger text-white me-2" (click)="deleteCustomContent(item.id!)">
                          Eliminar
                        </button>
                        <button class="btn bg-warning text-white" (click)="editingCustomContent(item, newCustomContent)">
                          Editar
                        </button>
                      </div> -->
                </div>
                <div style="line-height: 1.7;" [innerHtml]="item.description | safeHtml"></div>
              </article>
            </app-card>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 mt-3" [ngClass]="'order-'+item.order" *ngFor="let item of customContent.false">
            <app-card padding="27px 36px 79px 60px" boxShadow="rgba(0, 0, 0, 0.1) 0px 2px 1px" width="100"
              borderRadius="10px">
              <article card__content>
                <div class="d-flex align-items-start justify-content-between">
                  <h2 class="font-18 font-bold mb-4">
                    {{item.order}}. {{item.title}}
                  </h2>
                  <!-- <div>
                        <button class="btn bg-danger text-white me-2" (click)="deleteCustomContent(item.id!)">
                          Eliminar
                        </button>
                        <button class="btn bg-warning text-white" (click)="editingCustomContent(item, newCustomContent)">
                          Editar
                        </button>
                      </div> -->
                </div>
                <div style="line-height: 1.7;" [innerHtml]="item.description | safeHtml"></div>
              </article>
            </app-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>