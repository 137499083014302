export const normalizeDate = (
  dateToNormalize: Date | string,
  withoutTime: boolean = false
): string => {
  const date = new Date(dateToNormalize);

  if (withoutTime) {
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};
