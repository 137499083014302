<section class="mt-3">
  <ul class="row mx-0 px-0">
    <app-search-list-bar class="px-0">
      <section left-side class="col-3 d-flex align-items-center">
        <app-selector [data]="orderOptions"></app-selector>
      </section>
    </app-search-list-bar>
    <div class="mt-3" *ngIf="isLoading">
      <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
        <div card__content>
          <app-loading color="#de377c"></app-loading>
          <h2 class="text-center font-18 opacity-50 font-primary--b mt-3">
            Por favor, espere un momento
          </h2>
        </div>
      </app-card>
    </div>
    <section *ngIf="!isLoading" class="d-flex flex-column gap-2 px-0">
      <app-list-element
        *ngFor="let report of reportsToAdd; index as i"
        [entityData]="report"
        [customTemplate]="'DEFAULT'"
        [customIdProp]="'order'"
        [isCheckable]="true"
        [isSingleRowDesign]="true"
        [idLabel]="'#'"
        [disabled]="report.checked"
        (onClick)="triggerCheckbox($event, report)"
      >
      </app-list-element>
    </section>
  </ul>
</section>
