import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { InsightServiceService } from './../../../services/insight-service.service';
import { Islide } from './../../../models/insight';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './insight-page.component.html',
  styleUrls: ['./insight-page.component.scss']
})

export class InsightPageComponent implements OnInit {
  inProcess: boolean = false
  slides: Array<Islide> = [];
  editMode: boolean = false
  slide: Islide = {
    title: '',
    description: '',
    link: ''
  }

  constructor(
    private insightService: InsightServiceService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getSlides()
  }

  getSlides() {
    this.inProcess = true
    this.insightService.getSlides().subscribe({
      next: (data: Array<Islide>) => {
        this.inProcess = false
        this.slides = data
      },
      error: (e) => {
        this.inProcess = false
        console.debug(e)
      }
    })

  }

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
    })
  }

  newSlide() {
    this.inProcess = true
    this.insightService.newSlides(this.slide).subscribe({
      next: (data) => {
        this.inProcess = false
        this.getSlides()
        this.modalService.dismissAll()
        console.debug(data)
      },
      error: (e) => {
        this.inProcess = false
        console.debug(e)
      },
    })
  }

  deleteSlide(slideId: number, content: any) {

    if (confirm('¿Está seguro de realizar esta acción?')) {
      this.inProcess = true
      this.modalService.open(content, { backdrop: 'static' })
      setTimeout(() => {

      }, 2000);
      this.insightService.deleteSlides(slideId).subscribe({
        next: (data) => {
          this.inProcess = false
          this.getSlides()
          this.modalService.dismissAll()
        },
        error: (e) => {
          this.inProcess = false
          this.modalService.dismissAll()
        },
      })
    }
  }

  editSlideMode(slide: Islide, content: any) {
    this.editMode = true
    this.slide = slide
    this.open(content)
  }
  editSlide() {
    // console.debug(this.slide)
    this.inProcess = true
    this.insightService.updateSlides(this.slide).subscribe({
      next: (data) => {
        this.inProcess = false
        this.getSlides()
        this.modalService.dismissAll()
        console.debug(data)
      },
      error: (e) => {
        this.inProcess = false
        console.debug(e)
      },
    })
  }

}
