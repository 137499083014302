import { ActivityQuestion } from './../../models/activities/activity.models';
import { Activity } from "src/app/models/activities/activity.models";

export class AddActivity {
  static readonly type = '[Activity] add new activity';
  constructor(public activity: Activity) { }
}
export class RemoveActivity {
  static readonly type = '[Activity] remove activity by id';
  constructor(public activityId: string) { }
}
export class SetActivity {
  static readonly type = '[Activity] set activity values';
  constructor(public activity: Activity) { }
}
export class SetActivityCreated {
  static readonly type = '[Activity] set activity created';
  constructor(public activity: ActivityQuestion | null) { }
}
export class ResetActivityCreated {
  static readonly type = '[Activity] reset activity created';
  constructor() { }
}
