import { OptionActivity } from 'src/app/models/activities/option.models';
import { generateID } from 'src/app/utils/fakers';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-regular-valoration-activity',
  templateUrl: './regular-valoration-activity.component.html',
  styleUrls: ['./regular-valoration-activity.component.scss']
})
export class RegularValorationActivityComponent implements OnInit {
  @Input() video?: string

  @Input() statement: string = '¿Lorem ipsum?'
  selectedValue: number = 1
  @Input() options: Array<OptionActivity> = []

  constructor() { }

  ngOnInit(): void {
  }

  updateRadio(index: number) {
    this.options.forEach(value => {
      value.selected = false
    });
    this.options[index].selected = true
  }

}
