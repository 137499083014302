export interface User {
  email?: string;
  password: string;
  username: string;
}
export interface NewUser {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface IUserDetail {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  tax_id: string | number | null;
  image_profile: string | null;
  is_subscribed: boolean;
  is_active: boolean;
  description: string;
  authored_at: string;
  preliminar_phase_completed: boolean;
  completed: boolean;
  user_type:
    | 'Particular'
    | 'Student'
    | 'Parent'
    | 'Teacher'
    | 'School'
    | 'Back_Office_Admin';
}
