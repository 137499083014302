<main class="page insight-page">
  <!-- HEADER -->
  <ng-template #newSlideModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Nuevo slide (Insight)</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <form>
        <div class="row">
          <!-- title -->
          <div class="col-12 d-flex flex-column">
            <label for="title">Título</label>
            <input class="input" type="text" id="title" [(ngModel)]="slide.title" name="title" placeholder="Título">
          </div>
          <!-- description -->
          <div class="col-12 d-flex flex-column my-3">
            <label for="description">Descripción</label>
            <textarea name="description" id="description" [(ngModel)]="slide.description" cols="30" rows="5"
              placeholder="Escribe el contenido del slide aquí"></textarea>
          </div>
          <!-- Link -->
          <div class="col-12 d-flex flex-column">
            <label for="link">Enlace</label>
            <input class="input" type="text" [(ngModel)]="slide.link" id="link" name="link"
              placeholder="http://growthroad.es/">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark bg-transparent text-red" (click)="modal.close()">Volver</button>
      <button *ngIf="!editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="newSlide()">
        <span class="text-white" *ngIf="!inProcess">
          Guardar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
      <button *ngIf="editMode" type="button" class="btn btn-outline-dark bg-green " [disabled]="inProcess"
        (click)="editSlide()">
        <span class="text-white" *ngIf="!inProcess">
          Editar
        </span>
        <app-loading *ngIf="inProcess" color="#fff"></app-loading>
      </button>
    </div>
  </ng-template>
  <ng-template #loaderModal let-modal>
    <div class="modal-header justify-content-center">
      <h4 class="modal-title text-center pb-3" id="modal-basic-title">Porfavor espere un momento</h4>
    </div>
  </ng-template>
  <section>
    <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
      <article card__content>
        <div class="row align-items-center">
          <div class="col ">
            <h1 class="font-24 font-primary--eb">
              Insight
            </h1>
          </div>
          <div class="col-auto off">
            <button class="btn bg-green text-white" (click)="open(newSlideModal)" type="button">
              Añadir otro
            </button>
          </div>
        </div>
      </article>
    </app-card>
  </section>

  <!-- Insight table -->
  <section class="mt-3">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Título</th>
          <th scope="col">Descripción</th>
          <th scope="col">Enlace</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let slide of slides; index as i">
          <th scope="row">{{ i + 1 }}</th>
          <td>
            {{ slide.title }}
          </td>
          <td>
            {{ slide.description }}
          </td>
          <td>
            {{ slide.link }}
          </td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" ngbDropdownToggle id="userActions" type="button">
                Acciones
              </button>

              <div ngbDropdownMenu aria-labelledby="userActions">

                <button ngbDropdownItem (click)="editSlideMode(slide, newSlideModal)">
                  Editar
                </button>

                <button ngbDropdownItem (click)="deleteSlide(slide.id!, loaderModal)">
                  Eliminar
                </button>

                <!-- <button ngbDropdownItem >
                    <span *ngIf="user.in_progress" class="spinner-border spinner-border-sm text-white" role="status"
                      aria-hidden="true"></span>
                    {{ !user.is_active ? 'Habilitar' : 'Deshabilitar' }}
                  </button> -->
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</main>
