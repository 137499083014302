import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICareerUniversity } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { ErrorsService } from 'src/app/services/errors.service';
import { ModalComponent } from '../../core/modal/modal.component';

@Component({
  selector: 'app-careers-university-page',
  templateUrl: './careers-university-page.component.html',
  styleUrls: ['./careers-university-page.component.scss']
})
export class CareersUniversityPageComponent implements OnInit {
  @ViewChild('content') modal!: ModalComponent;
  careerToDelete!: string | number
  careers: ICareerUniversity[] = []
  isLoading: boolean = true

  constructor(
    private careersService: CareersService,
    private errorsService: ErrorsService,
    private modalService: NgbModal,
    private title: Title,
  ) {
    this.title.setTitle('Carreras Universitarias | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.careersService.getCareers().subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICareerUniversity[]) => this.careers = data,
      complete: () => this.isLoading = false
    })
  }

  confirmDeleteCareer(id: string | number) {
    this.careerToDelete = id;
    this.modalService.open(this.modal, { centered: true })
  }

  deleteCareer(): void {
    const careerId = this.careerToDelete as number

    this.careersService.deleteCareer(careerId).subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: ICareerUniversity) => console.log('deleteCareer data', data),
      complete: () => {
        const careerIdx = this.careers.findIndex((career => career.id === careerId))
        this.careers.splice(careerIdx, 1)
      }
    })
    this.dismissModal()
  }

  dismissModal() {
    this.modalService.dismissAll()
  }
}
