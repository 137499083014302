import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreationalPagesLayoutService {
  private readonly _onClickHeaderButton: Subject<boolean> = new Subject();

  private readonly _onSubmit: Subject<boolean> = new Subject();
  private readonly _onDismiss: Subject<boolean> = new Subject();
  private readonly _onDelete: Subject<boolean> = new Subject();

  public readonly layoutConfig: BehaviorSubject<{
    headerTitle: string;
    headerTitleIcon: string;
    headerButtonText?: string;
    headerButtonIcon?: string;
    footerConfig?: {
      submitButtonText: string;
      dismissButtonText?: string;
      deleteButtonText?: string;
    };
  }> = new BehaviorSubject({
    headerTitle: '',
    headerTitleIcon: '',
  });

  constructor() {}

  public get onClickHeaderButton() {
    return this._onClickHeaderButton.asObservable();
  }

  public set triggerClickHeaderButton(value: boolean) {
    if (value === true) {
      this._onClickHeaderButton.next(value);
    }
  }

  public get onSubmit() {
    return this._onSubmit.asObservable();
  }

  public set triggerSubmit(value: boolean) {
    if (value === true) {
      this._onSubmit.next(value);
    }
  }

  public get onDismiss() {
    return this._onDismiss.asObservable();
  }

  public set triggerDismiss(value: boolean) {
    if (value === true) {
      this._onDismiss.next(value);
    }
  }

  public get onDelete() {
    return this._onDelete.asObservable();
  }

  public set triggerDelete(value: boolean) {
    if (value === true) {
      this._onDelete.next(value);
    }
  }
}
