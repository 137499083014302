import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICustomContent } from 'src/app/models/customContent.models';
import { PhrasesService } from 'src/app/services/phrases.service';
import { generateID } from 'src/app/utils/fakers';

@Component({
  templateUrl: './custom-content.component.html',
  styleUrls: ['./custom-content.component.scss']
})
export class CustomContentComponent implements OnInit {
  editMode: boolean = true
  inProcess: boolean = false
  categoryId?: string


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ]
  };

  customContent: any = null

  customContents!: { true: Array<ICustomContent>, false: Array<ICustomContent> }

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private phrasesService: PhrasesService,
  ) { }

  ngOnInit(): void {
    this.categoryId = this.route.snapshot.paramMap.get('id')!
    this.getCustomContents(this.categoryId!)
  }

  open(content: any, toEditMode: boolean = false) {
    this.editMode = true
    if (!toEditMode) {
      this.editMode = false
      this.customContent = {
        title: '',
        description: '',
        order: 1,
        left_aligned: true,
        category: this.categoryId
      }
    }

    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg'
    })
  }


  createCustomContent() {
    this.inProcess = true
    this.phrasesService.newCustomContent(this.customContent).subscribe({
      next: (data) => {
        this.inProcess = false
        this.modalService.dismissAll()
        console.debug(data)
        this.getCustomContents(this.categoryId!)
        this.customContent = {
          title: '',
          description: '',
          order: 1,
          left_aligned: true,
          category: this.categoryId
        }
      },
      error: (error) => {
        this.inProcess = false
        this.modalService.dismissAll()
        console.debug(error)
      },
    })
  }

  editingCustomContent(institution: any, content: any) {
    this.customContent = institution
    this.open(content, true)
  }

  editCustomContent() {
    this.inProcess = true
    this.phrasesService.editCustomContent(this.customContent).subscribe({
      next: (data) => {
        this.inProcess = false
        this.modalService.dismissAll()
        console.debug(data)
        this.getCustomContents(this.categoryId!)
      },
      error: (error) => {
        this.inProcess = false
        this.modalService.dismissAll()
        console.debug(error)
      },
    })
  }

  getCustomContents(categoryId: string) {
    this.phrasesService.getCustomContentByCategory(categoryId).subscribe({
      next: (data: any) => {
        this.inProcess = false
        // this.modalService.dismissAll()
        // this.customContents = data as Array<ICustomContent>

        const groupByLeftAligned = data.reduce((group: any, item: any) => {
          const { left_aligned } = item;
          group[left_aligned] = group[left_aligned] ?? [];
          group[left_aligned].push(item);
          return group;
        }, {});

        console.debug(groupByLeftAligned)
        this.customContents = groupByLeftAligned

        // this.getCustomContents(this.categoryId!)
      },
      error: (error) => {
        this.inProcess = false
        // this.modalService.dism}issAll()
        console.debug(error)
      },
    })
  }

  deleteCustomContent(customContentId: string) {
    if (confirm('Está seguro que desea realizar esta acción')) {
      this.phrasesService.removeCustomContent(customContentId).subscribe({
        next: (data) => {
          this.inProcess = false
          this.modalService.dismissAll()
          console.debug(data)
          this.getCustomContents(this.categoryId!)
        },
        error: (error) => {
          this.inProcess = false
          this.modalService.dismissAll()
          console.debug(error)
        }
      })

    }
  }
}
