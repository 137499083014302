import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CAREER_TYPE, CAREER_TYPE_LABEL, ICareerToCreate, ICareerUniversity } from 'src/app/models/career.model';
import { CareersService } from 'src/app/services/careers.service';
import { handleFormErrors } from 'src/app/utils/handleFormErrors';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new-career-university-page',
  templateUrl: './new-career-university-page.component.html',
  styleUrls: ['./new-career-university-page.component.scss']
})
export class NewCareerUniversityPageComponent implements OnInit {
  createCareerForm!: FormGroup
  career!: ICareerUniversity
  CAREER_TYPE_LABEL = CAREER_TYPE_LABEL
  CAREER_TYPE = CAREER_TYPE
  formErrors: any = {}
  isLoading: boolean = false
  icon: { preview: any, file: any } = {
    preview: null,
    file: null
  }
  outputProfile: string = ''
  outputTitle: string = ''
  outputOrder: number = 1
  outputLeftAligned: boolean = false

  acronym: string = ''
  description: string = ''
  descriptionOrder: number = 1
  descriptionLeftAligned: boolean = false

  job: string = ''
  jobTitle: string = ''
  jobOrder: number = 1
  jobLeftAligned: boolean = false

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la frase.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ]
  };

  constructor(
    private careerService: CareersService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router,
    private title: Title
  ) {
    this.title.setTitle('Nueva Carrera Universitaria | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.createCareerForm = this.initFrom()
  }

  initFrom(): FormGroup {
    return this.fb.group({
      title: ['', [Validators.required]],
      type: ['', [Validators.required]],
      primary_color: ['', [Validators.required]],
      // secundary_color: ['', [Validators.required]],
    })
  }

  onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.icon.file = file

      const reader = new FileReader()
      reader.onload = () => this.icon.preview = reader.result
      reader.readAsDataURL(file)
    }
  }

  onSubmit() {
    this.formErrors = null
    this.isLoading = true

    const { errors, totalErrors } = handleFormErrors(this.createCareerForm)

    if (totalErrors) {
      console.error('Create Career University Error', errors)
      this.formErrors = errors
      this.isLoading = false
      return
    }

    // !this.description || !this.job || !this.outputProfile || !this.icon.file
    if (!this.description || !this.job || !this.outputProfile) {
      this.modalService.open('Faltan campos requeridos')
      this.isLoading = false
      return
    }

    let careerToCreate: ICareerToCreate = this.createCareerForm.value

    careerToCreate = {
      ...careerToCreate,
      icon: this.icon.file,

      output_profile: this.outputProfile,
      output_title: this.outputTitle,
      output_order: this.outputOrder,
      output_left_aligned: this.outputLeftAligned,

      job: this.job,
      job_title: this.jobTitle,
      job_order: this.jobOrder,
      job_left_aligned: this.jobLeftAligned,

      acronym: this.acronym,
      description: this.description,
      description_order: this.descriptionOrder,
      description_left_aligned: this.descriptionLeftAligned,
    }

    this.careerService.createCareer(careerToCreate).subscribe({
      error: (err) => console.error('createCareer error', err),
      next: (data: ICareerUniversity) => this.career = data,
      complete: () => {
        this.isLoading = false
        this.router.navigate([`/dashboard/reportes/carreras-universitarias/`])
      }
    })
  }

  dismissModal() {
    this.modalService.dismissAll();
  }
}

