<div class="activity-wrapper">
  <!-- <div [froalaView]="statement" class="activity-statement"></div> -->
  <div [innerHtml]="statement | safeHtml"></div>
  <hr class="separator">
  <div class="video-container mb-3" *ngIf="video">
    <app-video [options]="{
            fluid: true,
            aspectRatio: '16:9',
            autoplay: false,
            controls: true,
            sources: [{
              src: video,
              type: 'video/mp4' }]
            }">
    </app-video>
  </div>
  <textarea class="input" [(ngModel)]="inputData" placeholder="{{placeholder}}" id="" cols="30" rows="10"></textarea>
</div>
