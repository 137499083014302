import { UpdateType } from './type.actions';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

interface TypeStateModel {
  type: string | null
}

const STATE_TOKEN = new StateToken<TypeStateModel>(
  'Type'
);

@State<TypeStateModel>({
  name: STATE_TOKEN,
  defaults: {
    type: null
  }
})

@Injectable()
export class TypeState {
  @Action(UpdateType)
  updateType(ctx: StateContext<TypeStateModel>, action: UpdateType) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      type: action.payload
    });
  }

  @Selector()
  static getType(state: TypeStateModel) {
    return state.type
  }
}

