<div class="activities-page page mt-3" *ngIf="isLoading">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <app-loading color="#de377c"></app-loading>
    </div>
  </app-card>
</div>

<div class="activities-page page" *ngIf="!isLoading && !coupon">
  <app-card borderRadius="10px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <div card__content>
      <span class="font-25 font-bold">
        ¡Hubo un error al cargar un cupón!
      </span>
    </div>
  </app-card>
</div>

<main class="activities-page page" *ngIf="!isLoading && coupon">
  <app-card borderRadius="10px" padding="20px 40px" boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px">
    <article card__content class="row">
      <h1 class="font-24 font-primary--eb">
        {{coupon.code}}
      </h1>
    </article>
  </app-card>

  <app-card
    width="100%"
    borderRadius="10px"
    padding="20px 40px"
    class="coupon-wrapper"
    boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
  >
    <form
      (ngSubmit)="onSubmit()"
      [formGroup]="couponEditForm"
      card__content class="w-100"
    >
      <div>
        <label for="name" class="text-start w-100 font-bold">Nombre del Cupon</label>

        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['name']"
        >
          {{formErrors?.['name']?.label}}
        </span>
    
        <input
          id="name"
          name="name"
          type="text"
          [value]="coupon.name"
          class="input w-100"
          placeholder="Ingrese nombre del cupón"
          formControlName="name"
        />
      </div>
    
      <div class="mt-3">
        <label for="code" class="text-start w-100 font-bold">Codigo</label>
    
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['code']"
        >
          {{formErrors?.['code']?.label}}
        </span>
    
        <input
          id="code"
          name="code"
          type="text"
          [value]="coupon.code"
          class="input w-100"
          placeholder="Ingrese codigo del cupón"
          formControlName="code"
        />
      </div>

      <div class="mt-3">
        <label for="description" class="text-start w-100 font-bold">Descripción del Cupon</label>
    
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['description']"
        >
          {{formErrors?.['description']?.label}}
        </span>
    
        <input
          type="text"
          id="description"
          name="description"
          class="input w-100"
          [value]="coupon.description"
          placeholder="Ingrese descripción del cupon"
          formControlName="description"
        />
      </div>

      <div class="row mt-3">
        <div class="col-6">
          <label for="discount_percentage" class="text-start w-100 font-bold">Porcentaje de Descuento <span class="text-success">{{discountPercentage}}%</span></label>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['discount_percentage']"
          >
            {{formErrors?.['discount_percentage']?.label}}
          </span>

          <!-- <input
            id="discount_percentage"
            name="discount_percentage"
            class="input w-100"
            type="number"
            [max]="100"
            maxlength="3"
            [defaultValue]="0"
            oninput="this.value|=0"
            [value]="coupon.discount_percentage"
            placeholder="Ingrese porcentaje a descontar"
            formControlName="discount_percentage"
          /> -->

          <input
            type="text"
            id="discount_percentage"
            name="discount_percentage"
            class="input w-100"
            appPercentageDirective
            [(ngModel)]="discountPercentage"
            formControlName="discount_percentage"
            placeholder="Ingrese porcentaje a descontar"
          />
        </div>

        <div class="col-6">
          <label for="available_quantity" class="text-start w-100 font-bold">Cantidad de Cupones</label>
      
          <span
            class="text-start d-block font-14 text-error"
            *ngIf="formErrors?.['available_quantity']"
          >
            {{formErrors?.['available_quantity']?.label}}
          </span>
      
          <input
            id="available_quantity"
            name="available_quantity"
            class="input w-100"
            type="number"
            [defaultValue]="0"
            oninput="this.value|=0"
            [max]="100"
            [value]="coupon.available_quantity"
            placeholder="Ingrese porcentaje a descontar"
            formControlName="available_quantity"
          />
        </div>
      </div>
    
      <!-- <div class="mt-3">
        <label for="is_available" class="text-start w-100 font-bold">Estado del Cupon</label>
    
        <span
          class="text-start d-block font-14 text-error"
          *ngIf="formErrors?.['is_available']"
        >
          {{formErrors?.['is_available']?.label}}
        </span>

        <div class="d-flex align-items-center mt-1">
          <span class="font-20 font-primary--eb {{isCouponActive ? 'text-success' : 'text-error'}}">
            {{isCouponActive ? 'Activo' : 'Inactivo'}}
          </span>
      
          <label class="switch ms-3">
            <input
              id="is_available"
              name="is_available"
              formControlName="is_available"
              type="checkbox"
              (click)="toggleIsActive()"
              [checked]="isCouponActive"
            >
            <span class="slider round"></span>
          </label>
        </div>
      </div> -->
      
      <button class="btn font-btn bg-red w-100 font-26 mt-3">
        <app-loading *ngIf="isLoading" color="rgba(255, 255, 255, 1)"></app-loading>
    
        <span class="text-white" *ngIf="!isLoading">
          Actualizar Cupon
        </span>
      </button>
    </form>
  </app-card>
</main>