import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ParametersService } from 'src/app/services/parameters.service';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';

@Component({
  templateUrl: './parameters-page.component.html',
  styleUrls: ['./parameters-page.component.scss']
})
export class ParametersPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  parameters: { id: number, parameter_name: string, description: string | null }[] = []

  constructor(
    private parameterService: ParametersService,
    private errorsService: ErrorsService,
    private title: Title
  ) {
    this.title.setTitle('Parámetros | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.parameterService.getAllParameters().subscribe({
      error: ({ error }) => {
        const { htmlErrorsContent } =  this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
      next: (data: any) => this.parameters = data
    })
  }
}
