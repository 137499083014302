<div class="modal-header" #modal>
  <h4 class="font-header text-center">{{title}}</h4>
</div>

<div class="modal-body">
  <div class="font-regular" [outerHTML]="content"></div>
</div>

<div class="modal-footer modal-footer-center">
  <button type="button" class="modal-footer--cta {{ content ? '' : 'mt-3' }}" (click)="close()">
    {{ actionLabel || 'Entendido' }}
  </button>
</div>