<!-- PROGRAM CONTENT LIST -->
<section class="mt-3" *ngIf="!addContent">
  <app-page-loader *ngIf="isLoading"></app-page-loader>
  <ul
    class="d-flex flex-column gap-2"
    *ngIf="!isLoading"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <app-list-element
      *ngFor="let content of chapterContents; index as i"
      cdkDrag
      [entityData]="content"
      [isDragable]="true"
      [actions]="listElementActions"
      (onClickAction)="handleListElementClickAction($event, content)"
    >
    </app-list-element>
  </ul>
  <ng-container *ngIf="!isLoading">
    <article
      class="mt-3"
      *ngIf="chapterContents && chapterContents.length === 0"
    >
      <app-card
        borderRadius="10px"
        padding="80px 120px"
        boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
      >
        <section card__content>
          <h2 class="text-center font-24 opacity-50">
            No has agregado ningún contenido aún,
            <span
              class="text-green cursor-pointer"
              (click)="handleTurnAddContent()"
              >agregalos aquí</span
            >
          </h2>
        </section>
      </app-card>
    </article></ng-container
  >
</section>

<!-- CONTENT TO ADD LIST -->
<section class="mt-3" *ngIf="addContent">
  <app-search-list-bar>
    <section left-side class="col-3 d-flex align-items-center">
      <app-selector [data]="orderOptions"></app-selector>
    </section>
  </app-search-list-bar>
  <app-page-loader *ngIf="isLoading"></app-page-loader>
  <ul
    class="d-flex flex-column gap-2"
    *ngIf="!isLoading"
    cdkDropList
    (cdkDropListDropped)="drop($event)"
  >
    <app-list-element
      *ngFor="let content of contentsToAdd; index as i"
      cdkDrag
      [entityData]="content"
      [isCheckable]="true"
      [disabled]="content.checked"
      (onClick)="triggerCheckbox($event, content)"
    >
    </app-list-element>
  </ul>
  <article class="mt-3" *ngIf="contentsToAdd && contentsToAdd.length === 0">
    <app-card
      borderRadius="10px"
      padding="80px 120px"
      boxShadow="rgba(0, 0, 0, .1) 0px 2px 0px"
    >
      <section card__content>
        <h2 class="text-center font-24 opacity-50">
          No has creado ningún contenido aún,
          <span
            class="text-green cursor-pointer"
            routerLink="/dashboard/pages/contenidos/nuevo/"
            >crealos aquí</span
          >
        </h2>
      </section>
    </app-card>
  </article>
</section>
