import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Store } from '@ngxs/store';

import { ChapterNormalized, normalizePages } from 'src/app/utils/pages';
import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';
import { OpenModal } from 'src/app/store/ui/ui.actions';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';

@Component({
  selector: 'app-pages-chapters-page',
  templateUrl: './pages-chapters-page.component.html',
  styleUrls: ['./pages-chapters-page.component.scss']
})
export class PagesChaptersPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  chapterId!: string
  seasonId!: number
  chapter!: any
  isLoading: boolean = false

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private location: Location,
    private store: Store,
    private title: Title
  ) {
    this.title.setTitle('Temas del Capítulo | Backoffice - Growth Road')
  }

  ngOnInit(): void {
    this.seasonId = Number(this.route.snapshot.paramMap.get('seasonId'))
    this.chapterId = this.route.snapshot.paramMap.get('chapterId')!
    this.getThemesByChapterId(this.chapterId)
  }

  navigateToBack() {
    this.location.back()
  }

  getThemesByChapterId(chapterId: string) {
    this.isLoading = true
    this.pageService.getBranch(chapterId).subscribe({
      next: (data) => {
        this.chapter = data
        this.isLoading = false
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.isLoading = false

        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      },
    })
  }

  confirmDelete(id: string | number) {
    // TODO IMPROVE UI
    if (confirm('¿Seguro desea realizar esta acción?')) {
      this.deletePage(id)
    }
  }

  deletePage(id: string | number) {
    this.pageService.deletePage(id as string).subscribe({
      complete: () => {
        this.getThemesByChapterId(this.chapterId)
        this.store.dispatch(new OpenModal('Página eliminada exitosamente.'))
      },
      error: ({ error }) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error)
        this.modal.open('Algo ha ocurrido', htmlErrorsContent)
      }
    })
  }
}
