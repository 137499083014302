import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';

import { PageService } from 'src/app/services/page.service';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { Router } from '@angular/router';
import { CreationalPagesLayoutService } from '../../../services/creational-pages-layout.service';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { sortByProperty } from 'src/app/utils/filters';
import { SearchBarListService } from 'src/app/services/search-bar-list.service';
import { GroupService } from 'src/app/services/group.service';
import { Group } from 'src/app/models/group.model';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  templateUrl: './team-list-page.component.html',
  styleUrls: ['./team-list-page.component.scss'],
  providers: [SearchBarListService],
})
export class TeamListPageComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  groups!: Observable<any>;
  isLoading: boolean = true;
  groupId!: string;
  published: boolean = false;
  orderOptions: string[] = ['Alfabeticamente', 'Mas reciente', 'Mas antiguo'];
  private groupsData$: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_white.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private groupService: GroupService,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private store: Store,
    public searchBarListService: SearchBarListService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Grupos | Backoffice - Growth Road');
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: 'Grupos',
      headerTitleIcon: '/assets/images/icons/icon_team.svg',
      headerButtonText: 'Crear grupo',
      headerButtonIcon: '/assets/images/icons/icon_create.svg',
    });
  }

  ngOnInit(): void {
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.router.navigate(['dashboard/grupos/nuevo']);
        }
      });

    this.getGroups();
  }

  ngAfterViewInit(): void {
    this.groups = combineLatest([
      this.groupsData$,
      this.searchBarListService.getSearchInput().pipe(startWith('')),
      this.searchBarListService
        .getFilterSelected()
        .pipe(startWith('Mas reciente')),
    ]).pipe(
      tap(() => (this.isLoading = true)),
      map(([groups, inputSearchResult, selectedOption]) => {
        return [
          this.handleApplyFilter(groups, selectedOption),
          inputSearchResult,
        ];
      }),
      map(([groups, inputSearchResult]) => {
        return (groups as any[]).filter((program: any) =>
          this.searchBarListService
            .concatPropertiesValues(program, ['id', 'name'])
            .includes(inputSearchResult as string)
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );

    this.groups.subscribe({
      next: () => (this.isLoading = false),
      error: (error) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(error);
        this.isLoading = false;

        this.modal.open('Algo ha ocurrido', htmlErrorsContent);
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getGroups() {
    this.isLoading = true;
    this.groupService
      .getGroups()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: Group[]) => {
        this.isLoading = false;
        this.groupsData$.next(data);
      });
  }

  public handleApplyFilter(
    data: Record<string, string | number>[],
    filter: string
  ) {
    const options = {
      [this.orderOptions[0]]: () => sortByProperty(data, 'name', 'asc'),
      [this.orderOptions[1]]: () => sortByProperty(data, 'id', 'desc'),
      [this.orderOptions[2]]: () => sortByProperty(data, 'id', 'asc'),
    };

    if (options[filter]) {
      return options[filter]();
    }

    return data;
  }

  confirmDelete() {
    this.universalModalService.openModal(this.modalDeleteTemplateRef);
  }

  public handleCloseModal() {
    this.universalModalService.closeModal();
  }

  public deleteGroup() {
    this.handleCloseModal();
    this.groupService.deleteGroup(this.groupId).subscribe({
      next: (response: any) => {
        this.router.navigate(['dashboard/grupos']);
        this.universalModalService.openModal(
          undefined,
          null,
          'Grupo eliminado exitosamente'
        );
        this.getGroups();
      },
      error: (response: any) => {
        this.universalModalService.openModal(undefined, null, 'Algo salio mal');
      },
    });
  }

  public handleListElementClick(event: any) {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: event.name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([`/dashboard/grupos/${event.id}/usuarios`]);
  }

  public handleListElementClickAction(actionName: string, group: any): void {
    this.groupId = group.id;
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(group),
      delete: () => this.confirmDelete(),
    };

    actions[actionName]();
  }

  public handleListElEdit(group: any) {
    this.router.navigate([`/dashboard/grupos/${group.id}/editar`]);
  }
}
