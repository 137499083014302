import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Store } from '@ngxs/store';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Page } from 'src/app/models/page.models';
import { ActivityQuestion } from 'src/app/models/activities/activity.models';
import { OpenModal } from 'src/app/store/ui/ui.actions';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss'],
})
export class PageFormComponent implements OnInit {
  @ViewChild('inputImage') inputImage!: ElementRef<HTMLInputElement>;
  previewMainImage: any = null;
  isMainImageFile: boolean = true;
  questionaryId: string | null = null;
  chapterId: number | null = null;
  themeId: number | null = null;
  subthemeId: number | null = null;
  activityId: number | null = null;

  @Input() editMode: boolean = false;
  @Input() withoutOrder: boolean = false;
  @Input() activities: Array<ActivityQuestion> = [];
  @Input() three: Array<any> = [];
  @Input() public type: string | null = 'chapter';
  @Input() public page: Page = {
    questionary: null,
    id: '',
    name: '',
    description: '',
    background_color: '#00CAA8',
    gradient_color: '#078CC7',
    font_color: '#ffffff',
    video_url: null,
    order: 0,
    image: null,
    is_activity: false,
    parent: null,
    previous: null,
    next: null,
    is_preliminar_limit: false,
    time: 0,
  };

  orderMin: number = 1;
  orderMax: number = 999;

  types: Array<{ id: string; body: string; slug: string }> | [] = [
    {
      id: '1',
      body: 'Capítulo',
      slug: 'chapter',
    },
    {
      id: '2',
      body: 'Tema',
      slug: 'theme',
    },
    {
      id: '3',
      body: 'Subtema',
      slug: 'subtheme',
    },
    {
      id: '4',
      body: 'Actividad',
      slug: 'activity',
    },
  ];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Ingrese la descripción de la página.',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'font-primary', name: 'Nunito' },
      { class: 'font-secondary', name: 'Creato Display' },
    ],
    customClasses: [
      // {
      //   name: 'quote',
      //   class: 'quote',
      // },
      // {
      //   name: 'redText',
      //   class: 'redText'
      // },
      // {
      //   name: 'titleText',
      //   class: 'titleText',
      //   tag: 'h1',
      // },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName'],
      [
        // 'fontSize',
        'insertImage',
        'insertVideo',
        'customClasses',
      ],
    ],
  };

  constructor(private store: Store, protected sanitizer: DomSanitizer) {}

  resetForm() {
    this.page = {
      id: '',
      name: '',
      description: '',
      background_color: '#ffffff',
      gradient_color: '#ffffff',
      font_color: '#374052',
      video_url: null,
      order: 0,
      image: null,
      is_activity: false,
      parent: null,
      previous: null,
      next: null,
      questionary: this.page.questionary, //?? keep last questionary,
      is_preliminar_limit: false,
    };
    this.questionaryId = null;
    this.chapterId = null;
    this.themeId = null;
    this.subthemeId = null;
    this.activityId = null;
    this.type = 'chapter';
  }

  // TODO: OPTIMIZE!!
  selectablePages() {
    let nextSelectablePages = this.getAllChapters();
    let prevSelectablePages = this.getAllChapters();
    if (this.page.next) {
      prevSelectablePages = this.getAllChapters().filter(
        (page) => page.id != this.page.next
      );
    }
    if (this.page.previous) {
      nextSelectablePages = this.getAllChapters().filter(
        (page) => page.id != this.page.previous
      );
    }
    return { nextSelectablePages, prevSelectablePages };
  }

  getAllQuestionary() {
    let questionaries: Array<any> = [];
    if (this.three.length) {
      questionaries = this.three;
    }
    return questionaries;
  }

  getPagesBy(queryKey: string, queryValue: number): Array<Page> | [] {
    let page: Array<Page> = [];
    const query = this.findInThree(this.three, queryKey, queryValue);
    if (query) {
      page = query.pages!;
    }
    return page;
  }

  getAllChapters() {
    let chapters: Array<any> = [];
    if (this.three.length) {
      chapters = this.three[0].pages;
      if (this.type === 'subtheme') {
        chapters = this.three[0].pages.filter((page: any) => page.pages.length);
      }
    }
    return chapters;
  }

  getAllThemes() {
    let themes: Array<any> = [];
    if (this.three.length) {
      const chapter = this.three[0].pages.find(
        (chapter: any) => chapter.id == this.page.parent
      );
      if (chapter) {
        themes = chapter.pages;
      }
    }
    return themes;
  }

  ngOnInit(): void {}

  handleMinMax(event: any) {
    let parentResult;
    const value = event.target.value;

    if (this.page.parent) {
      parentResult = this.findInThree(this.three, 'id', this.page.parent!);
    } else {
      parentResult = this.three[0];
    }
    this.orderMax = parentResult.pages.length;

    if (value > this.orderMax) {
      this.page.order = this.orderMax;
    }
    if (value < this.orderMin) {
      this.page.order = this.orderMin;
    }
  }

  findInThree(
    three: Array<any>,
    key: string,
    value: string | number
  ): Page | undefined {
    let queryResult;
    JSON.stringify(three, (_, nestedValue) => {
      if (nestedValue && nestedValue[key] == value) {
        queryResult = nestedValue;
      }
      return nestedValue;
    });
    return queryResult;
  }

  _mainImageChange(event: any) {
    this.page.image = event.target.files[0];
    const imageType = event.target.files[0].type;

    const imageSize = event.target.files[0].size;
    const invalidSize = imageSize > 1000000; //?? 1mb === 1000000 bytes

    const invalidType =
      imageType !== 'image/png' &&
      imageType !== 'image/jpeg' &&
      imageType !== 'image/jpg' &&
      imageType !== 'image/gif' &&
      imageType !== 'image/svg+xml';
    const isInvalid = invalidType;

    const reader = new FileReader();
    if (invalidType) {
      this.store.dispatch(new OpenModal('Archivo invalido.'));
      this.page.image = null;
      return;
    }
    if (invalidSize) {
      this.store.dispatch(
        new OpenModal('El maximo de peso soportado por archivo es 1mb.')
      );
      this.page.image = null;
      return;
    }

    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.previewMainImage = this.sanitizer.bypassSecurityTrustUrl(
        reader.result as string
      );
    };
  }

  _mainVideoChange(event: any) {
    this.page.video_url = event.target.files[0];
    console.debug(event.target.files[0]);
  }

  togglePreliminarLimit() {
    this.page.is_preliminar_limit = !this.page.is_preliminar_limit;
  }

  toggleFileTypeImage() {
    this.isMainImageFile = !this.isMainImageFile;
  }

  handleInputImgClick() {
    this.inputImage.nativeElement.click();
  }
}
