import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ErrorsService } from 'src/app/services/errors.service';

import { ModalComponent } from 'src/app/components/core/modal/modal.component';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ReportService } from '../../../services/report.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';

@Component({
  selector: 'app-reports-page',
  templateUrl: './reports-page.component.html',
  styleUrls: ['./reports-page.component.scss'],
})
export class ReportsPageComponent implements OnInit {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('modalDeleteTemplateRef', { read: TemplateRef })
  modalDeleteTemplateRef!: TemplateRef<any>;
  reportGroupId!: string;
  reportId!: string;
  contentDetails!: any;
  reports: any = [];
  isLoading: boolean = false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public listElementActions: { action: string; icon: string }[] = [
    {
      action: 'edit',
      icon: '/assets/images/icons/icon_edit_new.svg',
    },
    {
      action: 'delete',
      icon: '/assets/images/icons/icon_delete_new.svg',
    },
  ];

  constructor(
    private errorsService: ErrorsService,
    private route: ActivatedRoute,
    private location: Location,
    private title: Title,
    private router: Router,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private reportService: ReportService,
    private universalModal: UniversalModalService
  ) {
    this.title.setTitle('Secciones del informe | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        console.log(value);
        if (value) {
          this.goToCreation();
        }
      });

    this.reportGroupId = this.route.snapshot.paramMap.get('reportGroupId')!;
    this.getReportGroupDetails();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  navigateToBack() {
    this.location.back();
  }

  getReportGroupDetails() {
    this.reportService
      .getReportGroupsDetails(this.reportGroupId)
      .subscribe((response: any) => {
        this.creationalPagesLayoutService.layoutConfig.next({
          headerTitle: response.internal_name,
          headerTitleIcon: '/assets/images/icons/icon_content.svg',
          headerButtonIcon: 'assets/images/icons/icon_create.svg',
          headerButtonText: 'Añadir sección',
        });
        this.contentDetails = response;
        this.reports = response.sections;
        console.log(response);
      });
  }

  public goToCreation() {
    this.router.navigate([
      `dashboard/informes/${this.reportGroupId}/reportes/añadir`,
    ]);
  }

  confirmDelete() {
    this.universalModal.openModal(this.modalDeleteTemplateRef);
  }

  public deleteReport() {
    this.reportService
      .deleteReport(this.reportId, this.reportGroupId)
      .subscribe({
        complete: () => {
          this.handleCloseModal();
          this.universalModal.openModal(
            undefined,
            null,
            'Sección eliminada exitosamente'
          );
          this.getReportGroupDetails();
        },
        error: ({ error }) => {
          this.handleCloseModal();
          const { htmlErrorsContent } = this.errorsService.handleErrors(error);
          this.universalModal.openModal(
            undefined,
            null,
            'No se pudo eliminar la sección'
          );
        },
      });
  }

  public handleListElementClickAction(actionName: string, report: any): void {
    this.reportId = report.id;
    const actions: { [key: string]: () => void } = {
      edit: () => this.handleListElEdit(report),
      delete: () => this.confirmDelete(),
    };

    actions[actionName]();
  }

  public handleListElEdit(entity: any) {
    this.router.navigate([
      // `/dashboard/pages/contenidos/${this.reportGroupId}/temas/${entity.id}/editar`,
    ]);
  }

  updateOrder() {
    this.reports.forEach((element: any, index: number) => {
      element.order = index + 1;
    });
  }

  public drop(event: CdkDragDrop<any[]>): void {
    console.log(event);
    moveItemInArray(this.reports, event.previousIndex, event.currentIndex);

    if (event.previousIndex !== event.currentIndex) {
      console.log(this.reports[event.currentIndex]);
      this.reportService
        .updateReportSectionOrder(this.reportGroupId, {
          section_id: this.reports[event.currentIndex].id,
          new_order: event.currentIndex + 1,
        })
        .subscribe((data) => this.getReportGroupDetails());
    }
  }

  public handleCloseModal() {
    this.universalModal.closeModal();
  }
}
