import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-universal-modal',
  templateUrl: './universal-modal.component.html',
  styleUrls: ['./universal-modal.component.scss'],
})
export class UniversalModalComponent implements OnInit, OnDestroy {
  constructor() {}
  @Input() contentTemplate!: TemplateRef<any> | null;
  @Input() contentTemplateCtx!: Object | null;
  @Input() message!: string | null;
  @Output() closeMeEvent = new EventEmitter();
  @Output() confirmEvent = new EventEmitter();
  ngOnInit(): void {
    console.log('Modal init');
  }
  ngOnDestroy(): void {
    console.log(' Modal destroyed');
  }

  closeMe() {
    this.closeMeEvent.emit();
  }
  confirm() {
    this.confirmEvent.emit();
  }

  public isTemplate(): TemplateRef<any> | string {
    if (this.contentTemplate instanceof TemplateRef) {
      return this.contentTemplate as TemplateRef<any>;
    }

    return '';
  }
}
