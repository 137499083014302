import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { ErrorsService } from 'src/app/services/errors.service';
import { PageService } from 'src/app/services/page.service';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CreationalPagesLayoutService } from 'src/app/services/creational-pages-layout.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UniversalModalService } from 'src/app/services/universal-modal.service';
import { Content } from 'src/app/models/content.model'; // Assuming this model exists and replaces theme/subtheme/activity types
import { Page } from 'src/app/models/page.models';

@Component({
  selector: 'app-pages-subthemes-page',
  templateUrl: './pages-subthemes-page.component.html',
  styleUrls: ['./pages-subthemes-page.component.scss'],
})
export class PagesSubthemesPageComponent implements OnInit, OnDestroy {
  contentId!: number;
  themeId!: number;
  subthemeId!: string;
  subthemeDetails!: Content;
  subthemeActivities: Content[] = [];
  isLoading = false;
  private destroyed$ = new ReplaySubject<void>(1);

  public listElementActions = [
    { action: 'edit', icon: '/assets/images/icons/icon_edit_new.svg' },
    { action: 'delete', icon: '/assets/images/icons/icon_delete_new.svg' },
  ];

  @ViewChild('deleteConfirmationModalTemplate')
  deleteConfirmationModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteErrorModalTemplate')
  deleteErrorModalTemplate!: TemplateRef<any>;
  @ViewChild('deleteSuccessModalTemplate')
  deleteSuccessModalTemplate!: TemplateRef<any>;

  private selectedActivityId!: string;

  constructor(
    private errorsService: ErrorsService,
    private pageService: PageService,
    private route: ActivatedRoute,
    private location: Location,
    private title: Title,
    private creationalPagesLayoutService: CreationalPagesLayoutService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private universalModalService: UniversalModalService
  ) {
    this.title.setTitle('Actividades del Subtema | Backoffice - Growth Road');
  }

  ngOnInit(): void {
    this.contentId = Number(this.route.snapshot.paramMap.get('contentId'));
    this.themeId = Number(this.route.snapshot.paramMap.get('themeId'));
    this.subthemeId = this.route.snapshot.paramMap.get('subthemeId')!;

    this.creationalPagesLayoutService.onClickHeaderButton
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.goToCreation();
      });

    this.getSubthemeDetails(this.subthemeId);
    this.getActivitiesBySubthemeId(this.subthemeId);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  navigateToBack(): void {
    this.location.back();
  }

  public goToCreation(): void {
    this.router.navigate([
      `dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/nuevo`,
    ]);
  }

  private getSubthemeDetails(id: number | string): void {
    this.pageService.getPageById(id).subscribe((response) => {
      this.subthemeDetails = response as Content;
      this.creationalPagesLayoutService.layoutConfig.next({
        headerTitle: this.subthemeDetails.internal_name,
        headerTitleIcon: '/assets/images/icons/icon_content.svg',
        headerButtonIcon: 'assets/images/icons/icon_create.svg',
        headerButtonText: 'Crear actividad',
      });

      this.breadcrumbService.pushToUrl({
        id: this.subthemeDetails.id,
        name: this.subthemeDetails.internal_name,
      });
    });
  }

  getActivitiesBySubthemeId(subthemeId: string, disableLoading = false): void {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.pageService.getContentThemes(subthemeId).subscribe({
      next: (data: unknown) => {
        this.subthemeActivities = (data as Content[]).map(
          (element: Content) => ({
            ...element,
            name: element.internal_name ?? '',
            description: element.internal_description ?? '',
          })
        );
        this.isLoading = false;
      },
      error: (errorResponse) => {
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  openDeleteConfirmationModal(activityId: string): void {
    this.selectedActivityId = activityId;
    this.universalModalService.openModal(this.deleteConfirmationModalTemplate);
  }

  deleteActivity(): void {
    this.universalModalService.closeModal();
    this.isLoading = true;
    this.pageService.deletePage(this.selectedActivityId).subscribe({
      next: () => {
        this.universalModalService.openModal(this.deleteSuccessModalTemplate);
        this.getActivitiesBySubthemeId(this.subthemeId);
      },
      error: (errorResponse) => {
        const { htmlErrorsContent } = this.errorsService.handleErrors(
          errorResponse.error
        );
        this.isLoading = false;
        this.universalModalService.openModal(this.deleteErrorModalTemplate);
      },
    });
  }

  closeModal(): void {
    this.universalModalService.closeModal();
  }

  updateOrder(): void {
    this.subthemeActivities.forEach((element, index) => {
      element.order = index + 1;
    });
  }

  public drop(event: CdkDragDrop<Content[]>): void {
    moveItemInArray(
      this.subthemeActivities,
      event.previousIndex,
      event.currentIndex
    );

    if (event.previousIndex !== event.currentIndex) {
      const updatedActivity = {
        ...this.subthemeActivities[event.currentIndex],
        order: event.currentIndex + 1,
      };
      this.pageService.updatePage(updatedActivity as Page).subscribe(() => {
        this.getActivitiesBySubthemeId(this.subthemeId, true);
      });
    }
  }

  public handleListElementClick(activity: Content): void {
    this.creationalPagesLayoutService.layoutConfig.next({
      headerTitle: activity.internal_name,
      headerTitleIcon:
        this.creationalPagesLayoutService.layoutConfig.getValue()
          .headerTitleIcon,
    });
    this.router.navigate([
      `/dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${activity.id}/preguntas`,
    ]);
  }

  public handleListElementClickAction(
    actionName: string,
    activity: Content
  ): void {
    const actions: { [key: string]: () => void } = {
      delete: () => this.openDeleteConfirmationModal(activity.id.toString()),
      edit: () => this.handleListElEdit(activity.id.toString()),
    };

    if (actions[actionName]) {
      actions[actionName]();
    } else {
      console.warn(`Action "${actionName}" is not defined.`);
    }
  }

  private handleListElEdit(id: string): void {
    this.router.navigateByUrl(
      `/dashboard/pages/contenidos/${this.contentId}/temas/${this.themeId}/subtemas/${this.subthemeId}/actividades/${id}/editar`
    );
  }
}
